<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog :visible.sync="showDetail" title="查看详情" :width="`40%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='9'>
        <template #uplpadfaceImg>
          <upload-file ref="Upload" :initFileList="[...initFileList]" v-if="showDetail" :noButton="true" :hideDel="true" />
        </template>
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>
// import { getStockInside } from '@/api'
export default {
  inject: ['labelWidth', 'getInit',],
  data() {
    return {
      showDetail: false,
      detailOptions:
        [
          { label: '业务', key: 'typeValue', col: 22 },
          { label: '发生时间', key: 'occurrenceTime', col: 22 },
          { label: '内容', key: 'content', col: 22 },
          { label: '提交人', key: 'submitter', col: 22 },
          { label: '提交时间', key: 'createTime', col: 22 },
          { type: 'customize', customize: 'uplpadfaceImg', label: '附件', key: 'urlLists', col: 24, alignItems: 'flex-start' }, //attachmentList
        ],
      detailData: {},
      indexObj: {},
      initFileList:[]
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.detailOptions })
  },
  methods: {
    rowDetail(row) {
      this.showDetail = true
      row.occurrenceTime = row.occurrenceTime.slice(0,10)
      let imgArr = row.pictureUrl ? (row.pictureUrl || '').split(',') : ''
      if (imgArr.length > 0) {
        this.initFileList = this.util.backImgVideoObj(imgArr)
      } else {
        this.initFileList = []
      }
      this.detailData = row
    },
  }
}
</script>
<style lang='scss' scoped>
.dateList /deep/ .el-tag {
  margin-right: 15px;
  margin-bottom: 15px;
  &:hover {
    cursor: pointer;
  }
}
</style>