<template>
  <!-- 退款内容 -->
  <ja-table ref="tst" :columns="columns" :border="true" :noSearch="true" :showPage="false" :tableData="tableData" :loading="loading" @getInit="getInit" :isFromForm="true" :showIndex="false">
    <template #dishesName="{row}">
      <div class="box">
        <el-image class="img" :src="row.pictureUrl" fit="fill"></el-image>
        <p class="text">{{row.dishesName}}</p>
      </div>
    </template>
    <template #num="{row}">
      <div v-if="isFromDetail">{{row.num}}</div>
      <el-input-number v-else v-model="row.num" :disabled="row.refundNum == row.orderSumNum " size="mini" :max="row.orderSumNum - row.refundNum" :min='0'></el-input-number>
    </template>
  </ja-table>
</template>
<script>
import table from '@/views/mixins/table'

export default {
  title: '退款订单-弹窗 的 退款内容',
  mixins: [table],
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    isFromDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: [
        { label: '菜式', prop: 'dishesName', attribute: { showOverflowTooltip: false }, width: this.util.setFontSize(200, 1920) + 'px', slotName: 'dishesName' },
        { label: '总份数', prop: 'orderSumNum', },
        { label: '已退份数', prop: 'refundNum' },
        { label: '已退金额', prop: 'refundAmount' },
        { label: '退款份数', prop: 'num', slotName: 'num', },
      ],
      indexObj: {},
      tableData: []
    }
  },
  watch: {
    value:{
      immediate:true,
      handler(val){
        this.tableData = val
      }
    }
  },
  created() {
    this.loading = false
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.box {
  display: flex;
  .img {
    width: 50px;
    border-radius: 10px;
    height: 50px;
    display: block;
    flex-shrink: 0;
  }
  p {
    margin-left: 10px;
    width: 100%;
    text-align: left;
    line-height: 1.5;
  }
}
.el-input-number {
  width: 100%;
  /deep/ .el-input__inner {
    padding-left: 0;
  }
}
</style>