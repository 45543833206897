import http from './plugin/ajax'

// 楼栋管理-
export const addBuilding= (data) => http.post('/building', data)
export const updateBuilding= (data) => http.put('/building', data)
export const delBuilding = (data) => http.delete('/building/' + data)
export const getBuildingList= (data) => http.post('/building/list', data)

// 楼层列表
export const updateFloor= (data) => http.put('/floor', data)
export const getFloorList= (data) => http.post('/floor/list', data)

// 房间管理-房间列表
export const getRoomList= (data) => http.post('/room/list', data)
export const addRoom = (data) => http.post('/room', data)
export const updateRoom= (data) => http.put('/room', data)
export const delRoom = (data) => http.delete('/room/' + data)


// 楼层管理-楼层列表
export const loorList = (data) => http.post('/loor/list', data)
