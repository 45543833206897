<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog :visible.sync="showDetail" title="查看详情" :width="`40%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='9'>
        <!-- <template #receiverName>
          <div class="receiveBox">
            <p>{{detailData.receiverName}}</p>
          </div>
        </template> -->
        <template #content>
          <div class="contentMsgBox" v-html="detailData.content"></div>
        </template>
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>
// import { getStockInside } from '@/api'
export default {
  inject: ['labelWidth', 'getInit',],
  data() {
    return {
      showDetail: false,
      detailOptions:
        [
          { label: '标题', key: 'title', col: 22 },
          // { label: '内容', key: 'content', col: 22 },
          { label: '内容', type: 'customize', customize: 'content', col: 24 },
          // { label: '发送方式', key: 'sendTypeValue', col: 22 },
          { label: '接收人', key: 'receiverNameList', col: 22, },
          { label: '状态', key: 'statusValue', col: 22 },
          { label: '创建人', key: 'createBy' },
          { label: '创建时间', key: 'createTime', col: 22 },
        ],
      detailData: {},
      indexObj: {},
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.detailOptions })
  },
  methods: {
    rowDetail(row) {
      this.showDetail = true
      this.detailData = { ...row }
    },
  }
}
</script>
<style lang='scss' scoped>
.dateList /deep/ .el-tag {
  margin-right: 15px;
  margin-bottom: 15px;
  &:hover {
    cursor: pointer;
  }
}

.receiveBox {
  overflow: auto;
  max-height: 300px;
  // margin-bottom: 3px;
  p {
    max-width: calc(100% - 10px);
  }
}


</style>

<style  lang='scss'>
.contentMsgBox {
  max-height: 500px;
  max-width: 100%;
  overflow-y: auto;
  width: 100%;
  img{
    display: block;
    max-width: 100%;
    margin: 15px auto;
  }
}
</style>