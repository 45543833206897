<template>
  <!-- 消息任务列表 -->
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :editDataFormat="editDataFormat" @rowDetail="normalEvent" :loading="loading" @rowCheck="rowCheck" @append="normalEvent({ref:'addEdit',method:'append'})" @getInit="getInit" @search="search" @reset="reset">
    </ja-table>

    <detail ref="detail" />
    <add-edit ref="addEdit" />
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit'
import Detail from './childComp/Detail.vue'

import { getRefundOrder } from '@/api'

export default {
  title: '退款订单',
  mixins: [table],
  components: {
    AddEdit, Detail
  },
  data() {
    return {
      statusOptions: [
        { label: '审批中', value: 3 },
        { label: '退款通过', value: 4 },
        { label: '退款拒绝', value: 5 },
      ],
      conditionData: [
        { input_type: 'input', key: 'orderCode', attribute: { placeholder: '订单号', size: 'small' } },
        { input_type: 'input', key: 'createBy', attribute: { placeholder: '申请人', size: 'small' } },
        { input_type: 'date', value: 'refundTime', type: 'date', placeholder: '申请日期', valueFormat: 'yyyy-MM-dd' },
        { input_type: 'select', key: 'status', attribute: { placeholder: '退款状态', size: 'small' }, options: [], },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
        { normalBtn: 'add', text: '新增退款', limit:'canteen::refundOrders::add'},
        { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas, rqUrl: '/order/refund/export', rqMethod: 'get' } ,limit:'canteen::refundOrders::download'}
      ],
      columns: [
        { label: '订单号', prop: 'orderCode',width: this.util.setFontSize(250, 1920) + 'px', attribute: { showOverflowTooltip: false, align: 'center' }  },
        { label: '订单总额', prop: 'orderSumCost', },
        { label: '退款金额', prop: 'refundCost' },
        { label: '退款状态', prop: 'statusValue', },
        { label: '申请人', prop: 'createBy' },
        { label: '联系电话', prop: 'mobile' },
        { label: '申请时间', prop: 'createTime', },
        { label: '审批时间', prop: 'updateTime', },
        {
          label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", align: 'center' },
          list: [
            { text: '查看', method: 'rowDetail' },
            { text: '审批', method: 'rowCheck', },
          ]
        }
      ],
      indexObj: {}
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    this.conditionData[this.indexObj.status].options = this.statusOptions
  },
  methods: {
    getInit() {
      this.loading = true
      const rqData = this.rqParmas()
      getRefundOrder(rqData).then(res => {
        this.loading = false
        const { total, data } = res.data
        this.total = total
        this.tableData = data
      })
    },
    rowCheck(row, cell, btn) {
      this.$refs.detail.rowDetail(row, null, btn)
    },
    editDataFormat(row, btn) {
      if (row.status != 3 && (btn.text == '审批')) {
        return false
      } 
      return true
    }
  }
}
</script>
