import http from './plugin/ajax'

/*
    菜单管理
*/

// 查询菜单列表
export const listMenu = (data) => http.post('/sys/menu/query', data)

// 根据角色ID查询菜单下拉树结构
export const roleMenuTreeselect = (data) => http.get('/system/menu/roleMenuTreeselect/', { params: data })

// 查询菜单下拉树结构
export const rqtreeselect = (data) => http.post('/system/menu/treeselect', { params: data })
// 新增菜单
export const addMenu = (data) => http.post('/sys/menu', data)
// 修改菜单
export const updateMenu = (data) => http.put('/sys/menu', data)
// 删除菜单
export const delMenu = (data) => http.delete(`/sys/menu/${data}`)

/* 人员管理 */
// 更新人员列表
export const editEmployeeItem = (data) => http.post('/sys/employee/permission', data)

// ****** 用户管理
// 查询所有角色权限
export const getAllRole = (data) => http.post('/sys/role/all', data)

// ****** 数字字典
// 字典列表
export const dictionMangerList = (data) => http.post('/sys/dictData/query', data)
// 新增字典
export const aaddDiction = (data) => http.post('/sys/dictData', data)
// 编辑字典
export const editDiction = (data) => http.put('/sys/dictData', data)
// 删除字典
export const delDiction = (data) => http.delete('/sys/dictData/' + data)
// 获取字典
export const getDictData = (data) => http.get('/sys/dictData/' + data)

// ****** 参数设置
// 查询 参数列表
export const rqParameterList = (data) => http.post('/sys/config/query', data)
// 新增 参数
export const addParameter = (data) => http.post('/sys/config', data)
// 编辑 参数
export const editParameter = (data) => http.put('/sys/config', data)
// 删除 参数
export const delParameter = (v) => http.delete('/sys/config', { data: v })


/* 系统设置--权限管理 */
/* 角色管理 */
// 获取角色
export const getRole = (data) => http.post('/sys/role/query', data)
// 新增角色
export const roleAdd = (data) => http.post('/sys/role', data)
// 编辑角色
export const roleEdit = (data) => http.put('/sys/role', data)
// 状态修改
export const changeRoleStatus = (data) => http.put('/system/role/changeStatus', data)
// 删除角色
export const delRole = (data) => http.delete('/sys/role/' + data)
// 获取所有角色权限列表
export const allRoleTypeList = (data) => http.post('/system/role/optionselect', data)
// 获取某个角色的菜单信息树
export const rqRoleTreeList = (data) => http.post('/sys/menu/roleMenu', data)
// 修改某个角色的菜单权限
export const modifyRoleTree = (data) => http.post('/sys/role/authorization', data)
// 角色绑定用户列表
export const getRelationList = (data) => http.post('/sys/role/relation/list', data)
// 删除角色绑定用户
export const delRelation = (data) => http.delete('/sys/role/relation', {data:data})
// 角色绑定用户
export const addRelation = (data) => http.post('/sys/role/relation', data)
 // 修改密码
export const resetPassword = (data) => http.post('/sys/user/resetPassword', data)
//  个人中心 -- 上传图片
export const userPersonUpload = (data) => http.put('/sys/user/headPortrait', data)

// 查询角色详细
export function getRoleId (roleId) {
  return http({
    url: '/system/role/' + roleId,
    method: 'get'
  })
}
// 根据角色ID查询部门树结构
export function roleDeptTreeselect (roleId) {
  return http({
    url: '/system/dept/roleDeptTreeselect/' + roleId,
    method: 'get'
  })
}
// 角色数据权限
export function dataScope (data) {
  return http({
    url: '/system/role/dataScope',
    method: 'put',
    data: data
  })
}


/* 系统日志 */
// 登录日志
export const getloginInfor = (data) => http.get('/system/logininfor/list', { params: data })

