<template>
  <!-- 消息发送记录 -->
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" @rowDetail="normalEvent" @getInit="getInit" @search="search" @reset="reset">
       <template #content="{row}">
        <div class="table_content">
          {{row.contentText }}
        </div>
      </template>
    </ja-table>
    <!-- 查看详情 -->
    <detail ref="detail" />
  </div>
</template>
<script>
import { getMsgSendList } from '@/api'
import table from '@/views/mixins/table'
import Detail from './childComp/Detail.vue'

export default {
  title: '消息发送记录',
  mixins: [table],
  components: {
    Detail
  },
  data() {
    return {
      statusOptions: [
        { label: '发送中', value: 0 },
        { label: '发送成功', value: 1 },
        // { label: '审批拒绝', value: 2 },
        { label: '发送失败', value: 2 },
      ],
      conditionData: [
        { input_type: 'input', key: 'title', attribute: { placeholder: '消息标题', size: 'small' } },
        { input_type: 'input', key: 'content', attribute: { placeholder: '消息内容', size: 'small' } },
        { input_type: 'select', key: 'status', attribute: { placeholder: '状态', size: 'small' }, options: [], styleWidth: '100px' },
        // { input_type: 'select', key: 'method', attribute: { placeholder: '发送方式', size: 'small' }, options: [], styleWidth: '100px' },
        { input_type: 'input', key: 'receiverName', attribute: { placeholder: '接收人', size: 'small' } },
        { input_type: 'input', key: 'senderName', attribute: { placeholder: '发送人', size: 'small' } },
        { input_type: 'date', value: 'createTime', type: 'date', placeholder: '发送日期', valueFormat: 'yyyy-MM-dd', },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
        { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas, rqUrl: '/msgLogManager/export', }, limit:'msg::msgSendRecord::download'  }
      ],
      columns: [
        { label: '标题', prop: 'title', attribute: { showOverflowTooltip: true, align: 'center' } },
        // { label: '内容', prop: 'content', attribute: { showOverflowTooltip: true, align: 'center' },  },
        { label: '内容', prop: 'contentText', width: 250, attribute: { showOverflowTooltip: true, align: 'center' }, slotName: 'content' },
        // { label: '接收人', prop: 'receiverName' },
        // { label: '接收状态', prop: 'readStatusValue' },
        { label: '状态', prop: 'status' },
        { label: '已读/发送消息总数', prop: 'sendNumCompare',  },
        { label: '创建人', prop: 'createBy' },
        { label: '创建时间', prop: 'createTime',},
        {
          label: '操作', input_type: 'button', width: 120, attribute: { fixed: "right", align: 'center' },
          list: [
            { text: '查看', method: 'rowDetail' },
          ]
        },
      ],
      indexObj: {}
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    this.conditionData[this.indexObj.status].options = this.statusOptions
    // this.util.rqOptionsList({ who: [this.conditionData[this.indexObj.method]], rqUrl: "/sys/dictData/message_type", resRule: 'data' })
  },
  methods: {
    getInit() {
      this.loading = true
      const rqData = this.rqParmas()
      getMsgSendList(rqData).then(res => {
        this.loading = false
        const { total, data } = res.data
        this.total = total
        this.tableData = (data || []).map(item => {
          let content = item.content
          var arrEntities={'lt':'<','gt':'>','nbsp':' ','amp':'&','quot':'"'}; 
          item.contentText = content.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ').replace(/&(lt|gt|nbsp|amp|quot);/ig,function(all,t){return arrEntities[t];})
                                .replace(/&ldquo;/g,'“').replace(/&rdquo;/g,'”').replace(/&mdash;/g,'——')
          return item
        })
      })
    }
  }
}
</script>
