<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog :visible.sync="showDetail" title="查看详情" :width="`50%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='9'>
        <template #dateList>
            <div  v-if="dateList.length>0" class="dateList">
                <el-tag v-for="(item,index) in dateList" :key="index" :effect="activeDate == item?'dark':'plain'" @click="changeDate(item)">
                    {{ item}}
                </el-tag>
            </div>
        </template>
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>
import { getStockInside } from '@/api'
export default {
  inject: ['labelWidth', 'getInit',],
  data() {
    return {
      showDetail: false,
      detailOptions:
        [
          { label: '采购单号', key: 'purchaseCode', },
          { label: '关联计划编号', key: 'planCode', col: 24 },
          { label: '计划人信息', key: 'nickname', col: 24 },
          { label: '计划人电话', key: 'mobile', },
          {
            type: 'table', label: '采购明细', noSearch: true, showPage: false, key: 'detailList', col: 24, table_MaxHeight: 500,
            columns: [
              { label: '材料名称', prop: 'foodName', attribute: { showOverflowTooltip: false, } },
              { label: '单价（元）', prop: 'cost', },
              { label: '计划数量', prop: 'num', },
              { label: '单位', prop: 'unit', },
              { label: '采购金额', prop: 'purchaseAmount', },
              { label: '已入库数量', prop: 'insideNum', },
              { label: '未入库数量', prop: 'notSupplyNum', },
            ]
          },
          { label: '总采购价', key: 'totalCost', },
          { label: '供应商', key: 'supplier', },


          { isTitle: true, label: '来货信息', col: 24, noMarginBottom: true, },
          { type: 'customize', customize: 'dateList', label: '日期选择', key: 'urlLists', col: 22, alignItems: 'flex-start' }, //attachmentList
          {
            type: 'table', label: '来货明细', noSearch: true, showPage: false, key: 'goodsInfo', col: 24, table_MaxHeight: 500, 
            columns: [
              { label: '材料名称', prop: 'foodName', attribute: { showOverflowTooltip: false, } },
              { label: '数量', prop: 'num', },
              { label: '单位', prop: 'unit', },
            ]
          },
        ],
      detailData: {},
      indexObj: {},

      dateList: [],  // 来货明细的日期
      dateObjDetail: {},
      activeDate:'',
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.detailOptions })
  },
  methods: {
    rowDetail(row) {
      this.showDetail = true
      let obj = { ...row ,goodsInfo:[]}
      obj.detailList = row.detailList
      this.dateList = []

      this.$set(this.detailOptions[this.indexObj.supplier], 'hide', !row.planCode)
      this.$set(this.detailOptions[this.indexObj.planCode], 'hide', !row.planCode)

      this.detailData = obj
      this.getStockInside(row.purchaseCode)

    },
    getStockInside(purchaseCode) {
      getStockInside({ params: { pageSize: 200, pageIndex: 1, purchaseCode: purchaseCode } }).then(res => {
        let obj = {}
        res.data.data.forEach(item => {
          this.dateList.push(item.date)
          obj[item.date] = item.detailList
        });
        this.dateObjDetail = obj
        this.changeDate(this.dateList[0])
        // console.log('this.dateList===>',this.dateList,this.dateObjDetail,this.detailData)
      })
    },
    changeDate(date){
        this.activeDate = date
        let list = this.dateObjDetail[date]||[]
        this.$set(this.detailData,'goodsInfo',list)
    }
  }
}
</script>
<style lang='scss' scoped>
.dateList /deep/ .el-tag{
  margin-right: 15px;
  margin-bottom: 15px;
  &:hover{
    cursor: pointer;
  }
}
</style>