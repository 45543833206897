import http from './plugin/ajax'

// 社区管理-添加
export const addPark= (data) => http.post('/park', data)
export const getParkList= (data) => http.post('/park/list', data)
export const updatePark= (data) => http.put('/park', data)
export const delPark = (data) => http.delete('/park/' + data)
export const detailPark = (data) => http.get('/park/' + data)
export const getDepartment = (data) => http.get('/department', {params: data})

// 已指派管理员
export const getAdminList = (data) => http.post('/park/admin/list', data);
export const delAdminList = (data) => http.delete('/park/admin', {data:data})
export const getDepTree = (data) => http.get('/department/tree', {params: data}) // 部门树
export const getUserInfo = (data) => http.get('/employee', {params: data}) // 部门树
export const addParkAdmin = (data) => http.post('/park/admin', data) // 部门树
