<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @rowSend="rowSend" :editDataFormat="editDataFormat" @getInit="getInit" @search="search" @reset="reset" @append="normalEvent({ref:'addEdit',method:'append'})" @rowDetail="rowDetail" @rowApproval="rowApproval"></ja-table>
    <!-- 新增 弹窗 -->
    <add-edit ref="addEdit" />
    <!-- 查看/审批 -->
    <detail ref="detail" />
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit/AddEdit.vue'
import Detail from './childComp/Detail/Detail.vue'
import { rqVisitorApplyList , reSendMsgVisitor} from '@/api'

export default {
  title: "访客申请列表",
  mixins: [table],
  components: { AddEdit, Detail },
  name: 'index',
  data() {
    return {
      conditionData: [
        { input_type: 'input', key: 'applyPeople', attribute: { placeholder: '申请人姓名', } },
        { input_type: 'input', key: 'dockingPeople', attribute: { placeholder: '对接人姓名', } },
        {
          input_type: 'select', key: 'status', attribute: { placeholder: '状态', }, options: [
            { label: '审批中', value: '2' },
            { label: '审批通过', value: '3' },
            { label: '审批拒绝', value: '4' },]
        },
        { input_type: 'select', key: 'applyType', attribute: { placeholder: '申请类型', }, options: [{ label: '内部邀约', value: '0' }, { label: '用户预约', value: '1' },] },
        { input_type: 'select', key: 'visitorType', attribute: { placeholder: '业务类型', }, options: [] },
        { input_type: 'date', type: 'daterange', value: 'applyTime', startPlaceholder: '申请开始日期', endPlaceholder: '申请结束日期', valueFormat: 'yyyy-MM-dd' },
        { input_type: 'date', type: 'daterange', value: 'visitorTime', startPlaceholder: '来访开始日期', endPlaceholder: '来访结束日期', valueFormat: 'yyyy-MM-dd' },

        { normalBtn: 'search', },
        { normalBtn: 'reset', },
        { normalBtn: 'add', limit: 'visitor::apply::add' },
        { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas2, rqUrl: '/visitor/apply/export', rqMethod: 'post' }, limit: 'visitor::apply::download' }
      ],
      columns: [
        { label: '访客单号', prop: 'applyCode', width: 200, attribute: { showOverflowTooltip: true, align: 'center' } },
        { label: '申请人姓名', prop: 'applyPeople', },
        { label: '申请人公司', prop: 'applyCompany', width: 150, attribute: { showOverflowTooltip: true, align: 'center' } },
        { label: '业务类型', prop: 'visitorTypeName' },
        { label: '申请类型', prop: 'applyTypeName' },
        { label: '状态', prop: 'statusName', width: 130 },
        { label: '接待区域', prop: 'receptionArea', width: 150, attribute: { showOverflowTooltip: true, align: 'center' } },
        { label: '事由', prop: 'remark', width: 150, attribute: { showOverflowTooltip: true, align: 'center' } },
        { label: '来访时间', prop: 'visitorTime', width: 280, attribute: { showOverflowTooltip: true, align: 'center' } },
        { label: '对接人', prop: 'dockingPeople', },
        { label: '申请时间', prop: 'createTime', width: 160 },
        {
          label: '操作', input_type: 'button', width: 160,
          list: [
            { text: '查看', method: 'rowDetail', },
            { text: '重发消息', method: 'rowSend', limit: 'visitor::apply::resendMsg' },
            { text: '审批', method: 'rowApproval', limit: 'visitor::apply::approval' },
          ]
        }
      ],
      tableData: [],
      conditionData_indexObj: {},
    }
  },
  mounted() {
    this.conditionData_indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    let detailComponent = this.$refs.detail
    let addEditComponent = this.$refs.addEdit
    this.util.rqOptionsList({
      who: [
        this.conditionData[this.conditionData_indexObj.visitorType],
        // detailComponent.rowFormData[detailComponent.rowFormData_indexObj.visitorType],
        addEditComponent.rowFormData[addEditComponent.indexObj.visitorType]
      ], rqUrl: "/sys/dictData/visitor_type", resRule: 'data'
    })
  },
  methods: {
    editDataFormat(row, btn) {
      if ((row.status != 2) && btn.text == '审批') {
        return false
      }else if(( row.status != 3 || row.applyType != 0) && btn.text == '重发消息') {
        return false
      }else {
        return true
      }
    },
    rqParmas2() {
      let obj = this.rqParmas()
      if (obj.visitorTime) {
        obj.visitorTimeBegin = obj.visitorTime[0]
        obj.visitorTimeEnd = obj.visitorTime[1]
        delete obj.visitorTime
      }
      if (obj.applyTime) {
        obj.applyStartTime = obj.applyTime[0]
        obj.applyEndTime = obj.applyTime[1]
        delete obj.applyTime
      }
      return obj
    },
    getInit() {
      this.loading = true
      let obj = this.rqParmas2()
      rqVisitorApplyList(obj).then(res => {
        this.tableData = res.data.data.length > 0 ? res.data.data.map(item => {
          let start = item.visitorTimeBegin.slice(0, -3)
          let end = item.visitorTimeEnd.slice(0, -3)
          let visitorTime = `${start} 至 ${end}`
          return {
            ...item,
            visitorTime
          }
        }) : []
        this.total = res.data.total
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    rowDetail(row) {
      this.$refs.detail.rowDetail({ ...row, formType: 'detail' })
    },
    rowApproval(row) {
      this.$refs.detail.rowDetail({ ...row, formType: 'edit' })
    },
    // 重发消息
    rowSend(row) {
      this.$confirm(`是否确认重发访客单号为【${row.applyCode}】的消息？`).then(res => {
        reSendMsgVisitor(row.id).then(res => {
          this.$message.success("已发送")
          this.getInit()
        })
      })
    },
  }
}
</script>