<template>
  <!--  结单 -->
  <el-dialog :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" title="审批">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll"></ja-form>
  </el-dialog>
</template>
<script>
import { auditMsgTask } from '@/api'
export default {
  inject: ['labelWidth', 'getInit',],
  data() {
    return {
      rowFormData: [
        { input_type: 'radio', label: '审批结果', key: 'status', col: 22, options: [{ label: '同意', value: 1 }, { label: '驳回', value: 2 }] },
        { input_type: 'input', label: '审批备注', key: 'remark', type: 'textarea', col: 22, rows: 5 },
      ],
      rowForm: {},
      rules: {
        status: [{ required: true, message: '请选择审批结果', trigger: 'change' }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      rowFormData_indexObj: {},
    }
  },
  created() {
    this.rowFormData_indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
  },

  methods: {
    append(row,) {
      this.rowForm = {
        id: row.id
      }
      this.showEditAll = true
    },
    saveForm() {
      console.log('要提交的表单', this.rowForm, this.rqMethod)
      this.loadingForm = true
      auditMsgTask(this.rowForm).then(res => {
        this.loadingForm = false
        this.$message.success('提交成功')
        this.showEditAll = false
        this.getInit()
      }).catch(error => {
        this.loadingForm = false
      })
    },
  }
}
</script>