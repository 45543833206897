import Vue from 'vue'

const focus=Vue.directive('el-select-loadmore',{

      bind:function(el, binding) {
        // console.log()
          // 获取element-ui定义好的scroll盒子
          const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
          SELECTWRAP_DOM.addEventListener('scroll', function () {
              /**
              * scrollHeight 获取元素内容高度(只读)
              * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
              * clientHeight 读取元素的可见高度(只读)
              * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
              * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
              */
              const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
              if (condition) {
                  binding.value();
              }
          });
      }

})
export {focus}