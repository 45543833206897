<template>
  <router-view />
</template>

<script>
export default {
  title: "资讯",
  name: 'merchant'
}
</script>

<style scoped>
</style>
