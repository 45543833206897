<template>
  <div class="content">
    <el-row :gutter="20">
      <el-col :span="8">
        <videoTree
          @handleNodeClick="handleNodeClick"
          categoryType="2"
        ></videoTree>
        <div class="calendar">
          <el-calendar v-model="dateValue">
            <template
              slot="dateCell"
              slot-scope="{date, data}"
            >
              <p
                :class="data.isSelected ? 'is-selected' : ''"
                @click="clickCalendar(data,date)"
              >
                {{ data.day.split('-').slice(1)[1] }} {{ data.isSelected ? '✔️' : ''}}
              </p>
            </template>
          </el-calendar>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="video_box">
          <div id="player"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import videoTree from '../components/videoTree/videoTree.vue'
import { monitorView } from '@/api'
// import '@/assets/js/h5player.min.js'
const IS_MOVE_DEVICE = document.body.clientWidth < 992
export default {
  title: '视频监控',
  name: 'monitorVideo',
  components: {
    videoTree
  },
  data() {
    return {
      dateValue: new Date(),
      currId: '',
      currDate: '',
      player: null,
      urls: {
        realplay: ''
      },
      mode: 1
    }
  },
  computed: {},
  watch: {
    dateValue(newVal) {
      // console.log('你好----', new Date(newVal).Format('yyyy-MM-dd'))
    }
  },
  mounted() {
    console.log('JSPlugin----- window.', window.JSPlugin)
    this.init()
    this.createPlayer()
  },
  methods: {
    clickCalendar(data, date) {
      console.log(1515, data)
      this.currDate = data.day
      this.monitorView()
    },
    // 点击树
    handleNodeClick(data, date = '') {
      if (!data.children || data.children.length == 0 && data.level==3) {
        this.currId = data.id
        this.monitorView()
      }
    },
    monitorView() {
      monitorView({
        deviceId: this.currId,
        protocol: "ws"
      }).then((res) => {
        this.realplay(res.data)
      })
    },
    init() {
      // 设置播放容器的宽高并监听窗口大小变化
      window.addEventListener('resize', () => {
        this.player.JS_Resize()
      })
    },
    createPlayer() {
      this.player = new window.JSPlugin({
        szId: 'player',
        szBasePath: './js',
        iMaxSplit: 1,
        iCurrentSplit: IS_MOVE_DEVICE ? 1 : 2,
        openDebug: true,
        oStyle: {
          borderSelect: IS_MOVE_DEVICE ? '#000' : '#FFCC00'
        }
      })

      // 事件回调绑定
      this.player.JS_SetWindowControlCallback({
        windowEventSelect: function (iWndIndex) {
          //插件选中窗口回调
          console.log('windowSelect callback: ', iWndIndex)
        },
        pluginErrorHandler: function (iWndIndex, iErrorCode, oError) {
          //插件错误回调
          console.log('pluginError callback: ', iWndIndex, iErrorCode, oError)
        },
        windowEventOver: function (iWndIndex) {
          //鼠标移过回调
          //console.log(iWndIndex);
        },
        windowEventOut: function (iWndIndex) {
          //鼠标移出回调
          //console.log(iWndIndex);
        },
        windowEventUp: function (iWndIndex) {
          //鼠标mouseup事件回调
          //console.log(iWndIndex);
        },
        windowFullCcreenChange: function (bFull) {
          //全屏切换回调
          console.log('fullScreen callback: ', bFull)
        },
        firstFrameDisplay: function (iWndIndex, iWidth, iHeight) {
          //首帧显示回调
          console.log(
            'firstFrame loaded callback: ',
            iWndIndex,
            iWidth,
            iHeight
          )
        },
        performanceLack: function () {
          //性能不足回调
          console.log('performanceLack callback: ')
        }
      })
    },
    /* 预览&对讲 */
    realplay(playURL) {
      let { player, urls, mode } = this,
        index = player.currentWindowIndex
      // playURL = urls.realplay
      player.JS_Play(playURL, { playURL, mode }, index).then(
        () => {
          console.log('realplay success')
        },
        (e) => {
          console.error(e)
        }
      )
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  overflow-x: hidden;
  .calendar {
    width: 100%;
    margin: 18px;
  }
  .is-selected {
    color: #1989fa;
  }
  .video_box {
    background: #fff;
    position: relative;
    width: 96%;
    margin-top: 20px;
    margin-left: 24px;
    height: 800px;
  }
  /deep/ .el-calendar-table .el-calendar-day {
    height: 2.6vw;
  }
  #player {
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translateX(-50%);
  }
  @media screen and (max-width: 991px) {
    #player {
      width: calc(100vw - 16px);
      height: calc((100vw - 16px) * 5 / 8);
    }
  }
  @media screen and (min-width: 992px) {
    #player {
      width: calc(50vw - 8px);
      height: calc((50vw - 8px) * 5 / 8);
    }
  }
}
</style>
