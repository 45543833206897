export const deviceListQueryMap = {category: 0, subcategory: 1}
import util from '@/util/util.js'
/** 设备列表页面表头栏 */
export const deviceListColumn = [
  { label: '设备备注', prop: 'deviceName',attribute:{showOverflowTooltip:true,align:'center'} },
  { label: '设备编码', prop: 'deviceCode' },
  { label: '设备大类', prop: 'categoryName' },
  { label: '设备小类', prop: 'subcategoryName' },
  { label: '所属楼栋', prop: 'buildingName', width: 80 },
  { label: '所属楼层', prop: 'floorName', width: 80 },
  { label: '设备状态', prop: 'runStatus', width: 80 },
  { label: '设备品牌', prop: 'brand' },
  { label: '设备型号', prop: 'model' },
  { label: '倍率', prop: 'magnification' },
  { label: '计费类型', prop: 'billingType' },
  { label: '检测时间', prop: 'monitorTime', attribute: { showOverflowTooltip: true, align: 'center' } },
  {
    label: '操作',
    input_type: 'button',
    width: 100,
    attribute: { fixed: "right", align: 'center' },
    list: [{ text: '编辑', method: 'rowEdit',limit:'device::manage::update' }, { text: '删除', method: 'rowDelete',limit:'device::manage::del' }]// limit: 'admin::user::delete'
  }
]

export const deviceListFormDataMap = {category: 1, subcategory: 2, buildingId: 3, floorId: 4, roomId: 5}

/** 设备列表页面新增/编辑校验规则 */
export const deviceListRules = {
  deviceName: [{ required: true, message: '请输入设备备注', trigger: 'blur' }],
  category: [{ required: true, message: '请选择设备大类', trigger: 'change' }],
  subcategory: [{ required: true, message: '请选择设备小类', trigger: 'change' }],
  buildingId: [{ required: true, message: '请选择所属楼栋', trigger: 'change' }],
  magnification:[
    {
      required: true,
      trigger: 'blur',
      validator:util.formRules.checkNumber({ min: 0, fixedNum: 1 }),
    }
  ],
  billingType:[{
    required:true,
    message:"请选择计费类型",
    trigger:"change"
  }]
}