<template>
  <!-- 报修工单列表 -->
  <div class="allwh">
    <ja-table ref="tst" :showSelect="true" @handleSelectionChange="handleSelectionChange" v-model="filterData" :conditionData="conditionData" :editDataFormat="editDataFormat" :columns="columns" :tableData="tableData" :total="total" :loading="loading" @assignPerson="assignPerson" @append="normalEvent({ref:'addEdit',method:'append'})" @rowEdit="normalEvent" @getInit="getInit" @search="search" @reset="reset" @rowAssign="rowAssign" @rowDeal="rowDeal" @rowRefuse="rowRefuse" @rowDetail="normalEvent" @rowDealFinsh="rowDealFinsh">
    </ja-table>
    <detail ref="detail" />
    <!-- 新增 -->
    <add-edit ref="addEdit" />
    <!-- 指派工单 -->
    <assign-repair-person :isShowAssign.sync="isShowAssign" @finishAssign="finishAssign" />
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit'
import assignRepairPerson from './childComp/assignRepairPerson.vue'
import Detail from './childComp/Detail.vue'
import { getRepairManager, updateStatusRepair } from '@/api'
export default {
  title: '报修工单列表',
  mixins: [table],
  components: {
    AddEdit, assignRepairPerson, Detail
  },
  data() {
    return {
      conditionData: [
        { input_type: 'input', key: 'deviceCode', attribute: { placeholder: '设备编号', size: 'small' } },
        { input_type: 'input', key: 'deviceName', attribute: { placeholder: '设备名称', size: 'small' } },
        // { input_type: 'select', key: 'deviceType', attribute: { placeholder: '设备分类', size: 'small' }, options: [], styleWidth: '100px' },
        { input_type: 'select', key: 'deviceBrand', attribute: { placeholder: '品牌', size: 'small' }, options: [], styleWidth: '100px' },
        { input_type: 'input', key: 'deviceModel', attribute: { placeholder: '型号', size: 'small' } },
        {
          input_type: 'select', key: 'status', attribute: { placeholder: '状态', size: 'small' }, styleWidth: '100px', options: [
            { label: '创建', value: 0 },
            { label: '维修中', value: 1 },
            { label: '完成', value: 2 },
            { label: '拒绝', value: 3 },
          ]
        },
        { input_type: 'input', key: 'handlerName', attribute: { placeholder: '维修人', size: 'small' } },
        { input_type: 'date', value: 'createTime', type: 'date', placeholder: '创建日期', valueFormat: 'yyyy-MM-dd' },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
        { normalBtn: 'add', text: '新增报修工单', limit:'deviceAlarms::repair::add'},
        { text: '指派工单', input_type: 'button', method: 'assignPerson', size: 'small', attribute: { type: 'info', icon: 'el-icon-user', plain: true }, limit:'deviceAlarms::repair::assign'},
        { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas, rqUrl: '/repairManager/export', },limit:'deviceAlarms::repair::download' },
      ],
      columns: [
        { label: '工单号', prop: 'workNo', width: 150, attribute: { showOverflowTooltip: false, } },
        { label: '设备编号', prop: 'deviceCode', width: 130, attribute: { showOverflowTooltip: false, } },
        { label: '设备名称', prop: 'deviceName', },
        // { label: '类型', prop: 'repairType', },
        { label: '位置', prop: 'installLocation', width: 150, },
        { label: '状态', prop: 'statusValue', width: 130, },
        { label: '设备状态', prop: 'deviceStatus', },
        // { label: '费用（元）', prop: 'cost', width: 120, },
        // { label: '预计工时', prop: 'workTime', },
        { label: '维修人', prop: 'handlerName', },
        { label: '工单创建时间', prop: 'createTime', width: 150, },
        {
          label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", align: 'center', showOverflowTooltip: false, },
          list: [
            { text: '编辑', method: 'rowEdit', limit:'deviceAlarms::repair::edit'},
            { text: '指派', method: 'rowAssign', limit:'deviceAlarms::repair::assign' },
            { text: '处理', method: 'rowDeal', limit:'deviceAlarms::repair::deal'},
            { text: '拒绝', method: 'rowRefuse', limit:'deviceAlarms::repair::refuse'},
            { text: '查看', method: 'rowDetail', },
            { text: '处理完成', method: 'rowDealFinsh', limit:'deviceAlarms::repair::finish'},
          ]
        },
      ],
      indexObj: {},
      selectionIds: [],
      isShowAssign: false,
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    const params = { params: { pageIndex: 1, pageSize: 200, isAll: 2 } }
    // this.util.rqOptionsList({ who: [this.conditionData[this.indexObj.deviceType]], rqUrl: "/device/category", resRule: 'data.data', rqData: params, labelName: 'categoryName', valueName: 'id' })
    this.util.rqOptionsList({ who: [this.conditionData[this.indexObj.deviceBrand]], rqUrl: "/sys/dictData/device_brand_type", resRule: 'data' })
  },
  methods: {
    handleSelectionChange(val) {
      const ids = []
      val.forEach(item => {
        ids.push(item.id)
      })
      this.selectionIds.splice(0, this.selectionIds.length, ...ids)
    },
    //   指派工单
    assignPerson() {
      if (this.selectionIds.length == 0) {
        this.$message.warning('请先选择要指派的工单')
        return
      }
      this.isShowAssign = true
    },
    finishAssign() {
      this.$refs.tst.$refs.jaTable.clearSelection()
      this.selectionIds.splice(0, this.selectionIds.length,)
      this.isShowAssign = false
      this.getInit()
    },
    getInit() {
      this.loading = true
      const rqData = this.rqParmas()
      getRepairManager(rqData).then(res => {
        const { total, data } = res.data
        this.total = total
        this.tableData = data
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    editDataFormat(row, btn) {
      if (row.status != 0 && (btn.text == '编辑' || btn.text == '处理' || btn.text == "拒绝")) {
        return false
      } else if (row.status != 0 && row.status != 1 && btn.text == '指派') {
        return false
      } else if (row.status != 1 && btn.text == '处理完成') {
        return false
      } else {
        return true
      }
    },
    // 指派工单
    rowAssign(row) {
      this.$refs.tst.$refs.jaTable.clearSelection()
      this.$refs.tst.$refs.jaTable.toggleRowSelection(row, true)
      this.selectionIds = [row.id]
      this.isShowAssign = true
    },
    // 处理
    rowDeal(row) {
      this.$confirm(`是否确认处理工单“${row.workNo}”`).then(res => {
        updateStatusRepair({ id: row.id, status: 1 }).then(res => {
          this.$message.success("已处理")
          this.getInit()
        })
      })
    },
    // 拒绝
    rowRefuse(row) {
      this.$confirm(`是否拒绝维修该工单“${row.workNo}”`).then(res => {
        updateStatusRepair({ id: row.id, status: 3 }).then(res => {
          this.$message.success("已拒绝")
          this.getInit()
        })
      })
    },
    // 处理完成
    rowDealFinsh(row) {
      this.$confirm(`是否确认已完成该工单“${row.workNo}”的维修`).then(res => {
        updateStatusRepair({ id: row.id, status: 2 }).then(res => {
          this.$message.success("已完成维修")
          this.getInit()
        })
      })
    }
  }
}
</script>
