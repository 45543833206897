import http from './plugin/ajax'

// =========  访客模块  ===========

// 访客黑名单
export const getVisitorBlackList = (data) => http.post('/visitor/black/list', data)   // 查询
export const addVisitorBlack = (data) => http.post('/visitor/black', data)   // 新增
export const delVisitorBlack = (data) => http.delete('/visitor/black/' + data)   // 删除

// 访客通行记录
export const getVisitorPassRecord = (data) => http.post('/visitor/record/list', data)   // 查询


// 访客申请列表
export const checkVistorBlack = (data) => http.post('/visitor/apply/validation', data)   // 检验访客黑名单
export const rqVisitorApplyList = (data) => http.post('/visitor/apply/list', data)   // 查询
export const addVisitorApply = (data) => http.post('/visitor/apply', data)   // 新增
export const rqVisitorApplyItem = (data) => http.get('/visitor/apply/' + data)   // 查询单个
export const approvalVisitorApply = (data) => http.get('/visitor/apply/audit', { params: data })   // 访客审批
export const reSendMsgVisitor = (data)=> http.get('/visitor/apply/resendSms/' + data)  // 重发消息


// 访客申请统计
export const rqVistorCountList = (data) => http.post('/visitor/apply/count/list', data)   // 查询