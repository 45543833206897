<template>
  <!--  普通的表单 -->
  <el-dialog :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" title="新增黑名单">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll">
    </ja-form>
  </el-dialog>
</template>
<script>
import { addVisitorBlack } from '@/api'

export default {
  inject: ['labelWidth', 'getInit',],
  data() {
    return {
      rowFormData: [
        { input_type: "input", label: '访客姓名', key: 'name' },
        { input_type: "input", label: '访客电话', key: 'phone' },
        { input_type: "input", label: '访客公司', key: 'dept' },
        { input_type: "radio", label: '类型', key: 'type', options: [], col: 24 },
        { input_type: 'input', label: '备注', key: 'remark', type: 'textarea', col: 22, rows: 5 },
      ],
      rowForm: {},
      rules: {
        name: [{ required: true, message: '请输入访客姓名', trigger: 'blur' }],
        phone: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkPhone() }],
        dept: [{ required: true, message: '请输入访客公司', trigger: 'blur' }],
        type: [{ required: true, message: '请选择类型', trigger: 'change' }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗
    }
  },
  created() {

  },
  methods: {
    append() {
      this.isAdd = true
      this.rowForm = {}
      this.showEditAll = true
    },
    saveForm() {
      this.loadingForm = true
      addVisitorBlack(this.rowForm).then(res => {
        this.loadingForm = false
        this.$message.success('新增成功')
        this.showEditAll = false
        this.getInit()
      }).catch(error => {
        this.loadingForm = false
      })
    }
  }
}
</script>