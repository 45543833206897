<template>
  <!-- 菜单管理 -->
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" rowKey="menuId" :showPage="false" :treeProps="{children: 'children', hasChildren: 'hasChildren'}" :total="total" :loading="loading" :showIndex="false" @handleSelectionChange="handleSelectionChange" @handleUpdate="handleUpdate" @handleDelete="handleDelete" @handleAdd="handleAdd" @getInit="getInit" :valueFormat="formatter" @search="search" @reset="reset">
      <template #icon="{row}">
        <svg-icon :icon-class="row.icon !== null ? row.icon : ''" class="svgIcon" />
      </template>
    </ja-table>

    <!-- 添加或修改菜单对话框 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="showEditAll" width="55%" append-to-body :title="title" class="window">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px" v-loading="loading_form.isShow" :element-loading-text="loading_form.text" :element-loading-spinner="loading_form.icon" :element-loading-background="loading_form.bg" :element-loading-custom-class="loading_form.formLoading" v-if="showEditAll">
        <el-row>
          <el-col :span="24">
            <el-form-item label="上级菜单" class="is-required">
              <treeselect v-model="form.parentId" :options="menuOptions" :normalizer="normalizer" :show-count="true" placeholder="选择上级菜单" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="菜单类型" prop="menuType">
              <el-radio-group v-model="form.menuType" @change="selectMenuType">
                <el-radio label="M">层级目录</el-radio>
                <el-radio label="C">页面</el-radio>
                <el-radio label="F">功能按钮</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item v-if="form.menuType != 'F'" label="菜单图标">
              <el-popover placement="bottom-start" width="460" trigger="click" @show="$refs['iconSelect'].reset()">
                <IconSelect ref="iconSelect" @selected="selected" />
                <el-input slot="reference" v-model="form.icon" placeholder="点击选择图标" readonly>
                  <svg-icon v-if="form.icon" slot="prefix" :icon-class="form.icon" class="el-input__icon svgIcon" />
                  <i v-else slot="prefix" class="el-icon-search el-input__icon" />
                </el-input>
              </el-popover>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单名称" prop="menuName">
              <el-input v-model="form.menuName" placeholder="请输入菜单名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="显示排序" prop="orderNum">
              <el-input-number v-model="form.orderNum" controls-position="right" :min="0" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="form.menuType == 'C'" label="是否外链">
              <el-radio-group v-model="form.isFrame">
                <el-radio label="0">是</el-radio>
                <el-radio label="1">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="form.menuType != 'F'" label="路由地址" prop="path">
              <el-input v-model="form.path" placeholder="请输入路由地址" v-if="form.menuType == 'M'" />
              <el-select @change="selectPageRoute" :disabled="form.isFrame==0" filterable clearable v-if="form.menuType == 'C'" v-model="form.path" placeholder="请选择页面">
                <el-option v-for="(item,index) in routePage" :key="index+0.01" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="form.menuType == 'C'">
            <el-form-item label="组件路径" prop="component">
              <p>{{form.component}}</p>
              <!-- <el-input v-model="form.component" placeholder="请输入组件路径" /> -->
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="form.isFrame == 0 && form.menuType == 'C'">
            <el-form-item label="外链地址" prop="outLink">
              <el-input v-model="form.outLink" placeholder="请输入外链地址" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="form.menuType != 'F'" label="显示状态">
              <el-radio-group v-model="form.visible">
                <el-radio v-for="dict in visibleOptions" :key="dict.dictValue" :label="Number(dict.dictValue)">{{dict.dictLabel}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="form.menuType != 'F'" label="菜单状态">
              <el-radio-group v-model="form.status">
                <el-radio v-for="dict in statusOptions" :key="dict.dictValue" :label="Number(dict.dictValue)">{{dict.dictLabel}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="form.menuType != 'M'" label="权限标识">
              <el-input v-model="form.perms" placeholder="请输入权限标识" maxlength="50" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="form.menuType != 'M'" label="是否只读">
              <el-radio-group v-model="form.readOnly">
                <el-radio :label="Number(1)">是</el-radio>
                <el-radio :label="Number(0)">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import table from "@/views/mixins/table"
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import { listMenu, delMenu, addMenu, updateMenu } from "@/api"
import route_rules from '@/router/route_rule.js'
import IconSelect from "@/components/IconSelect"
import { menuManagerQuery, menuManagerColumn, menuManagerRules } from '../systemSettingColumn'

export default {
  title: '菜单管理',
  mixins: [table],
  name: 'index',
  components: {
    Treeselect, IconSelect
  },
  data() {
    return {
      filterData: {
        pageIndex: 1,
        pageSize: 10
      },
      conditionData: menuManagerQuery,
      columns: menuManagerColumn,
      tableData: [],
      loading: false,
      total: 30,
      // 显示状态数据字典
      visibleOptions: [
        { dictLabel: '隐藏', dictValue: '1' },
        { dictLabel: '显示', dictValue: '0' }
      ],
      readOnlyOptions: [
        { dictLabel: '是', dictValue: '1' },
        { dictLabel: '否', dictValue: '0' }
      ],
      // 菜单状态数据字典
      statusOptions: [
        { dictLabel: '停用', dictValue: '1' },
        { dictLabel: '正常', dictValue: '0' }
      ],
      // 菜单树选项
      menuOptions: [],
      // 是否显示弹出层
      showEditAll: false,
      // 弹出层标题
      title: "",
      rules: menuManagerRules,
      form: {},
      routePage: []
    }
  },
  created() {
    // 设置路由下拉菜单
    this.setRouteArr()
  },
  methods: {
    // 选择图标
    selected(name) {
      this.form.icon = name
    },
    selectPageRoute(v) {
      // console.log('选择路由路径',v)
      this.form.component = route_rules[v].path
    },
    setRouteArr() {
      // console.log(route_rules)
      this.routePage = Object.keys(route_rules).map((item, index) => {
        return {
          label: item + `（${route_rules[item].title}）`,
          value: item
        }
      })
    },
    handleSelectionChange(val) {
      // console.log('val', val)
    },
    formatter(val, key) {
      if (key === 'status') {
        return ['正常', '不正常'][val || 0]
      }
      return val || ''
    },
    getInit() {
      this.loading = true
      listMenu({
        menuName: this.filterData.menuName,
        status: this.filterData.status
      }).then(res => {
        this.loading = false
        this.tableData = this.util.handleTree(res.data.data, "menuId")
      })
    },
    // 表单重置
    resetFun() {
      this.$set(this.form, 'menuType', undefined)
      this.form = {
        menuId: undefined,
        parentId: 0,
        menuName: undefined,
        icon: undefined,
        menuType: "M",
        orderNum: undefined,
        isFrame: "1",
        isCache: "0",
        visible: 0,
        status: 0,
        readOnly: 0
      }
      // this.$refs.form.resetFields();
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children
      }
      return {
        id: node.menuId,
        label: node.menuName,
        children: node.children
      }
    },
    // 选择菜单类型
    selectMenuType() {
      // console.log('选择菜单类型  变化==-=》')
      if ((this.form.menuType == 'M' || this.form.menuType == 'C') && this.form.path) {
        this.$set(this.form, 'path', '')
        this.$set(this.form, 'isFrame', undefined)
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      // this.resetFun()
      const obj = {
        parentId: 0,
        menuType: "M",
        isFrame: "1",
        isCache: "0",
        visible: 0,
        status: 0,
        icon: undefined,
        isFrame: undefined,
        readOnly: 0
      }
      if (row != null && row.menuId) {
        obj.parentId = row.menuId
      } else {
        obj.parentId = 0
      }
      this.form = { ...obj }
      this.getTreeselect()
      this.title = "添加菜单"
      this.showEditAll = true
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      // console.log('点击了修改-----', row)
      this.resetFun()
      this.getTreeselect()
      this.form = { ...row }
      console.info(this.form)
      this.showEditAll = true
      this.title = "修改菜单"
    },
    /** 查询菜单下拉树结构 */
    getTreeselect() {
      listMenu({}).then(response => {
        // console.log('下拉菜单=====',response)
        this.menuOptions = []
        const menu = { menuId: 0, menuName: '主类目', children: [] }
        menu.children = this.util.handleTree(response.data.data, "menuId")
        // menu.children = this.util.handleTree(this.tableData, "menuId");
        this.menuOptions.push(menu)
      })
    },
    // 取消按钮
    cancel() {
      this.showEditAll = false
      this.resetFun()
    },
    // 自定义的检查表单
    checkForm() {
      if (!this.form.parentId && this.form.parentId != 0) {
        this.$message.warning('请选择上级菜单')
        return false
      }
      return true
    },
    /** 提交按钮 */
    submitForm: function () {
      const isOk = this.checkForm()
      if (!isOk) {
        return false
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading_form.isShow = true
          if (this.form.menuId != undefined) {
            updateMenu(this.form).then(response => {
              this.loading_form.isShow = false
              this.$message.success("修改成功")
              this.showEditAll = false
              this.getInit()
            }).catch(() => {
              this.loading_form.isShow = false
            })
          } else {
            addMenu(this.form).then(response => {
              this.loading_form.isShow = false
              this.$message.success("新增成功")
              this.showEditAll = false
              this.getInit()
            }).catch(() => {
              this.loading_form.isShow = false
            })
          }
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除名称为"' + row.menuName + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delMenu(row.menuId)
      }).then(() => {
        this.getInit()
        this.$message.success("删除成功")
      })
    }

  },
  watch: {
    'form.isFrame': {
      immediate: true,
      deep: true,
      handler: function (newVal, OldVal) {
        // console.log('发生改变==',newVal,OldVal)
        if (newVal === '0') {
          this.form.component = route_rules['web'].path
          this.$set(this.form, 'path', 'web')
        } else {
          this.form.component = ''
          // this.$set(this.form, 'path', '')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.svgIcon {
  // height: 30px !important;
  // width: 16px !important;
}
.window {
  /deep/ .el-input__inner {
    height: 38px;
  }
  /deep/ .el-select {
    width: 100% !important;
  }
}
</style>
