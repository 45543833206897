import { render, staticRenderFns } from "./tableRefund.vue?vue&type=template&id=6dd6c112&scoped=true&"
import script from "./tableRefund.vue?vue&type=script&lang=js&"
export * from "./tableRefund.vue?vue&type=script&lang=js&"
import style0 from "./tableRefund.vue?vue&type=style&index=0&id=6dd6c112&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dd6c112",
  null
  
)

export default component.exports