<template>
  <router-view />
</template>

<script>
export default {
  title: '用户管理',
  name: 'UserManage'
}
</script>

<style scope>

</style>
