<template>
  <!-- 设备管理-设备列表 -->
  <!-- rowDetail: 详情操作->详情面板 -->
  <!-- append: 新增操作->新增面板 -->
  <!-- rowEdit: 编辑操作->编辑面板 -->
  <!-- rowDelete: 删除操作->删除面板 -->
  <!-- getInit: 获取ajax数据 -->
  <!-- outPut_: 导出数据 单独导出 -->
  <!-- outPutExcell_obj：导出数据 封装导出-->
  <!-- editDataFormat: 检测当前行显示的内容（如：根据条件显示待处理、已处理）+ 可根据自定义的key值判断 -->
  <!-- handleSelectionChange -->
  <div class="allwh">
    <div class="allwh-wrapper">
      <ja-table ref="tst"
                v-model="filterData"
                :conditionData="conditionData"
                :columns="columns"
                :tableData="tableData"
                :total="total"
                :loading="loading"
                :valueFormat="formatter"
                @append="append"
                @rowEdit="rowEdit"
                @rowDelete="rowDelete"
                @getInit="getInit"
                @search="search"
                @reset="reset">
      </ja-table>

      <!-- 新增/编辑 -->
      <!-- reset: 取消 -->
      <!-- saveForm: 保存操作 -->
      <el-dialog :close-on-click-modal="false"
                :visible.sync="showEditAll"
                width="50%"
                :title="titleText">
        <ja-form :rules="rules"
                :label-width="labelWidth"
                v-model="rowForm"
                ref="jaForm"
                :formArray="rowFormData"
                @reset="showEditAll = false"
                @save="saveForm"
                @changeSubcategory="changeSubcategory"
                v-if="showEditAll"
                v-loading="loading_form.isShow"
                :element-loading-text="loading_form.text"
                :element-loading-spinner="loading_form.icon"
                :element-loading-background="loading_form.bg"
                :element-loading-custom-class="loading_form.formLoading">
        </ja-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import table from "@/views/mixins/table"
import util from '@/util/util.js'
import { deviceInfoGet, deviceInfoAdd, deviceInfoEdit, deviceInfoDelete } from '@/api'
import { deviceListQueryMap, deviceListColumn, deviceListFormDataMap, deviceListRules } from './deviceListColumn'
export default {
  title: '设备列表',
  mixins: [table],
  name: 'index',
  data () {
    return {
      filterData: { pageIndex: 1, pageSize: 10 }, // 默认页码
      conditionData: [
        { input_type: 'select', key: 'category',attribute: { placeholder: '设备大类', size: 'small' }, options: [],
           relevance:{ sonKey:'subcategory', This:this,  rqParam:{ rqUrl: "/device/category", rqData:()=>{return { params:{ pageIndex: 1, pageSize: 200, isAll: '2', parentId: this.filterData.category} }},labelName: 'categoryName', valueName: 'id'},  }},
        { input_type: 'select', key: 'subcategory', attribute: { placeholder: '设备小类', size: 'small' }, options: [] },
        { input_type: 'input', key: 'deviceName', attribute: { placeholder: '设备备注', size: 'small' } },
        { normalBtn: 'search' },
        { normalBtn: 'add',limit:'device::manage::add'},
        { normalBtn: 'reset' },
        { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas, rqUrl: '/device/info/export', rqMethod: 'post' },limit:'device::manage::download' }
      ],
      conditionDataMap: deviceListQueryMap,
      columns: deviceListColumn,
      tableData: [],
      loading: false,
      total: 0,
      // 新增/编辑
      titleText: '编辑',
      rowFormData: [
        { input_type: 'input', label: '设备备注', key: 'deviceName' },
        { input_type: 'select', label: '设备大类', key: 'category', options: [],
           relevance:{ sonKey:'subcategory', This:this, type:'select',  rqParam:{ rqUrl: "/device/category", rqData:()=>{return { params:{ pageIndex: 1, pageSize: 200, isAll: '2', parentId: this.rowForm.category} }},labelName: 'categoryName', valueName: 'id'},  }},
        { input_type: 'select', label: '设备小类', key: 'subcategory', options: [],method:"changeSubcategory" },
        { label:'所属楼栋', input_type: 'select',key: 'buildingId', 
          relevance:{ sonKey:'floorId', This:this, type:'select', otherSonKey:['roomId'],rqParam:{ rqUrl:"/floor/list",rqData:()=>{return { pageIndex: 1, pageSize: 200, buildingId: this.rowForm.buildingId } },rqMethod: 'post', labelName: 'floorName', valueName: 'id' }, }
        },
        { label:'所属楼层', input_type: 'select',key: 'floorId',options: [],
          relevance:{ sonKey:'roomId', This:this, type:'select', rqParam:{ rqUrl:"/room/list",rqData:()=>{return { pageIndex: 1, pageSize: 200,  floorId: this.rowForm.floorId } },rqMethod: 'post', labelName: 'roomName', valueName: 'id' }, }
        }, 
        { input_type: 'select', label: '绑定房间', key: 'roomId', options: [],},
        { input_type: 'input', label: '设备品牌', key: 'brand' },
        { input_type: 'input', label: '设备型号', key: 'model' },
        { input_type: 'input', label: '三维坐标', key: 'installLocation' },
        { input_type: 'input', label: '设备编码', key: 'deviceCode' },
        { input_type: 'input', label: '倍率', key: 'magnification',hide:true },
        { input_type: 'select', label: '计费类型', key: 'billingType',hide:true,
          options:[
            {
              value:1,
              label:"商户用"
            },
            {
              value:2,
              label:"楼层用"
            },
            {
              value:3,
              label:"园区用"
            }
          ]
        },
      ],
      rowFormDataMap: deviceListFormDataMap,
      rules: deviceListRules,
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`
    }
  },
  computed: {
   
  },
  watch: {
    
  },
  mounted () {
    const paramObj = { params:{ pageIndex: 1, pageSize: 200, isAll: '1'} }
    // 顶部搜索框
    this.util.rqOptionsList({ who: [this.conditionData[this.conditionDataMap.category], this.rowFormData[1]], rqUrl: "/device/category", rqData: paramObj, rqMethod: 'get', labelName: 'categoryName', valueName: 'id' }) // 获取设备大类列表

    // 表单里的
    const rowParamObj = { pageIndex: 1, pageSize: 200 }
    this.util.rqOptionsList({ who: this.rowFormData[this.rowFormDataMap.buildingId], rqUrl: "/building/list", rqData: rowParamObj, rqMethod: 'post', labelName: 'buildingName', valueName: 'id' }) // 楼栋选择
    
  },
  methods: {
    getInit: function (item) { // 查询设备列表
      this.loading = true
      deviceInfoGet(this.filterData).then(res => {
        console.log('res====',res)
        const { data,total } = res.data
        this.total =total
        this.tableData = data
      })
      this.loading = false
    },
    rowDelete: function (row) { // 删除
      this.$confirm('是否确认删除该设备信息').then(res => { // 删除设备列表信息
        deviceInfoDelete(row.id).then(res => {
          this.$message.success(res.msg)
          this.getInit()
        })
      })
    },
    saveForm: function () { // 保存
      this.loading_form.isShow = true
      if (this.isAdd) {
        const rowForm = this.rowForm
        const date = new Date()
        rowForm.monitorTime = util.dateFormat('YYYY-mm-dd HH:MM:SS', date)
        const paramObj = this.util.paramChange(rowForm, this.queryTableId, 'edit')
        deviceInfoAdd(paramObj).then(res => { // 新增设备列表信息
          this.loading_form.isShow = false
          this.$message.success(res.msg)
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          this.loading_form.isShow = false
          this.$message.error(error)
        })
      } else {
        const paramObj = this.util.paramChange(this.rowForm, this.queryTableId, 'edit')
        deviceInfoEdit(paramObj).then(res => { // 更新设备列表信息
          this.loading_form.isShow = false
          this.$message.success(res.msg)
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          this.loading_form.isShow = false
          this.$message.error(error)
        })
      }
    },
    append: function () {
      if (this.rowFormData[this.rowFormData.length - 1].label === '设备状态') {
        this.rowFormData.pop()
      }
      this.isAdd = true
      this.titleText = '新增'
      this.rowForm = {}
       this.util.clearSelectOptions(this.rowFormData) //  这个 要加，清除关联的下拉列表
      this.showEditAll = true
      this.changeSubcategory()
    },
    rowEdit: function (row) { // 编辑
      if (this.rowFormData[this.rowFormData.length - 1].label != '设备状态') {
        this.rowFormData.push({ input_type: 'text2', label: '设备状态', key: 'runStatus' })
      }
      this.isAdd = false
      this.titleText = '编辑'
      this.rowForm = { ...row }
      this.showEditAll = true
      this.$nextTick(()=>{
          this.util.setOptionsInit({arr:this.rowFormData,This:this,obj:this.rowForm,ref:'jaForm'})
      })
      this.changeSubcategory(null,this.rowForm.subcategory)
    },
    /***
     * 表单项改变设备小类
     */
    changeSubcategory(method, monitor, val, data){
      let index1 = this.rowFormData.findIndex(item=>item.key == 'magnification' )
      let index2 = this.rowFormData.findIndex(item=>item.key == 'billingType' )
      // console.log(method, monitor, val, data,index1)
      // 'magnification' 'billingType'
      if(monitor==125){
        this.rowFormData[index1].hide=false
        this.rowFormData[index2].hide=false
      }else{
        this.rowFormData[index1].hide=true
        this.rowFormData[index2].hide=true
      }
    },
    formatter: function (val, key) {
      if (key === 'billingType') {
        let data={
          1:"商户用",
          2:"楼层用",
          3:"园区用",
        }
        return data[val]
      }
      return val || ''
    },
  }
}
</script>

<style scoped>
</style>
