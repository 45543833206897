import http from './plugin/ajax'

// 新增预案
export const planEventManagementAdd = (data) => http.post('/eventManage/planEventManagement', data)

//编辑预案
export const planEventManagementEdit = (data) => http.put('/eventManage/planEventManagement', data)

//删除预案
export const planEventManagementDelete = (data) => http.delete('/eventManage/planEventManagement/' + data)

//预案信息列表
export const planEventManagementGet = (data) => http.get('/eventManage/planEventManagement', {
  params: data
})
// 预案参数名
export const getThresholdUnit = (data) => http.get('/eventManage/getThresholdUnit', {
  params: data
})

// 应急事件记录列表
export const getEventEmergency=(data) => http.get('/eventManage/getEventEmergency',{
  params:data
})
// 新增应急事件
export const addEventEmergency = (data) => http.post('/eventManage/addEventEmergency', data)
//修改应急事件
export const updateEventEmergency = (data) => http.put('/eventManage/updateEventEmergency', data)