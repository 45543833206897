<template>
  <!--  普通的表单 -->
  <el-dialog :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" :title="isAdd?'新增':'编辑'">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll">
      <!-- <template #msg>
        <div style="marginRight:80px;">
          <editor-mce :config="Config" :value.sync="rowForm.content" :Id="Date.now()" ref="msg" :url="$uploadUrl+'/oss/upload'"></editor-mce>
        </div>
      </template> -->
    </ja-form>
  </el-dialog>
</template>
<script>
import editorMce from '@/components/editorMce'
import { addMsgTemplalte, eidtMsgTemplalte } from '@/api'

export default {
  inject: ['labelWidth', 'getInit',],
  components: {
    editorMce
  },
  data() {
    return {
      Config: { height: 300 },
      rowFormData: [
        { input_type: 'input', label: '模板名称', key: 'title', maxlength: 20, col: 24, styleWidth: '400px !important' },
        // { input_type: 'customize', customize: 'msg', label: '模板内容', key: 'content', col: 24, },
        { input_type: 'input', type: 'textarea', label: '模板内容', key: 'content', col: 22, minRows: 4, maxlength: 2000 },
        { input_type: 'input', label: '关联事件编号', key: 'eventId', maxlength: 20, col: 24, styleWidth: '400px !important', maxlength: 20, },
      ],
      rowForm: {},
      rules: {
        title: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
        // eventId: [{ required: true, message: '请输入关联事件号', trigger: 'blur' }],
        content: [{ required: true, message: '请输入消息内容', trigger: 'change' }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      rowFormData_indexObj: {},
    }
  },
  // watch: {
  //   'rowForm.content': {
  //     handler: function () {
  //       if (this.$refs.jaForm) {
  //         this.$refs.jaForm.$refs.formbox.validateField('content')
  //       }
  //     }
  //   }
  // },
  created() {
    this.rowFormData_indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
  },
  methods: {
    append() {
      this.isAdd = true
      this.rowForm = { content: '' }
      this.showEditAll = true
    },
    rowEdit(row) {
      console.log('row---', row)
      this.isAdd = false
      this.titleText = '编辑'
      this.showEditAll = true
      this.rowForm = {...row}
    },
    saveForm() {
      this.loadingForm = true
      let rqMethod = this.isAdd ? addMsgTemplalte : eidtMsgTemplalte
      rqMethod(this.rowForm).then(res => {
        this.loadingForm = false
        this.$message.success('提交成功')
        this.showEditAll = false
        this.getInit()
      }).catch(error => {
        this.loadingForm = false
      })
    },
    // 富文本的校验
    editorCheck(rule, value, callback) {
      console.log('校验---》', rule, value,)
      if (rule.required) {
        if (value === undefined || value === "" || value === null || (!value && value != 0)) { // 如果为空
          return callback(new Error('请完善消息内容'))
        }
      }
    }
  }
}
</script>