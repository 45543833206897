<template>
  <div>
    <div class="allwh-wrapper">
      <ja-table
        ref="tst"
        v-model="filterData"
        :conditionData="conditionData"
        :columns="columns"
        :tableData="tableData"
        :total="total"
        :loading="loading"
        @append="append"
        @getInit="getInit"
        @search="search"
        @reset="reset"
        @handleSelectionChange="handleSelectionChange"
        showSelect
      >
      </ja-table>
    </div>
  </div>
</template>

<script>
import table from '@/views/mixins/table'
import util from '@/util/util.js'
import {
  deviceInfoGet,
  deviceInfoAdd,
  deviceInfoEdit,
  deviceInfoDelete
} from '@/api'
export default {
  title: '设备列表',
  mixins: [table],
  name: 'deviceList',
  data() {
    return {
      filterData: { pageIndex: 1, pageSize: 10 }, // 默认页码
      conditionDataMap:{category: 0, subcategory: 1},
      conditionData: [
        {
          input_type: 'select',
          key: 'category',
          attribute: { placeholder: '设备大类', size: 'small' },
          options: [],
          relevance: {
            sonKey: 'subcategory',
            This: this,
            rqParam: {
              rqUrl: '/device/category',
              rqData: () => {
                return {
                  params: {
                    pageIndex: 1,
                    pageSize: 200,
                    isAll: '2',
                    parentId: this.filterData.category
                  }
                }
              },
              labelName: 'categoryName',
              valueName: 'id'
            }
          }
        },
        {
          input_type: 'select',
          key: 'subcategory',
          attribute: { placeholder: '设备小类', size: 'small' },
          options: []
        },
        {
          input_type: 'input',
          key: 'deviceName',
          attribute: { placeholder: '设备名称', size: 'small' }
        },
        { normalBtn: 'search' },
        { normalBtn: 'add', text:'添加' },
        { normalBtn: 'reset' }
      ],
      columns: [
        {
          label: '设备名称',
          prop: 'deviceName',
          attribute: { showOverflowTooltip: true, align: 'center' }
        },
        { label: '设备编码', prop: 'deviceCode' },
        { label: '设备大类', prop: 'categoryName' },
        { label: '设备小类', prop: 'subcategoryName' },
        { label: '所属楼栋', prop: 'buildingName', width: 80 },
        { label: '所属楼层', prop: 'floorName', width: 80 },
        { label: '设备状态', prop: 'runStatus', width: 80 },
        { label: '设备品牌', prop: 'brand' },
        { label: '设备型号', prop: 'model' },
        {
          label: '检测时间',
          prop: 'monitorTime',
          attribute: { showOverflowTooltip: true, align: 'center' }
        }
      ],
      tableData: [],
      loading: false,
      total: 0,
      diviideList:[],
      // 新增/编辑
      titleText: '编辑',
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`
    }
  },
  computed: {},
  watch: {},
  mounted() {
    const paramObj = { params: { pageIndex: 1, pageSize: 200, isAll: '1' } }
    // 顶部搜索框
    
    this.util.rqOptionsList({
      who: [
        this.conditionData[this.conditionDataMap.category],
      ],
      rqUrl: '/device/category',
      rqData: paramObj,
      rqMethod: 'get',
      labelName: 'categoryName',
      valueName: 'id'
    }) // 获取设备大类列表
  },
  methods: {
    getInit: function (item) {
      // 查询设备列表
      this.loading = true
      deviceInfoGet(this.filterData).then((res) => {
        console.log('res====', res)
        const { data, total } = res.data
        this.total = total
        this.tableData = data
      })
      this.loading = false
    },
    append: function () {
     if(this.res){
       this.$message.error('请先选择相同的设备小类！')
     }else if(this.diviideList.length==0){
       this.$message.error('至少选择一个设备！')
     }else{
       this.$emit("diviideList",this.diviideList)
     }
     
    },
    /**
     * 点击每一项的时候除
     */
    handleSelectionChange(val){
     this.diviideList=val
     this.res=val.some(function(value, index) {
         return val[0]['subcategory']!=value['subcategory']
     })
     
    //  if(this.res){
    //    this.$message.error('请先选择相同的设备小类！')
    //  }
    }
  }
}
</script>

<style scoped>
</style>
