import http from './plugin/ajax'

// ---  消息通知  ---

// 消息模板 页面
export const getMsgTemplalteList = (data) => http.get('/msgTemplateManager', { params: data })
export const eidtMsgTemplalte = (data) => http.put('/msgTemplateManager', data)
export const addMsgTemplalte = (data) => http.post('/msgTemplateManager', data)
export const delMsgTemplalte = (data) => http.delete('/msgTemplateManager/' + data)


// 消息任务类别
export const getMsgTaskList = (data) => http.get('/msgTaskManager', { params: data })
export const eidtMsgTask = (data) => http.put('/msgTaskManager', data)
export const addMsgTask = (data) => http.post('/msgTaskManager', data)
export const auditMsgTask = (data) => http.put('/msgTaskManager/audit', data)
export const delMsgTask = (data) => http.delete('/msgTaskManager/'+data)


// 消息发送记录
export const getMsgSendList = (data) => http.get('/msgLogManager', { params: data })
export const getMsgSendRead = (data) => http.get('/msgLogManager/detail/' + data )


// 建议收集列表
export const getMsgSuggestList = (data) => http.get('/advise', { params: data })

