import { checkVistorBlack } from '@/api'

export default {
    watch: {
        "rowForm.visitors": {
            handler: function (val, old_val) {
                if (val.length > 0) {
                    const arr = [...val]
                    let num = 0
                    if (arr.length > 0) {
                        arr.forEach(item => {
                            if (item.name) {
                                num = num + 1
                            }
                        })
                    }
                    this.$set(this.rowForm, 'visitNum', num)
                }
            },
            deep: true
        }
    },
    data() {
        return {
            // 来访时间
            isShowTimeRight: true,
            isShowTimeLeft: true,
            selectableRangeTime: '00:00:00 - 23:59:59',
            selectableRangeTime_left: '00:00:00 - 23:59:59',

        }
    },
    methods: {
        // ---------  来访时间 相关js  ------------------
        disabledDateFun(time) {
            return time.getTime() < Date.now() - 8.64e7// 当天之后的时间可选
        },
        // 用车时间左边的发生变化
        setDisabledTimeRight() {
            this.isShowTimeRight = false
            const comeTime = this.rowForm.comeTime
            if (comeTime && comeTime.dayLeft && comeTime.dayRight && comeTime.dayLeft == comeTime.dayRight && comeTime.timeLeft) {
                this.selectableRangeTime = `${comeTime.timeLeft} - 23:59:59`
                if (comeTime.timeRight) {
                    const leftNum = Number(comeTime.timeLeft.replace(/:/ig, ''))
                    const rightNum = Number(comeTime.timeRight.replace(/:/ig, ''))
                    if (rightNum < leftNum) this.rowForm.comeTime.timeRight = ""
                }
                this.isShowTimeRight = true
            } else {
                this.selectableRangeTime = '00:00:00 - 23:59:59'
                this.isShowTimeRight = true
            }
        },
        // 用车时间左边日期的选择
        setDisabledDateRight() {
            this.setDisabledTimeRight()
            const comeTime = this.rowForm.comeTime
            if (comeTime && comeTime.dayLeft && comeTime.dayRight) {
                const time_l = (new Date(this.rowForm.comeTime.dayLeft)).getTime()
                const time_r = (new Date(this.rowForm.comeTime.dayRight)).getTime()
                if (time_l > time_r) {
                    this.$set(this.rowForm.comeTime, 'dayRight', "")
                    this.$set(this.rowForm.comeTime, 'timeRight', "")
                }
            }
            // 左边的日期改变时
            const now = new Date()
            let nowDay = this.$moment(now).format("YYYY-MM-DD HH:mm:ss")
            let nowDay_day = nowDay.split(' ')[0]
            let nowDay_time = nowDay.split(' ')[1]
            this.isShowTimeLeft = false
            if (comeTime && comeTime.dayLeft && comeTime.dayLeft == nowDay_day) { //若是今天
                this.selectableRangeTime_left = `${nowDay_time} - 23:59:59`
                if (comeTime.timeLeft) {
                    const leftNum = Number(comeTime.timeLeft.replace(/:/ig, ''))
                    const nowTime = Number(nowDay_time.replace(/:/ig, ''))
                    console.log(leftNum, nowTime)
                    if (nowTime > leftNum) {
                        this.rowForm.comeTime.timeLeft = ""
                    }
                }
            } else {
                this.selectableRangeTime_left = `00:00:00 - 23:59:59`
            }
            this.isShowTimeLeft = true
        },
        disabledDateFun2(time) {
            if (this.rowForm.comeTime && this.rowForm.comeTime.dayLeft) {
                const time_l = (new Date(this.rowForm.comeTime.dayLeft)).getTime()
                return time.getTime() < time_l - 8.64e7
            } else {
                return time.getTime() < Date.now()// 当天之后的时间可选
            }
        },
        // 检查用车时间
        checkuseCarTime() {
            const comeTime = this.rowForm.comeTime
            const textObj = {
                dayLeft: '请选择来访开始日期',
                timeLeft: '请选择来访开始时间',
                dayRight: '请选择来访结束日期',
                timeRight: '请选择来访结束时间'
            }
            const item = Object.keys(textObj).find(item => !comeTime[item])
            if (item) {
                this.$message.warning(textObj[item])
                return false
            }
            let startTime = new Date(this.rowForm.comeTime.dayLeft + ' ' + this.rowForm.comeTime.timeLeft).getTime()
            let endTime = new Date(this.rowForm.comeTime.dayRight + ' ' + this.rowForm.comeTime.timeRight).getTime()
            let now = new Date().getTime()
            if (startTime < now) { // 如果小于现在的时间
                this.$message.warning('来访时间要大于现在时间')
                return false
            } else if (startTime > endTime) { // 如果开始时间 大于 结束时间
                this.$message.warning('来访开始时间要小于结束时间')
                return false
            }
            return true
        },




        // ---------- 访客信息  表格相关js  --------------
        rowAdd_formTable(row, info, button) {// 新增
            this.rowForm.visitors.splice(info.$index + 1, 0, {imgList: []})
        },
        rowDelete_formTable(row, info, button) { // "删除"
            if (info.$index == 0 && this.rowForm.visitors.length == 1) {
                this.$message.warning('仅为一行，禁止删除')
            } else {
                this.rowForm.visitors.splice(info.$index, 1)
            }
        },
        // 展示访客提示框
        showModalVisitor(row, cell, btn) {
            // console.log('点击了===>',row,cell,)
            let { name} = row
            const text = `${name}为黑名单访客，请修正后重新提交`
            this.$confirm(text, '提示', {
                showCancelButton:false,
                distinguishCancelAndClose: true,
                type: 'warning'
            }).then(() => {

            }).catch(action => {
                
            });
        },

        // ------------- ----------------  对接人信息  相关 js  ---------------
        // 对接人即申请人 的 勾选触发
        checkedChange() {
            let val = this.rowForm.checked
            let keyArr = ['dockingPeople', 'dockingPhone', 'dockingCompany', 'dockingDept']
            this.$set(this.rowFormData[this.indexObj.selectBtn], 'disabled', val)
            if (val) {
                this.$set(this.rowForm, 'dockingPeople', this.rowForm.applyPeople)
                this.$set(this.rowForm, 'dockingPhone', this.rowForm.applyPhone)
                this.$set(this.rowForm, 'dockingCompany', this.rowForm.applyCompany)
                this.$set(this.rowForm, 'dockingDept', this.rowForm.applyDept)
            } else {
                keyArr.forEach(item => {
                    this.$set(this.rowForm, item, '')
                })
            }
        },
        // 选择用户
        selectUser() {
            this.showUser = true
        },
        //选择用户 
        closeUser(row) {
            if (row) {
                this.$set(this.rowForm, 'dockingPeople', row.nickname)
                this.$set(this.rowForm, 'dockingPhone', row.mobile)
                this.$set(this.rowForm, 'dockingCompany', row.companyName)
                this.$set(this.rowForm, 'dockingDept', row.deptName)
            }
            this.showUser = false
        },
        // ------------------------------------------ 提交表单前 的校验 相关  -------------------------------
        // 检查访客信息
        checkVistors() {
            const visitors = this.rowForm.visitors
            // console.log('访客信息====>', visitors[0])
            if (visitors.length == 1 && Object.keys(visitors[0]).length == 0) {
                this.$message.warning('请完善访客信息')
                return false
            } else {
                for (let i = 0; i < visitors.length; i++) {
                    const item = visitors[i]
                    if (!item.name) {
                        this.$message.warning('请完善访客信息第' + (i + 1) + '行的姓名')
                        i = visitors.length
                        return false
                    } else if (!item.phone || !item.phone.trim()) {
                        this.$message.warning('请完善访客信息第' + (i + 1) + '行的联系电话')
                        i = visitors.length
                        return false
                    }
                    else if (!item.dept) {
                        this.$message.warning('请完善访客信息第' + (i + 1) + '行的所属单位')
                        i = visitors.length
                        return false
                    }else if(item.imgList.length != 2 ){
                        this.$message.warning('请完善访客信息第' + (i + 1) + '行的健康码与行程码并成功上传')
                        i = visitors.length
                        return false
                    }
                    this.$set(this.rowForm.visitors[i], 'phone', item.phone.trim())
                    this.$set(this.rowForm.visitors[i], 'name', item.name.trim())
                    // 检查手机号
                    if (!this.checkNumber(item.phone)) {
                        this.$message.warning('第' + (i + 1) + '行的联系电话，格式不正确')
                        i = visitors.length
                        return false
                    }
                }
                return true
            }
        },
        // 检查手机号
        checkNumber(value) {
            let value_rule = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
            if (!value_rule.test(value) && value !== "" && value !== undefined) {
                return false;
            } else {
                return true
            }
        },
        // 访客信息 账号异常的提示
        showTipVistors() {
            let formVistors = this.rowForm.visitors
            let index_v = formVistors.findIndex(item => !item.isOk)
            if (index_v > -1) {
                this.$message.warning('访客信息第' + (index_v + 1) + '行存在异常')
                return false
            } else {
                return true
            }
        },
        // 黑名单的校验
        async checkVistorBlackFun() {
            // 先判断，是否需要 请求后端
            let formVistors = this.rowForm.visitors
            let index_v = formVistors.findIndex(item => { // 是否有未检查的
                if (!('isOk' in item)) return true
            })
            // console.log('看是否有未检查的', index_v)
            if (index_v > -1) {// 有未检查的，要请求
                this.$set(this.rowFormData[this.indexObj.visitors], 'loading', true)
                let res = await checkVistorBlack({
                    visitors: this.rowForm.visitors
                })
                if (res.code == 200) {
                    // console.log('res===', res)
                    const visitorMsgs = res.data.visitorMsgs
                    let visitors = [...this.rowForm.visitors]
                    if (visitorMsgs && visitorMsgs.length > 0) {
                        visitors.forEach((item, index) => {
                            let isIndex = visitorMsgs.findIndex(v => item.phone == v.visitorContact)
                            console.log(item, isIndex)
                            if (isIndex > -1) {
                                // let obj =
                                visitors[index] = {
                                    ...item,
                                    ...visitorMsgs[isIndex],
                                    isOk: false
                                }
                            } else {
                                visitors[index].isOk = true
                            }

                        })
                        this.rowForm.visitors.splice(0, visitors.length, ...visitors)
                    } else {
                        visitors.map((item, index) => {
                            item.isOk = true
                        })
                        this.rowForm.visitors.splice(0, visitors.length, ...visitors)
                    }
                    this.$set(this.rowFormData[this.indexObj.visitors], 'loading', false)

                    let status = this.showTipVistors()
                    return status
                }
            } else { // 若都检查过了，若是车牌号的，就不需要提示了 。存在异常。若是车牌号的
                let status = this.showTipVistors()
                return status
            }
        },
    },
}
