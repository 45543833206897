
<template>
  <!--  访客邀约的 新增表单 -->
  <el-dialog :close-on-click-modal="false" :visible.sync="showEditAll" width="60%" title="访客邀约">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" @visitorInputChnage="visitorInputChnage" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll" @showModalVisitor="showModalVisitor" @selectUser="selectUser">
      <!-- 来访时间 -->
      <template #comeTime>
        <div class="useCarTime">
          <el-date-picker v-model="rowForm.comeTime.dayLeft" type="date" placeholder="选择日期" @change="setDisabledDateRight" value-format="yyyy-MM-dd" :picker-options="{disabledDate:disabledDateFun}"></el-date-picker>
          <el-time-picker placeholder="开始时间" v-model="rowForm.comeTime.timeLeft" value-format="HH:mm:ss" format='HH:mm' @change="setDisabledTimeRight" class="time" :picker-options="{selectableRange:selectableRangeTime_left}" v-if="isShowTimeLeft"></el-time-picker>
          <span class="split">至</span>
          <el-date-picker v-model="rowForm.comeTime.dayRight" type="date" placeholder="选择日期" @change="setDisabledTimeRight" value-format="yyyy-MM-dd" :picker-options="{disabledDate:disabledDateFun2}"></el-date-picker>
          <el-time-picker placeholder="结束时间" v-model="rowForm.comeTime.timeRight" value-format="HH:mm:ss" format='HH:mm' :picker-options="{selectableRange:selectableRangeTime}" v-if="isShowTimeRight" class="time"></el-time-picker>
        </div>
      </template>
      <!-- 对接人 标题 -->
      <template #receiveName>
        <el-checkbox v-model="rowForm.checked" class="checkBox" @change="checkedChange">对接人即申请人</el-checkbox>
      </template>
      <!-- 访客信息 -->
      <template #visitors>
        <ja-table ref="tst" :isFromForm="true" :columns="table_columns" :tableData="rowForm.visitors" :showIndex="true" :showPage="false" :noSearch="true" :loading="false" :editDataFormat="editDataFormatTable" @rowAdd_formTable="rowAdd_formTable" @rowDelete_formTable="rowDelete_formTable" :indexFiexd="false">
          <template #imgList="row">
            <upload-file ref="Upload" :autoUpload="true" accept='.jpeg,.png,.jpg' :keyItem="row.$index" :fileSize="10" :btnAttr="{ type:'text',btnText:'上传' }" :isSamllImgs="true" @uploadFinish="uploadFinish" @imgRemoveMethod="imgRemoveMethod" :limit="2" :isLimitHide="true"/>
          </template>
        </ja-table>
      </template>
    </ja-form>
    <select-user :show.sync="showUser" v-if="showUser" @closeUser="closeUser"></select-user>
  </el-dialog>
</template>
<script>
import addEdit from './AddEdit.js'
import selectUser from './selectUser.vue'

import { addVisitorApply } from '@/api'

export default {
  inject: ['labelWidth', 'getInit',],
  mixins: [addEdit],
  components: {
    selectUser
  },
  data() {
    return {
      // 访客信息
      table_columns: [
        { label: '姓名', prop: 'name', column_type: 'input', method: 'visitorInputChnage' },
        { label: '联系电话', prop: 'phone', column_type: 'input', method: 'visitorInputChnage' },
        { label: '所属单位', prop: 'dept', column_type: 'input' },
        { slotName: 'imgList', label: '健康码、行程码', prop: 'imgList',width: `${this.util.setFontSize(160)}px`, },
        {
          label: '操作', input_type: 'button', width: `${this.util.setFontSize(170)}px`, NotFixed: true, attribute: { align: 'center', showOverflowTooltip: false, },
          list: [
            { text: '增加', method: 'rowAdd_formTable', key: 'visitors' },
            { text: '删除', method: 'rowDelete_formTable', key: 'visitors' },
            { type: "success", icon: "el-icon-check", circle: true, class: "btnSuccess", hide: false, plain: true, input_type: true },
            { type: "warning", icon: "el-icon-close", circle: true, class: "btnError", hide: false, plain: true, input_type: true, method: 'showModalVisitor' },
          ]
        }
      ],
      rowFormData: [
        { input_type: 'input', label: '申请人姓名', key: 'applyPeople', disabled: true },
        { input_type: 'input', label: '申请人公司', key: 'applyCompany', disabled: true },
        { input_type: 'input', label: '申请人部门', key: 'applyDept', disabled: true },
        { input_type: 'input', label: '申请人电话', key: 'applyPhone', disabled: true },
        { input_type: 'radio', label: '来访类型', key: 'visitorType', col: 24, options: [], },
        { input_type: 'customize', customize: 'visitors', label: '访客信息', key: 'visitors', col: 22, isRequired: true },

        // {
        //   input_type: 'table', label: '访客信息', key: 'visitors', col: 22, showPage: false, noSearch: true, loading: false, editDataFormat: this.editDataFormatTable, isRequired: true,
        //   columns: [

        //   ]
        // },
        { input_type: 'text2', label: '访客人数', key: 'visitNum', isNextNotText: true },
        { input_type: 'input', label: '车牌号', key: 'carNum', },
        { input_type: 'customize', customize: 'comeTime', label: '来访时间', key: 'comeTime', col: 24, isRequired: true, },
        { input_type: 'input', label: '接待区域', key: 'receptionArea', col: 22, },
        { input_type: 'input', label: '事由', key: 'remark', col: 22, },

        { input_type: 'title', text: '对接人信息', col: 24, customizeTitle: 'receiveName' },
        { input_type: 'input', label: '对接人', disabled: true, key: 'dockingPeople', col: 11, },
        { input_type: 'button', text: '选择', readonly: true, isFlex: true, colMarginLeft: '2%', method: 'selectUser', key: 'selectBtn', disabled: false },
        { input_type: 'input', label: '对接人电话', key: 'dockingPhone', disabled: true },
        { input_type: 'input', label: '对接人公司', key: 'dockingCompany', disabled: true },
        { input_type: 'input', label: '对接人部门', key: 'dockingDept', disabled: true },
      ],
      rowForm: {},
      rules: {
        visitorType: [{ required: true, message: '请选择来访类型', trigger: 'change' }],
        receptionArea: [{ required: true, message: '请输入接待区域', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入事由', trigger: 'blur' }],
        dockingPeople: [{ required: true, message: '请完善对接人', trigger: 'change' }],
        dockingPhone: [{ required: true, message: '请完善对接人电话', trigger: 'change' }],
        dockingCompany: [{ required: true, message: '请完善对接人公司', trigger: 'change' }],
        dockingDept: [{ required: true, message: '请完善对接人部门', trigger: 'change' }],
      },
      showEditAll: false, // 显示新增/编辑弹窗
      loadingForm: false,
      indexObj: {},
      userInfo: {},
      // 选择用户 相关
      showUser: false
    }
  },
  created() {
    this.userInfo = this.$store.getters.roles
    this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
  },
  methods: {
    append() {
      this.isAdd = true
      this.titleText = '新增'
      const obj = {}
      obj.applyPeople = this.userInfo.realName
      obj.applyCompany = this.userInfo.companyName
      obj.applyDept = this.userInfo.deptName
      obj.applyPhone = this.userInfo.phoneNumber
      obj.visitors = []
      obj.comeTime = []
      this.$set(this.rowFormData[this.indexObj.selectBtn], 'disabled', false)
      obj.visitors.push({imgList:[]})
      this.rowForm = JSON.parse(JSON.stringify(obj))
      this.showEditAll = true
    },
    async saveForm() {
      if (!this.checkVistors()) return
      if (!await this.checkVistorBlackFun()) return
      if (!this.checkuseCarTime()) return
      let obj = { ...this.rowForm, visitorDetails: [...this.rowForm.visitors] }
      obj.visitorTimeBegin = this.rowForm.comeTime.dayLeft + ' ' + this.rowForm.comeTime.timeLeft
      obj.visitorTimeEnd = this.rowForm.comeTime.dayRight + ' ' + this.rowForm.comeTime.timeRight
      obj.visitorDetails = obj.visitorDetails.map(v=>{
        return{
          ...v,
          imagesUrl: v.imgList.join(','),
          imgList:''
        }
      })
      console.log('提交了表单---', obj)
      obj.applyType = "0"
      obj.parkId = this.userInfo.nowParkId
      this.loadingForm = true
      addVisitorApply(obj).then(res => {
        this.loadingForm = false
        this.getInit()
        this.$message.success('提交成功')
        this.showEditAll = false
      }).catch(err => {
        this.loadingForm = false

      })

    },
    editDataFormatTable(row, btn) {
      if ((!row.isOk && btn.type == 'success') || (row.isOk && btn.type == 'warning')
        || (!('isOk' in row) && (btn.type == 'warning' || btn.type == 'success'))
      ) {
        return false
      } else {
        return true
      }
    },
    visitorInputChnage(a, row, index) {
      if (row.isOk === false || row.isOk === true) {
        let item = this.rowForm.visitors[index]
        delete item.isOk
        this.$set(this.rowForm.visitors, index, { ...item })
      }
    },
    // =======  附件上传 相关 =========
    uploadFinish(fileList, index, response) {
      this.rowForm.visitors[index].imgList.push(response.data)
    },
    imgRemoveMethod(index, file) {
      let list = this.rowForm.visitors[index].imgList
      let del_index = list.findIndex(v => v === file.response.data)
      this.rowForm.visitors[index].imgList.splice(del_index, 1)
    }
  }
}
</script>

<style lang="scss">
.useCarTime {
  .el-date-editor.el-input {
    width: 180px !important ;
  }
  .el-date-editor.el-input.time {
    width: 130px !important ;
    margin-left: 8px;
  }
  .split {
    margin: 0 15px;
  }
}
.checkBox {
  margin-left: 50px;
}
.btnSuccess {
  &:focus,
  &:hover {
    color: #67c23a !important;
    border-color: #c2e7b0 !important;
    background-color: #f0f9eb !important;
    background: #f0f9eb !important;
    cursor: initial;
  }
}
.btnError,
.btnSuccess {
  transform: scale(0.7);
}
</style>