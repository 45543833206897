<template>
  <div id="app"
       v-loading="loading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <router-view :loading="loading"
                 @loading="onloading" />
  </div>
</template>
<script>
export default {
  data () {
    return {
      loading: true
    }
  },
  created () {
    const state = localStorage.getItem('state')
    if (state) {
      this.$store.replaceState({ ...this.$store.state, ...JSON.parse(state) })
    }
  },
  watch: {
    loading () { // val
      // console.log(val)
    },
    '$store.state': {
      deep: true,
      handler (val) {
        localStorage.setItem('state', JSON.stringify(val))
      }
    }
  },
  methods: {
    onloading () {
      this.loading = false
    }
  }
}
</script>
<style lang="scss">
@import '~@/style/mixin.scss';
#app {
  height: 100vh;
  width: 100vw;
  // min-width: 1400px;
  // min-width: 1200px;
  color: $font-color;
}
.wrap {
  > .allwh {
    // margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
</style>
