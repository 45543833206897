import http from './plugin/ajax'

// =========  食堂 ===========
export const dictData = (data) => http.get('/sys/dictData/' + data) // 字典
// 餐具管理
export const getTableware = (data) => http.post('/tableware/manager', data)
export const editTableware = (data) => http.put('/tableware/manager', data)    // 修改 
export const addTableware = (data) => http.post('/tableware/manager/add', data)
export const delTableware = (data) => http.delete('/tableware/manager/' + data)   // 删除 一项

// 菜式管理
export const getDishes = (data) => http.post('/dishes/manager', data)
export const editDishes = (data) => http.put('/dishes/manager', data)
export const addDishes = (data) => http.post('/dishes/manager/add', data)
export const delDishes = (data) => http.delete('/dishes/manager/' + data)

// 套餐管理
export const getSetmeal = (data) => http.post('/setmeal/manager', data)
export const addSetmeal = (data) => http.post('/setmeal/manager/add', data)
export const editSetmeal = (data) => http.put('/setmeal/manager/', data)
export const delSetmeal = (data) => http.delete('/setmeal/manager/' + data)

export const getMenu = (data) => http.post('/plan/manager/getMenu', data)

// 用餐记录
export const getMealrecode = (data) => http.post('/mealrecode/manager', data)
// 订餐记录
export const getOrderrecord = (data) => http.post('/orderrecord/manager', data)


// 退款订单
export const getRefundOrder = (data) => http.post('/order/refund', data)  // 列表
export const getRefundData = (data) => http.get('/order/refund/getAddRefundContent/' + data) // 获取 弹窗 的退款内容
export const addRefundItem = (data) => http.post('/order/refund/add', data) // 新增
export const auditRefundItem = (data) => http.put('/order/refund/updateStatus', data) // 审核退款