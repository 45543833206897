<template>
  <!--  指派工单 -->
  <el-dialog :close-on-click-modal="false" :visible="isShowAssign" width="45%" title="指派工单" @close="close">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="close" @save="saveForm">
    </ja-form>
  </el-dialog>
</template>
<script>
import {  assignRepairMan } from '@/api'
export default ({
  inject: ['labelWidth', 'getInit',],
  props: {
    isShowAssign: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rowFormData: [
        {
          input_type: 'radio', label: '维修人', key: 'employeeCode', col: 24, options: []
        },
      ],
      rowForm: {},
      rules: {
        employeeCode: [{ required: true, message: '请选择维修人', trigger: 'change' }],
      },
      loadingForm: false,
      isAdd: false,
    }
  },
  created() {
    this.util.rqOptionsList({ who: [this.rowFormData[0]], rqUrl: "/repairManager/getRepairHandlerInfoByRole",resRule:'data', labelName: 'employeeName', valueName: 'employeeCode' })
  },
  methods: {
    close() {
      this.rowForm = {}
      this.$refs.jaForm.$refs.formbox.resetFields()
      this.$emit('update:isShowAssign', false)
    },
    saveForm() {
      this.loadingForm = true
      let employeeCode = this.rowForm.employeeCode
      let ids = this.rowForm.ids
      let repairDeviceIdList = ids && ids.length>0 ? ids : this.$parent.selectionIds
      assignRepairMan({employeeCode,repairDeviceIdList}).then(res=>{
        this.loadingForm = false
        this.$message.success('指派成功')
        this.rowForm = {}
        this.$emit('finishAssign')
      }).catch(err=>{
        this.loadingForm = false
      })
    }
  }
})
</script>
