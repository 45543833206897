<template>
  <div style="width:100%">
    <ul class="dateForm">
      <li :span="4">
        <el-form
          :model="dateForm"
          :rules="rulesDateForm"
          ref="dateForm"
        >
          <el-form-item
            prop="date"
            :rules='rulesDateForm.date'
          >
            <el-date-picker
              v-model="dateForm.date"
              type="date"
              size="small"
              :picker-options="pickerOptions"
              :disabled="form.datas.length>=1"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </li>
      <li :span="20">
        <el-button
          size="mini"
          type="primary"
          @click="click_add( )"
        >新增</el-button>
      </li>
    </ul>
    <el-table
      :data="form.datas"
      border
    >
      <!-- <el-table-column prop="id" label="序号" width="60"></el-table-column> -->
      <el-table-column
        prop="foodName"
        label="日期"
      >
        <template slot-scope="scope">
          {{scope.row.foodName}}
        </template>
      </el-table-column>

      <el-table-column
        prop="list"
        label="用餐时段"
      >
        <template slot-scope="scope">
          <!-- {{scope.row.list}} -->
          <ul class="total">
            <li :class="{currTotal:currTotalFun(item,scope.row.list)}" v-for="item in planTypeList" :key="item.dictValue">{{item.dictLabel}}</li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column
        prop="presellPlanMenuList"
        label="是否预售"
      >
        <template slot-scope="scope">
          <ul class="total">
            <li :class="{currTotal:preSaleFun(item,scope.row.presellPlanMenuList)}" v-for="item in preSaleText" :key="item.dictValue">{{item}}</li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column
        prop="totalCost"
        label="成本价（元）"
      >
        <template slot-scope="scope">
          {{scope.row.totalCost}}
        </template>
      </el-table-column>
      <el-table-column
        prop="operation"
        label="操作"
        width="190"
        align="center"
      >
        <template slot-scope="scope">
          <template v-if="scope.$index==form.datas.length-1">
            <el-button
              size="mini"
              @click="click_delete(scope.row, scope.$index)"
            >删除</el-button>
          </template>
          <template>
            <el-button
              size="mini"
              @click="customMenu(scope.row, scope.$index)"
            >制定餐牌</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!-- 增加 -->
    <!-- <add-mealplan></add-mealplan> -->
  </div>
</template>

<script>
import addMealplan from "./addMealplan.vue"
import table from '@/views/mixins/table'
import {dictData} from '@/api'
export default {
  mixins: [table],
  components:{
    addMealplan
  },
  data() {
    return {
      form: {
        datas: [
        ]
      },
      colIndex:0,
      planTypeList:[],
      dateForm: {
        date: ''
      },
      preSaleText:[
        "是",
        "否"
      ],
      pickerOptions: {
        disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000;
        }
      },
      //表单验证规则
      rulesDateForm: {
        date: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入日期'
          }
        ]
      },
      tomorrowDate: ''
    }
  },

  created() {
    //处理数据，为已有数据添加action:'view
    this.planStatusType('time_type')
    this.dayDate()
  },
  props:{
    list:{
      type:Object,
      default:function(){
        return {}
      }
    },
    detailList:{
      type:Array,
      default:function(){
        return []
      }
    },
  },
  watch:{
   
    list:{
      handler(newName, oldName) {
        if(newName.addPanMenuDetailReqList.length!=0){
          // 计算成本总价
          let itemTotal=newName.addPanMenuDetailReqList.map(z=>{
            return  Number(z.totalCost)
          })
          let presellPlanTotal=0
          if(newName.presellPlanMenuList.length!=0){
            let presellPlanTotalList=newName.presellPlanMenuList.map(z=>{
              return z.totalCost
            })
            presellPlanTotal=presellPlanTotalList.reduce((a,b)=>a+b)
          }
          console.log("预售价格---",itemTotal)
          let allTotal=itemTotal.reduce((a,b)=>a+b) + presellPlanTotal
          this.$set(this.form.datas[this.colIndex],'list',newName.addPanMenuDetailReqList)
          this.$set(this.form.datas[this.colIndex],'presellPlanMenuList',newName.presellPlanMenuList)
          this.$set(this.form.datas[this.colIndex],'totalCost', Number(allTotal).toFixed(2))
          this.$emit("formDatas",this.form.datas)
        }
      },
      deep: true
    },
    detailList:{
       handler(newList, oldList) {
        if(newList.length!=0){
          let list=newList.map(item=>{
            let timePlanMenuList=item.timePlanMenuList
            let presellPlanMenuList=item.presellPlanMenuList
            let totalList=timePlanMenuList.map(i=>{
              return  Number(i.num) * Number(i.cost)
            })

            let totalpresell=presellPlanMenuList.map(i=>{
              return  Number(i.num) * Number(i.cost)
            })
            let total2=0
            // 计算预售菜式价格
            if(totalpresell.length!=0){
               total2=totalpresell.reduce((a,b)=>a+b)
            }
            let allTotal=totalList.reduce((a,b)=>a+b) + total2
            return {
              foodName: timePlanMenuList[0].date,
              list:timePlanMenuList,
              presellPlanMenuList:item.presellPlanMenuList,
              totalCost:Number(allTotal).toFixed(2),
            }
          })
         this.form.datas=list.reverse()
        let foodName=this.getTomorrow(this.form.datas[this.form.datas.length-1].foodName)
         this.$set(this.dateForm,"date",foodName)
         this.$emit("formDatas",this.form.datas)
        }
      },
      deep: true
    },
  },
  mounted() {},
  methods: {
    // 请求字典接口
      planStatusType(type){
        dictData(type).then(res=>{
          if(res.code==200){
            // console.log("aaa",res)
            this.planTypeList=res.data
          }
        })
      },
    //新增操作
    click_add(item, index) {
      //模拟新增一条数据
      this.$refs['dateForm'].validate((valid) => {
          if (valid) {
            this.form.datas.push({ foodName: this.dateForm.date ,list:[],totalCost:''})
            // 计算明天的日期
            this.changDateValue()
          } else {
            console.log('error submit!!');
            return false;
          }
      });
    },
    dayDate(){
      let day2 = new Date();
      day2.setTime(day2.getTime());
      let day=day2.getDate()<10 ? '0'+day2.getDate():day2.getDate()
      let y=(day2.getMonth()+1)<10?'0'+(day2.getMonth()+1):(day2.getMonth()+1)
      //  console.log("this.dateForm.date111", day2.getFullYear(),day2.getMonth()+1,day)
      this.dateForm.date = day2.getFullYear()+"-" + y + "-" + day;
     
    },

    //删除操作
    click_delete(item, index) {
      this.$confirm('确定删除该条数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          //模拟删除一条数据
          this.form.datas.splice(index, 1)
         
          console.log("没有数据---")
          this.$emit("formDatas",this.form.datas)
           this.changDateValue()
          
        })
        .catch(() => {})
    },
 
    //计算明天
    getTomorrow(dateStr = '2021-08-31') {
      var date = new Date(dateStr)
      date.setDate(date.getDate() + 1)
      date = date.toJSON()
      date = date.substring(0, 10)
      return date
    },
    changDateValue(){
      let datas = this.form.datas
      let lastDate = datas[datas.length - 1].foodName
      this.tomorrowDate = this.getTomorrow(lastDate)
      this.$set(this.dateForm, 'date', this.tomorrowDate)
    },
    // 点击定制餐牌
    customMenu(row,index){
      // console.log(row)
      this.colIndex=index
      this.$emit("customMenu",{row,index,title:"制定餐牌"})
    },
   
    // 判断当前时段
    currTotalFun(item,list){
      // console.log('list----',list)
      if(list.length==0)return 
      var fla=false
      list.map(res=>{
        if(res.timeType==item.dictValue){
          fla=true
        }
      })
      return fla
    },
    preSaleFun(item,list){
      if(!list) return
      if(list.length==0){
       if(item=="否"){
          return true
        }
      }else{
        if(item=="是"){
          return true
        }
      }
    }
  }
}
</script>


<style  lang="scss" scoped>
.dateForm{
  li:nth-of-type(1){
    margin-right: 20px;
  }
  display: flex;
  justify-content:flex-end ;
}
.total{
  display: flex;
  flex-wrap: wrap;
  li{
    width: 20%;
    text-align: center;
    height: 30px;
    line-height: 30px;
    background: #eee;
    margin-right: 10px;
  }
  .currTotal{
    background: cornflowerblue;
    color: #fff;
  }
}

</style>
