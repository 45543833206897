<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      @getInit="getInit"
      @search="search"
      @rowDelete="rowDelete"
      :valueFormat="formatter"
      @reset="reset"
    >
    <template #pictureUrl="{row}">
        <!-- <div class="firstcell el-button--text" @click="seeDetails(row)">{{row.pictureUrl}}</div> -->
        <el-image
          style="width: 50px; height: 50px"
          :src="row.pictureUrl"
          :preview-src-list="[row.pictureUrl]"
          fit="cover"
        v-if="row.pictureUrl"></el-image>
      </template>
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="50%"
      @close="close"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :allDisabled="allDisabled"
        @reset="showEditAll = false"
        @save="saveForm"
        v-if="showEditAll"
        ref="jaForm"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
        <template #uplpadfaceImg>
            <upload-file ref="Upload" @uploadFinish="uploadFinish" @imgRemoveMethod="imgRemoveMethod" :autoUpload="true"   :accept="'.jpeg,.png,.jpg'"  :limit="1"  :initFileList="[...initFileList]" :fileSize="10" v-if="showEditAll"/>
        </template>
        <template #costType="{row}">
            <el-radio-group v-model="rowForm.costType" @change="changeCostType">
                <el-radio label="0">按整份成本</el-radio>
                <el-radio label="1">按单品材料成本</el-radio>
            </el-radio-group>
        </template>
        <template #cost="{row}" > 
          <div v-if="rowForm.costType==0" class="inputNumber">
            <el-input-number v-model="rowForm.cost" :precision="2" :min="0" :step="0.1"></el-input-number> <span slot="append">元/份</span>
          </div>
          <div v-if="rowForm.costType==1">
            <material-table :datas="datas" @addList="addList($event)"></material-table>
            <span>总成本价：{{rowForm.cost || 0}}元</span>
          </div>
        </template>
      </ja-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  getDishes,
  addDishes,
  editDishes,
  delDishes,
  dictData
} from '@/api'
import table from '@/views/mixins/table'
import materialTable from "./components/materialTable.vue"
export default {
  title: '菜式管理',
  mixins: [table],
  name: 'index',
  components:{
    materialTable
  },
  data() {
    return {
      show: false,
      showUser:false,
      parkId:1,
      radio:"",
      input:'',
      allDisabled:false,
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
        // cityId:''
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'dishesName',
          styleWidth: '200px',
          attribute: {
            placeholder: '菜式名称',
            size: 'small'
          }
        },
        {
          input_type: 'select',
          key: 'dishesType',
          styleWidth: '200px',
          attribute: {
            placeholder: '类型',
            size: 'small'
          },
          options: function () {
            return dictData('dishes_type').then((res) => {
              if (res.code == 200) {
                let data = res.data.map((item) => {
                  return {
                    label: item.dictLabel,
                    value: item.dictValue
                  }
                })
                return data
              }
            })
          }
        },
        {
          input_type: 'select',
          key: 'status',
          attribute: {
            placeholder: '状态',
            size: 'small',
            clearable: true
          },
          options: function () {
            return dictData('dishes_status_type').then((res) => {
              if (res.code == 200) {
                let data = res.data.map((item) => {
                  return {
                    label: item.dictLabel,
                    value: item.dictValue
                  }
                })
                return data
              }
            })
          }
        },
        {
          input_type: 'select',
          key: 'presellFlag',
          attribute: {
            placeholder: '预售',
            size: 'small',
            clearable: true
          },
          options:[
            { label: '否', value: 0 },
            { label: '是', value: 1 }
          ]
        },
        {
          input_type: 'select',
          key: 'supplier',
          attribute: {
            placeholder: '供应商',
            size: 'small',
            clearable: true
          },
          options: function () {
            return dictData('supplier').then((res) => {
              if (res.code == 200) {
                let data = res.data.map((item) => {
                  return {
                    label: item.dictLabel,
                    value: item.dictValue
                  }
                })
                return data
              }
            })
          }
        },
        {
          input_type: 'date',
          value: 'createTime',
          type:'date',
          styleWidth: '200px',
          placeholder:"创建日期"
        },
        {
          text: '查询',
          input_type: 'button', 
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '新增',
          input_type: 'button',
          method: 'append',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-plus',
            plain: true
          },
          limit: 'canteen::dishes::add'
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '名称',
          prop: 'dishesName',
          width: 100,
          attribute: { showOverflowTooltip: true, align: 'center' }
        },
        {
          label: '图片',
          prop: 'pictureUrl',
          slotName: 'pictureUrl',
        },
        {
          label: '类型',
          prop: 'dishesType'
        },
        {
          label: '成本',
          prop: 'cost'
        },
        {
          label: '是否预售',
          prop: 'presellFlag'
        },
        {
          label: '售价',
          prop: 'defaultPrice',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '状态',
          prop: 'statusValue',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '操作',
          input_type: 'button',
          width: 180,
          attribute: {
            fixed: 'right',
            align: 'center'
          },
          list: [
            {
              text: '编辑',
              method: 'rowEdit',
              limit: 'canteen::dishes::update'
            },
            { text: '删除', method: 'rowDelete',limit:'canteen::dishes::del' },
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      titleText: '编辑',
      initFileList:[],
      rowFormData: [
        {input_type: 'select',
          label: '菜式类型',
          key: 'dishesType',
          options: function () {
            return dictData('dishes_type').then((res) => {
              if (res.code == 200) {
                let data = res.data.map((item) => {
                  return {
                    label: item.dictLabel,
                    value: item.dictValue
                  }
                })
                return data
              }
            })
          }
        },
        {
          input_type: 'input',
          label: '菜式名称',
          key: 'dishesName'
        },
        {
          input_type: 'radio',
          label: '状态',
          options: [
            { label: '启用', value: 0 },
            { label: '禁用', value: 1 }
          ],
          key: 'status',
          method: 'selectUserType',
          show: 1
        },
        {
          input_type: 'radio',
          label: '是否预售',
          options: [
            { label: '否', value: 0 },
            { label: '是', value: 1 }
          ],
          key: 'presellFlag'
        },
        { input_type: 'input',label: '默认售价', key: 'defaultPrice',method:'frequencySelect',
        },
        {
          input_type: 'customize',customize:'uplpadfaceImg',label: '图片',key:'pictureUrl',col:24,
        },
        // { input_type: 'radio',label: '缴费日期', key: 'frequency',col:24,options:[],method:'frequencySelect', isFlex: true,
        //   rightBox:{ input_type:'customize',customize:'frequency' ,marginLeft:'20px' }
        // },
        {
          input_type: 'customize',customize:'costType',label: '材料与成本',key:'costType',col:24,
        },
        {
          input_type: 'customize',customize:'cost',hide:true,label: '整份成本价格', key:'cost',col:24,
        },
      ],
      checkImgSuccess:false,
      isExist:null,
      rules: {
        pictureUrl:[
          {
            // required: true,
            // message: '图片必传',
            trigger: 'change',
            validator:(rule,value,callback)=>{
              // if(!this.checkImgSuccess){
              //  return callback(new Error("图片必传！"))
              // }else 
              if(this.isExist){
                 return callback(new Error("存在上传失败文件，请重新上传!"))
              }else{
                callback()
              }
            }
          }
        ],
        dishesName: [
          {
            required: true,
            message: '请输入菜式名称',
            trigger: 'blur',
          }
        ],
        dishesType: [
          {
            required: true,
            message: '请选择菜式类型',
            trigger: 'change'
          }
        ],
        presellFlag: [
          {
            required: true,
            message: '请选择是否预售',
            trigger: 'change'
          }
        ],
        status: [
          {
            required: true,
            message: '请选择状态',
            trigger: 'change'
          }
        ],
        defaultPrice: [
          {
            required: true,
            // message: '请填写默认售价',
            trigger: 'blur',
            validator: this.util.formRules.checkNumber({min:0,fixedNum:2,nullText:"请填写默认售价"})
          }
        ],
        costType: [
          {
            required: true,
            message: '请选择成本价类型',
            trigger: 'change'
          }
        ],
        cost: [
          {
            required: true,
            // message: '请填写成本价',
            trigger: 'blur',
            validator:(rule,value,callback)=>{
               if(this.rowForm.costType==1 &&!this.rowForm.cost){
                return callback(new Error("请新增材料单品价！"))
              }else if(!this.rowForm.cost){
                // return callback(new Error("请选择填写成本价！"))
                  return callback(new Error("请选择填写成本价！"))
              }else{
                callback()
              }
            }
          }
        ],
      },
      rowForm: {
        
      },
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(120)}px`,
      // 图片相关
      headers: {},
      dialogVisible: false,
      dialogObj: null,
      fileList: [],
      isSearchIng: false,
      isAdd: true,
      datas:[]
    }
  },
  created() {
    //  console.log("$uploadUrl",this.$uploadUrl)
  },
  mounted() {
   
  },
  methods: {
    getInit: function () {
      this.loading = true
      // 查询园区列表信息
      getDishes(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    // 新增
    append() {
      this.titleText = '新增'
      this.isAdd = true
      this.rowForm = {
        pictureUrl:''
      }
      this.datas=[]
      this.initFileList=[]
      // this.rowForm.costType="1"
      this.util.clearSelectOptions(this.rowFormData)
      this.showEditAll = true
    },
    // 编辑
    rowEdit: function (row,details) {
      this.isAdd = false
      
      // console.log("row",row)
      this.rowForm = {
        ...row
      }
      this.initFileList = []
      if(this.rowForm.pictureUrl){
        this.initFileList=[
          {
            url:this.rowForm.pictureUrl,
            raw: {type :'image'},
            status:'success',
            response:{data:this.rowForm.pictureUrl}

          }
        ]
        this.checkImgSuccess=true
      }
      this.datas=this.rowForm.foodsCosts
      let index1 = this.rowFormData.findIndex(item=>item.key == 'cost' )
      this.rowFormData[index1].hide=false

      if(details=="details"){
        this.titleText = '详情'
      }else{
        this.titleText = '编辑'
      }
      // console.log("什么鬼--", this.rowForm)
      this.showEditAll = true
    },
    // 点击自定义
    changePrice(data){
      console.log("dee",data)
      let key=Object.keys(data)
      this.rowFormData.map(item=>{
        if(item.key==key[0]){
          if(data.defaultPrice){
            item.disabled=true
          }else{
            item.disabled=false
          }
        }
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm('确定删除该条数据吗？').then((res) => {
        console.log('row', row)
        delDishes(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    saveForm: function () {
      // 保存
      console.log("this.rowForm11111",this.rowForm)
      this.loading_form.isShow = true
      if (this.isAdd) {
        //  添加园区信息
        addDishes(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('新增成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      } else {
        //  更新园区信息
        editDishes(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('修改成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      }
    },
    // 图片上传成功之后
    uploadFinish(){
      let fileList = this.$refs.Upload.fileList
      console.log("fileList---",fileList)
      // this.rowForm.urlLists=141
      this.checkImgSuccess=true
      this.isExist = fileList.find(item=> item.response.code !=200)
      if(!this.isExist){
        let imgList=fileList.map(item=>{
          return item.response.data
        })
        this.rowForm.pictureUrl=imgList[0]
      }
      this.$refs.jaForm.$refs.formbox.clearValidate('pictureUrl')
    },
    // 删除图片
    imgRemoveMethod(){
      this.checkImgSuccess=false
      this.rowForm.pictureUrl = ''
      console.log("this.rowForm",this.rowForm)
    },
    // 监听成本与材料
    changeCostType(type){
      let index1 = this.rowFormData.findIndex(item=>item.key == 'cost' )
      this.rowFormData[index1].hide=false
      console.log(type,index1)
      this.$set(this.rowForm,"cost","")
      if(type==0){
        this.rowFormData[index1].label="整份成本价"
        // 
      }else{
         this.rowFormData[index1].label="材料成本价"
      }
    },
    // 新增增或删除
    addList({total,datas}){
      // console.log("哈哈哈",data)
      // 计算总价
      this.rowForm.cost=total.toFixed(2);
      this.rowForm.foodsCosts=datas
      this.$refs.jaForm.$refs.formbox.clearValidate('cost')
    },
    // 关闭弹窗
    close(){
      let index1 = this.rowFormData.findIndex(item=>item.key == 'cost' )
      this.rowFormData[index1].hide=true
    },
    formatter: function (val, key) {
      if (key === 'presellFlag') {
        return ['否', '是'][val || 0]
      }
      return val || ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';
 .inputNumber{
   /deep/  .el-input-number .el-input__inner{
      padding-left: 0;
    }
 }

</style>
