<template>
  <!-- 设备管理-设备列表 -->
  <!-- rowDetail: 详情操作->详情面板 -->
  <!-- append: 新增操作->新增面板 -->
  <!-- rowEdit: 编辑操作->编辑面板 -->
  <!-- rowDelete: 删除操作->删除面板 -->
  <!-- getInit: 获取ajax数据 -->
  <!-- outPut_: 导出数据 单独导出 -->
  <!-- outPutExcell_obj：导出数据 封装导出-->
  <!-- editDataFormat: 检测当前行显示的内容（如：根据条件显示待处理、已处理）+ 可根据自定义的key值判断 -->
  <!-- handleSelectionChange -->
  <div class="allwh">
    <div class="allwh-wrapper">
      <ja-table ref="tst"
        v-model="filterData"
        :conditionData="conditionData"
        :columns="columns"
        :tableData="tableData"
        showSelect
        :total="total"
        @handleSelectionChange="handleSelectionChange"
        :loading="loading"
        @append="append"
        @getInit="getInit"
        @search="search"
        @reset="reset">
      </ja-table>
    </div>
  </div>
</template>

<script>
import table from "@/views/mixins/table"
import util from '@/util/util.js'
import { deviceInfoGet} from '@/api'
export default {
  title: '设备列表',
  mixins: [table],
  name: 'index',
  data () {
    return {
      filterData: { pageIndex: 1, pageSize: 10 }, // 默认页码
      conditionData: [
        
        { input_type: 'input', key: 'deviceName', attribute: { placeholder: '设备名称', size: 'small' } },
        { normalBtn: 'search' },
        { normalBtn: 'add',text:"添加"},
        { normalBtn: 'reset' },
      ],
      conditionDataMap: {category: 0, subcategory: 1},
      columns: [
        { label: '设备名称', prop: 'deviceName',attribute:{showOverflowTooltip:true,align:'center'} },
        { label: '设备编码', prop: 'deviceCode' },
        { label: '设备大类', prop: 'categoryName' },
        { label: '设备小类', prop: 'subcategoryName' },
        { label: '所属楼栋', prop: 'buildingName', width: 80 },
        { label: '所属楼层', prop: 'floorName', width: 80 },
        { label: '设备状态', prop: 'runStatus', width: 80 },
        { label: '设备品牌', prop: 'brand' },
        { label: '设备型号', prop: 'model' },
        { label: '检测时间', prop: 'monitorTime', attribute: { showOverflowTooltip: true, align: 'center' } },
      ],
      rowFormData:[
        { input_type: 'input', label: '设备名称', key: 'deviceName' },
        { input_type: 'select', label: '设备大类', key: 'category', options: [],
           relevance:{ sonKey:'subcategory', This:this, type:'select',  rqParam:{ rqUrl: "/device/category", rqData:()=>{return { params:{ pageIndex: 1, pageSize: 200, isAll: '2', parentId: this.rowForm.category} }},labelName: 'categoryName', valueName: 'id'},  }},
      ],
      tableData: [],
      loading: false,
      total: 0,
      deviceData:[],
    }
  },
  computed: {
   
  },
  watch: {
    
  },
  props:{
    deviceParams:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  created() {

  },
  mounted () {

  },
  methods: {
    getInit: function (item) { // 查询设备列表
      this.loading = true
      this.filterData={...this.filterData,...this.deviceParams}
      deviceInfoGet(this.filterData).then(res => {
        console.log('res====',res)
        const { data,total } = res.data
        this.total =total
        this.tableData = data
      })
      this.loading = false
    },
    append: function () {
       if(this.deviceData.length==0){
        this.$message.error("设备不能为空")
       }else{
        this.$emit("appendDevice",this.deviceData,this.filterData.billingType)
       }
    },
    // 选中
    handleSelectionChange(data){
      this.deviceData=data
    }
   
  }
}
</script>

<style scoped>
</style>
