<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="isShowSee"
    v-if="isShowSee"
    width="80%"
    @close="close"
    title="查看"
  >
    <ul class="list" v-for="(item,i) in dataList" :key="i">
      <li>
        <div class="list_padiding">
          {{item.timeTypeValue}}
        </div>
      </li>
      <li>
        <ol class="list_chi" v-for="(c,j) in item.planMenuDetailTypeDtoList" :key="j">
          <li><div class="list_padiding">{{c.typeName}}</div></li>
          <li>
            <div class="list_padiding">
              <div v-for="(d,z) in c.drPlanMenuDetailList" :key="z" class="list_div">
                <el-image
                v-if="c.typeName=='菜式'"
                style="width: 100px; height: 100px"
                :src="d.pictureUrl"
                fit="fit">
                </el-image>
                <div style="padding-top:5px">
                  {{d.dishesName}} {{ d.defaultPrice}}元
                </div>
              </div>
            </div>
            
          </li>
        </ol>
      </li>
    </ul>
  </el-dialog>
</template>

<script>

import { getPlandetailMenu } from '@/api'
export default {
  components: {

  },
  filters:{
   
  },
  data() {
    return {
      isShowSee:true,
      dataList:[]
    }
  },
  props: {
   planId: {
      type: Number,
      default: ''
    }
  },
  watch: {
   
  },
  created() {
    this.getPlandetailMenu()
  },
  methods: {
    close() {
      // console.log('关闭------')
      this.$emit("showSee",false)
      // this.rowForm.addPanMenuDetailReqList=[]
    },
    getPlandetailMenu(){
      getPlandetailMenu({planId:this.planId}).then(res=>{
        this.dataList=res.data
      })
    }
  }
}
</script>


<style lang="scss" scoped>
  .list{
    display: flex;
    border: 1px solid #eee;
    border-bottom:none;
    align-items: center;
    & > li{
      .list_padiding{
        padding: 30px;
        // padding-bottom: 5px;
        text-align:center;
         display: flex;
         flex-wrap:wrap;
        .list_div{
         margin-right: 18px;
         margin-bottom: 20px;
        }
      }
      width: 10%;
      .list_chi{
        display: flex;
        align-items: center;
        border-left: 1px solid #eee;
        border-bottom: 1px solid #eee;
        // margin-bottom: 20px;
        & > li:nth-of-type(1){
          width: 10%;
          
        }
        & > li:nth-of-type(2){
          border-left: 1px solid #eee;
          width: 90%;
        }
      }
      .list_chi:last-child{
        border-bottom: none;
      }
    }
    & > li:nth-of-type(2){
      width: 90%;
    }
  }
  .list:last-child{
    border-bottom: 1px solid #eee;
  }
</style>
