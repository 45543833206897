<template>
  <div>
    <!-- 调查人数 弹窗 -->
    <el-dialog :visible="isShowNumberSurvey" title="调查人数" :width="`40%`" append-to-body @close="close" v-if="isShowNumberSurvey">
      <div class="content">
        <div class="join tr">
          <div class="label">
            <div class="text">参与人员</div><span class="num">({{detailData.join.num}})人</span>
          </div>
          <ul class="persons">
            <el-tag class="tags light" @click="showOneSurvey" v-for="(item,index) in detailData.join.list" :key="index">{{item.name}}</el-tag>
          </ul>
        </div>
        <div class="unJoin tr">
          <div class="label">
            <div class="text">未参与人员</div><span class="num">({{detailData.unJoin.num}})人</span>
          </div>
          <ul class="persons">
            <el-tag class="tags info" type="info" size="small" v-for="(item,index) in detailData.unJoin.list" :key="index">{{item.name}}</el-tag>
          </ul>
        </div>
      </div>
    </el-dialog>

    <!-- 个人调查数据 -->
    <personalSurvey :isShowPerSurvey.sync="isShowPerSurvey"/>
  </div>
</template>
<script>
import personalSurvey from './personalSurvey.vue'
// import { getStockInside } from '@/api'
export default {
  props: {
    isShowNumberSurvey: {
      type: Boolean,
      default: false
    }
  },
  components: {
    personalSurvey
  },
  data() {
    return {
      detailData: {},
      isShowPerSurvey: false // 是否展示个人调查数据
    }
  },
  created() {

  },
  methods: {
    rowDetail(row) {
      //   this.detailData = {...row}
      this.detailData = {
        join: {
          num: '80', list: [
            { name: '张消息' },
            { name: '李小明' },
            { name: '李小明' },
            { name: '李小明' },
            { name: '李小明' },
            { name: '李小明' },
            { name: '李小明' },

          ],
        },
        unJoin: {
          num: '10', list: [
            { name: '王百合' },
            { name: '王百合' },
            { name: '王百合' },
            { name: '王百合' },
            { name: '王百合' },
            { name: '王百合' },
            { name: '王百合' },
            { name: '王百合' },
            { name: '王百合' },
            { name: '王百合' },
            { name: '王百合' },
          ]
        }
      }
    },
    close() {
      this.$emit('update:isShowNumberSurvey', false)
    },
    // 打开个人调查数据
    showOneSurvey() {
      this.isShowPerSurvey = true
    }
  }
}
</script>
<style lang='scss' scoped>
.content {
  .tr {
    display: flex;
    margin-bottom: 10px;
    font-size: 14px;
    .label {
      .text {
        line-height: 20px;
      }
      width: 8em;
      margin-right: 1em;
      margin-top: 8px;
      text-align: right;
      .num {
        margin-top: 8px;
        line-height: 20px;
        display: block;
      }
    }
  }
}
/deep/ .el-tag.tags {
  margin-right: 10px;
  margin-bottom: 10px;
  &.light:hover {
    cursor: pointer;
    background-color: #409eff;
    color: #fff;
    border-color: #409eff;
  }
}
/deep/ .el-tag.el-tag--info {
  //   color: #787b80;
}
.persons {
  padding: 8px;
  width: 100%;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  min-height: 100px;
  overflow-y: auto;
  max-height: 300px;
}
</style>