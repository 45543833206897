<template>
  <div class="Tree">
    <div class="allwh-wrapper">
      <el-row :gutter="20">
        <el-col :span="18">
          <el-input
            placeholder="输入关键字进行过滤"
            v-model="params.search"
          >
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-button
            type="primary"
            @click="search"
          >搜索</el-button>
        </el-col>
      </el-row>
      <ul class="tag">
        <li
          :class="[currIndex == i?'curr_tag':'']"
          @click="clickTag(i)"
          v-for="(item,i) in tagList"
          :key="i"
        >{{item}}</li>
      </ul>
      <scrollbar
        tag="div"
        wrap-class="test__wrap"
        view-class="test__list"
        ref="scrollbar"
      >
        <div>
          <el-tree
            class="filter-tree"
            :data="data"
            :props="defaultProps"
            default-expand-all
            @node-click="handleNodeClick"
            @node-expand="nodeExpand"
            ref="tree"
          >
          </el-tree>
        </div>
      </scrollbar>
    </div>
  </div>
</template>

<script>
import scrollbar from '@/components/scrollbar'
import { getSidebarTree } from '@/api'
export default {
  title: '视频监控树',
  name: 'videoTree',
  components: {
    scrollbar
  },
  data() {
    return {
      tagList: ['按位置', '按分组'],
      filterText: '',
      data: [
        {
          id: 1,
          label: '一级 1',
          children: [
            {
              id: 4,
              label: '二级 1-1',
              children: [
                {
                  id: 9,
                  label: '三级 1-1-1'
                },
                {
                  id: 10,
                  label: '三级 1-1-2'
                }
              ]
            }
          ]
        },
        {
          id: 2,
          label: '一级 2',
          children: [
            {
              id: 5,
              label: '二级 2-1'
            },
            {
              id: 6,
              label: '二级 2-2'
            }
          ]
        },
        {
          id: 3,
          label: '一级 3',
          children: [
            {
              id: 7,
              label: '二级 3-1'
            },
            {
              id: 8,
              label: '二级 3-2'
            }
          ]
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      params: {
        search: '',
        selectType: '0',
        categoryType: ''
      },
      currIndex: ''
    }
  },
  props: {
    categoryType: {
      type: String,
      default: '2'
    }
  },
  computed: {},
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
    categoryType(newVal, oldVla) {
      // console.log("you----",newVal)
      this.params.categoryType = newVal
      this.getSidebarTree()
    }
  },
  created() {
    this.getSidebarTree()
    this.params.categoryType = this.categoryType
  },
  mounted() {},
  methods: {
    async clickTag(i) {
      this.currIndex = i
      this.params.selectType = i
      this.params.search = ''
      await this.getSidebarTree()
      this.$emit('clickTag', this.data, i)
    },
    async getSidebarTree() {
      this.data = []
      await getSidebarTree(this.params).then((res) => {
        // console.log(res)
        this.data = res.data
      })
    },
    async search() {
      await this.getSidebarTree()
      this.$emit('search', this.data)
    },
    // 点击树
    handleNodeClick(data) {
      // console.log(data)
      this.$emit('handleNodeClick', data)
    },
    //节点树展开时
    nodeExpand(data, node) {
      // console.log(data,node)
      this.$emit('handleNodeExpand', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.Tree {
  padding: 20px;
  background: #fff;
  margin: 18px;
  width: 100%;
  .tag {
    display: flex;
    padding: 25px;
    justify-content: space-around;
    li {
      font-size: 18px;
      cursor: pointer;
      width: 120px;
      height: 45px;
      line-height: 45px;
      border-radius: 15px;
      border: 1px solid #409eff;
      color: #409eff;
      text-align: center;
    }
    .curr_tag {
      background: #409eff;
      color: #fff;
    }
  }
}
/deep/.test__wrap {
  height: 400px;
}
</style>
