<template>
  <div class="edit">
    <el-dialog title="选择用户" :visible.sync="show" width="80%" @close="close" :before-close="close" append-to-body :close-on-click-modal="false">
      <el-row>
        <el-col :span="5">
          <div class="selectUser_left">
            <el-input placeholder="输入关键字进行过滤" size="small" v-model="filterText" class="treeIput">
            </el-input>
            <el-tree class="filter-tree" :data="treeData" :props="defaultProps" :highlight-current="true" default-expand-all :filter-node-method="filterNode" :current-node-key="currentNodekey" @node-click="handleNodeClick" ref="tree" node-key="id">
            </el-tree>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="selectUser_right">
            <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" @search="search" @rowDelete="rowDelete" @handleSelectionChange="handleSelectionChange" @reset="reset" @selectPerson="selectPerson">
            </ja-table>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import JaForm from '@/components/ja-form'
import table from '@/views/mixins/table'

import {
  delPark,
  getDepTree,
  getUserInfo
} from '@/api'
export default {
  name: 'EditOccupant',
  mixins: [table],
  components: {
    JaForm
  },
  data() {
    return {
      filterData: {
        pageIndex: 1,
        pageSize: 10
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'nickname',
          styleWidth: '200px',

          attribute: {
            placeholder: '用户姓名',
            size: 'small'
          }
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        { label: '用户账号', prop: 'loginName', attribute: { showOverflowTooltip: false, } },
        { label: '姓名', prop: 'nickname', },
        { label: '所属公司', prop: 'companyName', attribute: { showOverflowTooltip: false, } },
        { label: '所属部门', prop: 'deptName', attribute: { showOverflowTooltip: false, } },
        { label: '联系电话', prop: 'mobile', attribute: { showOverflowTooltip: false, } },
        { label: '岗位', prop: 'positionName', attribute: { showOverflowTooltip: false, } },
        {
          label: '操作', input_type: 'button', width: 80,
          list: [
            { text: '选择', method: 'selectPerson', },
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      filterText: '',
      treeData: [

      ],
      currentNodekey: "",
      adminIds: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      userInfo: {},
      parkId: 0,
      deptId: 0
    }
  },
  props: {
    title: String,
    id: String,
    show: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  created() {
    this.userInfo = this.$store.getters.roles
    this.parkId = this.userInfo.nowParkId
    this.deptId = this.userInfo.deptId
    this.filterData.deptId = this.deptId
    this.getDepTree()
    console.log('this.=====', this.show,)
  },
  methods: {
    /** 保存表单 */
    saveForm() {
      this.close()
    },
    /** 关闭弹出框 */
    close() {
      this.$emit('closeUser', false)
    },
    search(){
      this.filterData.pageIndex = 1
      this.getUserInfo()
    },
    getUserInfo() {
      let  filterData = this.filterData
      filterData.parkAdmin = true
      filterData.parkId = this.parkId
      this.loading = true
      getUserInfo(filterData).then((res) => {
        this.loading = false
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      }).catch(err=>{
        this.loading = false
      })
    },
    reset() {
      this.filterData = {
        pageIndex: 1,
        pageSize: this.InitPageSize,
        deptId: this.deptId
      }
      this.pagination.total = 0
      this.pagination.pageSize = this.InitPageSize
      this.getUserInfo(this.filterData)
    },
    // 删除某一项
    rowDelete(row) {
      this.$confirm('是否确认删除该用户吗？').then((res) => {
        console.log('row', row)
        delPark(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    // 多选
    handleSelectionChange(val) {
      console.log('val---', val)
      this.adminIds = val
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 获取部门树的，数据
    getDepTree() {
      getDepTree({ parkId: this.parkId, pageIndex: 1, pageSize: 200 }).then(res => {
        // console.log("-----res",res)
        if (res.code == 200) {
          this.treeData = res.data.data
          this.loading = true
          // console.log(this.treeData)

          if (this.treeData[0]) {
            this.currentNodekey = this.deptId;
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(this.currentNodekey)
            })


            // 查询用户列表信息
            this.getUserInfo(this.filterData);
          } else {
            this.total = 0
            this.loading = false
          }

        }
      })
    },
    handleNodeClick(data) {
      if (!data.children || data.children.length == 0) {
        this.filterData.deptId = data.id
        this.getUserInfo(this.filterData);
        // console.log("yu---")
      }
    },
    // 添加人员
    selectPerson(row) {
      this.$emit('closeUser', row)
    }
  }
}
</script>

<style scope>
.treeIput {
  /* padding: 10px 15px; */
  margin: 28px 0;
}
.selectUser_right {
  border-left: 1px solid #e3e3e3;
  margin-left: 25px;
}
</style>
