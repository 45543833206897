<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog :visible.sync="showDetail" title="查看详情" :width="`40%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='9'>
        <template #uplpadfaceImg>
          <upload-file ref="Upload" :initFileList="[...initFileList]" v-if="showDetail" :noButton="true" :hideDel="true" />
        </template>
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>
export default {
  inject: ['labelWidth', 'getInit',],
  data() {
    return {
      showDetail: false,
      detailOptions:
        [
          { label: '设备编号', key: 'deviceCode' },
          { label: '设备名称', key: 'deviceName' },
          { label: '设备位置', key: 'installLocation', col: 22 },
          { label: '联系人', key: 'linkman' },
          { label: '联系方式', key: 'mobile' },
          // { label: '类型', key: 'type', options: [{ label: '物业维修', value: '0' }, { label: '外委维修', value: '1' },] },
          // { label: '预计工时', key: 'workTime' },
          { label: '报修内容', key: 'content', col: 22 },
          // { label: '预估金额', key: 'cost', col: 22 },
          { type: 'customize', customize: 'uplpadfaceImg', label: '附件', key: 'urlLists', col: 22, alignItems: 'flex-start' }, //attachmentList

        ],
      detailData: {},
      indexObj: {},
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.detailOptions })
  },
  methods: {
    rowDetail(row) {
      let obj = { ...row }
      // obj.workTime = obj.workTime + ' 小时'
      // obj.cost = obj.cost + ' 元'
      // this.$set(this.detailOptions[this.indexObj.cost], 'hide', row.type == 0)
      this.$set(this.detailOptions[this.indexObj.deviceCode],'hide',row.source === 'wx')
      this.$set(this.detailOptions[this.indexObj.deviceName],'hide',row.source === 'wx')
      let imgArr = row.pictureUrl ? row.pictureUrl.split(',') : ''
      if (imgArr.length > 0) {
        this.initFileList = this.util.backImgVideoObj(imgArr)
      } else {
        this.initFileList = []
      }
      this.detailData = { ...obj }
      this.showDetail = true
    },
  }
}
</script>
<style lang='scss' scoped>
</style>