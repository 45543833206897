import http from './plugin/ajax'
export * from './sysSetting'
export * from './login'
export * from './basicInfo'
export * from './house'
export * from './canteen'
export * from './purchase'
export * from './conferenceRoom'
export * from './conferenceRoomOrder'
export * from './visitor'
export * from './device'
export * from './meterReadingSet'
export * from './merchant'
export * from './information'
export * from './deviceAlarmsRepair'
export * from './message'
export * from './emergency'

export * from './building'

// 获取左侧菜单栏的接口
export const rqReftMenu = (data) => http.post('/auth/getRouters', data)
// 个人中心账号信息获取
export const getPersonalmess = (data) => http.post('/auth/user/currentUser')
// 个人切换园区
export const switchPark = (data) => http.post('/auth/user/switchPark',data)
// 是否需要验证码
export const rqIsNeedCode = (data) => http.get('/sys/config/key/login_validate_code', data)

// 导出execl 表格 公共接口
export const outPutCommonExcell = (v) => http[v.method](`${v.url}`, v.method=='get'?{params: v.data,responseType: 'blob'}:v.data, { responseType: 'blob' })

// 确认按钮的公共接口
export const comfirmCommonBtn = (v) => http.post(v.url, v.data)

// 消息页面 的 读取接口
export const rqMessageArticle = (data) => http.get('/public/notice/' + data)

// 查询通用配置列表
export const queryAll = (data1, data2) => http.post('/query/' + data1, data2)

/**
 * 数字字典
 */
 export const reqDictData = (data) => http.get('/sys/dictData' + data)
