<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      @getInit="getInit"
      @search="search"
      @rowDelete="rowDelete"
      @approve="approve"
      @see="see"
      :valueFormat="valueFormat"
      @reset="reset"
    >
      <template #pictureUrl="{row}">
        <!-- <div class="firstcell el-button--text" @click="seeDetails(row)">{{row.pictureUrl}}</div> -->
        <el-image
          style="width: 50px; height: 50px"
          :src="row.pictureUrl"
          :preview-src-list="[row.pictureUrl]"
          fit="cover"
        ></el-image>
      </template>
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="50%"
      @close="close"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :allDisabled="allDisabled"
        @reset="showEditAll = false"
        @save="saveForm"
        v-if="showEditAll"
        ref="jaForm"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
        <template #equipmentAlarm>
          <el-button
            size="mini"
            type="primary"
            @click="insertEvent('xTable1',)"
          >新增</el-button>
          <vxe-table
            border
            show-overflow
            keep-source
            ref="xTable1"
            align="center"
            height="150"
            :edit-rules="validRules"
            :data="rowForm.eventDevice"
            :edit-config="{trigger: 'click', mode: 'cell', icon: 'fa fa-pencil', showStatus: true}"
          >
            <vxe-column
              field="deviceNames"
              title="设备"
              :edit-render="{}"
            >
              <template #edit="scope">
                <el-input
                  v-model="scope.row.deviceNames"
                  clearable
                  @clear="clearDeviceNames(scope)"
                  @focus="focusDevice(scope)"
                  @change="$refs.xTable1.updateStatus(scope)"
                >
                </el-input>
              </template>
              <template #default="{ row }">{{ row.deviceNames }}</template>
            </vxe-column>
            <vxe-column
              field="columName"
              title="参数名"
              :edit-render="{}"
            >
              <template #edit="scope">
                <el-select
                  v-model="scope.row.columName"
                  clearable
                >
                  <el-option
                    v-for="item in paramNameList"
                    :key="item.id"
                    :label="item.valueName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </template>
              <template #default="{ row }">{{ getSelectLabel(row.columName, paramNameList,'id','valueName') }}</template>
            </vxe-column>
            <vxe-column
              field="columValue"
              title="参数值"
              :edit-render="{}"
            >
              <template #edit="{ row }">
                <el-input
                  v-model="row.columValue"
                  type="text"
                ></el-input>
              </template>
              <template #default="{ row }">{{row.columValue}}</template>
            </vxe-column>
            <vxe-column
              field="setCondition"
              title="触发条件"
              :edit-render="{}"
            >
              <template #edit="scope">
                <el-select
                  v-model="scope.row.setCondition"
                  @change="$refs.xTable1.updateStatus(scope)"
                >
                  <el-option
                    v-for="item in condition"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
              <template #default="{ row }">{{ getSelectLabel(row.setCondition, condition) }}</template>
            </vxe-column>
            <vxe-column
              title="操作"
              width="120"
            >
              <template #default="scope">
                <el-button
                  size="mini"
                  type="text"
                  @click="removeRowEvent(scope,'xTable1')"
                >删除</el-button>
              </template>
            </vxe-column>
          </vxe-table>
        </template>
        <template #processingProcess>
          <!-- <el-button
            size="mini"
            type="primary"
            @click="insertEvent('xTableLinkage')"
          >新增</el-button> -->
          <vxe-table
            border
            show-overflow
            keep-source
            ref="xTableLinkage"
            align="center"
            max-height="400"
            :data="rowForm.eventLinkage"
            :edit-config="{trigger: 'click', mode: 'cell', icon: 'fa fa-pencil', showStatus: true}"
          >
            <vxe-column
              field="actionType"
              title="处理事件"
              :edit-render="{}"
            >
              <template #edit="scope">
                <el-select
                  v-model="scope.row.actionType"
                  disabled
                  @change="$refs.xTableLinkage.updateStatus(scope)"
                >
                  <el-option
                    v-for="item in actionTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
              <template #default="{ row }">{{ getSelectLabel(row.actionType, actionTypeList) }}</template>
            </vxe-column>
            <vxe-column
              field="contentName"
              title="事件内容"
              :edit-render="{}"
            >
              <template #edit="scope">
                <el-input
                  v-model="scope.row.contentName"
                  @focus="focusContent(scope)"
                  clearable
                  type="text"
                ></el-input>
              </template>
              <template #default="{ row }">{{row.contentName}}</template>
            </vxe-column>
            <!-- <vxe-column
              title="操作"
              width="180"
            >
              <template #default="{ row }">
                <el-button
                  size="mini"
                  type="text"
                  @click="removeRowEvent(row,'xTableLinkage')"
                >删除</el-button>
              </template>
            </vxe-column> -->
          </vxe-table>
        </template>
      </ja-form>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showDetail"
      width="50%"
      @close="close"
      title="详情"
    >
      <ja-detail
        :options="detailOptions"
        :detailData="detailData"
      ></ja-detail>
    </el-dialog>
    <!-- 选择设备弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showDevice"
      width="50%"
      title="选择设备"
    >
      <deviceList
        @appendDevice="appendDevice"
        :deviceListType="deviceListType"
        v-if="showDevice"
      ></deviceList>
    </el-dialog>
    <!-- 选择设备弹窗end -->
    <select-user
      :parkId="parkId"
      :show.sync="showUser"
      v-if="showUser"
      @closeUser="closeUser"
    ></select-user>
  </div>
</template>
<script>
import {
  planEventManagementGet,
  planEventManagementAdd,
  planEventManagementEdit,
  planEventManagementDelete,
  infoExamineAndApprove,
  getThresholdUnit
} from '@/api'
import table from '@/views/mixins/table'
import editorMce from '@/components/editorMce'
import deviceList from './components/deviceList.vue'
import selectUser from '@/views/home/components/selectUser/selectUser.vue'
import XEUtils from 'xe-utils'
export default {
  title: '预案事件记录',
  mixins: [table],
  components: {
    editorMce,
    deviceList,
    selectUser
  },
  name: 'merchantManage',
  data() {
    return {
      deviceListType: '',
      parkId: 1,
      validRules: {
        deviceNames: [{ required: true, message: '请选择设备' }],
        columName: [{ required: true, message: '请填参数名' }],
        columValue: [{ required: true, message: '请填数值值' }],
        setCondition: [{ required: true, message: '请选触发条件' }],
      },
      paramNameList: [],
      optionsList: [
        {
          value: '1',
          label: '初步沟通'
        },
        {
          value: '2',
          label: '资料发送'
        },
        {
          value: '3',
          label: '已面谈'
        }
      ],
      actionTypeList: [
        {
          value: 1,
          label: '发送通知 '
        },
        {
          value: 2,
          label: '开启门禁 '
        },
        {
          value: 3,
          label: '关闭门禁 '
        }
      ],
      condition: [
        {
          value: "1",
          label: '大于'
        },
        {
          value: "2",
          label: '等于'
        },
        {
          value: "3",
          label: '小于'
        }
      ],
      showDevice: false,
      Config: { height: 300 },
      showDetail: false,
      detailOptions: [
        { label: '标题', key: 'title', col: 24 },
        { label: '排序', key: 'weight', col: 24 },
        { label: '图片', type: 'img', key: 'pictureUrl', col: 24 },
        { label: '内容', key: 'content', col: 24 }
      ],
      detailData: {},
      show: false,
      showUser: false,
      parkId: 1,
      allDisabled: false,
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
        // cityId:''
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'eventName',
          attribute: {
            placeholder: '事件名称',
            size: 'small'
          }
        },
        {
          input_type: 'select',
          key: 'level',
          attribute: {
            placeholder: '事件级别'
          },
          options: [
            {
              label: '紧急',
              value: '1'
            },
            {
              label: '一般',
              value: '2'
            }
          ]
        },
        {
          input_type: 'select',
          key: 'isApprove',
          attribute: {
            placeholder: '是否审批'
          },
          options: [
            {
              label: '是',
              value: '1'
            },
            {
              label: '否',
              value: '2'
            }
          ]
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '新增',
          input_type: 'button',
          method: 'append',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-plus',
            plain: true
          },
          limit: 'mcyManage::planEventRecord::add'
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '事件名称',
          prop: 'eventName'
        },
        {
          label: '事件级别',
          prop: 'level'
        },
        {
          label: '是否需要审批',
          prop: 'isApprove'
        },
        {
          label: '创建人',
          prop: 'createBy',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '创建时间',
          prop: 'createTime'
        },
        {
          label: '操作',
          input_type: 'button',
          width: 180,
          attribute: {
            fixed: 'right',
            align: 'center'
          },
          list: [
            {
              text: '编辑',
              method: 'rowEdit',
              limit: 'imcyManage::planEventRecord::edit'
            },
            {
              text: '删除',
              method: 'rowDelete',
              limit: 'mcyManage::planEventRecord::del'
            }
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      titleText: '编辑',
      initFileList: [],
      rowFormData: [
        {
          input_type: 'input',
          label: '事件名称',
          key: 'eventName'
        },
        {
          input_type: 'select',
          label: '事件级别',
          key: 'level',
          options: [
            {
              label: '紧急',
              value: 1
            },
            {
              label: '一般',
              value: 2
            }
          ]
        },
        {
          input_type: 'radio',
          label: '是否需要审批',
          key: 'isApprove',
          options: [
            {
              label: '是',
              value: 1
            },
            {
              label: '否',
              value: 2
            }
          ]
        },
        {
          input_type: 'customize',
          customize: 'equipmentAlarm',
          label: '设备告警条件',
          key: 'eventDevice',
          col: 22
        },
        {
          input_type: 'customize',
          customize: 'processingProcess',
          label: '处理过程',
          key: 'eventLinkage',
          col: 22
        }
      ],
      checkImgSuccess: false,
      isExist: null,
      rules: {
        eventName: [
          {
            required: true,
            message: '请输入事件名称',
            trigger: 'blur'
          }
        ],
        isApprove: [
          {
            required: true,
            message: '请选择是否需要审批',
            trigger: 'change'
          }
        ],
        level: [
          {
            required: true,
            message: '请选择事件级别',
            trigger: 'change'
          }
        ]
      },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`,
      // 图片相关
      headers: {},
      dialogVisible: false,
      dialogObj: null,
      fileList: [],
      isSearchIng: false,
      isAdd: true,
      rowIndex1: '',
      rowIndex2: '',
      nameObjList: []
    }
  },
  created() {},
  watch: {
    // 'rowForm.content': {
    //   handler: function () {
    //     if (this.$refs.jaForm) {
    //       this.$refs.jaForm.$refs.formbox.validateField('content')
    //     }
    //   }
    // }
  },
  mounted() {},
  methods: {
    insertEvent(refs) {
      if (refs == 'xTable1') {
        this.rowForm.eventDevice.push({
          deviceNames: '',
          columName: '',
          columValue: '',
          setCondition: '',
          deviceSubcategoryId: ''
        })
      } else if (refs == 'xTableLinkage') {
        this.rowForm.eventLinkage.push({
          actionType: '',
          content: '',
          contentBox: '',
          contentName: ''
        })
      }
    },
    // 节点事项表格删除
    removeRowEvent({ rowIndex }, refs) {
      console.log('删除')
      if (refs == 'xTable1') {
        this.rowForm.eventDevice.splice(rowIndex, 1)
      } else if (refs == 'xTableLinkage') {
        this.rowForm.eventLinkage.splice(rowIndex, 1)
      }
    },
    getSelectLabel(value, list, valueProp = 'value', labelField = 'label') {
      const item = list.find((item) => item[valueProp] === value)
      return item ? item[labelField] : null
    },
    formatDate(value, format) {
      return XEUtils.toDateString(value, format)
    },
    getInit: function () {
      this.loading = true
      planEventManagementGet(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    // 新增
    append() {
      this.titleText = '新增'
      this.isAdd = true
      this.rowForm = {
        eventDevice: [],
        eventLinkage: [
          { actionType: 1, content: '', contentBox: '', contentName: '' },
          { actionType: 2, content: '', contentBox: '', contentName: '' },
          { actionType: 3, content: '', contentBox: '', contentName: '' }
        ]
      }
      this.initFileList = []
      this.util.clearSelectOptions(this.rowFormData)
      this.showEditAll = true
    },
    // 编辑
    rowEdit: function (row, details) {
      this.isAdd = false

      // console.log("row",row)
      this.rowForm = {
        ...row,
      }
      if (details == 'details') {
        this.titleText = '详情'
      } else {
        this.titleText = '编辑'
      }
      if(this.rowForm.eventDevice.length!=0){
        let params={
            category: this.rowForm.eventDevice[0].deviceCategoryId,
            subcategory: this.rowForm.eventDevice[0].deviceSubcategoryId
        }
        this.getThresholdUnit(params)
      }

      this.showEditAll = true
    },
    // 查看
    see(row) {
      this.showDetail = true
      this.detailData = row
    },
    // 删除
    rowDelete(row) {
      this.$confirm('确定删除该条数据吗？').then((res) => {
        console.log('row', row)
        planEventManagementDelete(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    async saveForm() {
      // 保存
      const $table = this.$refs.xTable1
      let resValidate=true
      // 验证设备告警条件的表单
      await $table.fullValidate(
        this.rowForm.eventDevice,
        (res) => {
          if(res){
            console.log("验证", res)
            resValidate=false;
          }
        }
      )
      if(!resValidate){
        return false
      }

      this.loading_form.isShow = true
      if (this.isAdd) {
        //  添加园区信息
        planEventManagementAdd(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('新增成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            this.loading_form.isShow = false
          })
      } else {
        //  更新园区信息
        planEventManagementEdit(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('修改成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            this.loading_form.isShow = false
          })
      }
    },
    /**
     *设备告警条件：
     设备input的清仓按钮 
     */
    clearDeviceNames({ row, rowIndex }){
      this.rowForm.eventDevice[rowIndex]['deviceSubcategoryId'] =""
      this.rowForm.eventDevice[rowIndex]['deviceCategoryId'] =""
      this.rowForm.eventDevice[rowIndex]['deviceIds'] =""
    },
    // 审核
    approve: function (row) {
      let params = {
        id: row.id,
        approveStatus: 0,
        approveRemark: ''
      }
      let _this = this
      this.$prompt('请输入备注', '审批', {
        confirmButtonText: '通过',
        cancelButtonText: '拒绝',
        distinguishCancelAndClose: true,
        callback: function (action, instance) {
          params.approveRemark = instance.inputValue
          console.info(action)
          if (action == 'confirm') {
            params.approveStatus = 1
            infoExamineAndApprove(params).then((res) => {
              if (res.code === 200) {
                _this.$message.success('已通过')
              }
              _this.getInit()
            })
          } else if (action == 'cancel') {
            params.approveStatus = 2
            infoExamineAndApprove(params).then((res) => {
              if (res.code === 200) {
                _this.$message.success('已拒绝')
              }
              _this.getInit()
            })
          }
        }
      })
    },
    // 转换值
    valueFormat(val, key, row) {
      if (key == 'isApprove') {
        let list = {
          1: '是',
          2: '否',
        }
        return list[val]
      }else if(key == 'level'){
        let list = {
          1: '紧急',
          2: '一般',
        }
        return list[val]
      }
      return val
    },
    getThresholdUnit(params){
      getThresholdUnit(params).then(res=>{
        this.paramNameList=res.data
      })
    },
    // 关闭表单弹窗
    close() {
      let index1 = this.rowFormData.findIndex(
        (item) => item.key == 'suggestedPrice'
      )
    },
    // 添加设备
    appendDevice(data, type) {
      // console.log('tyoe', data, type)
      this.showDevice = false
      // 判断是点击哪个弹窗
      if (type == 'focusDevice') {
        // 判断数据列表有一个不为空的时候
        let eventDeviceRes = this.rowForm.eventDevice.some((res) => {
          return res['deviceSubcategoryId'] != ''
        })
        if (eventDeviceRes) {
          let res = this.rowForm.eventDevice.some((value, index) => {
            if (value['deviceSubcategoryId'] != '') {
              return value['deviceSubcategoryId'] != data[0]['subcategory']
            } else {
              return false
            }
          })
          if (res) {
            this.$message.error('请选择相同的设备小类！')
            return false
          }
        }
        this.rowForm.eventDevice[this.rowIndex1]['deviceSubcategoryId'] =
          data[0].subcategory
        this.rowForm.eventDevice[this.rowIndex1]['deviceCategoryId'] =
          data[0].category
        this.rowForm.eventDevice[this.rowIndex1]['deviceIds'] =
          data[0].id
        this.$set(
          this.rowForm.eventDevice[this.rowIndex1],
          'deviceNames',
          data[0].deviceName
        )
        //请求参数名的接口
        let params={
          category: data[0].category,
          subcategory:data[0].subcategory
        }
        if(this.paramNameList.length==0){
          this.getThresholdUnit(params)
        }
        
      } else {
        // 赋值设备数据
        this.closeUser('',data,'deviceName')
      }
    },
    //设备名称聚焦
    focusDevice(scope) {
      this.showDevice = true
      this.deviceListType = 'focusDevice'
      this.rowIndex1 = scope.rowIndex
      console.log(scope)
    },
    // 事件内容
    focusContent({ row, rowIndex }) {
      // console.log(row)
      this.deviceListType = 'focusContent'
      this.rowIndex2 = rowIndex
      if (row.actionType == 1) {
        this.showUser = true
      } else if (row.actionType == 2 || row.actionType == 3) {
        this.showDevice = true
      } else if (row.actionType == '') {
        this.$message.error('先选择处理事件！')
      }
    },
    //选择用户
    closeUser(show, data, name = 'name') {
      this.rowForm.eventLinkage[this.rowIndex2].contentBox = this._.unionWith(
        this.rowForm.eventLinkage[this.rowIndex2].contentBox,
        data,
        this._.isEqual
      )
      if (this.rowForm.eventLinkage[this.rowIndex2].contentBox.length != 0) {
        let ids = []
        let nameList = this.rowForm.eventLinkage[this.rowIndex2].contentBox.map(
          (item) => {
            ids.push(item.id)
            return item[name]
          }
        )
        this.rowForm.eventLinkage[this.rowIndex2].content = ids.join(',')
        this.$set(
          this.rowForm.eventLinkage[this.rowIndex2],
          'contentName',
          nameList.join(',')
        )
      }
      this.showUser = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}
/deep/.c--tooltip {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
