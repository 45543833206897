<template>
  <div class="allwh">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      :showIndex="true"
      @handleSelectionChange="handleSelectionChange"
      @rowDetail="normalEvent"
      @append="normalEvent({ref:'addEdit',method:'append'})"
      @rowCharge="rowCharge"
      @rowEdit="normalEvent"
      @rowDelete="rowDelete"
      @getInit="getInit"
      :editDataFormat="editDataFormat"
      @rowOutOf="rowOutOf"
      @search="search"
      @reset="reset"
    >
      <template #roomName="{row}">
        <div class="firstcell el-button--text" @click="seeDetails(row)">{{row.roomName}}</div>
      </template>
    </ja-table>
    <!-- 新增 -->
    <add-edit ref="addEdit" />
    <!-- 收费标准 -->
    <charge-standard ref="charge"/>
  </div>
</template>

<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit'

import { getRoomList, getDictData, delRoom } from '@/api'

export default {
  title: '房间管理',
  mixins: [table],
  name: 'roomManage',
  components: { AddEdit },
  data() {
    return {
      labelWidth: `${this.util.setFontSize(150)}px`,
      filterData: { pageIndex: 1, pageSize: 10 },
      conditionData: [
        { input_type: 'select',key: 'buildingId',attribute: {placeholder: '楼栋',size: 'small'},options: [],styleWidth:'100px',method:'selectFun',
          relevance:{ sonKey:'floorId', This:this, // 必填。而 rqParam 是异步请求，必填字段
            rqParam:{ rqUrl:"/floor/all",rqData:()=>{return {params:{ buildingId: this.filterData.buildingId } }},labelName:'floorName',valueName:'floorId',resRule:'data'}, // 
          }
        },
        { input_type: 'select',key: 'floorId',attribute: {placeholder: '楼层',size: 'small'},options: [],styleWidth:'100px'},
        {
          input_type: 'select',
          attribute: {
            placeholder: '用途',
            size: 'small'
          },
          key: 'purpose',
          options: function () {
            return getDictData('room_purpose').then((res) => {
              console.log(res.data)
              let data = res.data.map((item) => {
                return {
                  label: item.dictLabel,
                  value: item.dictValue
                }
              })
              return data
            })
          }
        },
        {
          input_type: 'select',
          attribute: {
            placeholder: '户型',
            size: 'small'
          },
          key: 'category',
          options: function () {
            return getDictData('room_category').then((res) => {
              if (res.code == 200) {
                let data = res.data.map((item) => {
                  return {
                    label: item.dictLabel,
                    value: item.dictValue
                  }
                })
                return data
              }
            })
          }
        },
        { input_type: 'input', attribute: {
            placeholder: '房间名称',
            size: 'small'
          }, key: 'roomName' },
        { normalBtn: 'search' },
        { normalBtn: 'reset' },
        // { normalBtn:'download'},
        { normalBtn: 'add',limit:"room::management::add" }
      ],
      columns: [
        { label: '房间名称', prop: 'roomName',slotName:"roomName",name:"roomName" },
        { label: '所在楼栋', prop: 'building' },
        { label: '所在楼层', prop: 'floor' },
        { label: '户型', prop: 'category', width: 250 },
        { label: '套内面积（m²）', prop: 'insideArea' },
        { label: '建筑面积（m²）', prop: 'outsideArea' },
        { label: '用途', prop: 'purpose' },
        { label: '状态', prop: 'status' },
        {
          label: '操作',
          input_type: 'button',
          width: 200,
          attribute: { fixed: 'right', align: 'center' },
          list: [
            { text: '编辑', method: 'rowEdit',limit:'room::management::update' },
            { text: '删除', method: 'rowDelete',limit:'room::management::del'},
          ]
        }
      ],
      tableData: [
        
      ],
      loading: false,
      total: 300,
      activeRow : null,
    }
  },
  created() {
    
    let param=this.$route.params
    if(param.id){
      this.filterData.buildingId=param.id
      // console.log("this.filterData",this.filterData)
      this.getInit()
    }
  },
  mounted() {
    let index2 =  this.conditionData.findIndex(item=>item.key == 'buildingId' )
        this.util.rqOptionsList({who:[this.conditionData[index2]],rqUrl:"/building/list",rqData:{ pageIndex: 1, pageSize: 200 },rqMethod:'post',labelName:'buildingName',valueName:'id'})
  },
  methods: {
    editDataFormat(row, btn) {
      if ((row.residentName == null ) && btn.text == '迁出') {
        return false
      } else {
        return true
      }
    },
    handleSelectionChange(val) {
      console.log('val', val)
    },
    getInit() {
      this.loading = true
      getRoomList(this.filterData).then((res) => {
        console.log(res)
        this.loading = false
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },

    // 删除
    rowDelete(row) {
      this.$confirm('房间删除后，该房间的相关数据将不再可用，请谨慎操作。').then((res) => {
        delRoom( row.id ).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    // 点击标题
    seeDetails(row){
      // console.log(row)
      this.$refs.addEdit.rowEdit(row,"details")
    }
  }
}
</script>

<style lang="scss" scoped>
.firstcell {
  cursor: pointer;
}
</style>
