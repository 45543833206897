<template>

  <div class="calendar-wrapper">
    <!-- <div class="calendar-toolbar">
      <div
        class="prev"
        @click="prevMonth"
      >上个月</div>
      <div class="current">{{ currentDateStr }}</div>
      <div
        class="next"
        @click="nextMonth"
      >下个月</div>
    </div> -->
    
    
    <ul class="title_but">
      <li>
        <div class="current">{{ currentDateStr }}</div>
      </li>
      <li>
        <el-button-group>
          <el-button type="primary"  @click="prevMonth">上个月</el-button>
          <el-button type="primary"  @click="currMonth">当月</el-button>
          <el-button type="primary"  @click="nextMonth" >下个月</el-button>
        </el-button-group>
      </li>
    </ul>
    <div class="calendar-week">
      <div
        class="week-item"
        v-for="item of weekList"
        :key="item"
      >{{ item }}</div>
    </div>
    <div class="calendar-inner">
      <div
        class="calendar-item"
        v-for="(item, index) of calendarList"
        :key="index"
        @click="setHolidays(item)"
        :class="[item.disable ? 'disabled' : '', item.setVal ? 'curr_item':'']"
      >
        <el-row class="calendar-item-chi">
          <el-col :span="6">{{ item.date }}</el-col>
          <el-col :span="18" v-if="!item.disable">
            <ul class="timetypeList">
              <li v-for="(val,i) in timetypeList" :key="i">
                <span :class="{currTime:filterCurr(item,val)}">{{val.dictLabel}}</span>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>

</template>
<script>
import { getMenu,dictData } from '@/api'
export default {
  data() {
    return {
      current: {}, // 当前时间
      weekList: ['一', '二', '三', '四', '五', '六', '日'],
      calendarList: [], // 用于遍历显示
      changeCalendarList:[],
      params:{
        year:"",
        month:"",
        id:"",
        dataJson:""
      },
      timetypeList:[],
      shareDate: new Date() // 享元模式，用来做优化的,
    }
  },
  props: {
    dateValue:{
      type:Object,
      default:function () {
        return {}
      }
    }
  },
  watch: {
    dateValue:{
      handler:function(){
        this.changeCalendar()
        // this.addHolidayt()
      },
      deep: true
    }
  },
  computed: {
    // 显示当前时间
    currentDateStr() {
      let { year, month } = this.current
      return `${year}年${this.pad(month + 1)}月`
    }
  },
  created() {
    // this.getHolidayList()
    this.dictData('time_type')
  },
  mounted() {
    this.init()
  },
  methods: {
    dictData(tyep){
      dictData(tyep).then(res=>{
        if(res.code==200){
          this.timetypeList=res.data
        }
      })
    },
    getHolidayList(){
      let month=this.current.month
      let params={
        year:this.current.year,
        month:++month
      }
      // console.log("参数---",params)
      let _this=this
      
      getMenu(params).then(res=>{
        if(res.code==200){
           let data=res.data.timeTypeList
          //  console.log("长电",data.length)
          this.calendarList.map((item,i)=>{
            
            if (!item.disable) {
              //  _this.$set(item,"timeType", data[item.date].timeTypeList)
              if(data[item.date]){
                // console.log(data[item.date])
                 _this.$set(item,"timeType", data[item.date-1].timeTypeList)
              }
            }
             return item
          })
        }
      })

    },
    // // 提交节假日配置
    // addHolidayt(){
    //   addHolidayt(this.params).then(res=>{
    //     if(res.code==200){
    //      this.$message.success(res.msg)
    //     //  this.$emit("closeAllocation")
    //     }
    //   })
    // },
    init() {
      // 初始化当前时间
      this.setCurrent()
      this.calendarCreator()
    },
    // 判断当前月有多少天
    getDaysByMonth(year, month) {
      return new Date(year, month + 1, 0).getDate()
    },
    getFirstDayByMonths(year, month) {
      return new Date(year, month, 1).getDay()
    },
    getLastDayByMonth(year, month) {
      return new Date(year, month + 1, 0).getDay()
    },
    // 对小于 10 的数字，前面补 0
    pad(str) {
      return str < 10 ? `0${str}` : str
    },
    // 点击上一月
    prevMonth() {
      this.current.month--
      // 因为 month的变化 会超出 0-11 的范围， 所以需要重新计算
      this.correctCurrent()
      // 生成新日期
      this.calendarCreator()
    },
    // 当月
    currMonth(){
      let d = new Date()
      let year = d.getFullYear()
      let month = d.getMonth()
      let date = d.getDate()
      this.current = {
        year,
        month,
        date
      }
       this.correctCurrent()
       // 生成新日期
       this.calendarCreator()
    },
    // 点击下一月
    nextMonth() {
      this.current.month++
      // 因为 month的变化 会超出 0-11 的范围， 所以需要重新计算
      this.correctCurrent()
      // 生成新日期
      this.calendarCreator()
    },
    // 格式化时间，与主逻辑无关
    stringify(year, month, date) {
      let str = [year, this.pad(month + 1), this.pad(date)].join('-')
      return str
    },
    // 设置或初始化 current
    setCurrent(d = new Date()) {
      let year = d.getFullYear()
      let month = d.getMonth()
      let date = d.getDate()
      this.current = {
        year,
        month,
        date
      }
      // console.log("setCurrent",this.current)
       // 请求后台已配置数据
      // this.getHolidayList()
    },
    // 修正 current
    correctCurrent() {
      let { year, month, date } = this.current
      // console.log("correctCurrent",year, month)
      let maxDate = this.getDaysByMonth(year, month)
      // 预防其他月跳转到2月，2月最多只有29天，没有30-31
      date = Math.min(maxDate, date)

      let instance = new Date(year, month, date)
      this.setCurrent(instance)
    },
    // 生成日期
    calendarCreator() {
      // 一天有多少毫秒
      const oneDayMS = 24 * 60 * 60 * 1000

      let list = []
      let { year, month } = this.current
      // let  year=2022 
      // let  month=5
      // console.log("年月",year, month)
      // 当前月份第一天是星期几, 0-6
      let firstDay = this.getFirstDayByMonths(year, month)
      // 填充多少天
      let prefixDaysLen = firstDay === 0 ? 6 : firstDay - 1
      // 毫秒数
      let begin = new Date(year, month, 1).getTime() - oneDayMS * prefixDaysLen

      // 当前月份最后一天是星期几, 0-6
      let lastDay = this.getLastDayByMonth(year, month)
      // 填充多少天， 和星期的排放顺序有关
      let suffixDaysLen = lastDay === 0 ? 0 : 7 - lastDay
      // 毫秒数
      let end =
        new Date(year, month + 1, 0).getTime() + oneDayMS * suffixDaysLen

      while (begin <= end) {
        // 享元模式，避免重复 new Date
        this.shareDate.setTime(begin)
        let year = this.shareDate.getFullYear()
        let curMonth = this.shareDate.getMonth()
        let date = this.shareDate.getDate()
        list.push({
          year: year,
          month: curMonth,
          date: date,
          disable: curMonth !== month,
          value: this.stringify(year, curMonth, date)
        })
        begin += oneDayMS
      }

      this.calendarList = list
      this.getHolidayList()
      // console.log("this.calendarList = list",this.calendarList)
    },
    // 点击日历改变日期
    changeCalendar(){
      this.current.year=this.dateValue.year,
      this.current.month=this.dateValue.month,
      // console.log("改变122",this.current)
      this.correctCurrent()
      // 生成新日期
      this.calendarCreator()
    },
    // 点击
    setHolidays(item){
      
    },
    /***
     * list 餐牌列表数据
     * currItem 默认值
     */
    filterCurr(list,currItem){
      if(list.timeType){
        // console.log("hhhh",list.timeType,currItem.dictValue)
         return list.timeType.includes(Number(currItem.dictValue))
      }else{
        return false
      }
      // return list.timeType.includes(currItem.dictValue)
    }
  }
}
</script>
<style lang="scss" scoped>
.calendar-wrapper {
  // width: 100%;
  height: 145%;
  background: #fff;
  padding: 10px 15px;
  margin: 20px 15px;
  overflow: hidden;
}

.calendar-toolbar {
  display: flex;
  width: 322px;
  margin: 0 auto;
  text-align: center;
}

.calendar-toolbar .current {
  flex: 1;
}

.calendar-week {
  display: flex;
  margin-top: 10px;
  text-align: center;
}
.calendar-week .week-item {
  flex: 1;
  width:14%;
  line-height: 60px;
  font-weight: 600;
}
.calendar-inner{
  border-left: 1px solid #e6e6e6;
  overflow: hidden;
  border-bottom: 1px solid #e6e6e6;
}
.calendar-item {
  float: left;
  width: 14.2%;
  height: 160px;
  // line-height: 180px;
  text-align: center;
  cursor: pointer;
  border-top: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  color: #333;
  .calendar-item-chi{
    padding-top: 20px;
    .timetypeList{
      // text-align:left;
      li{
        // margin-bottom: 8px;
        span{
          display: inline-block;
          width: 30%;
          height: 24px;
          line-height: 24px;
          margin-bottom: 8px;
          border-radius: 5px;
          // background: #bdbdbd;
        }
      }
      .currTime{
        background:#40a4f5;
        color:#f6f8fa;
      }
    }
  }
}
.calendar-item.disabled {
  color: #ccc;
}
.calendar-item.checked {
  color: red;
}
.curr_item{
 background:#0083ee;
 color:#fff;
}
.title_but{
  padding-top: 30px;
  display: flex;
  justify-content:flex-end;
  li{
    width: 50%;
    font-size: 24px;
    font-weight: bold;
    color: #666;
    align-self:center;
    text-align:right;
  }
}
</style>