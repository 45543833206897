import http from './plugin/ajax'

// 获取省市区数据
export const queryregion = (data) => http.get('/sys/region', data)

// 添加园区信息
export const addParkInfo = (data) => http.post('/park/add', data)

// 更新园区信息
export const updateParkInfo = (data) => http.post('/park/update', data)

// 分页查询企业信息列表
export const queryCompanyInfo = (data) => http.post('/department/', data)

// 添加企业信息
export const addCompanyInfo = (data) => http.post('/company/add', data)

// 更新企业信息
export const updateCompanyInfo = (data) => http.post('/company/update', data)

// 删除企业信息
export const deleteCompanyInfo = (data) => http.delete('/company/' + data)

// 查询部门信息
export const queryDeptList = (data) => http.get('/department', {
  params: data
})

// 添加部门信息
export const addDepartmentInfo = (data) => http.post('/department/add', data)

// 更新部门信息
export const updateDepartmentInfo = (data) => http.post('/department/update', data)

// 删除部门信息
export const deleteDepartmentInfo = (data) => http.delete('/department/' + data)

/** 用户管理 */
// 添加用户信息
export const employeeAdd = (data) => http.post('/employee/add', data)

// 更新用户信息
export const employeeUpdate = (data) => http.post('/employee/update', data)

// 删除用户信息
export const employeeDelete = (data) => http.delete('/employee/delete/' + data)

export const getEmployList = (data) => http.get('/employee', {
  params: data
})

export const getCompanyList = (data) => http.get('/company', {
  params: data
})

// 同步食堂饭卡
export const updateEmployeeCard= (data) => http.put('/recharge/updateEmployeeCard', data)