<template>
  <!-- 楼层列表 -->
  <!-- rowDetail: 详情操作->详情面板 -->
  <!-- append: 新增操作->新增面板 -->
  <!-- rowEdit: 编辑操作->编辑面板 -->
  <!-- rowDelete: 删除操作->删除面板 -->
  <!-- getInit: 获取ajax数据 -->
  <!-- outPut_: 导出数据 -->
  <div class="allwh minWidth1000">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData"
      :total="total" :loading="loading" @append="append"
      @rowEdit="rowEdit" @getInit="getInit" :valueFormat="formatter"
      @search="search" @reset="reset" >
      <template #floorName="{row}">
        <div class="firstcell el-button--text" @click="seeDetails(row)">{{row.floorName}}</div>
      </template>
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="showEditAll" width="40%"  @close="close" :title="titleText">
      <ja-form :rules="rules" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData"
        @reset="showEditAll = false" :allDisabled="allDisabled" @save="saveForm" v-if="showEditAll" v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text" :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg" :element-loading-custom-class="loading_form.formLoading">
      </ja-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  updateFloor,
  getFloorList
} from '@/api'
import table from "@/views/mixins/table"
export default {
  title: '楼层列表',
  mixins: [table],
  name: 'index',
  data () {
    return {
      filterData: { // 默认页码
        pageIndex: 1,
        pageSize: 10
      },
      allDisabled:false,
      conditionData: [ // 表格上方的搜索/选择操作栏
        {
          input_type: 'input',
          key: 'buildingName',
          
          attribute: {
            placeholder: '楼栋名称',
            size: 'small'
          },
        },
        {
          input_type: 'select',
          key: 'type',
          attribute: {
            placeholder: '请选择',
            size: 'small',
            clearable: true
          },
          options: [
            {
              label:"地面",
              value:"地面",
            },
            {
              label:"地下",
              value:"地下",
            }
          ]
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        },
      ],
      columns: [ // 表头数据
        {
          label: '楼层名称',
          prop: 'floorName',
          slotName:"floorName",
          name:"floorName"
        },
        {
          label: '所属楼栋',
          prop: 'buildingName'
        },
        {
          label: '地面/地下',
          prop: 'type'
        },
        {
          label: '操作',
          input_type: 'button',
          width: 100,
          attribute: {
            fixed: "right",
            align: 'center'
          },
          list: [{
            text: '编辑',
            method: 'rowEdit',
            limit: 'floor::list::update'
          }]
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      titleText: '编辑',
      rowFormData: [
        {
          input_type: 'input',
          label: '楼层名称',
          key: 'floorName',
         
        },
        {
          input_type: 'text2',
          label: '地面/地下',
          key: 'type'
        },
        {
          input_type: 'text2',
          label: '所在楼栋',
          key: 'buildingName'
        },
        {
          input_type: 'input',
          type: 'textarea',
          label: '备注',
          key: 'remark',
          col: 22
        }
      ],
      rules: {
        floorName: [{
          required: true,
          message: '请输入楼层名称',
          trigger: 'blur'
        }]
      },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    formatter: function (val, key) {
      if (key === 'status') {
        return ['启用', '禁用'][val || 0]
      }
      return val || ''
    },
    getInit: function () {
      this.loading = true
      // 获取楼层信息列表
      // const paramObj = this.util.paramChange(this.filterData, this.queryTableId)
      getFloorList(this.filterData).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    saveForm: function (row) { // 保存
      this.loading_form.isShow = true
      const paramObj = this.util.paramChange(this.rowForm, this.queryTableId, 'edit')
      if (this.isAdd) {
        
      } else {
        // 更新楼层信息
        updateFloor(paramObj).then(res => {
          this.loading_form.isShow = false
          this.$message.success('修改成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          console.log(error)
          this.loading_form.isShow = false
        })
      }
    },
    // 新增
    append () {
      this.isAdd = true
      this.rowForm = {}
      this.showEditAll = true
      // console.log('新增')
    },
    // 编辑
    rowEdit: function (row,details) {
      // console.log(row)
      if(details=="details"){
        this.allDisabled=true
        this.titleText = '详情'
      }else{
        this.titleText = '编辑'
      }
      this.isAdd = false
      this.rowForm = {
        ...row
      }
      this.showEditAll = true
    },
    // 点击标题查看详情
    seeDetails(row){
      this.rowEdit(row,"details")
    },
    // 点击关闭弹窗
    close(){
       this.allDisabled=false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
