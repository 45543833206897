<template>
  <div class="left-wrapper">
    <div class="title">企业列表</div>
    <div class="alarmNav">
      <div class="icon-list">
        <input placeholder="企业名称" style="margin-right: 20px;"/>
        <i class="el-icon-plus" @click="append2"></i>
        <i class="el-icon-edit" @click="edit2"></i>
        <i class="el-icon-delete" @click="delete2"></i>
      </div>
      <ul>
        <li v-for="(item,index) in companyList" :key="index" class="alarmItem" @click="changeColor(index, item)"
          :class="index == colorIndex ?'setColor':''">{{item.alarmType}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponayList',
  props: {
    companyList: Array
  },
  methods: {
    addCompany () {
      this.$router.push('/home/basicInformation/companiesList')
    },
    editCompany () {

    },
    deleteCompany () {

    },
    changeColor () {

    }
  }
}
</script>

<style lang="scss" scope>
@import '~@/style/mixin.scss';

  .left-wrapper {
    width: 25%;
    height: calc(100% - 15px);
    min-width: 200px;
    padding: 0 0 0 15px;
    margin-top: 15px;
    box-sizing: border-box;
    & > div.title {
      // border-left: 2px solid $theme_bordercolor_0083ee;
      padding-left: 15px;
      background-color: #fff;
      line-height: 40px;
      padding: 10px 25px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 15px;
        bottom: 0;
        margin: auto;
        width: 2px;
        height: 12px;
        background-color: $theme_background_0083ee;
      }
    }

    .alarmNav {
      height: calc(100% - 75px);
      padding-top: 20px;
      border: 1px solid $theme_bordercolor_F0F2F5;
      background-color: $theme_background_fff;
      text-align: right;

      .icon-list > i {
        padding-right: 10px;
        cursor: pointer;
      }

      ul {
        height: calc(100% - 16px);
        margin-top: 15px;
        // border: 1px solid $theme_bordercolor_F0F2F5;
        background-color: $theme_background_fff;
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;

        .alarmItem {
          text-align: center;
          height: 40px;
          line-height: 40px;
          cursor: pointer;
        }

        .setColor {
          background-color: $theme_background_0083ee_70;
          color: $theme_color_fff
        }
      }
    }
  }
</style>
