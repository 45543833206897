<template>
  <!-- 设备列表 -->
  <!-- rowDetail: 详情操作->详情面板 -->
  <!-- append: 新增操作->新增面板 -->
  <!-- rowEdit: 编辑操作->编辑面板 -->
  <!-- rowDelete: 删除操作->删除面板 -->
  <!-- getInit: 获取ajax数据 -->
  <!-- outPut_: 导出数据 -->
  <div class="allwh minWidth1000">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData"
      :total="total" :loading="loading" @append="append"
      @rowEdit="rowEdit" @getInit="getInit" :valueFormat="formatter"
      @rowDelete="rowDelete"
      @lookRoom="lookRoom"
      @search="search" @reset="reset" >
      <template #buildingName="{row}">
        <div class="firstcell el-button--text" @click="seeDetails(row)">{{row.buildingName}}</div>
      </template>
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="showEditAll" width="40%" @close="allDisabled=false" :title="titleText">
      <ja-form :rules="rules" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData"
        @reset="showEditAll = false" @save="saveForm" v-if="showEditAll" v-loading="loading_form.isShow"
        :allDisabled="allDisabled"
        :element-loading-text="loading_form.text" :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg" :element-loading-custom-class="loading_form.formLoading">
      </ja-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getBuildingList,
  addBuilding,
  delBuilding,
  updateBuilding
} from '@/api'
import table from "@/views/mixins/table"

export default {
  title: '楼栋管理',
  mixins: [table],
  name: 'index',
  data () {
    return {
      filterData: { // 默认页码
        pageIndex: 1,
        pageSize: 10
      },
      allDisabled:false,
      conditionData: [ // 表格上方的搜索/选择操作栏
        {
          input_type: 'input',
          key: 'buildingName',
          attribute: {
            placeholder: '楼栋名称',
            size: 'small',
          }
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        },
        {
          text: '新增',
          input_type: 'button',
          method: 'append',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-plus',
             limit: 'building::management::add',
            plain: true
          }
          // limit: 'admin::user::add'
        }
      ],
      columns: [ // 表头数据
        {
          label: '楼栋名称',
          prop: 'buildingName',
          slotName:"buildingName",
          name:"buildingName"
        },
        {
          label: '地面层数',
          prop: 'aboveFloors',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '地下层数',
          prop: 'undergroundFloors'
        },
        {
          label: '电梯数量（部）',
          prop: 'elevator'
        },
        {
          label: '房间数（户）',
          prop: 'room'
        },
        {
          label: '操作',
          input_type: 'button',
          width: 200,
          attribute: {
            fixed: "right",
            align: 'center'
          },
          list: [
            {
              text: '编辑',
              method: 'rowEdit',
              limit: 'building::management::updata'
            },
            { text: '查看房间', method: 'lookRoom',
              limit:'building::management::viewRoom'
            },
            { text: '删除', method: 'rowDelete',limit:'building::management::del'},
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      // 新增/编辑
      titleText: '新增',
      rowFormData: [
        {
          input_type: 'input',
          label: '楼栋名称',
          key: 'buildingName',
           width: 300,attribute:{showOverflowTooltip:true,align:'center'},
         
        },
        {
          input_type: 'input',
          label: '地面层数',
          key: 'aboveFloors',
         
        },
        {
          input_type: 'input',
          label: '地下层数',
          key: 'undergroundFloors',
         
        },
        {
          input_type: 'input',
          type: 'textarea',
          label: '备注',
          key: 'remark',
          col: 22
        }
      ],
      rules: {
        buildingName: [{
          required: true,
          message: '请输入楼栋名称',
          trigger: 'blur'
        }],
        aboveFloors:[
          {
          validator: this.util.formRules.positiveinteger(),
          trigger: 'blur',
          }
        ],
        undergroundFloors:[
          {
          validator: this.util.formRules.positiveinteger(),
          trigger: 'blur',
          }
        ]
      },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`
    }
  },
  computed: {
  },
  mounted () {

  },
  methods: {
    formatter: function (val, key) {
      if (key === 'status') {
        return ['启用', '禁用'][val || 0]
      }
      return val || ''
    },
    getInit: function () {
      this.loading = true
      // 获取楼栋信息列表
      // const paramObj = this.util.paramChange(this.filterData, this.queryTableId)
      getBuildingList(this.filterData).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    saveForm: function () { // 保存
      this.loading_form.isShow = true
      const paramObj = this.util.paramChange(this.rowForm, this.queryTableId, 'edit')
      if (this.isAdd) {
        // 添加楼栋信息
        addBuilding(paramObj).then(res => {
          this.loading_form.isShow = false
          this.$message.success('新增成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          console.log(error)
          this.loading_form.isShow = false
        })
      } else {
        // 更新楼栋信息
        updateBuilding(paramObj).then(res => {
          this.loading_form.isShow = false
          this.$message.success('修改成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          console.log(error)
          this.loading_form.isShow = false
        })
      }
    },
    // 新增
    append () {
      this.isAdd = true
      this.rowForm = {}
      this.titleText = '新增'
      this.showEditAll = true
      // console.log('新增')
    },
    // 编辑
    rowEdit: function (row,details) {
      // console.log(row)
      if(details=="details"){
        this.allDisabled=true
        this.titleText = '详情'
      }else{
        this.titleText = '编辑'
      }
      this.isAdd = false
      this.rowForm = {
        ...row
      }
      this.showEditAll = true
    },
     // 删除
    rowDelete (row) { 
      this.$confirm("楼栋删除后，所有该楼栋下的物业数据将不再可用，请谨慎操作。").then(res => {
        console.log("row",row)
        delBuilding(
         row.id
        ).then(res=>{
         if(res.code==200){
            this.$message.success("已删除")
            this.total=this.total-1
            this.getInit()
         }
        })  
      })
    },
    // 查看房间
    lookRoom(val){
      // console.log("你好",val)
      this.$router.push({name:'basicInformation_roomManage',params: {id:val.id}})
    },
    seeDetails(row){
       this.rowEdit(row,"details") 
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
