<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="showExamine"
    v-if="showExamine"
    width="80%"
    @close="close"
    :title="titleText"
  >
    <ja-form
      :rules="rules"
      :label-width="labelWidth"
      v-model="rowForm"
      :formArray="rowFormData"
      @reset="refuse"
      @save="saveForm"
      :btnBox="btnBox"
      v-if="showExamine"
      ref="jaForm"
      v-loading="loading_form.isShow"
      :element-loading-text="loading_form.text"
      :element-loading-spinner="loading_form.icon"
      :element-loading-background="loading_form.bg"
      :element-loading-custom-class="loading_form.formLoading"
    >
      <template #title>
        <ul class="title">
          <li v-for="(item,i) in periodList" :key="i" @click="changeDate(item)">{{item}}</li>
        </ul>
      </template>
      <template #planMenuDetailTimeTypeDtos="{data}">
       <ul class="planMenuList">
          <li v-for="(item,i) in planMenuDetailTimeTypeDtos" :key="i">
          <p> {{item.timeType}}</p>
          <ja-table
            ref="tst"
            border
             :showPage="false"
            :columns="columns"
            :tableData="item.planMenuDetailTypeDtoList"
            > 
              </ja-table>
          </li>
       </ul>
      </template>
      <template #dishesDtos="{data}">
        <ul class="planMenuList dishesDtos">
          <li v-for="(item,i) in dishesDtos" :key="i">
            <ja-table
              ref="tst"
              :showPage="false"
              border
              :columns="columnsDishesDtos"
              :tableData="item.foodsCosts"
              > 
            </ja-table>
            <el-row>
              <el-col :span="12">供应商：{{item.supplier}}</el-col>
              <el-col :span="12">总价：{{item.foodsCosts|totalPrice}}</el-col>
            </el-row>
          </li>
        </ul>
      </template>
    </ja-form>
  </el-dialog>
</template>

<script>
import table from '@/views/mixins/table'
// import mealCardContent from './mealCardContent.vue'
import { getCheckPlan,updateStatus } from '@/api'
export default {
  mixins: [table],
  components: {
    // mealCardContent
  },
  filters:{
    totalPrice(list){
      let price=list.map(item=>{
        // console.log("price----item",item)
        return item.foodCostTotal
      })
      let filterPrice=price.filter(res=>{
        if(res!="undefined" || res!=null){
          return res
        }
      })
      // itemTotal.reduce((a,b)=>a+b)
      let total=0
      console.log("price25----",filterPrice)
      if(filterPrice.length!=0){
        total=filterPrice.reduce((a,b)=>a+b)
      }
      
      return total
    }
  },
  data() {
    return {
      titleText: '审核',
      showExamine: true,
      rowForm: {},
      list: [],
      labelWidth: `${this.util.setFontSize(140)}px`,
      summaryList: [],
      btnBox: [
        { label: '通过', method: 'save', type: 'primary', },
        { label: '拒绝', method: 'reset', col: 8, isCenter: true }
      ],
      columns: [
        {
          label: '菜式名称',
          prop: 'typeName',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '份数',
          prop: 'num'
        },
        {
          label: '单位',
          prop: 'unit'
        }
      ],
      columnsDishesDtos: [
        {
          label: '材料名称',
          prop: 'foodName',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '数量',
          prop: 'num'
        },
        {
          label: '单位',
          prop: 'unit'
        },
        {
          label: '单价',
          prop: 'cost'
        },
        {
          label: '总价',
          prop: 'foodCostTotal'
        }
      ],
      rowFormData: [
        {
          input_type: 'customize',
          customize: 'title',
          label: '',
          col: 24
        },
        {
          input_type: 'customize',
          customize: 'planMenuDetailTimeTypeDtos',
          label: '计划餐牌',
          // key: 'planMenuDetailTimeTypeDtos',
          col: 24
        },
        {
          input_type: 'customize',
          label: '采购明细表',
          customize: 'dishesDtos',
          col: 24,
          key: 'dishesDtos'
        }
      ],
      isAdd: true,
      rules: {
        barCode: [
          {
            required: true,
            message: '请输入关联条码',
            trigger: 'blur'
          }
        ],
        
      },
      periodList: [],
      dishesDtos:[],
      planMenuDetailTimeTypeDtos:[]
    }
  },
  props: {
    period: {
      type: String,
      default: ''
    },
    planId: {
      type: Number,
      default: ''
    }
  },
  watch: {
    // period(val) {
    //   let str = this.period.split('至')
    //   this.periodList=this.getAllDates(str[0],str[1])
    //   console.log("hahhah----",this.periodList,val)
    // },
  },
  created() {
    console.log(15151516161,this.planId)
    let str = this.period.split('至')
    this.periodList=this.getAllDates(str[0],str[1])
    this.getCheckPlan(this.planId,this.periodList[0])
  },
  methods: {
    // 计算指定日期范围内的所有日期
    getAllDates(begin, end) {
      //返回 日期的数组 如 ['2020-07-10','2020-07-11']
      var arr = []
      var ab = begin.split('-')
      var ae = end.split('-')
      var db = new Date()
      db.setUTCFullYear(ab[0], ab[1] - 1, ab[2])
      var de = new Date()
      de.setUTCFullYear(ae[0], ae[1] - 1, ae[2])
      var unixDb = db.getTime() - 24 * 60 * 60 * 1000
      var unixDe = de.getTime() - 24 * 60 * 60 * 1000
      for (var k = unixDb; k <= unixDe; ) {
        //console.log((new Date(parseInt(k))).format());
        k = k + 24 * 60 * 60 * 1000
        arr.push(new Date(parseInt(k)).Format('yyyy-MM-dd'))
      }
      return arr
    },
    getCheckPlan(planId,date){
      this.loading_form.isShow = true
      getCheckPlan({planId,date}).then(res=>{
        if(res.code==200){
          this.loading_form.isShow = false
          this.dishesDtos=res.data.dishesDtos
          this.planMenuDetailTimeTypeDtos=res.data.planMenuDetailTimeTypeDtos
          console.log("this.planMenuDetailTimeTypeDtos",res,this.planMenuDetailTimeTypeDtos)
        }
      })
    },
    updateStatus(planId,status){
      updateStatus({planId,status}).then(res=>{
        if(res.code=200){
          this.$message.success('审核成功')
          this.$emit("showExamine",false)
        }
      })
    },
    // 关闭
    close() {
      // console.log('关闭------')
      this.$emit("showExamine",false)
      // this.rowForm.addPanMenuDetailReqList=[]
    },
    refuse(){
      this.updateStatus(this.planId,1)
      // this.$emit("showExamine",false)
    },
    saveForm() {
      // 保存
      console.log('this.rowForm11111', this.rowForm)
      // this.loading_form.isShow = true
        this.updateStatus(this.planId,0)
        
    },
    addList() {},
    // 显示弹框
    showExamineDialog() {
      this.showExamine = true
    },
    // 点击头部
    changeDate(date){
      this.getCheckPlan(this.planId,date)
    }
  }
}
</script>


<style lang="scss" scoped>
.title {
  display: flex;
  flex-wrap: wrap;
  margin-left: 130px;
  li {
    width:90px;
    height: 30px;
    margin-bottom: 20px;
    line-height: 30px;
    text-align:center;
    margin-right: 10px;
    color: #fff;
    cursor: pointer;
    background:#409EFF;
  }
}
.planMenuList{
  display: flex;
  flex-wrap: wrap;
  li{
    width: 50%;
    p{
      text-align:center;
      margin-bottom: -60px;
    }
  }
}

</style>
