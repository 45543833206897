<template>
  <!-- 问卷调查 -->
  <div class="allwh">

    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" @append="normalEvent({ref:'addEdit',method:'append'})" @rowEdit="normalEvent({ref:'addEdit',method:'rowEdit'})" @rowDetail="normalEvent" @getInit="getInit" @search="search" @reset="reset">
    </ja-table>

    <!-- 查看详情 -->
    <detail ref="detail" />

    <!-- 新增 -->
    <add-edit ref="addEdit" />
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import Detail from './childComp/Detail.vue'
import AddEdit from './childComp/AddEdit'

export default {
  title: '问卷调查',
  mixins: [table],
  components: {
    Detail, AddEdit
  },
  data() {
    return {
      conditionData: [
        { input_type: 'input', key: 'contractCode', attribute: { placeholder: '标题', size: 'small' } },
        { input_type: 'date', value: 'createDate', type: 'date', placeholder: '有效期' },
        { input_type: 'select', key: 'category', attribute: { placeholder: '结果公开', size: 'small' }, options: [], },
        { input_type: 'select', key: 'category', attribute: { placeholder: '状态', size: 'small' }, options: [], },
        { input_type: 'select', key: 'attribute', attribute: { placeholder: '创建人', size: 'small' }, options: [], },
        { input_type: 'date', value: 'createDate', type: 'date', placeholder: '创建日期' },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
        { normalBtn: 'add', text: '新增问卷调查', },

      ],
      columns: [
        { label: '标题', prop: 'title', width: 300, attribute: { showOverflowTooltip: true, align: 'center' }, },
        { label: '问卷公开', prop: 'content', },
        { label: '结果公开', prop: 'receiverName' },
        { label: '有效期', prop: 'status', width: 140 },
        { label: '状态', prop: 'methodText', },
        { label: '发送人数', prop: 'senderName' },
        { label: '参与人数', prop: 'senderName' },
        { label: '创建人', prop: 'senderName' },
        { label: '创建时间', prop: 'createTime', width: 140 },
        {
          label: '操作', input_type: 'button', width: 160, attribute: { fixed: "right", align: 'center' },
          list: [
            { text: '编辑', method: 'rowEdit' },
            { text: '查看', method: 'rowDetail' },
            { text: '审批', method: 'rowCommit', },
          ]
        },
      ],
      indexObj: {}
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    // this.util.rqOptionsList({ who: [this.conditionData[this.indexObj.category]], rqUrl: "/sys/dictData/tenant_category", resRule: 'data' })
    // this.util.rqOptionsList({ who: [this.conditionData[this.indexObj.attribute]], rqUrl: "/sys/dictData/tenant_attribute", resRule: 'data' })
  },
  methods: {
    getInit() {
      this.loading = false
      this.tableData = [{}]
    }
  }
}
</script>
