<template>
  <!-- 系统设置-日志管理 -->
  <!-- rowDetail: 详情操作->详情面板 -->
  <!-- append: 新增操作->新增面板 -->
  <!-- rowEdit: 编辑操作->编辑面板 -->
  <!-- rowDelete: 删除操作->删除面板 -->
  <!-- getInit: 获取ajax数据 -->
  <!-- outPut_: 导出数据 单独导出 -->
  <!-- outPutExcell_obj: 导出数据 封装导出-->
  <!-- editDataFormat: 检测当前行显示的内容（如：根据条件显示待处理、已处理）+ 可根据自定义的key值判断 -->
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData"
      :total="total" :pgHide="total && total > 0 ? false : true" :loading="loading"
      @handleSelectionChange="handleSelectionChange" @getInit="getInit" @search="search" @reset="reset">
    </ja-table>
  </div>
</template>

<script>
import table from "@/views/mixins/table"
// import { GetAccessQuery } from "@/api"
import { logQuery, logColumn } from '../systemSettingColumn'
export default {
  title: '日志管理',
  mixins: [table],
  name: 'index',
  data () {
    return {
      labelWidth: `${this.util.setFontSize(150)}px`,
      filterData: {
        pageIndex: 1,
        pageSize: 10
      },
      conditionData: logQuery,
      columns: logColumn,
      tableData: [],
      loading: false,
      total: 0
    }
  },
  computed: {},
  created () {
    // 顶部搜索框
  },
  methods: {
    handleSelectionChange (val) {
      console.log('val', val)
    },
    getInit () {
      const tableData = this.tableData
      this.tableData = tableData.map(item => {
        const obj = item
        obj.imgList = []
        obj.imgList[0] = item.faceImg
        return obj
      })
    }
  }
}
</script>

<style scoped>
  /deep/ .ja_new_form .right-customize {
    display: block;
  }

  /deep/ .ja_new_form .right-customize .iconButton {
    position: absolute;
    left: 182px;
    top: 11px;
    width: 20px;
    height: 20px;
    line-height: 1;
    padding: 0;
  }

  /deep/ .ja_new_form .el-icon-plus {
    font-size: 10px;
    font-weight: bold;
  }

  /deep/ .ja_new_form .el-tag {
    margin-left: 10px;
  }

  /deep/ .ja_new_form .el-tag:first-child {
    margin-left: 0;
  }

  /deep/ .ja_new_form .el-tag .el-icon-close {
    line-height: 17px;
  }

</style>
