<template>
  <div>
    <!-- 个人调查数据 -->
    <el-dialog :visible="isShowPerSurvey" title="个人调查数据" :width="`40%`" append-to-body @close="close">
      <div class="person">调查人员：<span style="font-weight:bold">张大大</span></div>
      <div class="content">
        <el-card class="box-card" v-for="(item,index) in list" :key="index">
          <div slot="header" class="title">
            <span class="text">【{{index+1}}】{{item.title}}</span>
            <el-tag size="small" :type="item.type==0?'warning':''" class="right">{{typeList[item.type]}}</el-tag>
          </div>
          <div class="text item">{{item.answer}}</div>
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    isShowPerSurvey: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      typeList: ['单选', '多选'],
      list: [
        { title: '请问您对食堂的满意评分', answer: ' 满意（饭菜很好）', type: 1 },
        { title: '请问您期望的菜式', answer: ' 湘菜 / 粤菜', type: 0 },
        { title: '请问您期望的菜式请问您期望的菜式请问您期望的菜式请问您期望的菜式请问您期望的菜式请问您期望的菜式', answer: ' 湘菜 / 粤菜', type: 0 },
        { title: '请问您期望的菜式', answer: ' 湘菜 / 粤菜', type: 0 },
        { title: '请问您期望的菜式', answer: ' 湘菜 / 粤菜', type: 0 },
      ]

    }
  },
  created() {

  },
  methods: {
    rowDetail(row) {

    },
    close() {
      this.$emit('update:isShowPerSurvey', false)
    },

  }
}
</script>
<style lang='scss' scoped>
.content {
  margin-top: 30px;
  .box-card {
    margin-bottom: 10px;
    .title {
      .text {
        font-size: 16px;
        line-height: 1.8;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      .right {
        margin-left: 20px;
      }
    }
  }
}
/deep/ .el-card__header {
  // padding: 17px 20px;
}
</style>