<template>
  <!-- 设备列表 -->
  <!-- rowDetail: 详情操作->详情面板 -->
  <!-- append: 新增操作->新增面板 -->
  <!-- rowEdit: 编辑操作->编辑面板 -->
  <!-- rowDelete: 删除操作->删除面板 -->
  <!-- getInit: 获取ajax数据 -->
  <!-- outPut_: 导出数据 -->
  <div class="allwh minWidth1000">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData"
      :total="total" :loading="loading" @append="append" :showIndex="true"
      @rowEdit="rowEdit" @rowDelete="rowDelete" @getInit="getInit" :valueFormat="formatter"
      @search="search" @reset="reset">
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="showEditAll" width="40%" :title="titleText">
      <ja-form :rules="rules" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData"
        @reset="showEditAll = false" @save="saveForm" v-if="showEditAll" v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text" :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg" :element-loading-custom-class="loading_form.formLoading">
      </ja-form>
    </el-dialog>
    <!-- 分配通行权限 -->
    <el-dialog :close-on-click-modal="false" title="通行权限" :visible.sync="openDataScope" width="600px" append-to-body
      :v-loading="loading_form.isShow" :element-loading-text="loading_form.text"
      :element-loading-spinner="loading_form.icon" :element-loading-background="loading_form.bg"
      :element-loading-custom-class="loading_form.formLoading">
      <ja-scope class="box" :formArray="rowFormData2" :rowForm2="rowForm2" :checkedKeys="rowForm2.deviceIds"
        @submitDataScope="submitDataScope" @reset2="openDataScope=false">
      </ja-scope>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCompanyList,
  addCompanyInfo,
  updateCompanyInfo,
  deleteCompanyInfo,
} from '@/api'
import table from "@/views/mixins/table"
export default {
  title: '公司列表',
  mixins: [table],
  name: 'index',
  data () {
    return {
      filterData: { // 默认页码
        pageIndex: 1,
        pageSize: 10
      },
      conditionData: [ // 表格上方的搜索/选择操作栏
        {
          input_type: 'input',
          key: 'companyName',
          attribute: {
            placeholder: '公司名称',
            size: 'small'
          }
        },
        { normalBtn: 'search' },
        { normalBtn: 'reset' },
        { normalBtn: 'add',limit:'company::list::add' }
        // { normalBtn: 'download' }

      ],
      columns: [ // 表头数据
        {
          label: '公司名称',
          prop: 'companyName',
          attribute:{showOverflowTooltip:true,align:'center'}
        },
        {
          label: '公司地址',
          prop: 'address',
          attribute:{showOverflowTooltip:true,align:'center'}
        },
        {
          label: '负责人',
          prop: 'linkMan',
        },
        {
          label: '联系电话',
          prop: 'phone'
        },
        {
          label: '创建时间',
          prop: 'createTime',
          width:140,
        },
        {
          label: '操作',
          input_type: 'button',
          width: 150,
          attribute: {
            fixed: "right",
            align: 'center'
          },
          list: [
            {
              text: '编辑',
              method: 'rowEdit',
              limit: 'company::list::update'
            },
            {
              text: '删除',
              method: 'rowDelete',
              limit: 'company::list::del'
            }
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 0,
      // 新增/编辑
      titleText: '编辑',
      rowFormData: [
        {
          input_type: 'input',
          label: '公司名称',
          key: 'companyName'
        },
        {
          input_type: 'input',
          label: '公司地址',
          key: 'address'
        },
        {
          input_type: 'input',
          label: '负责人',
          key: 'linkMan'
        },
        {
          input_type: 'input',
          label: '联系电话',
          key: 'phone'
        }
      ],
      rules: {
        companyName: [{
          required: true,
          message: '请输入公司名称',
          trigger: 'blur'
        }],
        // address: [{
        //   required: true,
        //   message: '请输入公司地址',
        //   trigger: 'blur'
        // }],
        // link_man: [{
        //   required: true,
        //   message: '请输入负责人',
        //   trigger: 'blur'
        // }],
        phone: [{
          required: false,
          trigger: 'blur',
          phoneType:3,
          validator: this.util.formRules.checkPhone()
        }]
      },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`,
      // 通行权限
      rowFormData2: [
        {
          input_type: 'select',
          label: '所属楼栋',
          key: 'building_id',
          options: []
        },
        {
          input_type: 'select',
          label: '所属楼层',
          key: 'floor_id',
          options: []
        }
      ],
      rowForm2: {},
      openDataScope: false // 是否显示弹出层（数据权限）
    }
  },

  mounted () {
    
  },
  methods: {
    formatter: function (val, key) {
      if (key === 'status') {
        return ['启用', '禁用'][val || 0]
      }
      return val || ''
    },
    getInit: function () {
      this.loading = true
      getCompanyList(this.filterData).then(({ data }) => {
        this.tableData = data.data
        this.total = data.total
      })
      this.loading = false
      
    },
    rowDelete: function (row) { // 删除
      this.$confirm('请先删除公司下属部门与人员，否测无法删除！').then(res => {
        deleteCompanyInfo(row.id).then(res => {
          this.$message.success("已删除")
          this.getInit()
        })
      })
    },
   
    submitDataScope: function (row, checkedKeys) {
      /** 提交按钮（通行权限） */
      // console.log(row, checkedKeys)
      const param = {}
      param.companyId = row.id
      param.deviceIds = checkedKeys
      
    },
    saveForm: function () { // 保存
      this.loading_form.isShow = true
      const paramObj = this.util.paramChange(this.rowForm, this.queryTableId, 'edit')
      if (this.isAdd) {
        // 添加公司信息
        addCompanyInfo(paramObj).then(res => {
          this.loading_form.isShow = false
          this.$message.success('新增成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          console.log(error)
          this.loading_form.isShow = false
        })
      } else {
        // 更新公司信息
        updateCompanyInfo(paramObj).then(res => {
          this.loading_form.isShow = false
          this.$message.success('修改成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          console.log(error)
          this.loading_form.isShow = false
        })
      }
      this.loading_form.isShow = false
    },
    // 新增
    append () {
      this.isAdd = true
      this.titleText = '新增'
      this.rowForm = {}
      this.showEditAll = true
    },
    // 编辑
    rowEdit(row) {
      this.isAdd = false
      this.titleText = '编辑'
      this.rowForm = {
        ...row
      }
      this.showEditAll = true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
