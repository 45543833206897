import util from '@/util/util.js'

// 访客信息
const table_columns = {
  pc: [
    { label: '姓名', prop: 'name', width: `${util.setFontSize(100)}px` },
    { label: '联系电话', prop: 'phone', },
    { label: '所属单位', prop: 'dept', attribute: { showOverflowTooltip: false }, width: `${util.setFontSize(150)}px` },
    { label: '健康码、行程码', prop: 'img', slotName: 'img', width: `${util.setFontSize(160)}px` },

  ],
  wx: [
    { label: '姓名', prop: 'name' },
    { label: '联系电话', prop: 'phone' },
    { label: '健康码、行程码', prop: 'img', slotName: 'img', width: `${util.setFontSize(160)}px` },
  ]
}
// 公共的
const common = [
  [
    { input_type: 'text2', label: '申请人姓名', key: 'applyPeople', },
    { input_type: 'text2', label: '申请人公司', key: 'applyCompany', },
  ],
  [
    { input_type: 'text2', label: '申请人电话', key: 'applyPhone', },
    { input_type: 'text2', label: '申请类型', key: 'applyTypeName', },
  ],
  [
    { input_type: 'customize', customize: 'applyDetails', label: '访客信息', key: 'applyDetails', col: 22, alignItems: 'flex-start' },
    { input_type: 'text2', label: '访客人数', key: 'applyPeopleNum', },
    { input_type: 'text2', label: '车牌号', key: 'carNum', },
  ],
  [
    { input_type: 'text2', label: '来访时间', key: 'visitorTime', col: 24, },
  ],
  [
    { input_type: 'text2', label: '拜访单位公司', key: 'dockingCompany', },
    { input_type: 'text2', label: '拜访单位', key: 'dockingDept', },
  ]
]

// 电脑端的新增
const pc = [
  ...common[0],
  { input_type: 'text2', label: '申请人部门', key: 'applyDept', },
  ...common[1],

  { input_type: 'text2', label: '来访类型', key: 'visitorTypeName', options: [] },

  ...common[2],
  ...common[3],
  { input_type: 'text2', label: '接待区域', key: 'receptionArea', col: 24, },
  { input_type: 'text2', label: '事由', key: 'remark', col: 24, },

  { input_type: 'title', text: '对接人信息', col: 24, noMarginBottom: true },
  { input_type: 'text2', label: '对接人', key: 'dockingPeople', },
  { input_type: 'text2', label: '对接人电话', key: 'dockingPhone', },
  { input_type: 'text2', label: '对接人公司', key: 'dockingCompany', },
  { input_type: 'text2', label: '对接人部门', key: 'dockingDept', },
]

// 手机端的外部邀约
const wx_public = [
  ...common[0],
  ...common[1],
  ...common[2],
  ...common[3],
  ...common[4],
  { input_type: 'customize', customize: 'uplpadfaceImg', label: '附件', key: 'applyDetails', col: 22, alignItems: 'flex-start' },
]

// 手机端的内部邀约
const wx_inside = [
  ...common[0],
  { input_type: 'text2', label: '申请人部门', key: 'applyDept', },
  ...common[1],
  ...common[2],
  ...common[3],
  ...common[4],
]

export default {
  table_columns,
  pc,
  wx_public,
  wx_inside
}