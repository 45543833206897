<template>
  <!--  普通的表单 -->
  <el-dialog :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" :title="isAdd?'新增':'编辑'">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll" >
      <template #msg>
        <div style="marginRight:80px;">
          <editor-mce :config="Config" :value.sync="rowForm.content" :Id="Date.now()" ref="msg" :url="$uploadUrl+'/oss/upload'"></editor-mce>
        </div>
      </template>
      <template #employeeIds>
        <div class="msgContent">
          <div class="tagContent">
            <el-tag class="tags" v-if="rowFormData[2].options.length>0 && !rowForm.checked_f" :key="index" v-for="(item,index) in rowFormData[2].options" closable :disable-transitions="true" @close="handleClose(item)">
              {{item.deptName}}（{{item.nickname}}）
            </el-tag>
            <!-- isAll = 0 时才会展示 -->
            <div v-if="rowForm.checked_f">{{rowForm.except_person_name?`所有人，除了“${rowForm.except_person_name}”`:'所有人'}}</div>
          </div>
          <el-button @click="selectPerson()" type="primary" size="samll" class="btn">选择人员</el-button>
        </div>
      </template>
    </ja-form>

    <el-dialog width="80%" title="选择用户" :visible.sync="showSelectBox" v-if="showSelectBox" append-to-body class="personBox">
      <select-user @selectRow="selectRow" :selectedPersons="[...selectedPersons]" v-if="showSelectBox" :isOnePerson="false" :isShowSelectAll="true" :params_f="{...rowForm.params}" :except_person_f="[...rowForm.except_person]" :except_person_name_f="rowForm.except_person_name" :checked_f="rowForm.checked_f" />
    </el-dialog>
  </el-dialog>
</template>
<script>
import editorMce from '@/components/editorMce'
import selectUser from './selectUser/selectUer.vue'
import { addMsgTask, eidtMsgTask } from '@/api'

export default {
  inject: ['labelWidth', 'getInit',],
  components: {
    editorMce,
    selectUser
  },
  data() {
    return {
      Config: { height: 300 },
      rowFormData: [
        // { input_type: 'select', label: '模板名称', key: 'template', maxlength: 20, col: 18, options: [], method: 'selectEventId', },
        { input_type: 'input', label: '标题', key: 'title', maxlength: 20, col: 18, },
        { input_type: 'customize', customize: 'msg', label: '消息内容', key: 'content', col: 24, },
        // { input_type: 'input', type: 'textarea', label: '消息内容', key: 'content', col: 24, minRows: 4, maxlength: 2000 },
        { label: '接收人', key: 'employeeIds', input_type: 'customize', customize: 'employeeIds', isRequired: true, multiple: true, col: 24, options: [], styleWidth: '60%' }
      ],
      rowForm: {},
      rules: {
        title: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
        content: [{ required: true, message: '请输入消息内容', trigger: 'change' }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      rowFormData_indexObj: {},
      //选择用户
      showSelectBox: false,
      selectedPersons: []
    }
  },
  watch: {
    'rowForm.content': {
      handler: function () {
        if (this.$refs.jaForm) {
          this.$refs.jaForm.$refs.formbox.validateField('content')
        }
      }
    }
  },
  created() {
    this.rowFormData_indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
  },
  methods: {
    append() {
      this.isAdd = true
      this.rowForm = JSON.parse(JSON.stringify({
        checked_f: false,
        content: '',
      }))
      this.rowForm.params = {}
      this.selectedPersons = []
      this.$set(this.rowForm, 'employeeIds', [])
      this.rowFormData[this.rowFormData_indexObj.employeeIds].options.splice(0, this.rowFormData[this.rowFormData_indexObj.employeeIds].options.length)
      this.showEditAll = true
    },
    rowEdit(row) {
      this.isAdd = false
      this.titleText = '编辑'
      this.showEditAll = true
      this.rowForm = { ...row }
      this.$set(this.rowForm, 'employeeIds', [])
      let receiveObj = { ...row.sysMessageConditionDto }
      if(receiveObj.params){
        receiveObj.params.companyId = Number(receiveObj.params.companyId)  || ''
      }
      this.rowForm.params = { ...receiveObj.params || {} }
      let obj = {}
      obj.list = [...receiveObj.employees || []]
      obj.checked = receiveObj.isAll == 0
      obj.params = receiveObj.params || {}
      obj.except_row = receiveObj.except_person || []
      obj.except_rowIds = receiveObj.ignoreEmployeeIds || []
      let strNames = ''
      obj.except_row.forEach(item => { strNames = strNames + item.nickname + '、' })
      strNames = strNames.substring(0, strNames.length - 1)
      obj.except_rowNames = strNames

      this.selectRow(obj)

    },
    selectEventId() {
      const obj = this.rowFormData[0].options.find(item => item.value == this.rowForm.eventId)
      this.$set(this.rowForm, 'content', obj.content)
      this.$set(this.rowForm, 'title', obj.title)
    },
    saveForm() {
      // 接收人
      const obj = JSON.parse(JSON.stringify(this.rowForm))
      obj.contentType = obj.type
      if (!this.rowForm.checked_f && this.rowForm.employeeIds.length == 0) {//若不是全选
        this.$message.warning('请选择接收人')
        return
      }
      this.loadingForm = true
      let rqMethod = this.isAdd ? addMsgTask : eidtMsgTask
      rqMethod(obj).then(res => {
        this.loadingForm = false
        this.$message.success('提交成功')
        this.showEditAll = false
        this.getInit()
      }).catch(error => {
        this.loadingForm = false
      })
    },
    selectPerson() {
      this.showSelectBox = true
    },
    // 接收人 tag标签 的删除
    handleClose(tag) {
      console.log('要删除的', tag)
      this.rowFormData[this.rowFormData_indexObj.employeeIds].options.splice(this.rowFormData[this.rowFormData_indexObj.employeeIds].options.indexOf(tag), 1)
      const del_index = this.rowForm.employeeIds.findIndex(item => item == tag.id)
      this.rowForm.employeeIds.splice(del_index, 1)
      const del_index2 = this.selectedPersons.findIndex(v => v.id == tag.id)
      this.selectedPersons.splice(del_index2, 1)
    },
    // 选择用户
    selectRow(res) {
      this.showSelectBox = false
      const { list, checked, params, except_row, except_rowIds, except_rowNames } = res
      this.selectedPersons = list
      this.$set(this.rowForm, 'checked_f', checked || false)

      if (!checked) {
        ['params', 'ignoreEmployeeIds', 'except_person_name'].forEach(v => {
          delete this.rowForm[v]
        })

        this.rowFormData[this.rowFormData_indexObj.employeeIds].options = [...list]
        this.rowForm.employeeIds = list.map(item => item.id)
        this.rowForm.isAll = 1
      } else {
        // 全选的话
        this.rowFormData[this.rowFormData_indexObj.employeeIds].options.splice(0, this.rowFormData[this.rowFormData_indexObj.employeeIds].options.length)
        this.rowForm.employeeIds.splice(0, this.rowForm.employeeIds.length)
        this.rowForm.isAll = 0
        this.$set(this.rowForm, 'params', params)
        this.$set(this.rowForm, 'except_person', except_row)
        this.$set(this.rowForm, 'ignoreEmployeeIds', except_rowIds)
        this.$set(this.rowForm, 'except_person_name', except_rowNames)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-tag.tags {
  margin-right: 10px;
}
/deep/ .msgContent {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .tagContent {
    padding: 8px;
    width: 100%;
    border: 1px solid #dcdfe6;
    margin-right: 10px;
    border-radius: 4px;
    min-height: 100px;
  }
  .btn {
    flex-shrink: 0;
  }
}
.personBox {
  // z-index: 3000 !important;
}
</style>