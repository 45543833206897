import { MessageBox } from 'element-ui'
import http from '@/api/module/plugin/ajax'
import {stringToVariable , dealOptions } from './common'
import $config from './config'

Date.prototype.Format = function (fmt) {
  //author: meizz
  var o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds() //毫秒
  }
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  for (var k in o)
    if (new RegExp('(' + k + ')').test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1
          ? o[k]
          : ('00' + o[k]).substr(('' + o[k]).length)
      )
  return fmt
}

export default {

  /**
   * 不同分辨率echarts字体设置
   * @param {*} width 传入width
   * @param {*} designWidth 设计稿width
   */
  setFontSize: function (width, designWidth = 1920) {
    // let docEl = document.documentElement
    const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    if (!clientWidth) return
    const fontSize = (clientWidth / designWidth)
    return width * fontSize
  },
  // legend的根据name获取百分比
  checkLegendValue(name, arr, showValue) {
    let value = 0
    if (arr.length === 0) return 0
    const sum = arr.reduce((pre, cur) => {
      pre += cur.value
      return pre
    }, 0)
    arr.forEach(v => {
      if (v.name === name) {
        if (showValue) {
          // 显示值
          value = v.value
        } else {
          // 显示百分比
          value = sum === 0 ? 0 : (v.value * 100 / sum).toFixed(2) + '%'
        }
      }
    })
    return value
  },
  /**
   * // 对象中的数据填充进数组相应的value
   * @param {*} obj 传入的对象
   * @param {*} arr 待填充数据的数组（数组对象需包含key字段，值为传入obj的键名）
   * @param {*} string 待填充值的数组对象的键名
   */
  ObjToArr(obj, arr, value = 'value') {
    arr.forEach(v => {
      for (const key in obj) {
        if (key === v.key) {
          v[value] = obj[key]
        }
      }
    })
    return arr
  },

  /**
   * // 随机数生成
   * @param {*} size 生成随机数的个数 默认 1
   * @param {*} min 最小数 默认 10
   * @param {*} max 最大数 默认 100
   * * */
  createRandom(size = 1, min = 10, max = 100) {
    const arr = []
    for (let i = 0; i < size; i++) {
      arr.push(Math.round(Math.random() * (max - min)) + min)
    }
    return size === 1 ? arr[0] : arr
  },
  /**
   * // 删除表格数据
   * @param {*} cb 回调函数
   * @param {*} content 提示内容
   * * */
  confirmData(content, cb) {
    if (!cb) {
      cb = content
      content = '是否删除改条数据'
    }
    MessageBox(content).then(() => {
      cb()
    }).catch(() => {
      this.$message.info('已取消')
    })
  },
  // 导出excel表格，对返回数据的处理
  dealExcelRespose(res, filename) {
    const link = document.createElement('a')
    const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    link.style.display = 'none'
    link.href = URL.createObjectURL(blob)
    let name = filename || ""
    if(res.headers && res.headers['content-disposition']){
      name = decodeURIComponent(res.headers['content-disposition'].split(';')[1].split("filename=")[1])
    }
    link.download = name// 下载的文件名
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  },
  /**
   * 构造树型结构数据
   * @param {*} data 数据源
   * @param {*} id id字段 默认 'id'
   * @param {*} parentId 父节点字段 默认 'parentId'
   * @param {*} children 孩子节点字段 默认 'children'
   * @param {*} rootId 根Id 默认 0
   */
  handleTree(data, id, parentId, children, rootId) {
    id = id || 'id'
    parentId = parentId || 'parentId'
    children = children || 'children'
    rootId = rootId || Math.min.apply(Math, data.map(item => { return item[parentId] })) || 0
    // 对源数据深度克隆
    const cloneData = JSON.parse(JSON.stringify(data))
    // 循环所有项
    const treeData = cloneData.filter(father => {
      const branchArr = cloneData.filter(child => {
        // 返回每一项的子级数组
        return father[id] === child[parentId]
      })
      branchArr.length > 0 ? father.children = branchArr : ''
      // 返回第一层
      return father[parentId] === rootId
    })
    return treeData != '' ? treeData : data
  },
  // 表单清空
  resetForm(refName) {
    if (this.$refs[refName]) {
      this.$refs[refName].resetFields()
    }
  },
  // 数字范围下拉框
  numberRange(min, max,who) {
    const arr = []
    for (let i = min; i < max + 1; i++) {
      arr.push({
        label: i,
        value: i
      })
    }
    if(who){
      who.options = arr
    }
    return arr
  },
  // 表单规则验证
  formRules: {
    // 手机号验证
    checkPhone: () => {
      return function (rule, value, callback) {
        const value_rule = /^[1][3,4,5,6,7,8,9][0-9]{9}$/  // 手机号 ，默认只验证这个   phoneType  = 1
        const value_rule2 = /\d{3}-\d{8}|\d{4}-\d{7}/  // 电话 ，如  010-12345678     phoneType  = 2
        const value_rule3 = /(^1([3|4|5|7|8|])\d{9}$)|(^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$)/  // 手机号  与 座机电话   phoneType  = 3
        if (rule.required) {
          if (value === undefined || value === "" || value.trim() === '' || value === null) { // 如果为空
            return callback(new Error('请输入手机号'))
          }
        }
        if(value !== "" && value !== undefined && value !== null){
          let val = value.trim()
          if(!val){
            return callback('号码不能为空格')
          }
          if ( (!value_rule.test(val) &&  (!rule.phoneType ||rule.phoneType ==1))
           || rule.phoneType == 2 && !value_rule2.test(val)  || rule.phoneType == 3 && !value_rule3.test(val)  
          ) {
            return callback(new Error('号码格式不正确'))
          }else {
            return callback()
          }
        }else{
          return callback()
        }
      }
    },
    // 正整数
    positiveinteger:()=>{
      return function (rule, value, callback){  
        if(value!=="" || rule.required){
          if(Number.isInteger(Number(value)) && Number(value) >= 0 && Number(value) <= 200){                
            callback();
          }else{                 
            callback(new Error("请输入有效数字0-200"));               
          } 
        }else{
          callback();
        }
        
      }    
    },
    // 身份证号验证
    checkIdCard: () => {
      return function (rule, value, callback) {
        const value_rule = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$)/
        if (rule.required) {
          if (value === undefined || value === "") { // 如果为空
            return callback(new Error('请输入身份证号'))
          }
        }
        if (!value_rule.test(value) && value !== "" && value !== undefined) {
          // console.log(1111, value && value !== 0)
          return callback(new Error('身份证号输入有误'))
        } else {
          return callback()
        }
      }
    },
    // 数字范围验证
    checkNumber: ({
      max = window.Infinity, min = 0, maxErrorText = '数值不能大于' + max, minErrorText = '数值不能小于' + min, nullText = "请输入数值",
      fixedNum, isInt = false, isIncludeMin = true, isIncludeMax = true
    } = {}) => {
      return function (rule, value, callback) {
        // console.log('数字  value', value, rule)
        if (rule.required) {
          if (value === undefined || value === "" || value === null || (!value && value != 0)) { // 如果为空
            // console.log('验证数字===》', value)
            return callback(new Error(nullText))
          }
        }
        if(value !== "" && value !== undefined && value !== null){
          if (isNaN(Number(value))) {
            return callback(new Error('请输入数字'))
          }
          if (isInt) {
            if (value.toString().includes('.')) { // 若是小数
              return callback(new Error('请输入整数'))
            }
          }
          if (fixedNum) {
            if (value.toString().includes('.')) {
              const smallNum = value.toString().split('.')[1].length
              if (smallNum > fixedNum) {
                return callback(new Error(`只能保留${fixedNum}位小数`))
              }
            }
          }
          if (value > max) {
            return callback(new Error(maxErrorText))
          } else if (value == max && !isIncludeMax) {
            return callback(new Error(`最大值不能超过${max}`))
          } else if (value < min) {
            return callback(new Error(minErrorText))
          } else if (value == min && !isIncludeMin) {
            return callback(new Error(`最小值要超过${min}`))
          } else {
            return callback()
          }
        }else{
          return callback()
        }
      }
    },
    // 三维坐标验证
    checkCoordinate: () => {
      return function (rule, value, callback) {
        const value_rule = /^[0-9]{3},[0-9]{3},[0-9]{3}$/
        if (rule.required) {
          if (value === undefined || value === "") { // 如果为空
            return callback(new Error('请输入三维坐标'))
          }
        }
        if (!value_rule.test(value) && value !== "" && value !== undefined) {
          return callback(new Error('三维坐标输入有误'))
        } else {
          return callback()
        }
      }
    }
  },
  // 表单涉及到的常用方法
  formFun: {
    // 点击 “全选”多选框
    checkAllFun: (allBoxObj, formArr, value_arr) => {
      if (allBoxObj.checkAll) {
        formArr.splice(0, formArr.length, ...value_arr)
      } else { // 如果是全选
        formArr.splice(0, formArr.length)
      }
    },
    // 点击 单选 多选框
    checkboxItemFun(allBoxObj, checked_options, value_arr) {
      if (checked_options.length == value_arr.length) { // 若全部选择
        allBoxObj.checkAll = true
      } else {
        allBoxObj.checkAll = false
      }
    }
  },
  /**
   * 清除下拉列表的 options 跟 值
   * @param {*} Arr 要传入的数组，如 this.conditionData, this.rowFormData
   * @param {*} keyName  要传入字段的对应字段。如，this.conditionData，就是 ‘key’,  而 this.columns   就是 ‘prop’
   * @param {*} keyWord  要被 遍历的字段
   * @param {*} This  传 this
   * @param {*} who 要清除谁的值
   */
   // 清除下拉列表的 options 跟 值
  clearSelectList({Arr = [],keyName,keyWord,This,who}){
    let index = Arr.findIndex(item=>item[keyName] === keyWord)
    This.$set(who,keyWord,'')
    Arr[index].options = []
  },
  /**
   * 点击重置 或新增 时，要清除关联的下拉列表
   * @param {*} arr 要传入的数组，如 this.conditionData, this.rowFormData
   */
  clearSelectOptions(arr){
    // console.log('arr===>',arr)
    let clearArr = []
    arr.forEach(item=>{
      if(item.relevance){
        const {sonKey,otherSonKey} = item.relevance
        if(sonKey){
          if(!clearArr.includes(sonKey)) clearArr.push(sonKey)
        }
        if(otherSonKey){
          otherSonKey.forEach(item=>{
            if(!clearArr.includes(item))clearArr.push(item)
          })
        }
      }
    })
    if(clearArr.length>0){
      clearArr.forEach(item=>{
        let index = arr.findIndex(v=>v.key == item)
        // console.log(index,arr[index])
        arr[index].options = []
      })
    }
  },
   /**
   * 点击编辑弹窗，处理下拉列表的初始化
   * @param {*} arr  传 要被遍历的数组，如 this.rowFormData,
   * @param {*} ref  传 ja-form 的 ref 名
   * @param {*} obj  传 表单对象 ，如 this.rowForm
   * @param {*} This  传 this
   */
  setOptionsInit({arr,ref,obj,This}){
    let initArr = []
    arr.forEach((item,index)=>{
        const relevance = item.relevance
        if(relevance && relevance.type == 'select' && relevance.sonKey){
            initArr.push({
                key:item.key,
                index
            })
        }
    })

    if(initArr.length>0){
            initArr.forEach(item=>{
            // console.log("This.$refs[ref]---",This.$refs[ref])
            This.$refs[ref].dealSelect(arr[item.index], obj[item.key],true)
        })
    }
},
  /**
   * 点击编辑弹窗，处理下拉列表的初始化。针对嵌套在表单里的表格里的下拉框
   * @param {*} arr  传 要被遍历的数组，如 this.rowFormData,
   * @param {*} ref  传 ja-form 的 ref 名
   * @param {*} objArr  传 表单的表格数组 ，如 this.rowForm.tableList
   * @param {*} This  传 this
   */
  setOptionsInit2({arr,ref,objArr,This}){
    let initArr = [] // 找到了所有 拥有 sonKey 父Key
    arr.forEach((item,index)=>{
        const relevance = item.relevance
        if(relevance && relevance.type == 'select' && (relevance.sonKey ||relevance.sonArr && relevance.sonArr.length > 0 )){
          initArr.push(item.prop)
        }
    })
    // console.log('处理******',initArr,objArr,arr)
    if(initArr.length>0){
      objArr.forEach((item,index)=>{
        initArr.forEach(v=>{
          let itemData = arr.find(objItem=>objItem.prop == v)
          This.$refs[ref].$refs.JaTable[0].dealTableFormSelect(itemData,item[v],true,index)
        })
      })
    }
  },
  /**
   * // 返回 options的列表， 下拉列表
   * @param {*} rqUrl 数据源
   * @param {*} rqData 请求参数，可以是字符串，或多项
   * @param {*} who  谁要改变options，
   * @param {*} isLastOk 是否上一个请求完成了,用于 需要得到上一个异步得结果时
   * @param {*} labelName 作为 options 数组 的{label:'',value:''},label 读取的是后端哪一个字段
   * @param {*} valueName 作为 options 数组 的{label:'',value:''},value 读取的是后端哪一个字段
   * @param {*} rqMethod 请求方法，默认是 get
   * @param {*} otherKeys options 数组的每一项，是否还需返回的其它值，[{keyName,keyValue}]
   * @param {*} resRule  针对后端返回的层级，可以自定义，如要读取的是 “res.data.list” ,就传入  resRule:'data.list'
   * @param {*} isArrayList  若为true 返回数组，如后端 返回 [{label:'哈哈',value:'1'},{label:'苹果',value:'3'},]，能处理为数组 【'','哈哈','','苹果'】
   * @param {*} isReturnAll  是否返回一个项的所有字段
   */
  // 返回 options的列表，
  rqOptionsList: ({ rqUrl = '', rqData, who = null, isLastOk = true, labelName, valueName, rqMethod = 'get', otherKeys = [],resRule = null,isArrayList = false,This = null,isReturnAll = false}) => {
    return new Promise((resolve, reject) => {
      if (!isLastOk) {
        return false
      } else {
        // 数字字典
        http[rqMethod](rqUrl, rqData).then(res => {
          let data = resRule ? stringToVariable({oldObj:res,str:resRule}) : res.data.data
          let options = dealOptions({optionsArr:data,labelName, valueName, otherKeys,isArrayList ,isReturnAll})
          if (who) {
              if (Array.isArray(who)) {
                who.forEach((item, index) => {
                  item.options = options
                  // item.options = []
                  // item.options.splice(0, item.options.length, ...options)
                })
              } else {
                who.options = options
                // who.options = []
                // who.options.splice(0, who.options.length, ...options)
                // console.log('who---',who)
              }
          }
          resolve(options)
        }).catch(err => {
          reject(err)
          console.log('err==>数据请求', err)
        })
      }
    })
  },
  isAuth(key) {
    return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
  },
  /**
   * 通用接口入参格式转化
   * @param {*} object 原始入参格式
   * @param {*} queryTableId 当前接口的操作对应的table_id
   * @param {*} scenes 操作场景 edit: 编辑
   */
  paramChange(object, queryTableId, scenes) {
    let paramObj = {} // 入参JSON
    if (scenes === 'edit') {
      for (let key in object) {
        if (object.hasOwnProperty(key) === true) {
          const keyNew = this.toCamel(key)
          paramObj[keyNew] = object[key]
        }
      }
    } else {
      // const filterArr = ['pageIndex', 'pageSize', 'currentPage', 'showCount'] // 不需要转换的key
      const filterArr = ['pageIndex', 'pageSize',] // 不需要转换的key
      paramObj.queryFieldList = []
      paramObj.queryTableId = queryTableId // 固定的
      for (let key in object) {
        if (object.hasOwnProperty(key) === true) {
          if (filterArr.indexOf(key) === -1) {
            let obj = {}
            obj.field = this.toCamel(key)
            obj.value1 = object[key]
            paramObj.queryFieldList.push(obj)
          } else {
            paramObj[key] = object[key]
          }
        }
      }
    }
    return paramObj
  },
  toCamel(a) {
    return a.replace(/_(\w)/g, function (a, b) {
      return b.toUpperCase()
    })
  },
  /**
   * 日期格式化
   * @param {*} fmt 格式化格式
   * @param {*} date 日期
   * @returns
   */
  dateFormat (fmt, date) {
    let ret
    const opt = {
      'Y+': date.getFullYear().toString(), // 年
      'm+': (date.getMonth() + 1).toString(), // 月
      'd+': date.getDate().toString(), // 日
      'H+': date.getHours().toString(), // 时
      'M+': date.getMinutes().toString(), // 分
      'S+': date.getSeconds().toString() // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }
    for (const k in opt) {
      ret = new RegExp('(' + k + ')').exec(fmt)
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
      }
    }
    return fmt
  },
  // 将对象转拼接在请求地址上的字符串
  rqObjToStr(obj){
      let str = ""
      if(obj){
          let keys = Object.keys(obj)
          keys.forEach(k=>{
              str = str +`&${k}=${obj[k]}`
          })
          str = str.slice(1)
      }
      return str
  },
  // 创建 a 链接
  exportConfig(rqUrl,fileName,isNotDown){
      // 文件名自定义
      if("download" in document.createElement("a")){
          const elink = document.createElement("a")
          if( !isNotDown ) {
            elink.download = fileName || ''
          }else{
            elink.target='_blank'
          }
          elink.style.display = "none"
          elink.href = rqUrl
          document.body.appendChild(elink)
          elink.click()
          document.body.removeChild(elink)
      }
  },
  /**
   * 得知某一项， 获得该项 在当前数组，的索引。最终返回一个对象
   * @param {*} arr 传进来的数组。如，this.rowFormData
   * @param {*} labelName  传进来要查的字段 
   * 
   */
  getArrItemIndex({arr,labelName='key'}){
    let obj = {}
    arr.forEach((item,index)=>{
      let isObj = Object.prototype.toString.call(obj) === '[object Object]'
      if(isObj){ // 若是对象
        if(item[labelName]){
          obj[item[labelName]] = index
        }
      }
    })
    return obj
  },

  //防抖函数
  debounce(func,delay=500){
    let timer=null;
    return function(...args){
        if(timer){
          clearTimeout(timer)
        }
        timer=setTimeout(()=>{
            func.apply(this,args)
        },delay)
    }
  },
  // 两数相乘
  accMul(arg1,arg2){
    var m=0,s1=arg1.toString(),s2=arg2.toString();
    try{m+=s1.split(".")[1].length}catch(e){}
    try{m+=s2.split(".")[1].length}catch(e){}
    return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m)
  },
  // 两数相加
  accAdd(arg1, arg2) {
    var r1, r2, m, c;
    try {
        r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
        r2 = 0;
    }
    c = Math.abs(r1 - r2);
    m = Math.pow(10, Math.max(r1, r2));
    if (c > 0) {
        var cm = Math.pow(10, c);
        if (r1 > r2) {
            arg1 = Number(arg1.toString().replace(".", ""));
            arg2 = Number(arg2.toString().replace(".", "")) * cm;
        } else {
            arg1 = Number(arg1.toString().replace(".", "")) * cm;
            arg2 = Number(arg2.toString().replace(".", ""));
        }
    } else {
        arg1 = Number(arg1.toString().replace(".", ""));
        arg2 = Number(arg2.toString().replace(".", ""));
    }
    return (arg1 + arg2) / m;
  },
  // 返回 资源文件 的数组，暂时针对的是 图片 跟 视频
  backImgVideoObj(attachmentList){
    let arr = attachmentList.map(item => {
      if (!item) return
      let imgsTypeArr = $config.imgType
      let videoType = $config.videoType
      let arrItem = item.split('/')
      let suffix = arrItem[arrItem.length-1].split('.')[1]
      return {
        url: item, 
        raw: { type: imgsTypeArr.includes(suffix) ? 'image' : videoType.includes(suffix)?'video':'other'  },
        status: 'success',
        response: { data: item }
      }
    })
    return arr
  },
}
