<template>
  <!-- 消息任务列表 -->
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :editDataFormat="editDataFormat" @rowDelete="rowDelete" @rowDetail="normalEvent" :loading="loading" @rowCheck="rowCheck" @append="normalEvent({ref:'addEdit',method:'append'})" @rowEdit="normalEvent" @getInit="getInit" @search="search" @reset="reset">
      <template #content="{row}">
        <div class="table_content">
          {{row.contentText }}
        </div>
      </template>
    </ja-table>

    <add-edit ref="addEdit" />
    <detail ref="detail" />
    <!-- 审核 -->
    <result-order ref="resOrder" />
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit'
import Detail from './childComp/Detail.vue'
import ResultOrder from './childComp/ResultOrder.vue'

import { getMsgTaskList, delMsgTask } from '@/api'

export default {
  title: '消息任务列表',
  mixins: [table],
  components: {
    AddEdit, Detail, ResultOrder
  },
  data() {
    return {
      statusOptions: [
        { label: '审批中', value: 0 },
        { label: '审批通过', value: 1 },
        { label: '审批拒绝', value: 2 },
      ],
      conditionData: [
        { input_type: 'input', key: 'title', attribute: { placeholder: '标题名称', size: 'small' } },
        { input_type: 'select', key: 'status', attribute: { placeholder: '状态', size: 'small' }, options: [], },
        // { input_type: 'select', key: 'sendType', attribute: { placeholder: '发送方式', size: 'small' }, options: [], },
        { input_type: 'input', key: 'createBy', attribute: { placeholder: '创建人', size: 'small' } },
        { input_type: 'date', value: 'createTime', type: 'date', placeholder: '创建日期', valueFormat: 'yyyy-MM-dd' },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
        { normalBtn: 'add', text: '新增消息任务', limit:'msg::msgTask::add'},
      ],
      columns: [
        { label: '标题', prop: 'title', attribute: { showOverflowTooltip: true, align: 'center' } },
        // { label: '内容', prop: 'content', attribute: { showOverflowTooltip: true, align: 'center' }, },
        { label: '内容', prop: 'contentText', width: 250, attribute: { showOverflowTooltip: true, align: 'center' }, slotName: 'content' },
        { label: '状态', prop: 'statusValue' },
        // { label: '发送方式', prop: 'sendTypeValue', },
        { label: '创建人', prop: 'createBy' },
        { label: '创建时间', prop: 'createTime', },
        {
          label: '操作', input_type: 'button', width: 160, attribute: { fixed: "right", align: 'center' },
          list: [
            { text: '查看', method: 'rowDetail' },
            { text: '编辑', method: 'rowEdit', limit:'msg::msgTask::edit' },
            { text: '删除', method: 'rowDelete', limit:'msg::msgTask::delete' },
            { text: '审核', method: 'rowCheck', limit:'msg::msgTask::audit'},
          ]
        }
      ],
      indexObj: {}
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    this.conditionData[this.indexObj.status].options = this.statusOptions
    // this.util.rqOptionsList({ who: [this.conditionData[this.indexObj.sendType]], rqUrl: "/sys/dictData/message_type", resRule: 'data' })
  },
  methods: {
    getInit() {
      this.loading = true
      const rqData = this.rqParmas()
      getMsgTaskList(rqData).then(res => {
        this.loading = false
        const { total, data } = res.data
        this.tableData = (data || []).map(item => {
          let content = item.content
          var arrEntities={'lt':'<','gt':'>','nbsp':' ','amp':'&','quot':'"'}; 
          item.contentText = content.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ').replace(/&(lt|gt|nbsp|amp|quot);/ig,function(all,t){return arrEntities[t];})
                                .replace(/&ldquo;/g,'“').replace(/&rdquo;/g,'”').replace(/&mdash;/g,'——')
          return item
        })
        this.total = total
      })
    },
    rowCheck(row) {
      this.$refs.resOrder.append(row)
    },
    editDataFormat(row, btn) {
      if (row.status != 0 && (btn.text == '审核' || btn.text == '编辑' || btn.text == '删除')) {
        return false
      } else {
        return true
      }
    },
    // 删除
    rowDelete(row) {
      this.$confirm(`是否确认删除标题为“${row.title}”的`).then(res => {
        delMsgTask(row.id).then(res => {
          this.$message.success("已删除")
          this.getInit()
        })
      })
    },
  }
}
</script>
<style scoped>
.table_content {
  max-height: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
