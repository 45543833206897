<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      @getInit="getInit"
      @search="search"
      @rowDelete="rowDelete"
      @reset="reset"
    >
      <template #pictureUrl="{row}">
        <!-- <div class="firstcell el-button--text" @click="seeDetails(row)">{{row.pictureUrl}}</div> -->
        <el-image
          style="width: 50px; height: 50px"
          :src="row.pictureUrl"
          :preview-src-list="[row.pictureUrl]"
          fit="cover"
        ></el-image>
      </template>
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="40%"
      @close="close"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :allDisabled="allDisabled"
        @reset="showEditAll = false"
        @save="saveForm"
        @directivesFocus="directivesFocus"
        v-if="showEditAll"
        ref="jaForm"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
      </ja-form>
    </el-dialog>
    <!-- 设备列表 -->
    <el-dialog :close-on-click-modal="false"
                :visible.sync="divideShow"
                width="50%"
                title="选择设备">
      <divideList @diviideList="diviideList" v-if="divideShow"></divideList>
    </el-dialog>
  </div>
</template>
<script>
import {
  deviceGroupGet,
  deviceGroupAdd,
  deviceGroupEdit,
  deviceGroupDelete,
} from '@/api'
import table from '@/views/mixins/table'
import divideList from './components/divideList.vue'
export default {
  title: '设备分组',
  mixins: [table],
  name: 'merchantManage',
  components:{
    divideList
  },
  data() {
    return {
      show: false,
      showUser: false,
      divideShow:false,
      parkId: 1,
      allDisabled: false,
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
        // cityId:''
      },
      conditionData: [
        {
          input_type: 'select',
          key: 'category',
          attribute: { placeholder: '设备大类', size: 'small' },
          options: [],
          relevance: {
            sonKey: 'subcategory',
            This: this,
            rqParam: {
              rqUrl: '/device/category',
              rqData: () => {
                return {
                  params: {
                    pageIndex: 1,
                    pageSize: 200,
                    isAll: '2',
                    parentId: this.filterData.category
                  }
                }
              },
              labelName: 'categoryName',
              valueName: 'id'
            }
          }
        },
        {
          input_type: 'select',
          key: 'subcategory',
          attribute: { placeholder: '设备小类', size: 'small' },
          options: []
        },
        {
          input_type: 'input',
          key: 'deviceCode',
          styleWidth: '200px',
          attribute: {
            placeholder: '设备编号',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'deviceName',
          attribute: {
            placeholder: '设备名称',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'groupName',
          attribute: {
            placeholder: '分组名称',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'createBy',
          attribute: {
            placeholder: '创建人',
            size: 'small'
          }
        },
        {
          input_type: 'date',
          value: 'createTime',
          type: 'date',
          attribute: {
            placeholder: '创建日期',
            size: 'small'
          }
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '新增',
          input_type: 'button',
          method: 'append',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-plus',
            plain: true
          },
          limit: 'iot::divideGroups::add'
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '分组名称',
          prop: 'groupName'
        },
        {
          label: '关联设备数',
          prop: 'deviceCount'
        },
        {
          label: '设备小类',
          prop: 'subcategoryName'
        },
        {
          label: '创建人',
          prop: 'createBy',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '创建时间',
          prop: 'createTime'
        },
        {
          label: '操作',
          input_type: 'button',
          width: 180,
          attribute: {
            fixed: 'right',
            align: 'center'
          },
          list: [
            {
              text: '编辑',
              method: 'rowEdit',
              limit: 'iot::divideGroups::edit'
            },
            {
              text: '删除',
              method: 'rowDelete',
              limit: 'iot::divideGroups::del'
            }
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 10,
      titleText: '编辑',
      initFileList: [],
      diviideValList:[],
      rowFormData: [
        {
          input_type: 'input',
          label: '分组名称',
          key: 'groupName',
          maxlength:'30',
          col:22
        },
        {
          input_type: 'input',
          label: '关联设备',
          clearable:true,
          focusMethod:'directivesFocus',
          col:22,
          key: 'devicesNameList'
        },
      ],
      checkImgSuccess: false,
      isExist: null,
      rules: {
        groupName: [
          {
            required: true,
            message: '请输入分组名称',
            trigger: 'blur'
          }
        ],
        devicesNameList: [
          {
            required: true,
            message: '请先选择相同的设备小类！',
            trigger: 'change',
            validator:(rule, value, callback)=>{
                console.log("vlue---",value)
                if(value !="" && value!=undefined ){
                   console.log("vlue-434--",value)
                  let res=this.rowForm.devices.some((value, index)=>{
                      return this.rowForm.devices[0]['subcategory']!=value['subcategory']
                  })
                  if(res){
                     return callback(new Error('请先选择相同的设备小类！！！'))
                  }else{
                    return callback()
                  }
                }else{
                  return callback(new Error('请先选择设备小类！'))
                }
                 
            }
          }
        ]
      },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`,
      // 图片相关
      headers: {},
      dialogVisible: false,
      dialogObj: null,
      fileList: [],
      isSearchIng: false,
      isAdd: true
    }
  },
  created() {
    //  console.log("$uploadUrl",this.$uploadUrl)
  },
  mounted() {
    const paramObj = { params: { pageIndex: 1, pageSize: 200, isAll: '1' } }
    // 顶部搜索框
    
    this.util.rqOptionsList({
      who: [
        this.conditionData[0],
      ],
      rqUrl: '/device/category',
      rqData: paramObj,
      rqMethod: 'get',
      labelName: 'categoryName',
      valueName: 'id'
    }) 
  },
  methods: {
    getInit: function () {
      this.loading = true
      deviceGroupGet(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    // 新增
    append() {
      this.titleText = '新增'
      this.isAdd = true
      this.rowForm = {}
      this.initFileList = []
      this.util.clearSelectOptions(this.rowFormData)
      this.showEditAll = true
    },
    // 编辑
    rowEdit: function (row, details) {
      this.isAdd = false

      // console.log("row",row)
      this.rowForm = {
        ...row
      }
      // console.log(this.initFileList)
      if (details == 'details') {
        this.titleText = '详情'
      } else {
        this.titleText = '编辑'
      }
      let list=row.devices.map(res=>{
        return res.deviceName
      }) 
      list.join(',')
      console.log("什么鬼--", this.rowForm)
      this.$set(this.rowForm,'devicesNameList',list.join(','))
      this.showEditAll = true
    },

    // 删除
    rowDelete(row) {
      this.$confirm(
        '确定删除该条数据吗？'
      ).then((res) => {
        console.log('row', row)
        deviceGroupDelete(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    saveForm: function () {
      // 保存
      // console.log("this.rowForm11111",this.rowForm)
      this.loading_form.isShow = true
      this.rowForm.subcategory=this.rowForm.devices[0]['subcategory']
      this.rowForm.category= this.rowForm.devices[0]['category']
      if (this.isAdd) {
        //  添加园区信息
        deviceGroupAdd(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('新增成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      } else {
        //  更新园区信息
        deviceGroupEdit(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('修改成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      }
    },
    // 关闭表单弹窗
    directivesFocus(monitor, val, data){
      console.log(monitor, val, data)
      this.divideShow=true
    },
    diviideList(val){
      // this.$refs.jaForm.children.itemEventBtn()
      let changeList=val.map(res=>{
        return {
          deviceName:res.deviceName,
          deviceId:res.id,
          subcategory:res.subcategory,
          category:res.category
        }
      }) 
      this.rowForm.devices=this._.unionBy(this.rowForm.devices,changeList,'deviceId')
      let list=this.rowForm.devices.map(res=>{
        return res.deviceName
      }) 
      list.join(',')
      this.$set(this.rowForm,'devicesNameList',list.join(','))
      this.divideShow=false;
    },
    close(){
     
    }
  },
  watch:{
    "rowForm.devicesNameList" (newVal, oldVal){
      if(newVal==""){
        console.log("nihhh")
        this.rowForm.devices=[]
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}

// 上传图像
.imgDefault {
  width: 150px;
  height: 200px;
  border: 1px solid $theme_bordercolor_DCDFE6;
}

/deep/ .el-upload__tip {
  position: absolute;
  width: 20%;
  top: 45%;
  left: 37%;
  text-align: center;
  line-height: 1;
  margin: 10px 0 8px 0;
}

.upload-img-form /deep/ .el-upload--picture-card {
  background-color: initial;
  border: initial;
  border-radius: initial;
  box-sizing: initial;
  width: initial;
  height: initial;
  line-height: initial;
  vertical-align: initial;
  display: inline-block;
  text-align: initial;
}

/deep/ ul.el-upload-list--picture-card {
  display: block;
}

/deep/ .el-upload-list.el-upload-list--text {
  width: 50%;

  > li.el-upload-list__item {
    cursor: pointer;
  }
}

// 上传图片按钮
.uploadBtn {
  position: absolute;
  top: 30%;
  left: 40%;
  color: $theme_color_0083ee;
  background: transparent;
  border: 1px solid $theme_bordercolor_0083ee;

  &:hover,
  &:focus {
    background: transparent;
    color: $theme_color_0083ee;
  }
}

// 图片缩略图
.videoContent {
  width: 40%;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 180px;
      height: 200px;
      flex-shrink: 0;
      margin: 0;
      border: 1px solid $theme_bordercolor_DCDFE6;
      border-radius: 0;
      overflow: hidden;
      position: relative;

      &:hover {
        .videoPlay_IconBox {
          display: none;
        }
      }

      .img-avatar {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
