<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      @getInit="getInit"
      @search="search"
      @rowDelete="rowDelete"
      @reset="reset"
    >
      <template #pictureUrl="{row}">
        <!-- <div class="firstcell el-button--text" @click="seeDetails(row)">{{row.pictureUrl}}</div> -->
        <el-image
          style="width: 50px; height: 50px"
          :src="row.pictureUrl"
          :preview-src-list="[row.pictureUrl]"
          fit="cover"
        ></el-image>
      </template>
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="40%"
      @close="close"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :allDisabled="allDisabled"
        @reset="showEditAll = false"
        @save="saveForm"
        @clickFloorDevice="clickFloorDevice"
        @clickSelfDevice="clickSelfDevice"
        v-if="showEditAll"
        ref="jaForm"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
      </ja-form>
    </el-dialog>
    <!-- 选择设备弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showDevice"
      width="50%"
      title="选择设备"
    >
      <deviceList
        @appendDevice="appendDevice"
        :deviceParams="deviceParams"
        v-if="showDevice"
      ></deviceList>
    </el-dialog>
    <!-- 选择设备弹窗end -->
  </div>
</template>
<script>
import {
  bizBusinessGet,
  bizBusinessAdd,
  bizBusinessEdit,
  bizBusinessDelete,
  dictData
} from '@/api'
import table from '@/views/mixins/table'
import deviceList from "./components/deviceList.vue"
export default {
  title: '商户管理',
  mixins: [table],
  name: 'merchantManage',
  components:{
    deviceList
  },
  data() {
    return {
      show: false,
      showUser: false,
      parkId: 1,
      allDisabled: false,
      showDevice:false,
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
        // cityId:''
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'contractCode',
          styleWidth: '200px',
          attribute: {
            placeholder: '合同编号',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'corporateName',
          styleWidth: '200px',
          attribute: {
            placeholder: '公司名',
            size: 'small'
          }
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '新增',
          input_type: 'button',
          method: 'append',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-plus',
            plain: true
          },
          limit: 'merchant::merchantManage::add'
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '合同编号',
          prop: 'contractCode'
        },
        {
          label: '账务编号',
          prop: 'accountingCode'
        },
        {
          label: '公司名',
          prop: 'corporateName',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '性质',
          prop: 'nature',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '位置',
          prop: 'position'
        },
        {
          label: '自用面积',
          prop: 'area'
        },
        {
          label: '操作',
          input_type: 'button',
          width: 180,
          attribute: {
            fixed: 'right',
            align: 'center'
          },
          list: [
            {
              text: '编辑',
              method: 'rowEdit',
              limit: 'merchant::merchantManage::update'
            },
            {
              text: '删除',
              method: 'rowDelete',
              limit: 'merchant::merchantManage::del'
            }
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      titleText: '编辑',
      initFileList: [],
      rowFormData: [
        {
          input_type: 'input',
          label: '合同编号',
          key: 'contractCode'
        },
        {
          input_type: 'input',
          label: '财务编号',
          key: 'accountingCode'
        },
        {
          input_type: 'input',
          label: '公司名',
          key: 'corporateName',
        },
        {
          input_type: 'select',
          label: '性质',
          key: 'nature',
          options:function () {
            return dictData('business_type').then((res) => {
              if (res.code == 200) {
                let data = res.data.map((item) => {
                  return {
                    label: item.dictLabel,
                    value: item.dictValue
                  }
                })
                return data
              }
            })
          }
        },
        {
          input_type: 'input',
          label: '自用面积',
          key: 'area',
        },
        { label:'所属楼栋', input_type: 'select',key: 'buildingId', 
          relevance:{ sonKey:'floorId', This:this, type:'select', otherSonKey:['roomId'],rqParam:{ rqUrl:"/floor/list",rqData:()=>{return { pageIndex: 1, pageSize: 200, buildingId: this.rowForm.buildingId } },rqMethod: 'post', labelName: 'floorName', valueName: 'id' }, }
        },
        { label:'所属楼层', input_type: 'select',key: 'floorId',options: [],
          relevance:{ sonKey:'roomId', This:this, type:'select', rqParam:{ rqUrl:"/room/list",rqData:()=>{return { pageIndex: 1, pageSize: 200,  floorId: this.rowForm.floorId } },rqMethod: 'post', labelName: 'roomName', valueName: 'id' }, }
        }, 
        { input_type: 'select', label: '绑定房间', key: 'roomId', hide:true, options: [],},
        {
          input_type: 'input',
          label: '位置',
          key: 'position',
        },
        {
          input_type: 'input',
          label: '楼层分摊比例',
          key: 'floorAllocationProportion',
        },
        {
          input_type: 'input',
          label: '园区分摊比例',
          key: 'parkAllocationProportion',
        },
        {
          input_type: 'input',
          label: '楼层电表设备',
          key: 'floorDeviceCodes',
          focusMethod:'clickFloorDevice',
        },
        {
          input_type: 'input',
          label: '自用电表设备',
          key: 'selfDeviceCodes',
          focusMethod:'clickSelfDevice',
        },
      ],
      checkImgSuccess: false,
      isExist: null,
      rules: {
        contractCode: [
          {
            required: true,
            message: '请输入合同编号',
            trigger: 'blur'
          }
        ],
        accountingCode: [
          {
            required: true,
            message: '请输入财务编号',
            trigger: 'change'
          }
        ],
        corporateName: [
          {
            required: true,
            message: '请输入公司名',
            trigger: 'blur'
          }
        ],
        position: [
          {
            required: true,
            message: '请输入位置',
            trigger: 'blur'
          }
        ],
        buildingId: [
          {
            required: true,
            message: '请选择楼栋',
            trigger: 'change'
          }
        ],
        floorId: [
          {
            required: true,
            message: '请选择楼层',
            trigger: 'change'
          }
        ],
        nature: [
          {
            required: true,
            message: '请选择性质',
            trigger: 'change'
          }
        ],
        area: [
          {
            // message: '请填写建议价格',
            required: true,
            validator: this.util.formRules.checkNumber({ min: 0, fixedNum: 2 }),
            trigger: 'change'
          }
        ],
        floorAllocationProportion:[
          {
            required: false,
            trigger: 'blur',
            validator:this.util.formRules.checkNumber({ min: 0, fixedNum: 2 }),
          }
        ],
        parkAllocationProportion:[
          {
            required: false,
            trigger: 'blur',
            validator:this.util.formRules.checkNumber({ min: 0, fixedNum: 2 }),
          }
        ],
      },
      rowForm: {
      },
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`,
      // 图片相关
      headers: {},
      dialogVisible: false,
      dialogObj: null,
      fileList: [],
      isSearchIng: false,
      isAdd: true,
      deviceParams:{}
    }
  },
  created() {
    //  console.log("$uploadUrl",this.$uploadUrl)
  },
  mounted() {
     // 表单里的{category: 0, subcategory: 1}
    const rowParamObj = { pageIndex: 1, pageSize: 200 }
    this.util.rqOptionsList({ who: this.rowFormData[5], rqUrl: "/building/list", rqData: rowParamObj, rqMethod: 'post', labelName: 'buildingName', valueName: 'id' }) // 楼栋选择
  },
  methods: {
    getInit: function () {
      this.loading = true
      bizBusinessGet(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    // 新增
    append() {
      this.titleText = '新增'
      this.isAdd = true
      this.rowForm = {}
      this.$set(this.rowForm,"floorDeviceCodes","")
      this.$set(this.rowForm,"selfDeviceCodes","")
      this.initFileList = []
      this.util.clearSelectOptions(this.rowFormData)
      this.showEditAll = true
    },
    // 编辑
    rowEdit: function (row, details) {
      this.isAdd = false

      // console.log("row",row)
      this.rowForm = {
        ...row
      }
      console.log(this.initFileList)
      if (details == 'details') {
        this.titleText = '详情'
      } else {
        this.titleText = '编辑'
      }
      this.$nextTick(()=>{
          this.util.setOptionsInit({arr:this.rowFormData,This:this,obj:this.rowForm,ref:'jaForm'})
      })
      // console.log("什么鬼--", [].map(Number))
      this.showEditAll = true
    },

    // 删除
    rowDelete(row) {
      this.$confirm(
        '确定删除该条数据吗？'
      ).then((res) => {
        console.log('row', row)
        bizBusinessDelete(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    saveForm: function () {
      // 保存
      // console.log("this.rowForm11111",this.rowForm)
      this.loading_form.isShow = true
      if (this.isAdd) {
        //  添加园区信息
        bizBusinessAdd(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('新增成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      } else {
        //  更新园区信息
        bizBusinessEdit(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('修改成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      }
    },
    // 关闭表单弹窗
    close(){
     
    },
    /***
     * 设备弹窗，添加设备按钮事件
     */
    appendDevice(list,billingType){
      this.showDevice=false;
      let listVal=list.map(res=>{
        return res.deviceCode
      })
      if(billingType==2){ //楼层电表
        let floorList=this._uniqueArr(this.rowForm.floorDeviceCodes.split(','),listVal);
        this.$set(this.rowForm,"floorDeviceCodes",floorList.join(','))
      }else if(billingType==1){ //自用电表设备 
      //  console.log("list---",list,listVal,this.rowForm.selfDeviceCodes,billingType)
        let selfFiletrList=this._uniqueArr(this.rowForm.selfDeviceCodes.split(','),listVal);
        this.$set(this.rowForm,"selfDeviceCodes",selfFiletrList.join(','))
      }
    },
    /**
     * 数组合并，并去重
     */
    _uniqueArr(arr1=[],arr2=[]) {
        //合并两个数组
        arr1.push(...arr2)//或者arr1 = [...arr1,...arr2]
        //去重
        let arr3 = Array.from(new Set(arr1))   //let arr3 = [...new Set(arr1)]
        let arr4=arr3.filter(item=>{
          if( item !== ""){
             return item
          }
        })
        return arr4
    },
        /**
     * 点击楼层电表设备input框
     */
    clickFloorDevice(){
      this.showDevice=true;
      this.deviceParams={
        category: 112,
        subcategory: 125,
        billingType:2
      }
    },
    /**
     * 点击自用电表设备input框
     */
    clickSelfDevice(){
      this.showDevice=true
      this.deviceParams={
        category: 112,
        subcategory: 125,
        billingType:1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}

// 上传图像
.imgDefault {
  width: 150px;
  height: 200px;
  border: 1px solid $theme_bordercolor_DCDFE6;
}

/deep/ .el-upload__tip {
  position: absolute;
  width: 20%;
  top: 45%;
  left: 37%;
  text-align: center;
  line-height: 1;
  margin: 10px 0 8px 0;
}

.upload-img-form /deep/ .el-upload--picture-card {
  background-color: initial;
  border: initial;
  border-radius: initial;
  box-sizing: initial;
  width: initial;
  height: initial;
  line-height: initial;
  vertical-align: initial;
  display: inline-block;
  text-align: initial;
}

/deep/ ul.el-upload-list--picture-card {
  display: block;
}

/deep/ .el-upload-list.el-upload-list--text {
  width: 50%;

  > li.el-upload-list__item {
    cursor: pointer;
  }
}

// 上传图片按钮
.uploadBtn {
  position: absolute;
  top: 30%;
  left: 40%;
  color: $theme_color_0083ee;
  background: transparent;
  border: 1px solid $theme_bordercolor_0083ee;

  &:hover,
  &:focus {
    background: transparent;
    color: $theme_color_0083ee;
  }
}

// 图片缩略图
.videoContent {
  width: 40%;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 180px;
      height: 200px;
      flex-shrink: 0;
      margin: 0;
      border: 1px solid $theme_bordercolor_DCDFE6;
      border-radius: 0;
      overflow: hidden;
      position: relative;

      &:hover {
        .videoPlay_IconBox {
          display: none;
        }
      }

      .img-avatar {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
