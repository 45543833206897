<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowDetail="normalEvent"
      @rowEdit="rowEdit"
      @getInit="getInit"
      @search="search"
      @rowDelete="rowDelete"
      @reset="reset"
    >
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="50%"
      @close="close"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :allDisabled="allDisabled"
        @reset="showEditAll = false"
        @save="saveForm"
        v-if="showEditAll"
        ref="jaForm"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
      <template #detailList="{row}"> 
        <div>
          <material-table :datas="datas" @addList="addList($event)" :formType="formType" ref="material" :foodNameList="foodNameList"></material-table>
        </div>
      </template>
      </ja-form>
    </el-dialog>
    <!-- 查看详情 -->
    <detail ref="detail"/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Detail from './components/Detail'
import {
  getPurchase,
  addPurchase,
  editPurchase,
  delPurchase,
  getPurchaseDetail,
  getPlanPersonInfo
} from '@/api'
import table from '@/views/mixins/table'
import materialTable from "./components/materialTable.vue"
export default {
  title: '餐品采购管理',
  mixins: [table],
  name: 'index',
  components:{
    materialTable,Detail
  },
  data() {
    return {
      show: false,
      showUser: false,
      parkId: 1,
      allDisabled: false,
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'purchaseCode',
          attribute: {
            placeholder: '采购编号',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'planCode',
          attribute: {
            placeholder: '关联计划编号',
            size: 'small'
          }
        },
        {
          input_type: 'select',
          key: 'status',
          attribute: {
            placeholder: '状态',
            size: 'small',
            clearable: true
          },
          options: [{label: "采购中", value: 0}, {label: "已入库", value: 1}, {label: "取消", value: 2}]
        },
        {
          input_type: 'input',
          key: 'nickname',
          attribute: {
            placeholder: '采购人',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'supplier',
          attribute: {
            placeholder: '供应商',
            size: 'small'
          }
        },
        {
          input_type: 'date',
          value: 'createDate',
          type: 'date',
          placeholder: '创建日期'
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '新增',
          input_type: 'button',
          method: 'append',
          size: 'small',
          limit: 'purchase::purchaseManage::add',
          attribute: {
            type: 'primary',
            icon: 'el-icon-plus',
            plain: true
          },
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '采购单号',
          prop: 'purchaseCode'
        },
        {
          label: '关联计划编号',
          prop: 'planCode'
        },
        {
          label: '采购金额',
          prop: 'totalCost'
        },
        {
          label: '供应商',
          prop: 'supplier'
        },
        {
          label: '状态',
          prop: 'status'
        },
        {
          label: '备注',
          prop: 'remark'
        },
        {
          label: '采购人',
          prop: 'nickname'
        },
        {
          label: '创建时间',
          prop: 'createTime'
        },
        {
          label: '操作',
          input_type: 'button',
          width: 180,
          attribute: {
            fixed: 'right',
            align: 'center'
          },
          list: [
            {
              text: '编辑',
              method: 'rowEdit',
              limit: 'purchase::purchaseManage::update'
            },
            {
              text: '查看',
              method: 'rowDetail',
              limit: 'purchase::mealPlan::see'
            },
            {
              text: '删除',
              method: 'rowDelete',
              limit: 'purchase::purchaseManage::del'
            }
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      titleText: '编辑',
      initFileList: [],

      rowFormData: [
         {
          input_type: 'radio',
          label: '类型',
          key: 'formType',
          options:[
            {label:'普通填单',value:0},
            {label:'关联计划编号 填单',value:1},
          ],
          col:24
        },
        {
          input_type: 'input',
          label: '关联计划编号',
          key: 'planCode',
        },
        {
          input_type: 'input',
          label: '计划人信息',
          key: 'nickname',
          disabled:true
        },
        {
          input_type: 'select',
          label: '供应商',
          key: 'supplier',
          options:[],
        },
        {
          input_type: 'input',
          label: '计划人电话',
          key: 'mobile',
          disabled:true
        },
        {
          input_type: 'customize',customize:'detailList',label: '采购明细', key:'detailList',col:22,
        },
        {
          input_type: 'input',
          label: '总采购价',
          key: 'totalCost',
          disabled: true,
        },
        {
          input_type: 'input',
          label: '备注',
          type: 'textarea',
          col: 22,
          key: 'remark'
        },
        
      ],
      checkImgSuccess: false,
      isExist: null,
      rules: {
        planCode: [{ required: true, trigger: 'change', validator:this.checkPlanCode}],
        detailList: [{ required: true, message: '采购明细为空', trigger: 'blur'}],
        supplier: [{ required: true, message: '请选择供应商', trigger: 'blur'}],
      },

      rowForm: {},
      nsideData:[],
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`,

      debounce_fun:null,
      formType:'input', // 表单类型 ，还有 select
      indexObj_rFD:{},
      foodNameList:[] , // 采购明细 的 材料名称 是下拉框时 的 options 
    }
  },
  computed: {
    ...mapGetters(['roles'])
  },
  created() {
     this.debounce_fun = this.util.debounce(this.getPlanDetail, 400);
     this.indexObj_rFD = this.util.getArrItemIndex({ arr: this.rowFormData })
  },
  watch:{
    'rowForm.formType':{
      handler(val){
          console.log('触发--rowForm.formType',val,this.isAdd)
          
             this.formType = val== 0 ?'input':'select'
            this.dealFormItem_IsShow()
            if(this.isAdd){
            this.clearDetailList()
          }
         
      }
    },
    'rowForm.supplier':{
      handler(val,old){
        if(val && val != old ){
          console.log('触发--rowForm.supplier')
          let item = this.rowFormData[this.indexObj_rFD.supplier].options.find(item=>item.value == val)
          if(old){
            this.clearDetailList()
          }
          this.foodNameList = item.foodList.map(v=> ({label:v.foodName,value:v.foodName,...v}))
        }
      }
    }
  },
  mounted() {},
  methods: {
    getInit: function () {
      this.loading = true
      // 查询列表信息
      getPurchase({params: this.filterData}).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    // 处理 编辑 与 新增 的区别
    dealEditAdd(formType,isBoolean){
      this.formType = formType
      this.$set(this.rowFormData[this.indexObj_rFD.formType],'hide',isBoolean)
      this.$set(this.rowFormData[this.indexObj_rFD.planCode],'disabled',isBoolean)
      this.$set(this.rowFormData[this.indexObj_rFD.supplier],'disabled',isBoolean)
    },
    // 新增
    append() {
      this.titleText = '新增'
      this.isAdd = true
      this.rowForm = {formType:0}
      this.datas=[]
      this.util.clearSelectOptions(this.rowFormData)
      this.dealEditAdd('input',false)
      this.showEditAll = true
      this.dealFormItem_IsShow()

    },
    // 编辑method, row, scope, button
    rowEdit (row, details,scope) {
      // console.log("今天",row, details,scope.text)
      this.isAdd = false
      this.datas=row.detailList
      this.titleText = '编辑'
      this.dealEditAdd(row.planCode ? 'select':'input',true)
      if(this.formType == 'select'){
        let rqData = { planCode:row.planCode, pageIndex:1,  pageSize:200 }
         getPurchaseDetail(rqData).then(res=>{
            let supplierList = res.data.data.map(item=>({value: item.supplier,label:item.supplier,foodList:item.foodList}))
            this.rowFormData[this.indexObj_rFD.supplier].options = supplierList
            this.rowForm = {...row,formType:1}
            this.showEditAll = true
            console.log('this--->',this.rowForm)
        })
       
      }else{
        this.rowForm = {...row,formType:0}
        this.showEditAll = true
      }
      
     
    },
    // 删除
    rowDelete(row) {
      this.$confirm(
        '确定删除该条数据吗？'
      ).then((res) => {
        console.log('row', row)
        delPurchase(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success('已删除')
          } else {
            this.$message.warning(res.msg)
          }
          
          this.getInit()
        })
      })
    },
    saveForm: function () {
      // 保存
      // console.log("this.rowForm11111",this.rowForm)
      this.loading_form.isShow = true
      if (this.isAdd) {
        //  添加信息
        addPurchase(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            if (res.code === 200) {
              this.$message.success('新增成功')
            } else {
              this.$message.warning(res.msg)
            }
            
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      } else {
        //  更新信息
        editPurchase(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            if (res.code === 200) {
              this.$message.success('修改成功')
            } else {
              this.$message.warning(res.msg)
            }
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      }
    },
    // 关闭表单弹窗
    close(){
       this.allDisabled = false
    },
    // 新增增或删除
    addList({datas,sum}){
      // 计算总价
      this.$set(this.rowForm, 'detailList', datas)
      this.$set(this.rowForm, 'totalCost', sum)
      // this.rowForm.detailList=datas
      this.$refs.jaForm.$refs.formbox.clearValidate('detail')
    },

    // 检查关联计划编号
    checkPlanCode(rule, value, callback){
      this.debounce_fun(rule,value, callback)
    },
    async getPlanDetail(rule,value, callback){
      if( value === '' ||value && value.trim() == '' || (!value && value != 0)){ // 空,要 清空信息，并 切换 表单状态

        this.resetFormGoods()
        return  callback(new Error('请输入"关联计划编号"'))
      }
      let rqData = { planCode:value.trim(), pageIndex:1,  pageSize:200 }
      const res = await getPurchaseDetail(rqData)
      const {data, } = res.data
      if(data.length == 0 ){
        callback(new Error('该"关联计划编号"不存在'))
        this.resetFormGoods()
      }else{
        callback()
        let supplierList = data.map(item=>({value: item.supplier,label:item.supplier,foodList:item.foodList}))
        this.rowFormData[this.indexObj_rFD.supplier].options = supplierList
        // 获取计划人信息
        const person_res= await getPlanPersonInfo({planCode:value.trim()})
        const { nickname, mobile} = person_res.data
        this.$set(this.rowForm,'mobile',mobile)
        this.$set(this.rowForm,'nickname',nickname)
      }
    },
    // 控制表单，是否展示
    dealFormItem_IsShow(){
      let keyArr = ['planCode','nickname','supplier','mobile']
      let isShow = this.rowForm.formType == 0
      keyArr.forEach( k => {
        this.$set(this.rowFormData[this.indexObj_rFD[k]],'hide',isShow)
        if(this.isAdd){
          this.$set(this.rowForm,k,'')
        }
      })
      if(this.isAdd){
        this.rowFormData[this.indexObj_rFD.supplier].options = []
      }
    },
    // 清除所有
    resetFormGoods(){
      this.clearDetailList()
      // 清供应商
      if(this.rowForm.supplier)this.$set(this.rowForm,'supplier','') 
      this.rowFormData[this.indexObj_rFD.supplier].options = []
      // 清 计划人
      this.$set(this.rowForm,'mobile','')
      this.$set(this.rowForm,'nickname','')
    },
    // 清采购明细
    clearDetailList(){
      this.$set(this.rowForm, 'detailList', [])
      this.$set(this.rowForm,'totalCost','')
      this.datas = []
      this.foodNameList = []
      this.$nextTick(()=>{
        this.$refs.material.clearForm()
        this.$refs.material.$refs.form.resetFields()
      })
      
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}
.title {
  display: flex;
  flex-wrap: wrap;
  li {
    width:90px;
    height: 30px;
    margin-bottom: 20px;
    line-height: 30px;
    text-align:center;
    margin-right: 10px;
    color: #fff;
    cursor: pointer;
    background:#409EFF;
  }
}
.planMenuList{
  display: flex;
  flex-wrap: wrap;
  margin-top: -30px;
  li{
    // width: 50%;
    p{
      text-align:center;
    }
  }
}
</style>
