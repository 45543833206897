<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      @getInit="getInit"
      @search="search"
      @rowDelete="rowDelete"
      @examineAndVerify="examineAndVerify"
      @seeBtn="seeBtn"
      :editDataFormat='editDataFormat'
      @reset="reset"
    >
      <template #pictureUrl="{row}">
        <!-- <div class="firstcell el-button--text" @click="seeDetails(row)">{{row.pictureUrl}}</div> -->
        <el-image
          style="width: 50px; height: 50px"
          :src="row.pictureUrl"
          :preview-src-list="[row.pictureUrl]"
          fit="cover"
        ></el-image>
      </template>
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="80%"
      @close="close"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :allDisabled="allDisabled"
        @reset="showEditAll = false"
        @save="saveForm"
        v-if="showEditAll"
        ref="jaForm"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
        <template #addPlanMenuDetailReqList="{row}" > 
          <div>
            <material-table  @customMenu="customMenu" :detailList="detailList" @formDatas="formDatas" :list="list" @addList="addList($event)"></material-table>
            <span>总成本价：{{rowForm.cost || 0}}元</span>
          </div>
        </template>
      </ja-form>
    </el-dialog>
    <!-- 添加 制定餐牌-->
    <add-mealplan ref="mealplan" v-if="showMealplan" :mealplanTitle="mealplanTitle" :mealpalanRow="mealpalanRow" @saveFormMealplan="saveFormMealplan"></add-mealplan>
    <!-- 审核 -->
    <examine ref="examine" v-if="isShowExamine" :period="period" :planId="planId" @showExamine="showExamine"></examine>
    <!-- 查看 -->
    <see ref="see" v-if="isShowSee" :planId="planId" @showSee="showSee"></see>
  </div>
</template>
<script>
import addMealplan from "./components/addMealplan.vue"
import examine from "./examineAndVerify/examine.vue"
import see from "./see/see.vue"
import {
  getManagerList,
  addManagerList,
  editManager,
  delManagerList,
  dictData,
  detailManager
} from '@/api'
import table from '@/views/mixins/table'
import materialTable from "./components/materialTable.vue"
export default {
  title: '餐品计划',
  mixins: [table],
  name: 'index',
  components: {
    materialTable,
    addMealplan,
    see,
    examine
  },
  data() {
    return {
      showMealplan:false,
      show: false,
      showUser: false,
      mealpalanRow:{},
      parkId: 1,
      detailList:[],
      allDisabled: false,
      mealplanTitle:'',
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
        // cityId:''
      },
      conditionData: [
        {input_type: 'date',type: 'daterange',value: 'periodList',format:'yyyy-MM-dd HH:mm:ss',valueFormat:'yyyy-MM-dd HH:mm:ss', startPlaceholder: '开始日期',endPlaceholder: '结束日期',attribute: {unlinkPanels:true}},
        {
          input_type: 'input',
          key: 'dishesName',
          styleWidth: '120px',
          attribute: {
            placeholder: '菜式名称',
            size: 'small'
          }
        },
        {
          input_type: 'select',
          key: 'status',
          attribute: {
            placeholder: '状态',
            size: 'small',
            clearable: true
          },
          options: [
            {
              label:'创建', 
              value:'0', 
            },
            {
              label:'一审', 
              value:'1', 
            },
            {
              label:'二审', 
              value:'2', 
            },
            {
              label:'通过', 
              value:'3', 
            },
            {
              label:'拒绝', 
              value:'4', 
            }
          ],
        },
        {
          input_type: 'input',
          key: 'code',
          attribute: {
            placeholder: '计划编号',
            size: 'small'
          }
        },
        {
          input_type: 'date',
          value: 'createTime',
          type: 'date',
          styleWidth: '160px',
          placeholder: '创建日期'
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '新增',
          input_type: 'button',
          method: 'append',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-plus',
            plain: true
          },
          limit: 'purchase::mealPlan::add'
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        {
          label: '餐品计划编号',
          prop: 'code',
          width:200
        },
        {
          label: '周期',
          prop: 'period',
          width:200
        },
        {
          label: '状态',
          prop: 'status',
          width:80,
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '总成本（元）',
          prop: 'cost',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '备注',
          prop: 'remark',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '创建人',
          prop: 'createBy',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '创建时间',
          prop: 'createTime',
          width:"140px",
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '操作',
          input_type: 'button',
          width: 180,
          attribute: {
            fixed: 'right',
            align: 'center'
          },
          list: [
            {
              text: '编辑',
              method: 'rowEdit',
              limit: 'purchase::mealPlan::update'
            },
            {
              text: '删除',
              method: 'rowDelete',
              limit: 'purchase::mealPlan::del'
            },
            {
              text: '审核',
              method: 'examineAndVerify',
              limit: 'purchase::mealPlan::check'
            },
            {
              text: '查看',
              method: 'seeBtn',
              limit: 'purchase::mealPlan::see'
            },
          ]
        }
      ],
      tableData: [],
      loading: false,
      total:null,
      titleText: '编辑',
      initFileList: [],
      rowFormData: [
        {
          input_type: 'customize',customize:'addPlanMenuDetailReqList',label: '餐牌内容', key:'addPlanMenuDetailReqList',col:24,
        },
        {
          input_type: 'input',
          label: '备注',
          type: 'textarea',
          col: 22,
          key: 'remark'
        },
      ],
      checkImgSuccess: false,
      isExist: null,
      rules: {
        // addPlanMenuDetailReqList: [
        //   {
        //     required: true,
        //     message: '请增加整份成本价格',
        //     trigger: 'blur'
        //   }
        // ]
      },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`,
      // 图片相关
      headers: {},
      dialogVisible: false,
      dialogObj: null,
      fileList: [],
      isSearchIng: false,
      colIndex:'',
      list:{},
      isAdd: true,
      wholeCostList:[],
      period:'',
      planId:'',
      isShowExamine:false,
      isShowSee:false
    }
  },
  created() {
    //  console.log("$uploadUrl",this.$uploadUrl)
  },
  mounted() {},
  methods: {
    getInit: function () {
      this.loading = true
      console.log('filterData---',this.filterData)
      getManagerList(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    // 新增
    append() {
      this.titleText = '新增'
      this.isAdd = true
      this.rowForm = {}
      this.wholeCostList=[]
      this.initFileList = []
      this.showEditAll = true
    },
    // 编辑
    rowEdit: function (row, details) {
      this.isAdd = false

      // console.log("row",row)
      this.rowForm = {
        ...row
      }
      // this.initFileList=[this.rowForm.pictureUrl]
      this.detailManager(row.id)
        this.titleText = '编辑'

      // console.log("什么鬼--", [].map(Number))
      this.showEditAll = true
    },
    // 查看详情请求接口 
    detailManager(id){
      detailManager({planId:id}).then((res) => {
        // console.log("详情数据",res)
        if(res.code==200){
          // this.detailList=res.data.map(res=>{
          //   this.presellPlanMenuList=res.presellPlanMenuList
          //   return res.timePlanMenuList
          // })
          this.detailList=res.data
        }
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm(
        '确定删除该条数据吗？'
      ).then((res) => {
        console.log('row', row)
        delManagerList(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    saveForm: function () {
      // 保存
      // 判断是否有未制定计划的列表
      let empty=this.wholeCostList.filter(res=>res.totalCost=="")
      console.log('列表',empty)
      if(empty.length!=0 || this.wholeCostList.length==0){
        let date=this.wholeCostList.length==0?'':empty[0].foodName
        this.$message.error(date+'制定计划不能为空');
        return false
      }else{
        let list=this.wholeCostList.map(res=>{
          return res.list
        })
        // console.log('列表----',this.wholeCostList)
        let arr=[];
        for (let i = 0; i < list.length; i++) {
          const item = list[i];
          arr=arr.concat(item)
        }
        // console.log("arr000",arr)
        this.rowForm.addPlanMenuDetailReqList=arr
        
        // 预售
        
        let addPresellList=this.wholeCostList.map(res=>{
          // console.log("nihh",res)
          return res.presellPlanMenuList || [] 
        })
        console.log("addPresellList",addPresellList)
        let addPresellListArr=[];
        for (let i = 0; i < addPresellList.length; i++) {
          const item = addPresellList[i];
          addPresellListArr=addPresellListArr.concat(item)
        }
        this.rowForm.addPresellList=addPresellListArr
      }
      
      this.loading_form.isShow = true
      if (this.isAdd) {
        addManagerList(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('新增成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      } else {
        editManager(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('修改成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      }
    },
    // 新增增或删除
    addList({total,datas}){
      // console.log("哈哈哈",data)
      // 计算总价
      // this.rowForm.cost=total;
      // this.rowForm.foodsCosts=datas
      this.$refs.jaForm.$refs.formbox.clearValidate('cost')
    },
    // 关闭表单弹窗
    close(){
      
    },
    // 点击定制餐牌的事件传递 或编辑
    customMenu({row,index,title}){
      // console.log("弹窗",{row,index,title})
      // this.$refs['mealplan'].showDialog(index)
      this.mealpalanRow=row
      this.mealplanTitle=title
      console.log("定制餐牌",this.mealpalanRow)
      this.showMealplan=true
    },
    // 餐牌内容新增保存
    saveFormMealplan(data){
      // console.log("餐牌内容新增--", data)
      this.list=data || {}
      this.showMealplan=false
    },
    formDatas(data){
      // 计算总价
      let totalCostList=data.map(item=>{
        return item.totalCost
      })
      if(totalCostList.includes('') || data.length==0){
        this.$set(this.rowForm,"cost",0)
        return
      }else{
        let itemTotal=totalCostList.filter(res=>res!="")
        this.$set(this.rowForm,"cost",itemTotal.reduce((a,b)=>Number(a)+Number(b)))
        this.wholeCostList=data
      } 
      
    },

    // 审核按钮
    examineAndVerify(data){
      console.log("测试",data)
      // showExamineDialog
      this.period=data.period
      this.planId=data.id
      // this.$refs.examine.showExamineDialog()
      this.isShowExamine=true
    },
    showExamine(val){
      // console.log("测试2626",val)
      this.isShowExamine=val
      this.getInit()
    },
    showSee(val){
      this.isShowSee=val
    },
    seeBtn(data){
      this.planId=data.id
      // this.$refs.examine.showExamineDialog()
      this.isShowSee=true
    },
    /**
     * 控制按钮显示隐藏
     */
    editDataFormat(row, button, scope){
      // console.log("测试0999",row, button)
      if(row.status=="创建"){
        return true
      }else if((row.status=="一审" || row.status=="二审") &&( button.text=="审核" || button.text=="查看")){
         return true
      }else if((row.status=="通过" || row.status=="拒绝") &&( button.text=="查看")){
         return true
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}

// 上传图像
.imgDefault {
  width: 150px;
  height: 200px;
  border: 1px solid $theme_bordercolor_DCDFE6;
}

/deep/ .el-upload__tip {
  position: absolute;
  width: 20%;
  top: 45%;
  left: 37%;
  text-align: center;
  line-height: 1;
  margin: 10px 0 8px 0;
}

.upload-img-form /deep/ .el-upload--picture-card {
  background-color: initial;
  border: initial;
  border-radius: initial;
  box-sizing: initial;
  width: initial;
  height: initial;
  line-height: initial;
  vertical-align: initial;
  display: inline-block;
  text-align: initial;
}

/deep/ ul.el-upload-list--picture-card {
  display: block;
}

/deep/ .el-upload-list.el-upload-list--text {
  width: 50%;

  > li.el-upload-list__item {
    cursor: pointer;
  }
}

// 上传图片按钮
.uploadBtn {
  position: absolute;
  top: 30%;
  left: 40%;
  color: $theme_color_0083ee;
  background: transparent;
  border: 1px solid $theme_bordercolor_0083ee;

  &:hover,
  &:focus {
    background: transparent;
    color: $theme_color_0083ee;
  }
}

// 图片缩略图
.videoContent {
  width: 40%;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 180px;
      height: 200px;
      flex-shrink: 0;
      margin: 0;
      border: 1px solid $theme_bordercolor_DCDFE6;
      border-radius: 0;
      overflow: hidden;
      position: relative;

      &:hover {
        .videoPlay_IconBox {
          display: none;
        }
      }

      .img-avatar {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
