<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog :visible.sync="showDetail" title="查看详情" :width="`40%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='9' :loadingForm="loadingForm">
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>
import { getMsgSendRead } from '@/api'
export default {
  inject: ['labelWidth', 'getInit',],
  data() {
    return {
      showDetail: false,
      detailOptions:
        [
          { label: '标题', key: 'title', col: 24 },
          { label: '内容', key: 'content', col: 24 },
          // { label: '接收人', key: 'receiverName', col: 24 },
          { label: '状态', key: 'status', col: 24 },
          { label: '已读', key: 'readedText', col: 24 },
          { label: '未读', key: 'unReadText', col: 24 },
          // { label: '发送方式', key: 'methodValue', col: 24 },
          { label: '创建人', key: 'senderName' },
          { label: '创建时间', key: 'createTime', col: 24 },
        ],
      detailData: {},
      indexObj: {},
      loadingForm:false,
      dateList: [],  // 来货明细的日期
      dateObjDetail: {},
      activeDate: '',
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.detailOptions })
  },
  methods: {
   async rowDetail(row) {
      this.showDetail = true
      this.loadingForm = true
      this.rowForm = {}
      let res = await getMsgSendRead(row.applyId)
      row.readedText = res.data.readStatusUser.join('，')
      row.unReadText = res.data.deReadStatusUser.join('，')
      this.detailData = {...row}
      this.loadingForm = false
    },
  }
}
</script>
<style lang='scss' scoped>
.dateList /deep/ .el-tag {
  margin-right: 15px;
  margin-bottom: 15px;
  &:hover {
    cursor: pointer;
  }
}
</style>