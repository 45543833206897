<template>
  <router-view />
</template>

<script>
export default {
  title: "应急管理",
  name: 'merchant'
}
</script>

<style scoped>
</style>
