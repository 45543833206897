
/** 数字字典页面搜索栏 */
export const dicManagerQuery = [
  {
    input_type: 'input',
    key: 'dictLabel',
    lable: '字典名称',
    attribute: {
      placeholder: '字典名称',
      size: 'small'
    }
  },
  {
    input_type: 'select',
    key: 'status',
    lable: '字典状态',
    attribute: {
      placeholder: '字典状态',
      size: 'small',
      clearable: true
    },
    options: [
      {
        value: 0,
        label: '正常'
      },
      {
        value: 1,
        label: '停用'
      }
    ]
  },
  {
    input_type: 'input',
    key: 'dictType',
    lable: '字典类型',
    attribute: {
      placeholder: '字典类型',
      size: 'small'
    }
  },
  { normalBtn: 'search' },
  { normalBtn: 'add' },
  { normalBtn: 'reset' }
]

/** 数字字典页面表头栏 */
export const dicManagerColumn = [
  { label: '字典编码', prop: 'dictCode' },
  { label: '字典名称', prop: 'dictLabel' },
  { label: '字典值', prop: 'dictValue' },
  { label: '字典类型', prop: 'dictType' },
  { label: '状态', prop: 'status' },
  { label: '备注', prop: 'remark', width: 180 },
  { label: '创建时间', prop: 'createTime', width: 200 },
  {
    label: '操作',
    input_type: 'button',
    width: 180,
    list: [
      { text: '编辑', method: 'rowEdit', limit: 'admin::dictData::update' },
      { text: '移除', method: 'rowDelete', limit: 'admin::dictData::delete' }
    ]
  }
]

/** 数字字典页面新增/编辑项 */
export const dicManagerFormData = [
  {
    input_type: 'input',
    label: '字典名称',
    key: 'dictLabel'
  },
  {
    input_type: 'input',
    label: '字典类型',
    key: 'dictType'
  },
  {
    input_type: 'input',
    label: '字典值',
    key: 'dictValue'
  },
  {
    input_type: 'radio',
    label: '状态',
    options: [
      { label: '正常', value: 0 },
      { label: '停用', value: 1 }
    ],
    key: 'status',
    col: 24,
    method: 'selectUserType',
    show: 1
  },
  {
    label: '备注',
    key: 'remark',
    input_type: 'input',
    type: 'textarea',
    col: 22
  }
]

/** 数字字典页面新增/编辑校验规则 */
export const dicManagerRules = {
  dictLabel: [
    { required: true, message: '请输入字典名称', trigger: 'blur' }
  ],
  dictType: [
    { required: true, message: '请输入字典类型', trigger: 'blur' }
  ],
  status: [
    { required: true, message: '请选择状态', trigger: 'change' }
  ],
  dictValue: [
    { required: true, message: '请输入字典值', trigger: 'blur' }
  ]
}

/** 日志管理页面搜索栏 */
export const logQuery = [
  {
    input_type: 'select',
    key: 'parkId',
    attribute: {
      placeholder: '园区',
      size: 'small'
    },
    options: []
  },
  {
    input_type: 'select',
    key: 'log',
    attribute: {
      placeholder: '日志',
      size: 'small'
    },
    options: [{
      value: 0,
      label: '全部'
    }, {
      value: 1,
      label: '登录日志'
    }, {
      value: 2,
      label: '操作日志'
    }]
  },
  {
    input_type: 'date',
    type: "datetimerange",
    value: 'time',
    startPlaceholder: '开始时间',
    endPlaceholder: '结束时间',
    attribute: {
      size: 'small'
    }
  },
  { normalBtn: 'search' },
  { normalBtn: 'reset' }
]

/** 日志管理页面表头栏 */
export const logColumn = [
  {
    label: '用户名称',
    prop: 'name'
  },
  {
    label: '所属园区',
    prop: 'parkName',
    attribute: {
      showOverflowTooltip: true,
      align: 'center'
    }
  },
  {
    label: '地址',
    prop: 'ip'
  },
  {
    label: '日志类型',
    prop: 'logType'
  },
  {
    label: '状态',
    prop: 'status'
  },
  {
    label: '描述',
    prop: 'description',
    attribute: {
      showOverflowTooltip: true,
      align: 'center'
    }
  },
  {
    label: '记录时间',
    prop: 'time'
  }
]

/** 菜单管理页面搜索栏 */
export const menuManagerQuery = [
  {
    input_type: 'input',
    key: 'menuName',
    label: '菜单名称：',
    attribute: {
      placeholder: '请输入菜单名称',
      size: 'small'
    }
  },
  {
    input_type: 'select',
    key: 'status',
    label: '状态：',
    attribute: {
      placeholder: '菜单状态',
      size: 'small'
    },
    options: [
      { label: '正常', value: 0 },
      { label: '停用', value: 1 }
    ]
  },
  { normalBtn: 'search' },
  { normalBtn: 'reset' },
  {
    text: '新增',
    input_type: 'button',
    method: 'handleAdd',
    size: 'small',
    type: 'primary',
    icon: 'el-icon-plus',
    plain: true,
    limit: 'admin::menu::add'
  }
]

/** 菜单管理页面表头栏 */
export const menuManagerColumn = [
  { label: '菜单名称', prop: 'menuName', width: 250, align:'left', attribute: { showOverflowTooltip: true} },
  { label: '图标', prop: 'icon', width: 100, slotName: 'icon' },
  { label: '排序', prop: 'orderNum', width: 60 },
  { label: '权限标识', prop: 'perms',  attribute: { showOverflowTooltip: true, align: 'center' } },
  { label: '组件路径', prop: 'component',  attribute: { showOverflowTooltip: true, align: 'center' } },
  { label: '状态', prop: 'status', width: 100 },
  { label: '创建时间', prop: 'createTime' },
  {
    label: '操作',
    input_type: 'button',
    width: 170,
    attribute: {
      fixed: "right",
      align: 'center'
    },
    list: [
      { text: '修改', method: 'handleUpdate'},
      { text: '新增', method: 'handleAdd'},
      { text: '删除', method: 'handleDelete'}
    ]
  }
]

/** 菜单管理页面新增/编辑校验 */
export const menuManagerRules = {
  menuType: [
    { required: true, message: '请选择层级目录', trigger: 'change' }
  ],
  menuName: [
    { required: true, message: '请输入菜单名称', trigger: 'blur' }
  ],
  path: [
    { required: true, message: '请完善路由地址', trigger: 'change' }
  ],
  outLink: [
    { required: true, message: '请完善外链地址', trigger: 'change' }
  ]
}

/** 参数管理页面搜索栏 */
export const paramSettingQuery = [
  {
    input_type: 'input',
    key: 'configName',
    attribute: {
      placeholder: '参数名称',
      size: 'small'
    }
  },
  { normalBtn: 'search' },
  { normalBtn: 'reset' },
  // { normalBtn: 'add' }
]

/** 参数管理页面表头 */
export const paramSettingColum = [
  { label: '参数名称', prop: 'configName', width: 200, attribute: { showOverflowTooltip: true, align: 'center' } },
  // { label: '参数键名', prop: 'configKey', width: 200 },
  { label: '参数键值', prop: 'configValue', width: 200, attribute: { showOverflowTooltip: true, align: 'center' } },
  { label: '备注', prop: 'remark', attribute: { showOverflowTooltip: true, align: 'center' } },
  { label: '创建时间', prop: 'createTime' },
  {
    label: '操作',
    input_type: 'button',
    width: 140,
    attribute: {
      fixed: "right",
      align: 'center'
    },
    list: [
      { text: '编辑', method: 'rowEdit', limit: 'admin::config::update' },
      // { text: '删除', method: 'rowDelete', limit: 'admin::config::delete' }
    ]
  }
]

/** 参数管理页面新增/编辑项 */
export const paramSettingFormData = [
  { input_type: 'input', label: '参数名称',key: 'configName', disabled:true},
  { input_type: 'input', label: '参数键值', key: 'configValue'},
  {
    input_type: 'select',
    key: 'configValue',
    label: '参数键值',
    attribute: {
      placeholder: '选择',
      size: 'small'
    },
    options: [
    ]
  }, 
  { label: '备注', disabled:true, key: 'remark', input_type: 'input', type: 'textarea', col: 22, maxlength: 200, showWordLimt: true, rows: 5 }
]

/**接收vue文件的变量 */
export  const overallData= {
  newNum:30,
}
/** 参数管理页面新增/编辑校验规则 */
export const paramSettingRules = {
  configKey: [
    { required: true, message: '请输入参数键名', trigger: 'blur' }
  ],
  configValue: [
    { required: true, trigger: 'blur',
    validator: (rule, value, callback)=>{
      if (value === undefined || value === "") { // 如果为空
        return callback(new Error('请输入参数键值'))
      }else if(value.length>overallData.newNum){
        return callback(new Error('参数键值不能大于'+overallData.newNum+'的字符'))
      }else{
        return callback()
      }
    } }
  ],
  configType: [
    { required: true, message: '请选择状态', trigger: 'change' }
  ]
}

/** 角色管理页面搜索栏 */
export const roleManagerQuery = [
  {
    input_type: 'select',
    key: 'status',
    label: '查找：',
    attribute: {
      placeholder: '状态',
      size: 'small'
    },
    options: [
      { value: 0, label: '启用' },
      { value: 1, label: '禁用' }
    ]
  },
  {
    input_type: 'input',
    key: 'name',
    lable: '角色名称',
    attribute: {
      placeholder: '角色名称',
      size: 'small'
    }
  },
  { normalBtn: 'search' },
  { normalBtn: 'add',limit:'admin::role::add' },
  { normalBtn: 'reset' }
]

/** 角色管理页面表头 */
export const roleManagerColumn = [
  { label: '角色名称', prop: 'name' },
  { label: '角色说明', prop: 'remark', width: 300, attribute: { showOverflowTooltip: true, align: 'center' } },
  // { label: '权限字符', prop: 'roleKey' },
  // { label: '显示顺序', prop: 'roleSort' },
  { label: '状态', prop: 'status' },
  { label: '创建时间', prop: 'createTime', attribute: { showOverflowTooltip: true, align: 'center' } },
  {
    label: '操作',
    input_type: 'button',
    width: 220,
    attribute: {
      fixed: 'right',
      align: 'center'
    },
    list: [
      // { text: '查看', method: 'rowDetail' },
      { text: '权限设置', method: 'rowDataScope', limit: 'admin::role::auth' },
      { text: '编辑', method: 'rowEdit', limit: 'admin::role::update' },
      { text: '删除', method: 'rowDelete', limit: 'admin::role::delete' },
      { text: '绑定用户', method: 'bindUser',limit:'admin::role::bindUser'}
    ]
  }
]

/** 角色管理页面新怎/编辑项 */
export const roleManagerFormData = [
  { label: '角色名称', key: 'name', input_type: 'input' },
  { input_type: 'switch', label: '状态', key: 'status', activeValue: 0, inactiveValue: 1 },
  { label: '角色说明', key: 'remark', input_type: 'input', type: 'textarea', col: 22, maxlength: 200, showWordLimt: true, rows: 5 }
]

/** 角色管理页面新增/编辑校验规则 */
export const roleManagerRules = {
  name: [
    { required: true, message: "角色名称不能为空", trigger: "blur" }
  ],
  status: [
    { required: true }
  ]
}
