
<template>
  <div>
    <ja-table ref="tst" v-model="filterData" :columns="columns" :tableData="tableData" :conditionData="conditionData" :total="total" :border="true" :showSelect="!isOnePerson?true:false" :loading="loading" index_label="序号" :showIndex="true" @handleSelectItem="handleSelectItem" @handleSelectionAll="handleSelectionAll" @selectRow="selectRow" @getInit="getInit2" @search="search" @reset="reset">
      <template #right>
        <el-button type="primary" @click="submit()" class="submit" v-if="!isOnePerson">确认添加</el-button>
      </template>
      <template #isAll>
        <el-checkbox v-model="checked" @change="selectAllPerson" v-if="isShowSelectAll">全选</el-checkbox>
      </template>
    </ja-table>
    <div class="botttom">已选择：{{checked && isShowSelectAll ?(except_person_name?`所有人，除了“${except_person_name}”`:'所有人'):NowPersons2}}</div>
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import selectTable from '@/views/mixins/selectTable'
import { getEmployList } from '@/api'

export default {
  title: '选择用户',
  mixins: [table, selectTable],
  name: 'index',
  data() {
    let conditionData_own = [
      { input_type: 'select', key: 'companyId', attribute: { placeholder: '所属公司', size: 'small' }, options: [] },
      { input_type: 'input', key: 'nickname', attribute: { placeholder: '人员姓名', size: 'small' } },
    ]
    let columns_own = [
      { label: '人员名称', prop: 'nickname', attribute: { showOverflowTooltip: false, align: 'center'}},
      { label: '登录用户名', width: 120, prop: 'loginName', attribute: { showOverflowTooltip: true, align: 'center' } },
      { label: '人员类型', width: 100, prop: 'employeeType' },
      { label: '职位', prop: 'positionName', attribute: { showOverflowTooltip: false, align: 'center' } },
      // { label: '性别', prop: 'genderLabel' },
      { label: '所属公司', width: 150, prop: 'companyName', attribute: { showOverflowTooltip: true, align: 'center' } },
      { label: '所属部门', prop: 'deptName', width: 150, attribute: { showOverflowTooltip: true, align: 'center' } },
      { label: '状态', prop: 'statusLabel' },
      // { label: '身份证号', prop: 'idCard', attribute: { showOverflowTooltip: true, align: 'center' } },
      { label: '手机号', prop: 'mobile', attribute: { showOverflowTooltip: false, align: 'center' } ,width:130},
      { label: '用户角色', prop: 'roleName', attribute: { showOverflowTooltip: false, align: 'center'} ,width:180 },
      { label: '通行卡号', prop: 'accessCard',attribute: { showOverflowTooltip: false, align: 'center' },width:150  },
      { label: '人脸图像', prop: 'faceImg', slotName: 'img' },
      { label: '更新人', prop: 'updateBy', attribute: { showOverflowTooltip: true, align: 'center' } },
      { label: '更新时间', prop: 'updateTime', attribute: { showOverflowTooltip: false, align: 'center' },width:160 },
    ]
    return {
      rq_Method: getEmployList,
      keyName: 'nickname',
      conditionData: [...conditionData_own, { normalBtn: 'search', }, { normalBtn: 'reset' }, { slotName: 'isAll' }],
      columns: [...columns_own,
      {
        label: '操作', input_type: 'button', width: 120, attribute: { fixed: 'right', align: 'center' },
        list: [
          { text: '选择', method: 'selectRow' }
        ]
      }
      ],
    }
  },
  created() {
    // 顶部搜索框
    this.util.rqOptionsList({ who: [this.conditionData[0]], rqUrl: "/company", rqData: { params: { pageIndex: 1, pageSize: 200 } }, rqMethod: 'get', labelName: 'companyName', valueName: 'id' }) // 所属公司
  },
  methods: {
    
  }
}
</script>