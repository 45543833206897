<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog :visible.sync="showDetail" :title="titleText" :width="`50%`">
      <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showDetail = false" @save="saveForm" :btnBox="btnBox" v-if="showDetail">
        <template #applyDetails>
          <ja-table ref="tst" :isFromForm="true" :columns="table_columns" :tableData="rowForm.applyDetails" :showIndex="true" :showPage="false" :noSearch="true" :loading="false">
            <template #img='{row}'>
              <div class="imgsBox">
                <el-image :preview-src-list="[item]" fit="contain" class="cellImg" :src="item" alt="" v-if="item" v-for='(item,index) in row.imgs' :key="index">
                  <div slot="error" class="errorImg el-image__error">
                    加载失败
                  </div>
                  <div slot="placeholder" class="image-slot el-icon-picture-outline">
                  </div>
                </el-image>
              </div>
            </template>
          </ja-table>
        </template>
        <template #uplpadfaceImg>
          <upload-file ref="Upload" :initFileList="[...initFileList]" v-if="showDetail && initFileList.length > 0 " :noButton="true" :hideDel="true" />
        </template>
      </ja-form>
    </el-dialog>
  </div>
</template>
<script>
import { rqVisitorApplyItem, approvalVisitorApply } from '@/api'
import detailObj from './DetailObj.js'
export default {
  inject: ['labelWidth', 'getInit',],
  data() {
    return {
      titleText: '查看详情',
      rowFormData: [],
      table_columns: [],
      rowForm: {},
      rules: {},
      showDetail: false, // 显示新增/编辑弹窗
      loadingForm: false,
      btnBox_edit: [
        { label: '审批通过', method: 'save', type: 'success', monitor: true, col: 8, isCenter: true, plain: true, status: 1 },
        { label: '审批拒绝', method: 'save', type: 'danger', monitor: true, col: 8, isCenter: true, plain: true, status: 0 },
        { label: '取消', method: 'reset', col: 8, isCenter: true }
      ],
      btnBox: [],
      rowFormData_indexObj: {},
      initFileList: []
    }
  },
  created() {
    this.rowFormData_indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
  },
  methods: {
    async rowDetail(row) {
      this.rowForm = {}
      this.btnBox = row.formType == 'detail' ? [] : [...this.btnBox_edit]
      this.titleText = row.formType == 'detail' ? `查看详情 - ${row.applyCode}` : `审批访客 - ${row.applyCode}`

      // 设置详情弹窗，会出现哪些项
      if (row.applyType === '1') { // 手机端的外部邀约
        this.rowFormData = detailObj.wx_public
      } else { // 内部邀约
        // 有业务类型，是 来源 pc 。否则，是来源手机端的内部邀约
        this.rowFormData = row.visitorType ? detailObj.pc : detailObj.wx_inside
      }
      this.table_columns = row.visitorType ? detailObj.table_columns.pc : detailObj.table_columns.wx

      this.showDetail = true
      this.loadingForm = true
      let res = await rqVisitorApplyItem(row.id)
      let obj = res.data
      let start = obj.visitorTimeBegin.slice(0, -3)
      let end = obj.visitorTimeEnd.slice(0, -3)
      obj.visitorTime = `${start} 至 ${end}`
      obj.applyDetails = (obj.applyDetails || []).map(v => {
        return {
          ...v,
          imgs: (v.imagesUrl || '').split(',')
        }
      })
      let imgArr = obj.imagesUrl ? (obj.imagesUrl || '').split(',') : ''
      if (imgArr.length > 0) {
        this.initFileList = this.util.backImgVideoObj(imgArr)
      } else {
        this.initFileList = []
      }
      // console.log('this===',this.initFileList)
      this.rowForm = obj
      this.loadingForm = false

    },
    saveForm(btnItem) {
      let obj = { applyId: this.rowForm.id }
      if (btnItem.status == 1) { // 通过
        obj.aboolean = true
      } else { // 拒绝
        obj.aboolean = false
      }
      this.loadingForm = true
      approvalVisitorApply(obj).then(res => {
        this.loadingForm = false
        this.getInit()
        this.$message.success('审批完成')
        this.showDetail = false
      }).catch(() => {
        this.loadingForm = false
      })
      console.log('提交了表单---', this.rowForm)
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .cellImg {
  > img {
    max-height: 23px;
    width: 35px;
    display: flex;
    margin: auto;
  }
}
/deep/ .errorImg {
  width: 100%;
}
.imgsBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>