<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      @getInit="getInit"
      @search="search"
      @rowDelete="rowDelete"
      :valueFormat="formatter"
      @reset="reset"
    >
    <template #dishesInfoReqList="{row}">
          <el-image
            v-for="item in row.dishesInfoReqList"
            :key="item.id"
            style="width: 30px; height: 30px;margin-left:10px"
            :src="item.pictureUrl"
            :preview-src-list="[item.pictureUrl]"
            fit="cover"
          ></el-image>
      </template>
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="40%"
      @close="close"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :allDisabled="allDisabled"
        @reset="showEditAll = false"
        @save="saveForm"
        @tablewareTypeFun="tablewareTypeFun"
        ref="jaForm"
        v-if="showEditAll"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
         <!-- 关联餐具 -->
        <template #tablewareId="{data}">
            <el-select
              v-model="rowForm.tablewareId"
              placeholder="请选择"
              filterable
              value-key="id"
              @change="changeTableware"
              v-el-select-loadmore="loadmoreTableware"
            >
              <el-option
                v-for="item in tablewareList"
                :key="item.id"
                :label="item.tablewareName"
                :value="item">
              </el-option>
              <div v-if="tablewareDialog" style="text-align: center;color: #999;font-size: 12px;">
                 暂无更多数据
              </div>
            </el-select>
        </template>
         <!-- 关联餐具 end-->
         <!-- 菜式 -->
        <template #dishesInfoReqList="{data}">
            <el-select
              v-model="rowForm.dishesInfoReqList"
              placeholder="请选择"
              filterable
              multiple
              value-key="id"
              @change="changeDishes"
              v-el-select-loadmore="loadmore"
            >
              <el-option
                v-for="item in dishesList"
                :key="item.id"
                :label="item.dishesName"
                :disabled="item.status==1"
                :value="item">
              </el-option>
              <div v-if="dishesDialog" style="text-align: center;color: #999;font-size: 12px;">
                 暂无更多数据
              </div>
            </el-select>
            <ul class="img_list">
              <li v-for="item in rowForm.dishesInfoReqList" :key="item.id">
                 <el-image
                  style="width: 50px; height: 50px"
                  :src="item.pictureUrl"
                  :preview-src-list="[item.pictureUrl]"
                  fit="cover"
                ></el-image>
              </li>
            </ul>
        </template>
        <!-- 菜式end -->
      </ja-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  getSetmeal,
  addSetmeal,
  delSetmeal,
  editSetmeal,
  getDishes,
  dictData,
  getTableware
} from '@/api'
import table from '@/views/mixins/table'
export default {
  title: '套餐管理',
  mixins: [table],
  name: 'index',
  data() {
    return {
      show: false,
      showUser:false,
      parkId:1,
      allDisabled:false,
      dishesList:[],
      dishesDialog:false,
      tablewareDialog:false,
      tablewareList:[],
      tablewareFormData: {
        pageIndex: 1,
        pageSize: 10,
      },
      dishesFormData: {
        pageIndex: 1,
        pageSize: 10,
      },
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
        // cityId:''
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'menuName',
          attribute: {
            placeholder: '套餐名称',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'dishesName',
          attribute: {
            placeholder: '菜式名称',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'tablewareName',
          attribute: {
            placeholder: '餐具名称',
            size: 'small'
          }
        },
        {
          input_type: 'select',
          key: 'status',
          lable: '状态',
          attribute: {
            placeholder: '状态',
            size: 'small',
            clearable: true
          },
          options: function () {
            return dictData('dishes_status_type').then((res) => {
              if (res.code == 200) {
                let data = res.data.map((item) => {
                  return {
                    label: item.dictLabel,
                    value: item.dictValue
                  }
                })
                return data
              }
            })
          }
        },
        {
          input_type: 'date',
          value: 'createTime',
          type:'date',
          styleWidth: '200px',
          placeholder:"创建日期"
        },
        {
          text: '查询',
          input_type: 'button', 
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '新增',
          input_type: 'button',
          method: 'append',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-plus',
            plain: true
          },
          limit: 'canteen::package::add'
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '套餐名称',
          prop: 'menuName',
          width: 100,
          attribute: { showOverflowTooltip: true, align: 'center' }
        },
        {
          label: '菜式',
           width: 200,
          prop: 'dishesInfoReqList',
          slotName: 'dishesInfoReqList',
        },
        {
          label: '关联餐具',
          prop: 'tablewareName'
        },
        {
          label: '价格（元）',
          prop: 'defaultPrice',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '状态',
          prop: 'statusValue',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '创建时间',
          prop: 'createTime',
          width:140
        },
        {
          label: '操作',
          input_type: 'button',
          width: 180,
          attribute: {
            fixed: 'right',
            align: 'center'
          },
          list: [
            {
              text: '编辑',
              method: 'rowEdit',
              limit: 'canteen::package::update'
            },
            { text: '删除', method: 'rowDelete',limit:'canteen::package::del' },
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      titleText: '编辑',
      initFileList:[],
      rowFormData: [
        {
          input_type: 'input',
          label: '套餐名称',
          key: 'menuName'
        },
        {
          input_type: 'radio',
          label: '状态',
          maxlength: 30,
          options:[
            {
              value:0,
              label:"启用",
            },
            {
              value:1,
              label:"禁用",
            }
          ],
          key: 'status'
        },
        {
          input_type: 'radio',
          label: '是否关联餐具',
          key: 'tablewareType',
          col: 24,
          method: 'tablewareTypeFun',
          options: [
            {
              label: '关联',
              value: "0"
            },
            {
              label: '不关联',
              value: "-1"
            }
          ]
        },
        {
          input_type: 'customize',
          customize: 'tablewareId',
          label: '关联餐具',
          key: 'tablewareId',
          hide:true,
          col: 24
        },
        {
          input_type: 'customize',
          customize: 'dishesInfoReqList',
          label: '菜式',
          key: 'dishesInfoReqList',
          col: 24
        },
        {
          input_type: 'input',
          label: '价格',
          key: 'defaultPrice',
          disabled:true,
          col: 22
        }
      ],
      checkImgSuccess:false,
      isExist:null,
      rules: {
        tablewareType: [
          {
            required: true,
            message: '请关联餐具',
            trigger: 'blur',
          }
        ],
        menuName: [
          {
            required: true,
            message: '请填写套餐名称',
            trigger: 'blur'
          }
        ],
        dishesInfoReqList: [
          {
            required: true,
            message: '请选择菜式',
            trigger: 'change'
          }
        ],
        tablewareId: [
          {
            required: true,
            message: '请选择餐具',
            trigger: 'change'
          }
        ],
        status: [
          {
            required: true,
            message: '请选择状态',
            trigger: 'change'
          }
        ],
        defaultPrice: [
          {
            required: true,
            trigger: 'blur',
            validator: this.util.formRules.checkNumber({ min: 0, fixedNum: 2 }),
          }
        ]
      },
      rowForm: {
        dishesInfoReqList:[]
      },
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(160)}px`,
      // 图片相关
      headers: {},
      dialogVisible: false,
      dialogObj: null,
      fileList: [],
      isSearchIng: false,
      isAdd: true,
      suggestedPrice:'',
      totalDefaultPrice:''
    }
  },
  created() {
    //  console.log("$uploadUrl",this.$uploadUrl)
    
    
  },
  mounted() {
   
  },
  methods: {
    getInit: function () {
      this.loading = true
      // 查询园区列表信息
      getSetmeal(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    // 新增
    append() {
      this.titleText = '新增'
      this.isAdd = true
      this.rowForm = {}
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.jaForm.$refs.formbox.clearValidate('dishesInfoReqList')
        })
      }, 0);
      this.getTableware(this.tablewareFormData);
      this.getDishes(this.dishesFormData)
      this.showEditAll = true
    },
    // 编辑
    rowEdit: function (row,details) {
      this.isAdd = false
      
      // console.log("row",row)
      this.rowForm = {
        ...row
      }
      if(row.tablewareType==0){
        // tablewareId
         let index1 = this.rowFormData.findIndex(item=>item.key == 'tablewareId' ) 
         this.rowFormData[index1].hide=false
      }else{
        let index2 = this.rowFormData.findIndex(item=>item.key == 'defaultPrice' )
         this.rowFormData[index2].disabled=false
      }
      if(details=="details"){
        this.titleText = '详情'
        this.allDisabled=true
      }else{
        this.titleText = '编辑'
        this.tablewareFormData.pageIndex=1
        this.dishesFormData.pageIndex=1
        this.tablewareList=[this.rowForm.tablewareId]
        this.dishesList=this.rowForm.dishesInfoReqList
      }
      this.getTableware(this.tablewareFormData);
      this.getDishes(this.dishesFormData)
      this.showEditAll = true
    },
    // 删除
    rowDelete(row) {
      this.$confirm('确定删除该条数据吗').then((res) => {
        console.log('row', row)
        delSetmeal(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    saveForm: function () {
      this.loading_form.isShow = true
      if(this.rowForm.tablewareType==-1){
        this.rowForm.tablewareId={}
      }
      if (this.isAdd) {
        addSetmeal(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('新增成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      } else {
        //  更新园区信息
        editSetmeal(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('修改成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      }
    },
    // 图片上传成功之后
    uploadFinish(){
      let fileList = this.$refs.Upload.fileList
      console.log("fileList---",fileList)
      this.checkImgSuccess=true
      this.isExist = fileList.find(item=> item.response.code !=200)
      if(!this.isExist){
        fileList.map(item=>{
          return item.response.data
        })
      }
      this.$refs.jaForm.$refs.formbox.clearValidate('urlLists')
    },
    // 删除图片
    imgRemoveMethod(){
      this.checkImgSuccess=false
      console.log("this.rowForm",this.rowForm)
    },
    // 点击切换是否关联
    tablewareTypeFun(method, monitor, val, data){
       this.dishesList=[]
       this.dishesFormData.pageIndex=1;
      this.getDishes(this.dishesFormData)
      let index1 = this.rowFormData.findIndex(item=>item.key == 'tablewareId' ) // 关联餐具 
      let index2 = this.rowFormData.findIndex(item=>item.key == 'defaultPrice' ) // 价格
      
      // tablewareId等于0 关联餐具
      if(monitor==0){  
        this.$set(this.rowForm,"tablewareId","")
        this.rowFormData[index1].hide=false
        this.rowFormData[index2].disabled=true
      }else{
        this.rowFormData[index1].hide=true
        this.rowFormData[index2].disabled=false
      }
      this.$set(this.rowForm,"defaultPrice","")
      this.$set(this.rowForm,"dishesInfoReqList",[])
      this.$refs.jaForm.$refs.formbox.clearValidate('dishesInfoReqList')
    },
    // 选择关联餐具读取默认价格
    changeTableware(data){
      if(this.rowForm.tablewareType==0){
        let suggestedPrice=data.suggestedPrice
        if(suggestedPrice=='自定义'){
          this.suggestedPrice="自定义"
          if(this.titleText=="编辑"){ // 编辑的时候，选择自定义餐具的时候，计算一边菜式总价
            this.changeDishes(this.rowForm.dishesInfoReqList)
          }
          let totalDefaultPrice=this.totalDefaultPrice?this.totalDefaultPrice:''
          this.$set(this.rowForm,"defaultPrice",totalDefaultPrice)
        }else{
          this.suggestedPrice=""
          this.$set(this.rowForm,"defaultPrice",suggestedPrice)
        }
      }
    },
    changeDishes(data){
      if(this.rowForm.tablewareType !=0 || this.suggestedPrice=="自定义"){
          let priceList=data.map(item=>{
          return Number(item.defaultPrice)
        })
        let total=priceList.reduce((a,b)=>a+b)
         this.$set(this.rowForm,"defaultPrice",total)
        this.totalDefaultPrice=total
      }
    },
    loadmore() {
      this.dishesFormData.pageIndex++;
      if(!this.dishesDialog){
        this.getDishes(this.dishesFormData);
      }
    },
    loadmoreTableware() {
      this.tablewareFormData.pageIndex++;
      if(!this.tablewareDialog){
        this.getTableware(this.tablewareFormData);
      }
    },
    // 菜式管理列表
    getDishes(dishesFormData){
      console.log("加仓---",dishesFormData)
      let presellFlag={}
      if(this.rowForm.tablewareType==0){
        presellFlag.presellFlag=0 
      }else if(this.rowForm.tablewareType==-1){
        presellFlag.presellFlag=""
      }
      let params={...dishesFormData,...presellFlag}
      getDishes(params).then(res=>{
        if(res.code==200){
          if(res.data.data.length==0){
            this.dishesDialog=true
          }else{
            // this.dishesList=[...this.dishesList,...res.data.data]
            this.dishesList=this._.unionBy(this.dishesList,res.data.data,'id')
          }
        }
      })
    },
    // 餐具管理
    getTableware(tablewareFormData){
      getTableware(tablewareFormData).then(res=>{
        if(res.code==200){
          if(res.data.data.length==0){
            console.log("暂无更多数据!")
            this.tablewareDialog=true
          }else{
            this.tablewareList=this._.unionBy(this.tablewareList,res.data.data,'id')
          } 
        }
      })
    },
    close(){
      let index1 = this.rowFormData.findIndex(item=>item.key == 'tablewareId' )
      this.rowFormData[index1].hide=true
    },
    formatter (val, key) {
      
      if (key === 'tablewareId') {
        return val.tablewareName
      }
      //  console.log("val--",key)
      return val || ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

.img_list{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 20px;
  li{
    width: 18%;
  }
}
</style>
