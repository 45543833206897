<template>
  <div class="allwh">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      :showIndex="true"
      @getInit="getInit"
      @search="search"
      @reset="reset"
      @exportFun="exportFun"
      @importForm="normalEvent({ref:'importForm',method:'importForm'})"
    ></ja-table>
    <!-- 导出弹窗 -->
    <import-form ref='importForm' @openResult="openResult"/>
    <!-- 校验结果 弹窗 -->
    <check-result ref="checkResult" :results="results" />
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import meterCommon from '../common/meterCommon'
import importForm from '../common/importForm'
import checkResult from '../common/checkResult'
import {columns_} from '../common/data'

export default {
  title: '水表',
  mixins: [table,meterCommon],
  name: 'index',
  components: {
    importForm,
    checkResult
  },
  data() {
    return {
      columns: [
        ...columns_,
        { label: '读数（L）', prop: 'data' },
      ],
      // 抄表配置不一样的地方
      typeObj:{
        id:124
      },
    }
  },
 
  methods:{
    
  },
}
</script>