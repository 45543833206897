<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      :valueFormat="valueFormat"
      :editDataFormat="editDataFormat"
      @getInit="getInit"
      @search="search"
      @reset="reset"
      @rowView="rowView"
      @rowCancel="rowCancel"
      @approve="approve"
    >
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="40%"
      @close="close"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :noButton="true"
        ref="jaForm"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
      </ja-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  getOrderRecord,
  cancelOrder,
  approveOrder
} from '@/api'
import table from '@/views/mixins/table'
export default {
  title: '会议室预约记录',
  mixins: [table],
  name: 'index',
  data() {
    return {
      show: false,
      showUser: false,
      parkId: 1,
      allDisabled: false,
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'name',
          attribute: {
            placeholder: '会议主题',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'createBy',
          attribute: {
            placeholder: '申请人',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'meetingRoomName',
          attribute: {
            placeholder: '会议室名称',
            size: 'small'
          }
        },
        {
          input_type: 'select',
          key: 'status',
          attribute: {
            placeholder: '状态',
            size: 'small'
          },
          options:[
            {label:"待开始",value:0},
            {label:"进行中",value:1},
            {label:"完成",value:2},
            {label:"取消",value:3}
          ]
        },
        {
          input_type: 'date',
          value: 'createDate',
          type: 'date',
          valueFormat: 'yyyy-MM-dd',
          placeholder: '申请日期'
        },
        {
          input_type: 'date',
          value: 'meetingDate',
          type: 'date',
          valueFormat: 'yyyy-MM-dd',
          placeholder: '会议时间'
        },
        // {
        //   input_type: 'time',
        //   type: 'datetime',
        //   isArrowControl: true,
        //   pickerOptions:{selectableRange: '08:30:00 - 21:00:00'},
        //   format: 'HH:mm',
        //   value: 'periodList',
        //   placeholder: '开始时间'
        // },
        // {
        //   input_type: 'time',
        //   type: 'datetime',
        //   isArrowControl: true,
        //   pickerOptions:{selectableRange: '08:30:00 - 21:00:00'},
        //   format: 'HH:mm',
        //   value: 'periodList',
        //   placeholder: '结束时间'
        // },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        },
        { normalBtn: 'download',
          exportObj: { rqParmas: this.rqParmas, rqUrl: '/order/export', rqMethod: 'get' }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '会议室',
          prop: 'meetingRoomName'
        },
        {
          label: '会议主题',
          prop: 'name'
        },
        {
          label: '会议时间',
          prop: 'meetingTime'
        },
        // {
        //   label: '会议状态',
        //   prop: 'statusLabel'
        // },
        // {
        //   label: '审批状态',
        //   prop: 'approveStatusLabel'
        // },
        {
          label: '状态',
          prop: 'allStatus'
        },
        {
          label: '申请人',
          prop: 'createName'
        },
        {
          label: '申请时间',
          prop: 'createTime'
        },
        {
          label: '操作',
          input_type: 'button',
          width: 180,
          attribute: {
            fixed: 'right',
            align: 'center'
          },
          list: [
            {
              text: '查看',
              method: 'rowView',
              limit: 'conferenceRoom::record::view'
            },
            {
              text: '审批',
              method: 'approve',
              limit: 'conferenceRoom::record::approve'
            },
            {
              text: '取消',
              method: 'rowCancel',
              limit: 'conferenceRoom::record::cancel'
            }
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      titleText: '查看',
      initFileList: [],
      rowFormData: [
        {
          input_type: 'text2',
          label: '会议主题',
          key: 'name'
        },
        {
          input_type: 'text2',
          label: '会议日期',
          key: 'meetingDate'
        },
        {
          input_type: 'text2',
          label: '会议时间',
          key: 'meetingTime'
        },
        {
          input_type: 'text2',
          label: '会议室',
          key: 'meetingRoomName'
        },
        {
          input_type: 'text2',
          label: '主持人',
          key: 'host'
        },
        {
          input_type: 'text2',
          label: '参会人',
          key: 'joinByList',
        },
        {
          input_type: 'text2',
          label: '会议说明',
          key: 'remark',
        },
        {
          input_type: 'text2',
          label: '状态',
          key: 'allStatus',
        },
        {
          input_type: 'text2',
          label: '审批说明',
          key: 'approveRemark',
        },
        {
          input_type: 'text2',
          label: '申请人',
          key: 'createBy',
        },
        {
          input_type: 'text2',
          label: '电话',
          key: 'mobile',
        },
        {
          input_type: 'text2',
          label: '申请时间',
          key: 'createTime',
        },
      ],
      checkImgSuccess: false,
      isExist: null,
      rules: {},
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`,
      // 图片相关
      headers: {},
      dialogVisible: false,
      dialogObj: null,
      fileList: [],
      isSearchIng: false,
      isAdd: true
    }
  },
  created() {
    //  console.log("$uploadUrl",this.$uploadUrl)
  },
  mounted() {},
  methods: {
    getInit: function () {
      this.loading = true
      console.info(this.filterData)
      // this.tableData = [{meetingRoomName: '123'}];
      this.loading = false

      getOrderRecord({params: this.filterData}).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    // 编辑
    rowView: function (row,details) {
      console.log("row",row)
      this.isAdd = false
      
      
      this.rowForm = {
        ...row
      }
      this.rowForm.meetingTime = row.meetingStartTime + '至' + row.meetingEndTime
      this.rowForm.joinByList = this.rowForm.joinByList.join(',')
      this.rowForm.allStatus=this.valueFormat("","allStatus",this.rowForm)
      this.showEditAll = true
    },
    rowCancel: function (row) {
      console.log("row",row)
      this.$confirm(
        '确定取消本次会议吗？'
      ).then((res) => {
        cancelOrder(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success('已取消')
          }
          this.getInit()
        })
      })
      
    },
    approve: function(row) {
      console.log("row",row)
      let params = {
        id: row.id,
        approveStatus: 0,
        approveRemark: ''
      }
      let _this = this
      this.$prompt('请输入备注', '审批', {
        confirmButtonText: '通过',
        cancelButtonText: '拒绝',
        distinguishCancelAndClose: true,
        callback: function(action, instance) {
          params.approveRemark = instance.inputValue
          console.info(action)
          if (action == 'confirm'){
            params.approveStatus = 1
            approveOrder(params).then((res) => {
              if (res.code === 200) {
                _this.$message.success('已通过')
              }
              _this.getInit()
            })
          } else if (action == 'cancel') {
            params.approveStatus = 2
            approveOrder(params).then((res) => {
              if (res.code === 200) {
                _this.$message.success('已拒绝')
              }
              _this.getInit()
            })
          }
        }
      })
        
    },
    // 关闭表单弹窗
    close(){
      
    },
    valueFormat(val, key, row) {
      if (key == 'meetingTime') {
        val = row.meetingDate + ' ' + row.meetingStartTime + '至' + row.meetingEndTime
        return val
      }
      // let statusList=
      // console.log(row.approveStatusLabel)
      if(key=='allStatus'){
        let statusVal=""
        if (row.status == 3) {
          statusVal="取消"
        } else {
          if(row.approveStatus == 0){
            statusVal="审批中"
          } else if(row.approveStatus == 2){
            statusVal="拒绝"
          } else {
            statusVal = row.statusLabel
          }
        }
        return statusVal
      }
      return val
    },
    /**
     * 控制按钮显示隐藏
     */
    editDataFormat(row, button, scope){
      if(row.status == 3) {
        if(button.text=='取消' || button.text == "审批"){
          return false
        }
      } else {
        if(row.approveStatus == 2){
          if(button.text=='取消' || button.text == "审批"){
            return false
          }
        } else if(row.approveStatus == 1){
          if(button.text == "审批"){
            return false
          }
          if(button.text == "取消" && (row.status == 1 || row.status ==2)){
            return false
          }
        }
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}

// 上传图像
.imgDefault {
  width: 150px;
  height: 200px;
  border: 1px solid $theme_bordercolor_DCDFE6;
}

/deep/ .el-upload__tip {
  position: absolute;
  width: 20%;
  top: 45%;
  left: 37%;
  text-align: center;
  line-height: 1;
  margin: 10px 0 8px 0;
}

.upload-img-form /deep/ .el-upload--picture-card {
  background-color: initial;
  border: initial;
  border-radius: initial;
  box-sizing: initial;
  width: initial;
  height: initial;
  line-height: initial;
  vertical-align: initial;
  display: inline-block;
  text-align: initial;
}

/deep/ ul.el-upload-list--picture-card {
  display: block;
}

/deep/ .el-upload-list.el-upload-list--text {
  width: 50%;

  > li.el-upload-list__item {
    cursor: pointer;
  }
}

// 上传图片按钮
.uploadBtn {
  position: absolute;
  top: 30%;
  left: 40%;
  color: $theme_color_0083ee;
  background: transparent;
  border: 1px solid $theme_bordercolor_0083ee;

  &:hover,
  &:focus {
    background: transparent;
    color: $theme_color_0083ee;
  }
}

// 图片缩略图
.videoContent {
  width: 40%;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 180px;
      height: 200px;
      flex-shrink: 0;
      margin: 0;
      border: 1px solid $theme_bordercolor_DCDFE6;
      border-radius: 0;
      overflow: hidden;
      position: relative;

      &:hover {
        .videoPlay_IconBox {
          display: none;
        }
      }

      .img-avatar {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
