import echarts from 'echarts'
import store from '../store/index'
export const drawChart = (options, el) => {
  const myChart = echarts.init(el)
  window.onresize = () => {
    const echartRE = echarts.init(el)
    echartRE.resize()
  }
  myChart.setOption(options)
}
export const createRandom = (min, max, size) => {
  const arr = []
  for (let i = 0; i < size; i++) {
    arr.push(Math.round(Math.random() * (max - min) + min))
  }
  return size === 1 ? arr[0] : arr
}

// eslint-disable-next-line camelcase
export const setFontSize = (res, new_clientWidth) => {
  /**
   * 这是一个echarts px转rem的方法
   * 参数:
   * res（必须）number  传入的px 例如16px则传16
   */
  // eslint-disable-next-line no-unused-vars
  const docEl = document.documentElement
  let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  if (!clientWidth) return
  if (clientWidth >= 750) clientWidth = 750
  // eslint-disable-next-line camelcase
  const new_width = new_clientWidth || 400
  // eslint-disable-next-line camelcase
  const fontSize = (store.state.width / new_width)
  return res * fontSize
}

// 获取当前的时间
export const currTime = function () {
  var now = new Date();

  var year = now.getFullYear(); //年
  var month = now.getMonth() + 1; //月
  var day = now.getDate(); //日

  var hh = now.getHours(); //时
  var mm = now.getMinutes(); //分
  var ss = now.getSeconds(); //分

  var clock = year + "-";

  if (month < 10)
    clock += "0";

  clock += month + "-";

  if (day < 10)
    clock += "0";

  clock += day + " ";

  if (hh < 10)
    clock += "0";

  clock += hh + ":";
  if (mm < 10)
    clock += '0';
  clock += mm + ":";

  if (ss < 10)
    clock += '0';
  clock += ss;
  return clock;
}
