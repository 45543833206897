<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      @getInit="getInit"
      @search="search"
      @rowDelete="rowDelete"
      @approve="approve"
      @cancelApprove="cancelApprove"
      @see="see"
      :editDataFormat="editDataFormat"
      :valueFormat="valueFormat"
      @reset="reset"
    >
      <template #pictureUrl="{row}">
        <!-- <div class="firstcell el-button--text" @click="seeDetails(row)">{{row.pictureUrl}}</div> -->
        <el-image
          style="width: 50px; height: 50px"
          :src="row.pictureUrl"
          :preview-src-list="[row.pictureUrl]"
          fit="cover"
        ></el-image>
      </template>
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="50%"
      @close="close"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :allDisabled="allDisabled"
        @reset="showEditAll = false"
        @save="saveForm"
        v-if="showEditAll"
        ref="jaForm"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >

      </ja-form>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showDetail"
      width="50%"
      @close="close"
      title="详情"
    >
      <ja-detail :options="detailOptions" :detailData="detailData"></ja-detail>
    </el-dialog>
  </div>
</template>
<script>
import {
  getEventEmergency,
  addEventEmergency,
  updateEventEmergency,
  infoManagementDelete
} from '@/api'
import table from '@/views/mixins/table'
import editorMce from '@/components/editorMce'
export default {
  title: '应急事件记录',
  mixins: [table],
  components:{
    editorMce
  },
  name: 'merchantManage',
  data() {
    return {
      Config: { height: 300 },
      showDetail:false,
      detailOptions:
      [
        { label: '事件名称', key: 'eventName', col: 12 },
        { label: '事件级别', key: 'eventLevel', col:12},
        { label:'状态', key: 'eventState', col: 12 },
        { label: '事件类型', key: 'eventType', col: 12 },
        { label: '事发时间', key: 'eventTime', col: 12 },
        { label: '事件描述', key: 'remark', col: 12 },
        { label: '审批人', key: 'approvalPerson', col: 12 },
        { label: '位置', key: 'address', col: 12 },
        { label: '处理过程',type:"table",key: 'detailVOList', col: 24,columns: [
        // 表头数据
            {
              label: '处理事件',
              prop: 'handlingEvents',
            },
            {
              label: '事件内容',
              prop: 'handlingContent'
            },
            {
              label: '完成时间',
              prop: 'createTime'
            },
          ] 
        },
      ],
      detailData: {},
      show: false,
      showUser: false,
      parkId: 1,
      allDisabled: false,
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
        // cityId:''
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'eventName',
          attribute: {
            placeholder: '事件名称',
            size: 'small'
          }
        },
        {
          input_type: 'select',
          key: 'eventLevel',
          attribute: {
            placeholder: '事件级别',
            size: 'small'
          },
          options:[
            {
              label:"紧急 ",
              value:1
            },
            {
              label:"一般",
              value:2
            },
          ]
        },
        {
          input_type: 'select',
          key: 'eventType',
          attribute: {
            placeholder: '事件类型',
            size: 'small'
          },
          options:[
            {
              label:"手动",
              value:'1'
            },
            {
              label:"自动",
              value:'2'
            }
          ]
        },
        {
          input_type: 'select',
          key: 'eventState',
          attribute:{
           placeholder: '状态',
          },
          options:[
            {
              label:"完成",
              value:1
            },
            {
              label:"处理中",
              value:2
            },
            {
              label:"取消",
              value:3
            },
            {
              label:"审批中 ",
              value:4
            },
            {
              label:"审批拒绝 ",
              value:5
            },
          ]
        },
        {
          input_type: 'date',
          value: 'eventTime',
          type: 'date',
          placeholder: '事发时间'
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '新增',
          input_type: 'button',
          method: 'append',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-plus',
            plain: true
          },
          limit: 'ecyManage::ecyEventRecord::add'
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '事件名称',
          prop: 'eventName',
        },
        {
          label: '状态',
          prop: 'eventState'
        },
        {
          label: '事件级别',
          prop: 'eventLevel'
        },
        {
          label: '事件类型',
          prop: 'eventType',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '位置',
          prop: 'address',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '事发时间',
          prop: 'eventTime'
        },
        {
          label: '事件描述',
          prop: 'remark'
        },
        {
          label: '操作',
          input_type: 'button',
          width: 180,
          attribute: {
            fixed: 'right',
            align: 'center'
          },
          list: [
            {
              text: '取消',
              method: 'cancelApprove',
              limit:'ecyManage::ecyEventRecord::cancel',
            },
            {
              text: '事件描述',
              method: 'rowEdit',
              limit:'ecyManage::ecyEventRecord::edit',
            },
            {
              text: '审批',
              method: 'approve',
              limit:'ecyManage::ecyEventRecord::aduit',
            },
            {
              text: '查看',
              method: 'see',
            },
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      titleText: '编辑',
      initFileList: [],
      rowFormData: [
        {
          input_type: 'input',
          label: '事件名称',
          key: 'eventName'
        },
        {
          input_type: 'input',
          label: '事件描述',
          key: 'remark',
        },
       
        {
          input_type: 'input',
          label: '位置',
          key: 'address'
        },
        
      ],
      checkImgSuccess: false,
      isExist: null,
      rules: {
        eventName: [
          {
            required: true,
            message: '请输事件名称',
            trigger: 'blur'
          }
        ],
        address: [
          {
            required: true,
            message: '请输入位置',
            trigger: 'change'
          }
        ],
      },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`,
      // 图片相关
      headers: {},
      dialogVisible: false,
      dialogObj: null,
      fileList: [],
      isSearchIng: false,
      isAdd: true
    }
  },
  created() {
    //  console.log("$uploadUrl",this.$uploadUrl)
  },
  watch: {
    'rowForm.content': {
      handler: function () {
        if (this.$refs.jaForm) {
          this.$refs.jaForm.$refs.formbox.validateField('content')
        }
      }
    }
  },
  mounted() {},
  methods: {
    getInit: function () {
      this.loading = true
      getEventEmergency(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    // 新增
    append() {
      this.titleText = '新增'
      this.isAdd = true
      this.rowForm = {}
      this.initFileList = []
      this.rowFormData[this.filtersIndx('address')].disabled=false;
      this.rowFormData[this.filtersIndx('eventName')].disabled=false;
      this.showEditAll = true
    },
    filtersIndx(key){
      return this.rowFormData.findIndex(
        (item) => item.key == key
      )
    },
    // 编辑
    rowEdit: function (row, details) {
      this.isAdd = false

      // console.log("row",row)
      
      this.rowForm = {
        ...row
      }
      this.rowFormData[this.filtersIndx('address')].disabled=true;
      this.rowFormData[this.filtersIndx('eventName')].disabled=true;
      if (details == 'details') {
        this.titleText = '详情'
      } else {
        this.titleText = '编辑'
      }
      // console.log("什么鬼--", [].map(Number))
      this.showEditAll = true
    },
    // 查看
    see(row){
      this.showDetail=true
      this.detailData={
        ...row
      }
      for (const key in this.detailData) {
        console.log("key",this.detailData[key],key)
        this.detailData[key]=this.valueFormat(this.detailData[key],key)
        

      }
    },
    // 删除
    rowDelete(row) {
      this.$confirm(
        '确定删除该条数据吗？'
      ).then((res) => {
        console.log('row', row)
        infoManagementDelete(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    saveForm: function () {
      // 保存
      // console.log("this.rowForm11111",this.rowForm)
      this.loading_form.isShow = true
      if (this.isAdd) {
        //  添加园区信息
        addEventEmergency(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('新增成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      } else {
        //  更新园区信息
        updateEventEmergency(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('修改成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      }
    },
    // 审核
    approve: function(row) {
      let params = {
        id: row.id,
        eventState: 0,
        approveRemark: ''
      }
      let _this = this
      this.$prompt('请输入备注', '审批', {
        confirmButtonText: '通过',
        cancelButtonText: '拒绝',
        distinguishCancelAndClose: true,
        callback: function(action, instance) {
          params.approveRemark = instance.inputValue
          console.info(action)
          if (action == 'confirm'){
            params.eventState = 2
            updateEventEmergency(params).then((res) => {
              if (res.code === 200) {
                _this.$message.success('已通过')
              }
              _this.getInit()
            })
          } else if (action == 'cancel') {
            params.eventState = 5
            updateEventEmergency(params).then((res) => {
              if (res.code === 200) {
                _this.$message.success('已拒绝')
              }
              _this.getInit()
            })
          }
        }
      })
        
    },
    cancelApprove:function(row) {
      let params = {
        id: row.id,
        eventState: 3,
        approveRemark: ''
      }
      let _this = this
      this.$confirm(
        '确定取消该记录吗？'
      ).then((res) => {
        updateEventEmergency(params).then((res) => {
          _this.$message.success('已取消')
          _this.getInit()
        })
      })
    },
    // 转换值
    valueFormat(val, key, row){
      if (key == 'eventLevel') {
        let list={
              1:"紧急",
              2:"一般",
            }
        return list[val]
      }else if(key=="eventState"){
         let list={
              1:"完成",
              2:"处理中",
              3:"取消",
              4:"审批中",
              5:"审批拒绝",
            }
        return list[val]
      }else if(key=="eventType"){
         let list={
              1:"手动",
              2:"自动"
            }
        return list[val]
      }
      return val
    },
     /**
     * 控制按钮显示隐藏 审批状态
     *  
     */
    editDataFormat(row, button, scope){
      if(row.eventState == 4){
        if(button.text=='取消' || button.text=='审批' ){
          return true
        }
      }else if(row.eventState == 2 || row.eventState == 4){
        if(button.text=='事件描述'){
          return true
        }
      }
      if(button.text=='查看'){
        return true
      }
      return false
    },
    // 关闭表单弹窗
    close(){
     
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}

// 上传图像
.imgDefault {
  width: 150px;
  height: 200px;
  border: 1px solid $theme_bordercolor_DCDFE6;
}

/deep/ .el-upload__tip {
  position: absolute;
  width: 20%;
  top: 45%;
  left: 37%;
  text-align: center;
  line-height: 1;
  margin: 10px 0 8px 0;
}

.upload-img-form /deep/ .el-upload--picture-card {
  background-color: initial;
  border: initial;
  border-radius: initial;
  box-sizing: initial;
  width: initial;
  height: initial;
  line-height: initial;
  vertical-align: initial;
  display: inline-block;
  text-align: initial;
}

/deep/ ul.el-upload-list--picture-card {
  display: block;
}

/deep/ .el-upload-list.el-upload-list--text {
  width: 50%;

  > li.el-upload-list__item {
    cursor: pointer;
  }
}

// 上传图片按钮
.uploadBtn {
  position: absolute;
  top: 30%;
  left: 40%;
  color: $theme_color_0083ee;
  background: transparent;
  border: 1px solid $theme_bordercolor_0083ee;

  &:hover,
  &:focus {
    background: transparent;
    color: $theme_color_0083ee;
  }
}

// 图片缩略图
.videoContent {
  width: 40%;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 180px;
      height: 200px;
      flex-shrink: 0;
      margin: 0;
      border: 1px solid $theme_bordercolor_DCDFE6;
      border-radius: 0;
      overflow: hidden;
      position: relative;

      &:hover {
        .videoPlay_IconBox {
          display: none;
        }
      }

      .img-avatar {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
