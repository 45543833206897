import {getMeterList, exportMeter} from '@/api'
import {limitObj , } from '../common/data'

export default {
    data(){
        return{
            labelWidth: `${this.util.setFontSize(150)}px`,
            filterData: {
                pageIndex: 1,
                pageSize: 10
            },
            conditionData: [
                { label:'抄表日期',input_type: 'date',type: 'daterange',value: 'time',format:'yyyy-MM-dd',startPlaceholder: '开始日期',endPlaceholder: '结束日期',attribute: {unlinkPanels:true},valueFormat:'yyyy-MM-dd',},
                // { input_type: 'select',key: 'buildingId',attribute: {placeholder: '楼栋',size: 'small'},options: [],styleWidth:'100px',
                //   relevance:{ sonKey:'floorId', This:this, rqParam:{ rqUrl:"/floor/all",rqData:()=>{return {params:{ buildingId: this.filterData.buildingId } }},labelName:'floorName',valueName:'floorId',resRule:'data'},}
                // },
                // { input_type: 'select',key: 'floorId',attribute: {placeholder: '楼层',size: 'small'},options: [],styleWidth:'100px'},
                { input_type: 'input',key: 'deviceCode',attribute: { placeholder: '设备编码',size: 'small'}},
                { normalBtn:'search',},
                { normalBtn:'reset'},

                // { normalBtn:'import', limit:limitObj[this.typeObj.id].importLimit},
                // { normalBtn:'download',method:'exportFun', },
            ],
            tableData: [],
            loading: false,
            total: 0,
            results:null,
            columns_:[
                // { label: '楼栋', prop: 'applyCode' },
            ],
        }
    },
    created() {
        // this.util.rqOptionsList({ who: this.conditionData[1], rqUrl: "/building/list", rqData: { pageIndex: 1, pageSize: 200 }, rqMethod: 'post', labelName: 'buildingName', valueName: 'id' })
        this.conditionDataPush()
    },
    methods: {
        conditionDataPush(){
            let arr = [
                { normalBtn:'import', limit: limitObj[this.typeObj.id].importLimit},
                { normalBtn:'download',method:'exportFun', limit: limitObj[this.typeObj.id].exportLimit},
            ]
            this.conditionData.push(...arr)
        },
        // 要请求的参数
        rqParmas(){
            return {
                pageSize: this.filterData.pageSize,
                pageIndex: this.filterData.pageIndex,
                deviceCode: this.filterData.deviceCode || '',
                buildingId: this.filterData.buildingId || '',
                floorId: this.filterData.floorId || '',
                startTime: this.filterData.time?this.filterData.time[0]+' 00:00:00':'',
                endTime: this.filterData.time?this.filterData.time[1]+' 23:59:59':'',
                subcategory: this.typeObj.id,
            }
        },
        getInit() {
            // console.info('-----------' + this.typeObj.id)
            this.loading = true
            let data =this.rqParmas()
            getMeterList(data).then(res => {
                console.log(res)
                this.loading = false
                this.tableData = res.data.data
                this.total = res.data.total
            })
        },
        openResult({data,uploadFlag}){
            this.results = {data,uploadFlag}
            this.$nextTick(()=>{
              this.$refs.checkResult.rowDetail()
            })
        },
        // 导出
        exportFun(){
            let data =this.rqParmas()
            exportMeter(data).then(res => {
                this.util.dealExcelRespose(res)
            })
        }
    },
}