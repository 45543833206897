<template>
  <div class="allwh">
    <div class="allwh-wrapper">
      <!-- 系统设置-日志管理 -->
      <!-- rowDetail: 详情操作->详情面板 -->
      <!-- append: 新增操作->新增面板 -->
      <!-- rowEdit: 编辑操作->编辑面板 -->
      <!-- rowDelete: 删除操作->删除面板 -->
      <!-- rowDataScope: 通行权限 -->
      <!-- getInit: 获取ajax数据 -->
      <!-- outPut_: 导出数据 单独导出 -->
      <!-- outPutExcell_obj: 导出数据 封装导出-->
      <!-- editDataFormat: 检测当前行显示的内容（如：根据条件显示待处理、已处理）+ 可根据自定义的key值判断 -->
      <ja-table ref="tst"
                v-model="filterData"
                :conditionData="conditionData"
                :columns="columns"
                :tableData="tableData"
                :total="total"
                :loading="loading"
                :valueFormat="formatter"
                @append="append"
                @rowEdit="rowEdit"
                @rowDelete="rowDelete"
                @syncDr="syncDr"
                @rowDataScope="rowDataScope"
                @getInit="getInit"
                @search="search"
                @change="selectChange"
                @reset="reset">

        <!-- 人脸图像 -->
        <template #img='{row, prop}'>
          <el-image style="width: 40px;height: 45px;"
                    :preview-src-list="row.imgList"
                    :src="row[prop]"
                    alt=""
                    v-if="row[prop]" />
        </template>
      </ja-table>

      <!-- 新增/编辑 -->
      <!-- reset：重置-取消  -->
      <!-- save：保存 -->
      <el-dialog :close-on-click-modal="false"
                :visible.sync="showEditAll"
                width="40%"
                :title="titleText">
        <ja-form :rules="rules"
                :label-width="labelWidth"
                v-model="rowForm"
                :formArray="rowFormData"
                @reset="showEditAll = false"
                v-if="showEditAll"
                v-loading="loading_form.isShow"
                :element-loading-text="loading_form.text"
                :element-loading-spinner="loading_form.icon"
                :element-loading-background="loading_form.bg"
                :element-loading-custom-class="loading_form.formLoading"
                @save="saveForm"
                @change2="selectChange2">

          <template #statusPhone='{row}'>
            <el-switch class="statusPhone"
                      v-model="row.statusPhone" @change="changeStatusPhone"
                      >
            </el-switch>
            <p class="statusPhoneTxt">开通与关闭手机登录账号</p>
          </template>

          <template #upload>
            <div class="videoContent">
              <ul v-if="fileList.length > 0"
                  class="el-upload-list--picture-card ">
                <li :class="['el-upload-list__item', fileList[0].status=='success'?'is-success':'']">
                  <img :src="fileList[0].url"
                      alt="人脸图像"
                      class="img-avatar">
                  <!-- 图片放大+删除控件 -->
                  <label class="el-upload-list__item-status-label"><i class="el-icon-upload-success el-icon-check"></i></label>
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview"
                          @click="handlePictureCardPreview(fileList[0])">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete"
                          @click="imgRemoveMethod(fileList[0])">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </li>
              </ul>
              <p v-else
                class="imgDefault"
                width="150"
                height="200"
                src=""></p>
            </div>
            <div slot="tip"
                class="el-upload__tip">只能上传jpg/png文件，且不超过2MB</div>
              <el-upload
              :action="$uploadUrl+'/oss/upload'"
              list-type="picture-card"
              accept=".png,.jpg,.jpeg"
              ref="imgUpload"
              class="upload-img-form"
              multiple
              :show-file-list="false"
              :file-list="fileList"
              :limit="1"
              :headers="headers"
              :on-remove="imgRemoveMethod"
              :before-upload="beforeUploadImg"
              :on-preview="handlePictureCardPreview"
              :on-success="imgSuccessMethod"
              :on-change="imgListChange"
              :on-error="imgErrorMethod"
              :on-exceed="handleExceed" >
              <el-button size="small"
                        type="primary"
                        class="uploadBtn">上传图片</el-button>
            </el-upload>
          </template>

        </ja-form>
      </el-dialog>

      <!-- 图像预览 -->
      <el-dialog :visible.sync="dialogVisible"
                width="40%"
                append-to-body>
        <img width="100%"
            :src="dialogObj.url"
            alt=""
            v-if="dialogObj && dialogObj.url && dialogVisible">
      </el-dialog>

      <!-- 分配通行权限 -->
      <el-dialog :close-on-click-modal="false"
                title="通行权限"
                :visible.sync="openDataScope"
                width="500px"
                style="margin-top:15vh;"
                append-to-body
                :v-loading="loading_form.isShow"
                :element-loading-text="loading_form.text"
                :element-loading-spinner="loading_form.icon"
                :element-loading-background="loading_form.bg"
                :element-loading-custom-class="loading_form.formLoading">
        <ja-scope class="box"
                  :formArray="rowFormData2"
                  :rowForm2="rowForm2"
                  :checkedKeys="rowForm2.deviceIds"
                  @submitDataScope="submitDataScope"
                  @reset2="openDataScope=false">
        </ja-scope>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import table from "@/views/mixins/table"
import { getEmployList, employeeAdd, employeeUpdate, employeeDelete, updateEmployeeCard } from '@/api'
import { userQueryColumn, userQueryColumnMap, userColumn, userFormData, userFormDataMap, userFormRules, userForm2Data } from './userColumn'
export default {
  title: '用户管理',
  mixins: [table],
  name: 'index',
  data () {
    return {
      filterData: { pageIndex: 1, pageSize: 10, },
      conditionData: userQueryColumn,
      conditionDataMap: userQueryColumnMap,
      columns: userColumn,
      tableData: [],
      loading: false,
      total: 0,
      // 新增/编辑
      titleText: '编辑',
      rowFormData: userFormData,
      rowFormDataMap: userFormDataMap,
      rules: userFormRules,
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`,
      isAdd: true,
      // 图片相关
      headers: {},
      dialogVisible: false,
      dialogObj: null,
      fileList: [],
      isAbortUpImg: false,
      // 通行权限
      rowFormData2: userForm2Data,
      rowForm2: {},
      openDataScope: false // 是否显示弹出层（数据权限）
    }
  },
  computed: {
    loginByMobile (row) {
      const loginByMobile = row.loginByMobile !== 1
      return 
    }
  },
  watch: {
    'rowForm.companyId' (newVal, oldVal) { // 部门
      if(newVal != oldVal && newVal != '' &&  (newVal && newVal!=0 || newVal == 0)) {
        if (this.titleText === '新增') {
          if (this.rowForm['departmentId']) { this.rowForm['departmentId'] = null }
        } else if (this.titleText === '编辑' && typeof(oldVal) != 'undefined') {

        }
        const paramJson = { params: { pageIndex: 1, pageSize: 200, companyId: newVal } }
         // 所属部门
        this.util.rqOptionsList({ who: [this.rowFormData[this.rowFormDataMap.deptId]], rqUrl: "/department", rqData: paramJson, rqMethod: 'get', labelName: 'deptName', valueName: 'id' })
      }
    }
  },
  created () {
    // 顶部搜索框
    const paramObj = { params:{ pageIndex: 1, pageSize: 200 } }
    this.util.rqOptionsList({ who: [this.conditionData[this.conditionDataMap.companyId], this.rowFormData[this.rowFormDataMap.companyId]], rqUrl: "/company", rqData: paramObj, rqMethod: 'get', labelName: 'companyName', valueName: 'id' }) // 所属公司

    // 表单里的
  },
  methods: {
    getInit () { // 查询用户列表
      this.loading = true

      getEmployList(this.filterData).then(({ data }) => {
        //this.tableData = data.data
        if (data.data.length > 0) {
          this.tableData = data.data.map(item => {
            const obj = item
            obj.statusPhone = obj.loginByMobile === 0 // 0-开启 1-关闭
            obj.imgList = []
            obj.imgList[0] = item.faceImg
            return obj
          })
        } else {
          this.tableData = data.data
        }
        this.total = data.total
        this.loading = false
      })
    },
    formatter (val, key) {
      if (key === 'status') {
        return ['在职', '离职'][val || 0]
      }
      return val || ''
    },
    rowDelete (row) { // 删除
      this.$confirm('是否确认删除该用户信息').then(res => { // 删除用户信息
        employeeDelete(row.id).then(res => {
          this.$message.success(res.msg)
          this.getInit()
        })
      })
    },
    selectChange (row, val) { // 所属企业和所属公司下拉修改
      if (row === 'companyId') { // 设备大类
        if (this.filterData.deptId) { this.filterData.deptId = '' }
        const paramJson = { params:{ pageIndex: 1, pageSize: 200, companyId: val} }
        this.util.rqOptionsList({ who: [this.rowFormData[this.rowFormDataMap.deptId]], rqUrl: "/department", rqData: paramJson, rqMethod: 'get', labelName: 'deptName', valueName: 'id' }) // 所属部门
      }
    },
    selectChange2 (row, item, key) { // 所属企业和所属公司下拉修改
      if ( row.key === 'companyId' ) {
        if (this.rowForm['deptId']) { this.rowForm['deptId'] = null }
      }
    },
    append () { // 新增
      this.isAdd = true
      this.titleText = '新增'
      
      this.rowFormData[this.rowFormDataMap.nickname].disabled=false
      this.rowFormData[this.rowFormDataMap.password].hide = false
      this.rowFormData[this.rowFormDataMap.password].disabled = true
      this.rowFormData[this.rowFormDataMap.loginName].hide = true
      this.fileList = []
      this.dialogObj = {}
      this.rowForm = {
        password:123456,
        statusPhone:1
      }
      this.showEditAll = true
    },
    rowEdit (row) { // 编辑
      this.isAdd = false
      this.titleText = '编辑'
      this.rowForm = { ...row }
      if(row.faceImg){
        this.fileList = [{ "status": "success", "url": row.faceImg }]
        this.dialogObj = { "status": "success", "url": row.faceImg }
      }else{
        this.fileList = []
        this.dialogObj = {}
      }
      this.rowFormData[this.rowFormDataMap.password].hide = true
      this.rowFormData[this.rowFormDataMap.password].disabled = row.loginByMobile == 0 ? false:true
      this.rowFormData[this.rowFormDataMap.loginName].hide = false
      this.rowFormData[this.rowFormDataMap.nickname].disabled=true
      console.log('row-->',row)
      this.showEditAll = true
    },
    saveForm () { // 保存
      // if (this.isAbortUpImg) return
      console.log('this.---',this.rowForm)
      // return
      this.loading_form.isShow = true
      if (this.isAdd) {
        const rowForm = this.rowForm
        rowForm.loginByMobile = rowForm.statusPhone === true ? 0 : 1
        const paramObj = this.util.paramChange(rowForm, '', 'edit')
        employeeAdd(paramObj).then(res => { // 新增用户信息
          this.loading_form.isShow = false
          this.$message.success(res.msg)
          this.showEditAll = false
          this.getInit()
        }).catch(() => {
          this.loading_form.isShow = false
        })
      } else {
        const rowForm = this.rowForm
        rowForm.loginByMobile = rowForm.statusPhone === true ? 0 : 1
        const paramObj = this.util.paramChange(rowForm, '', 'edit')
        employeeUpdate(paramObj).then(res => { // 修改用户信息
          this.loading_form.isShow = false
          this.$message.success(res.msg)
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          this.loading_form.isShow = false
          // this.$message.error(error)
        })
      }
    },
    rowDataScope (row) { // 权限设置
      const paramJsonOld = this.global.dropdown
      const paramJson = {}
      paramJson.pageIndex = paramJsonOld.pageIndex
      paramJson.pageSize = paramJsonOld.pageSize
      paramJson.park_id = row.park_id
      const paramObj = this.util.paramChange(paramJson, 'building_info')
      this.util.rqOptionsList({ who: this.rowFormData2[0], rqUrl: "/query/building_info", rqData: paramObj, rqMethod: 'post', labelName: 'building_name', valueName: 'id', addAll: true }) // 获取楼栋列表

      
    },
    submitDataScope: function (row, checkedKeys) { /** 提交按钮（通行权限） */
      const param = {}
      param.companyId = row.org_id
      param.deviceIds = checkedKeys
      param.employeeId = row.id
    },
    changeStatusPhone(){
      let status = this.rowForm.statusPhone
      this.$set(this.rowFormData[this.rowFormDataMap.password],'disabled',status?false:true)
    },
    //= ==== 图片上传相关 =====
    imgListChange (file, fileList) {
      this.fileList.splice(0, this.fileList.length, ...fileList)
    },
    // 图片预览
    handlePictureCardPreview (file) {
      this.dialogObj = file
      this.dialogVisible = true
    },
    // 图片删除
    imgRemoveMethod (file, fileList) {
      if (this.fileList && this.fileList.length > 0) {
        this.fileList.splice(0, 1)
        this.rowForm.face_img = ''
      }
      if (this.$refs.imgUpload.uploadFiles && this.$refs.imgUpload.uploadFiles.length > 0) {
        this.$refs.imgUpload.uploadFiles.splice(0, 1)
      }
    },
    // 图片上传之前限制
    beforeUploadImg (file) {
      let fileSize = 0
      let fileSizeText = '文件大小不能超过2MB'
      fileSize = file.size / 1024 / 1024 < 2
      // fileSizeText = '图片大小不能超过10M8'
      if (!fileSize) {
        this.$message.warning(fileSizeText)
        this.$refs.imgUpload.abort()
        this.isAbortUpImg = true
        this.loading_form.isShow = false
        return false
      }
      this.loading_form.isShow = true
    },
    // 图片上传失败
    imgErrorMethod () {
      this.$message.error('图片上传失败，请重试')
      this.loading_form.isShow = false
      this.isAbortUpImg = true
      this.$refs.imgUpload.abort()
      return false
    },
    // 图片上传成功
    imgSuccessMethod (response) {
      this.rowForm.face_img = response.data
      this.loading_form.isShow = false
    },
    // 图片上传限制
    handleExceed (files) {
      if ((files.length + this.fileList.length) > 1) {
        this.$message.warning('最多只能上传1张图片')
      }
      return false
    },
    // 同步食堂饭卡
    syncDr (row) {
      updateEmployeeCard({employeeCode: row.id}).then(res => {
          this.loading_form.isShow = false
          this.$message.success(res.msg)
          this.getInit()
        }).catch(error => {
          this.loading_form.isShow = false
          // this.$message.error(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/style/mixin.scss';
  /deep/ .el-dialog {
    margin-top: 4vh !important;
  }
  /deep/ .el-dialog .statusPhone {
    margin-left: 20px;
  }
  /deep/ .el-dialog .statusPhoneTxt {
    color: $theme_color_c0c4cc;
    font-size: $theme_fontsize_12;
    display: inline-block;
    margin-left: 14px;
  }

  // 上传图像
  .imgDefault {
    width: 150px;
    height: 200px;
    border: 1px solid $theme_bordercolor_DCDFE6;
  }
  /deep/ .el-upload__tip {
    line-height: 1;
    margin: 12px 0 8px 0;
  }
  .upload-img-form /deep/ .el-upload--picture-card {
    background-color: initial;
    border: initial;
    border-radius: initial;
    box-sizing: initial;
    width: initial;
    height: initial;
    line-height: initial;
    vertical-align: initial;
    display: inline-block;
    text-align: initial;
  }
  /deep/ ul.el-upload-list--picture-card {
    display: block;
  }
  /deep/ .el-upload-list.el-upload-list--text {
    width: 50%;
    > li.el-upload-list__item {
      cursor: pointer;
    }
  }
  // 上传图片按钮
  .uploadBtn {
    color: $theme_color_0083ee;
    background: transparent;
    border: 1px solid $theme_bordercolor_0083ee;
    &:hover,
    &:focus {
      background: transparent;
      color: $theme_color_0083ee;
    }
  }
  // 图片缩略图
  .videoContent {
    width: 95%;
    padding-top: 12px;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 150px;
        height: 200px;
        flex-shrink: 0;
        margin: 0;
        border: 1px solid $theme_bordercolor_DCDFE6;
        border-radius: 0;
        overflow: hidden;
        position: relative;
        &:hover {
          .videoPlay_IconBox {
            display: none;
          }
        }
        .img-avatar {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
</style>
