import http from './plugin/ajax'

// 设备列表-新增设备列表信息
export const deviceInfoAdd = (data) => http.post('/device/info/add', data)

// 设备列表-更新设备列表信息
export const deviceInfoEdit = (data) => http.put('/device/info/edit', data)

// 设备列表-删除设备列表信息
export const deviceInfoDelete = (data) => http.delete('/device/info/' + data)

export const deviceInfoGet = (data) => http.get('/device/info', {
  params: data
})

// 门禁通行记录
export const entranceGuardGet = (data) => http.get('/entranceGuard', {
  params: data
})
// 设备-设备分组列表
export const deviceGroupGet = (data) => http.get('/device/group', {
  params: data
})
// 设备-设备分组新增
export const deviceGroupAdd = (data) => http.post('/device/group', data)
// 设备-备分组更新
export const deviceGroupEdit = (data) => http.put('/device/group', data)
// 设备-备分组删除
export const deviceGroupDelete = (data) => http.delete('/device/group/'+data)

// 视频列表分组
export const getSidebarTree= (data) => http.get('/monitorView/getSidebarTree', {
  params: data
})
// 查看视频流
export const monitorView= (data) => http.get('/monitorView/',{
  params:data
})

// 查看设备列表
export const deviceList=(data) => http.get('/device/console',{
  params:data
})
//设备统一接口
export const deviceControl=(data) => http.post("/device/control",data)

// 设备控制统一
export const deviceThreshold = (data) => http.post('/device/console/threshold', data)
// 一键报障
export const repairDevice=(data) => http.post("/device/console/repair",data)

// 电表报表账单
export const finance= (data) => http.get('/finance',{
  params:data
})
