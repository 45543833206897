<template>
  <!-- 查看留言 -->
  <div>
    <el-dialog :visible="isShowLeaveWord" title="查看留言" :width="`40%`" append-to-body @close="close" v-if="isShowLeaveWord">
      <ja-table ref="tst" :noSearch="true" :columns="columns" :border="true" :tableData="tableData" :total="total" :loading="loading" @getInit="getInit">
      </ja-table>
    </el-dialog>
  </div>
</template>

<script>
import table from '@/views/mixins/table'
export default ({
  mixins: [table],
  props: {
    isShowLeaveWord: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: [
        { label: '姓名', width: 120, prop: 'name', },
        { label: '留言内容', prop: 'words', attribute: { showOverflowTooltip: false,  }, },
      ]
    }
  },
  methods: {
    getInit() {
      this.loading = false
      this.tableData = [
        { name: '刘德华', words: '希望越做越好！' },
        { name: '黎明', words: '餐具很干净，价格公道' },
      ]
    },
    close() {
      this.$emit('update:isShowLeaveWord', false)
    },
  }
})
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding-top: 20px;
}
</style>