<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      @getInit="getInit"
      @search="search"
      @rowDelete="rowDelete"
      @reset="reset"
    >
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="40%"
      @close="close"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :allDisabled="allDisabled"
        @reset="showEditAll = false"
        @save="saveForm"
        @suggestedPriceTypeFun="suggestedPriceTypeFun"
        v-if="showEditAll"
        ref="jaForm"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
        <template #uplpadfaceImg>
          <upload-file
            ref="Upload"
            @uploadFinish="uploadFinish"
            @imgRemoveMethod="imgRemoveMethod"
            :autoUpload="true"
            :accept="'.jpeg,.png,.jpg'"
            :limit="1"
            :initFileList="[...initFileList]"
            :fileSize="10"
            v-if="showEditAll"
          />
        </template>
      </ja-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  getOrderrecord,
  addTableware,
  editTableware,
  delTableware,
  getDepartment
} from '@/api'
import table from '@/views/mixins/table'
export default {
  title: '订餐记录',
  mixins: [table],
  name: 'index',
  data() {
    return {
      show: false,
      showUser: false,
      parkId: 1,
      allDisabled: false,
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
        // cityId:''
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'dishesName',
          attribute: {
            placeholder: '套餐名称',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'orderBy',
          attribute: {
            placeholder: '订餐人',
            size: 'small'
          }
        },
        {
          input_type: 'date',
          value: 'createTime',
          type: 'date',
          placeholder: '用餐日期'
        },
         {
          input_type: 'select',
          key: 'status',
          styleWidth: '200px',
          options:[
            {
              label:'预定',
              value:0,
            },
            {
              label:'完成',
              value:1,
            },
            {
              label:'作废',
              value:2,
            },
          ],
          attribute: {
            placeholder: '状态',
            size: 'small'
          }
        },
        {
          input_type: 'date',
          value: 'orderTime',
          type: 'date',
          placeholder: '预定日期'
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '订单号',
          prop: 'code',
          width: 160
        },
        {
          label: '套餐/菜式',
          prop: 'orderRecord',
          width:160,
        },
        {
          label: '价格',
          prop: 'price'
        },
        {
          label: '类型',
          prop: 'type'
        },
        // {
        //   label: '份数',
        //   prop: 'num',
        // },
        {
          label: '订餐人',
          prop: 'orderBy'
        },
        {
          label: '状态',
          prop: 'status'
        },
        {
          label: '用餐人公司',
          prop: 'sysCompany'
        },
        {
          label: '用餐人部门',
          prop: 'deptName'
        },
        {
          label: '用餐时间',
          prop: 'orderTime'
        },
        {
          label: '预定日期',
          prop: 'createTime'
        },
      ],
      tableData: [],
      loading: false,
      total: 30,
      titleText: '编辑',
      initFileList: [],
      rowFormData: [
        {
          input_type: 'input',
          label: '餐具编号',
          key: 'tablewareCode'
        },
        {
          input_type: 'input',
          label: '餐具名称',
          key: 'tablewareName'
        },
        {
          input_type: 'radio',
          label: '价格类型',
          key: 'suggestedPriceType',
          col: 24,
          method: 'suggestedPriceTypeFun',
          options: [
            {
              label: '自定义',
              value: -1
            },
            {
              label: '建议价格',
              value: 0
            }
          ]
        },
        {
          input_type: 'input',
          label: '建议价格',
          key: 'suggestedPrice',
          hide:true,
          col: 24
        },
        {
          input_type: 'customize',
          customize: 'uplpadfaceImg',
          label: '图片',
          key: 'pictureUrl',
          col: 24
        },
        {
          input_type: 'input',
          type: 'textarea',
          label: '关联条码',
          key: 'barCode',
          col: 22
        },
        {
          input_type: 'input',
          label: '备注',
          type: 'textarea',
          col: 22,
          key: 'remark'
        },
        
      ],
      checkImgSuccess: false,
      isExist: null,
      rules: {
        pictureUrl: [
          {
            required: true,
            // message: '图片必传',
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (!this.checkImgSuccess) {
                return callback(new Error('图片必传！'))
              } else if (this.isExist) {
                return callback(new Error('存在上传失败文件，请重新上传!'))
              } else {
                callback()
              }
            }
          }
        ],
        tablewareCode: [
          {
            required: true,
            message: '请输入餐具编号',
            trigger: 'blur'
          }
        ],
        suggestedPriceType: [
          {
            required: true,
            message: '价格类型必须',
            trigger: 'change'
          }
        ],
        suggestedPrice: [
          {
            required: true,
            // message: '请填写建议价格',
            validator: this.util.formRules.checkNumber({ min: 0, fixedNum: 2 }),
            trigger: 'change'
          }
        ],
        tablewareName: [
          {
            required: true,
            message: '请输入餐具名称',
            trigger: 'blur'
          }
        ],
        barCode: [
          {
            required: true,
            message: '请输入关联条码',
            trigger: 'blur'
          }
        ]
      },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`,
      // 图片相关
      headers: {},
      dialogVisible: false,
      dialogObj: null,
      fileList: [],
      isSearchIng: false,
      isAdd: true
    }
  },
  created() {
    //  console.log("$uploadUrl",this.$uploadUrl)
  },
  mounted() {},
  methods: {
    getInit: function () {
      this.loading = true
      // 查询园区列表信息
      getOrderrecord(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    // 新增
    append() {
      this.titleText = '新增'
      this.isAdd = true
      this.rowForm = {}
      this.initFileList = []
      this.util.clearSelectOptions(this.rowFormData)
      this.showEditAll = true
    },
    // 编辑
    rowEdit: function (row, details) {
      this.isAdd = false

      // console.log("row",row)
      this.rowForm = {
        ...row
      }
      // this.initFileList=[this.rowForm.pictureUrl]
      if(this.rowForm.pictureUrl){
        this.initFileList=[
          {
            url:this.rowForm.pictureUrl,
            raw: {type :'image'},
            status:'success',
            response:{data:this.rowForm.pictureUrl}

          }
        ]
        this.checkImgSuccess=true
      }
      if(this.rowForm.suggestedPriceType ==0){
        let index1 = this.rowFormData.findIndex(item=>item.key == 'suggestedPrice' )
        this.rowFormData[index1].hide=false
      }else{
        this.rowForm.suggestedPrice=""
      }
      console.log(this.initFileList)
      if (details == 'details') {
        this.titleText = '详情'
      } else {
        this.titleText = '编辑'
      }
      // console.log("什么鬼--", [].map(Number))
      this.showEditAll = true
    },

    // 删除
    rowDelete(row) {
      this.$confirm(
        '确定删除该条数据吗？'
      ).then((res) => {
        console.log('row', row)
        delTableware(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    saveForm: function () {
      // 保存
      // console.log("this.rowForm11111",this.rowForm)
      this.loading_form.isShow = true
      if (this.isAdd) {
        //  添加园区信息
        addTableware(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('新增成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      } else {
        //  更新园区信息
        editTableware(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('修改成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      }
    },
    // 图片上传成功之后
    uploadFinish() {
      let fileList = this.$refs.Upload.fileList
      console.log('fileList---', fileList)
      this.checkImgSuccess = true
      this.isExist = fileList.find((item) => item.response.code != 200)
      if (!this.isExist) {
        let imgList=fileList.map((item) => {
          return item.response.data
        })
        this.rowForm.pictureUrl=imgList[0]
      }
      // this.rowForm.pictureUrl =
      //   'http://www.weaving-comm.com/assets/images/Home/house.png'
      this.$refs.jaForm.$refs.formbox.clearValidate('pictureUrl')
    },
    // 删除图片
    imgRemoveMethod() {
      this.checkImgSuccess = false
      console.log('this.rowForm', this.rowForm)
    },
    suggestedPriceTypeFun(method, monitor, val, data) {
      
      let index1 = this.rowFormData.findIndex(item=>item.key == 'suggestedPrice' )
      // console.log(method, monitor, val, data,index1)
      if(monitor==0){
        this.rowFormData[index1].hide=false
      }else{
        this.rowFormData[index1].hide=true
      }
    },
    // 关闭表单弹窗
    close(){
      let index1 = this.rowFormData.findIndex(item=>item.key == 'suggestedPrice' )
      this.rowFormData[index1].hide=true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}

// 上传图像
.imgDefault {
  width: 150px;
  height: 200px;
  border: 1px solid $theme_bordercolor_DCDFE6;
}

/deep/ .el-upload__tip {
  position: absolute;
  width: 20%;
  top: 45%;
  left: 37%;
  text-align: center;
  line-height: 1;
  margin: 10px 0 8px 0;
}

.upload-img-form /deep/ .el-upload--picture-card {
  background-color: initial;
  border: initial;
  border-radius: initial;
  box-sizing: initial;
  width: initial;
  height: initial;
  line-height: initial;
  vertical-align: initial;
  display: inline-block;
  text-align: initial;
}

/deep/ ul.el-upload-list--picture-card {
  display: block;
}

/deep/ .el-upload-list.el-upload-list--text {
  width: 50%;

  > li.el-upload-list__item {
    cursor: pointer;
  }
}

// 上传图片按钮
.uploadBtn {
  position: absolute;
  top: 30%;
  left: 40%;
  color: $theme_color_0083ee;
  background: transparent;
  border: 1px solid $theme_bordercolor_0083ee;

  &:hover,
  &:focus {
    background: transparent;
    color: $theme_color_0083ee;
  }
}

// 图片缩略图
.videoContent {
  width: 40%;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 180px;
      height: 200px;
      flex-shrink: 0;
      margin: 0;
      border: 1px solid $theme_bordercolor_DCDFE6;
      border-radius: 0;
      overflow: hidden;
      position: relative;

      &:hover {
        .videoPlay_IconBox {
          display: none;
        }
      }

      .img-avatar {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
