let columns_ = [
    // { label: '楼栋', prop: 'buildingName' },
    // { label: '楼层', prop: 'floorName' },
    // { label: '房间', prop: 'roomName' },
    { label: '设备名称', prop: 'deviceName',width:140 },
    { label: '设备编码', prop: 'deviceCode',width:140 },
    { label: '抄表时间', prop: 'createTime',width:150},
    { label: '导入时间', prop: 'importTime' ,width:150},
    { label: '来源', prop: 'source' },
]

let limitObj = {
    125:{ // 电表
        importLimit :'meter::electricity::import',
        exportLimit :'meter::electricity::export',
    },
    186:{ // 冷风
        importLimit :'meter::coldWind::import',
        exportLimit :'meter::coldWind::export',
    },
    187:{ // 冷水
        importLimit :'meter::coldWater::import',
        exportLimit :'meter::coldWater::export',
    },
    124:{ // 水表
        importLimit :'meter::water::import',
        exportLimit :'meter::water::export',
    }
}
export  {
    columns_ , limitObj, 
}