<template>
  <!-- 部门列表 -->
  <!-- rowDetail: 详情操作->详情面板 -->
  <!-- append: 新增操作->新增面板 -->
  <!-- rowEdit: 编辑操作->编辑面板 -->
  <!-- rowDelete: 删除操作->删除面板 -->
  <!-- getInit: 获取ajax数据 -->
  <!-- outPut_: 导出数据 -->
  <div class="allwh minWidth1000">
    <div class="allwh-wrapper" style="display: flex;">
      <!-- 公司列表 -->
      <!-- <company-list :companyList="companyData"/> -->

      <!-- 部门列表主体 -->
      <ja-table ref="tst" style="width: 100%" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData"
        :total="total" :loading="loading" @append="append" :showIndex="true"
        @rowEdit="rowEdit" @rowDelete="rowDelete" @getInit="getInit" :valueFormat="formatter"
        @search="search" @reset="reset">
      </ja-table>
      <!-- 新增/编辑 -->
      <!-- reset: 取消 -->
      <!-- saveForm: 保存操作 -->
      <el-dialog :close-on-click-modal="false" :visible.sync="showEditAll" width="40%" :title="titleText">
        <ja-form :rules="rules" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData"
          @reset="showEditAll = false" @save="saveForm" v-if="showEditAll" v-loading="loading_form.isShow"
          :element-loading-text="loading_form.text" :element-loading-spinner="loading_form.icon"
          :element-loading-background="loading_form.bg" :element-loading-custom-class="loading_form.formLoading">
        </ja-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  queryDeptList,
  addDepartmentInfo,
  updateDepartmentInfo,
  deleteDepartmentInfo,
} from '@/api'
import table from "@/views/mixins/table"
import CompanyList from './components/CompanyList.vue'
export default {
  title: '部门列表',
  mixins: [table],
  name: 'index',
  components: {
    CompanyList
  },
  data () {
    return {
      filterData: { // 默认页码
        pageIndex: 1,
        pageSize: 10
      },
      companyData: [],
      conditionData: [ // 表格上方的搜索/选择操作栏
        {
          input_type: 'select',
          key: 'companyId',
          attribute: {
            placeholder: '公司列表',
            size: 'small'
          },
          options: []
        },
        {
          input_type: 'input',
          key: 'deptName',
          attribute: {
            placeholder: '部门名称',
            size: 'small'
          }
        },
        { normalBtn: 'search' },
        { normalBtn: 'reset' },
        { normalBtn: 'add',limit:'dept::list::add' }
        // { normalBtn: 'download' }
      ],
      columns: [ // 表头数据
        {
          label: '部门名称',
          prop: 'deptName',attribute:{showOverflowTooltip:true,align:'center'}
        },
        {
          label: '所属公司',
          prop: 'companyName',attribute:{showOverflowTooltip:true,align:'center'}
        },
        {
          label: '负责人',
          prop: 'linkMan'
        },
        {
          label: '联系电话',
          prop: 'phone'
        },
        {
          label: '所管理社区',
          prop: 'parkName',
          attribute:{showOverflowTooltip:true,align:'center'}
        },
        {
          label: '创建时间',
          prop: 'createTime',attribute:{showOverflowTooltip:true,align:'center'}
        },
        {
          label: '操作',
          input_type: 'button',
          width: 150,
          attribute: {
            fixed: "right",
            align: 'center'
          },
          list: [
            {
              text: '编辑',
              method: 'rowEdit',
              limit:'dept::list::update'
            },
            {
              text: '删除',
              limit:'dept::list::del',
              method: 'rowDelete'
            }
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 0,
      titleText: '编辑',
      rowFormData: [
        {
          input_type: 'input',
          label: '部门名称',
          key: 'deptName',
        },
        {
          input_type: 'select',
          label: '所属公司',
          key: 'companyId',
          options: []
        },
        {
          input_type: 'input',
          label: '负责人',
          key: 'linkMan',
        },
        {
          input_type: 'input',
          label: '联系电话',
          key: 'phone',
        }
      ],
      rules: {
        deptName: [{
          required: true,
          message: '请输入部门名称',
          trigger: 'blur'
        }],
        companyId: [{
          required: true,
          message: '请选择所属公司',
          trigger: 'change'
        }],
        linkMan: [{
          required: true,
          message: '请输入负责人',
          trigger: 'blur'
        }],
        phone: [{
          // required: true,
          // message: '请输入联系电话',
          phoneType:3,
          trigger: 'blur',
          validator: this.util.formRules.checkPhone()
        }]

      },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`
    }
  },
  created(){
    this.util.rqOptionsList({who:[this.conditionData[0],this.rowFormData[1],],rqUrl:"/company",rqData:{params:{ pageIndex: 1, pageSize: 200 }},labelName:'companyName',valueName:'id'})
  },
  methods: {
    formatter: function (val, key) {
      if (key === 'status') {
        return ['启用', '禁用'][val || 0]
      }
      return val || ''
    },
    getInit: function () {
      this.loading = true
      queryDeptList(this.filterData).then(({ data }) => {
        this.loading = false
        this.tableData = data.data
        this.total = data.total
      })
    },
    rowDelete: function (row) { // 删除
      this.$confirm('请先删除部门下属人员，否测无法删除！').then(res => {
        deleteDepartmentInfo(row.id).then(res => {
          this.$message.success("已删除")
          this.getInit()
        })
      })
    },
    saveForm: function () { // 保存
      this.loading_form.isShow = true
      const paramObj = this.util.paramChange(this.rowForm, this.queryTableId, 'edit')
      if (this.isAdd) {
        addDepartmentInfo(paramObj).then(res => {
          this.loading_form.isShow = false
          this.$message.success('新增成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          console.log(error)
          this.loading_form.isShow = false
        })
      } else {
        updateDepartmentInfo(paramObj).then(res => {
          this.loading_form.isShow = false
          this.$message.success('修改成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          console.log(error)
          this.loading_form.isShow = false
        })
      }
    },
    // 新增
    append () {
      this.isAdd = true
      this.rowForm = {}
      this.titleText = '新增'
      this.showEditAll = true
      // console.log('新增')
    },
    // 编辑
    rowEdit: function (row) {
      this.isAdd = false
      this.titleText = '编辑'
      this.rowForm = {
        ...row
      }
      this.showEditAll = true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
