<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      :showIndex="false"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      @getInit="getInit"
      @search="search"
      @rowDelete="rowDelete"
      @reset="reset"
      border
      :objectSpanMethod="objectSpanMethod"
    >
    </ja-table>
  </div>
</template>
<script>
import {
  electricityList,
} from '@/api/module/eleCount'
import table from '@/views/mixins/table'
export default {
  title: '电表明细统计表',
  mixins: [table],
  name: 'index',
  data() {
    return {
      filterData: {
        // 默认页码 ,
        
        pageIndex: 1,
        pageSize: 10,
        dateTime:[this.setDate().syqxks, this.setDate().syqxjs]
        // cityId:''
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'deviceCode',
          styleWidth: '200px',
          attribute: {
            placeholder: '设备编号',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'electricMeterName',
          styleWidth: '200px',
          attribute: {
            placeholder: '电表名称',
            size: 'small'
          }
        },
        {
          input_type: 'date',
          type: 'daterange',
          value: 'dateTime',
          format:'yyyy-MM-dd',
          valueFormat:'yyyy-MM-dd', 
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          attribute: {unlinkPanels:true}
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        },
        { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas2, rqUrl: '/electricity/export', rqMethod: 'post' }, limit:'eleCount::electricity::download' }
      ],
      columns: [
        {
          label: '设备编号',
           width:180,
          prop: 'deviceCode'
        },
        {
          label: '电表名称',
          width:140,
          prop: 'electricMeterName',
        },
         {
          label: '倍率',
          prop: 'magnification',
        },
        {
          label: '示数类型',
          prop: 'indicationType'
        },
        {
          label: '上次行码',
          prop: 'lastMeterReading'
        },
        {
          label: '本次行码',
          prop: 'thisMeterReading'
        },
        {
          label: '已使用行码',
          prop: 'meterReadingUsed'
        },
        {
          label: '总用电量',
          prop: 'totalElectricPower'
        },
        {
          label: '单价（元/千瓦时)',
          prop: 'unitPrice'
        },
        {
          label: '合计电费',
          prop: 'totalMoney'
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      initFileList: [],
      isExist: null,
      fileList: [],
      isSearchIng: false,
      isAdd: true
    }
  },
  created() {
    this.setDate()
  },
  mounted() {},
  methods: {
    getInit: function () {
      this.loading = true
      this.tableData = []
      let obj = this.rqParmas2()
      electricityList(obj).then((res) => {
        if (res.code === 200) {
          let data=res.data.data;
          this.handlerData(data)
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    rqParmas2() {
      let obj = this.rqParmas()
      console.log(obj.dateTime)
      if (obj.dateTime) {
        obj.startTime = obj.dateTime[0]
        obj.endTime = obj.dateTime[1]
        delete obj.dateTime
      }
      return obj
    },
    // 关闭表单弹窗
    close(){
      
    },
    // 合并行方法
    objectSpanMethod({ row, column, rowIndex, columnIndex }){
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2) {
          if (rowIndex % 4 === 0) {
            return {
              rowspan: 4,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
    },
    // 整理数据
    handlerData(arrData) {
      arrData.forEach(item=>{
        item.meterInfos.forEach(meterInfo=>{
          this.tableData.push({
            deviceCode:item.deviceCode,
            electricMeterName:item.electricMeterName,
            magnification:item.magnification,
            ...meterInfo
          })
        })
      })
    },
    setDate(){
      let curDate = new Date();
      let y = curDate.getFullYear();
      let m = curDate.getMonth() + 1;//本身就得+1才等于当前月份，然而我要计算下一个月，所以直接+2
      if( m > 12 ){
          m = 1;
          y++ 
      }
      let monthLastDay = new Date(y,m,0).getDate();
      let syqxks = y + '-' + (m < 10 ? '0'+m : m) + '-' + '01';
      let syqxjs = y + '-' + (m < 10 ? '0'+m : m) + '-' + (monthLastDay < 10 ? '0'+monthLastDay : monthLastDay);
      return {
        syqxks,
        syqxjs
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}

// 上传图像
.imgDefault {
  width: 150px;
  height: 200px;
  border: 1px solid $theme_bordercolor_DCDFE6;
}

/deep/ .el-upload__tip {
  position: absolute;
  width: 20%;
  top: 45%;
  left: 37%;
  text-align: center;
  line-height: 1;
  margin: 10px 0 8px 0;
}

.upload-img-form /deep/ .el-upload--picture-card {
  background-color: initial;
  border: initial;
  border-radius: initial;
  box-sizing: initial;
  width: initial;
  height: initial;
  line-height: initial;
  vertical-align: initial;
  display: inline-block;
  text-align: initial;
}

/deep/ ul.el-upload-list--picture-card {
  display: block;
}

/deep/ .el-upload-list.el-upload-list--text {
  width: 50%;

  > li.el-upload-list__item {
    cursor: pointer;
  }
}

// 上传图片按钮
.uploadBtn {
  position: absolute;
  top: 30%;
  left: 40%;
  color: $theme_color_0083ee;
  background: transparent;
  border: 1px solid $theme_bordercolor_0083ee;

  &:hover,
  &:focus {
    background: transparent;
    color: $theme_color_0083ee;
  }
}

// 图片缩略图
.videoContent {
  width: 40%;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 180px;
      height: 200px;
      flex-shrink: 0;
      margin: 0;
      border: 1px solid $theme_bordercolor_DCDFE6;
      border-radius: 0;
      overflow: hidden;
      position: relative;

      &:hover {
        .videoPlay_IconBox {
          display: none;
        }
      }

      .img-avatar {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
