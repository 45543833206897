<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @getInit="getInit"
      @search="search"
      @reset="reset"
    >
    </ja-table>
  </div>
</template>
<script>
import {
  getStock,
} from '@/api'
import table from '@/views/mixins/table'
export default {
  title: '材料库存',
  mixins: [table],
  name: 'index',
  data() {
    return {
      show: false,
      showUser: false,
      allDisabled: false,
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'location',
          attribute: {
            placeholder: '库存位置',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'foodName',
          attribute: {
            placeholder: '材料名称',
            size: 'small'
          }
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '库存位置',
          prop: 'location'
        },
        {
          label: '材料名称',
          prop: 'foodName'
        },
        {
          label: '库存数量',
          prop: 'num'
        },
        {
          label: '单位',
          prop: 'unit'
        },
        {
          label: '总成本（元）',
          prop: 'cost'
        },
        // {
        //   label: '总成本（元）',
        //   prop: 'sumCost'
        // },
      ],
      tableData: [],
      loading: false,
      total: 30,
      initFileList: [],
      labelWidth: `${this.util.setFontSize(140)}px`,
    }
  },
  created() {
    
  },
  mounted() {},
  methods: {
    getInit: function () {
      this.loading = true
      // 查询列表信息
      getStock({params: this.filterData}).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        }
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}

</style>
