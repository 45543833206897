<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @rowEdit="rowEdit"
      @getInit="getInit"
      @search="search"
      @reset="resetFun"
      isTableSlot
    >
      <template #tableSlot>
        <el-table
          :data="tableData"
          :span-method="arraySpanMethod"
          border
          style="width: 100%"
        >
          <el-table-column
            prop="head"
            label="会议室"
            align="center"
            width="420"
          >
            <template slot-scope="scope">
              <div class="custom_table">
                <el-row>
                  <el-col :span="6">
                    <el-image
                      style="width: 50px; height: 50px"
                      :src="scope.row.head.pictureUrl"
                      :preview-src-list="[scope.row.head.pictureUrl]"
                      fit="fit"
                    >
                    </el-image>
                  </el-col>
                  <el-col :span="18" style="text-align: left">
                    <p>
                      {{ scope.row.head.name
                      }}<el-divider direction="vertical"></el-divider>
                      容纳人数:{{ scope.row.head.num }}人<el-divider
                        direction="vertical"
                      ></el-divider
                      >面积: {{ scope.row.head.price }}m²
                    </p>
                    <div>
                      <el-tag
                        size="mini"
                        v-for="(peizhiItem, z) in scope.row.head.serveList"
                        :key="z"
                        >{{ peizhiItem }}</el-tag
                      >
                    </div>
                    <el-button
                      type="primary"
                      size="mini"
                      :disabled="scope.row.head.status == 1 || judgeTimeVal"
                      @click="rowEdit(scope.row)"
                      v-if="util.isAuth('conferenceRoom::reservation::apply')"
                      >预约</el-button
                    >
                  </el-col>
                </el-row>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            width="110"
            v-for="(item, i) in tableTitle"
            :key="i"
          >
            <template slot-scope="scope">
              <div class="colorBlocking">
                <div class="wait" v-if="scope.row[scope.column.property] == 0">
                  待开始
                </div>
                <div
                  class="inProgress"
                  v-if="scope.row[scope.column.property] == 1"
                >
                  进行中
                </div>
                <div class="end" v-if="scope.row[scope.column.property] == 2">
                  已完成
                </div>
                <div
                  class="cancel"
                  v-if="scope.row[scope.column.property] == 3"
                >
                  已取消
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="40%"
      @close="close"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :allDisabled="allDisabled"
        @reset="showEditAll = false"
        @save="saveForm"
        @selectUser="selectUser"
        v-if="showEditAll"
        ref="jaForm"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
        <template #meetingTime>
          <el-time-select
            style="width: 130px"
            :clearable="false"
            @change="changeTime($event, 0)"
            placeholder="起始时间"
            v-model="paramsTime.startTime"
            :picker-options="{
              start: initStartTime,
              step: '00:10',
              end: paramsTime.endTime ? paramsTime.endTime : '23:59',
              maxTime: paramsTime.endTime
            }"
          >
          </el-time-select>
          至
          <el-time-select
            style="width: 130px"
            placeholder="结束时间"
            :clearable="false"
            @change="changeTime($event, 1)"
            v-model="paramsTime.endTime"
            :picker-options="{
              start: paramsTime.startTime
                ? paramsTime.startTime
                : systemDate == rowForm.meetingDate
                ? initStartTime
                : '09:00',
              step: '00:10',
              end: '23:59',
              minTime: paramsTime.startTime
            }"
          >
          </el-time-select>
        </template>
        <template #joinByList>
          <div class="textInput">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入参会人"
              @focus="selectUser"
              v-model="rowForm.joinByList"
            >
            </el-input>
            <i
              class="
                clearButton
                el-input__icon
                el-icon-circle-close
                el-input__clear
              "
              @click.prevent="
                rowForm.joinByList = ''
                rowForm.employeeIdList = ''
              "
            />
          </div>
        </template>
      </ja-form>
    </el-dialog>
    <select-user
      :parkId="parkId"
      :show.sync="showUser"
      v-if="showUser"
      @closeUser="closeUser"
    ></select-user>
  </div>
</template>
<script>
import { getOrder, addOrder } from '@/api'
import table from '@/views/mixins/table'
import selectUser from '@/views/home/components/selectUser/selectUser.vue'
import { currTime } from '@/util/index.js'
import util from '@/util/util.js'
let counter = 0
export default {
  title: '会议室预约',
  mixins: [table],
  name: 'index',
  components: {
    selectUser
  },
  data() {
    return {
      show: false,
      showUser: false,
      parkId: 1,
      allDisabled: false,
      initStartTime: '00:00',
      initEndTime: '',
      tableTitle: [],
      paramsTime: {
        startTime: '', //访客通行开始时间
        endTime: '' //访客通行结束时间
      },
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10,
        meetingDate: new Date()
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'meetingRoomName',
          attribute: {
            placeholder: '会议室名称',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'serve',
          attribute: {
            placeholder: '配置',
            size: 'small'
          }
        },
        {
          input_type: 'date',
          type: 'date',
          value: 'meetingDate',
          valueFormat: 'yyyy-MM-dd',
          placeholder: '日期'
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [],
      tableData: [],
      loading: false,
      total: 30,
      titleText: '编辑',
      initFileList: [],
      rowFormData: [
        {
          input_type: 'input',
          readonly: true,
          type: 'text',
          label: '会议室',
          key: 'meetingRoomName'
        },
        {
          input_type: 'input',
          readonly: true,
          type: 'text',
          label: '会议日期',
          key: 'meetingDate'
        },
        {
          input_type: 'customize',
          label: '会议时间',
          customize: 'meetingTime',
          key: 'meetingTime',
          col: 24
        },
        {
          input_type: 'input',
          label: '会议主题',
          key: 'name'
        },
        // {
        //   input_type: 'input',
        //   label: '主持人',
        //   key: 'host'
        // },
        {
          input_type: 'customize',
          label: '参会人',
          customize: 'joinByList',
          key: 'joinByList'
        },
        {
          input_type: 'input',
          label: '联系电话',
          key: 'mobile'
        },
        {
          input_type: 'input',
          type: 'textarea',
          label: '会议说明',
          col: 24,
          key: 'remark'
        }
      ],
      checkImgSuccess: false,
      isExist: null,
      rules: {
        meetingTime: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!this.paramsTime.startTime) {
                return callback(new Error('会议开始时间必选！'))
              } else if (!this.paramsTime.endTime) {
                return callback(new Error('会议结束时间必选！'))
              } else {
                callback()
              }
            }
          }
        ],
        name: [
          {
            required: true,
            message: '会议主题不能为空',
            trigger: 'blur'
          }
        ],
        host: [
          {
            required: true,
            message: '主持人不能为空',
            trigger: 'blur'
          }
        ],
        joinByList: [
          {
            required: true,
            message: '参会人不能为空',
            trigger: 'change'
          }
        ],
        mobile: [
          {
            required: true,
            // 校验手机号
            validator: (value, rule, callback) => {
              const reg =/^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/
              // console.log(this.rowForm.mobile);
              if (!reg.test(this.rowForm.mobile)) {
                callback(new Error('请输入正确的电话'));
              } else {
                callback()
              }
            },
             trigger: 'blur',
          }
        ],
        remark: [
          {
            required: true,
            message: '会议说明不能为空',
            trigger: 'blur'
          }
        ]
      },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`,
      // 图片相关
      headers: {},
      dialogVisible: false,
      dialogObj: null,
      fileList: [],
      isSearchIng: false,
      nameObjList: [],
      times: '',
      currTime: '',
      systemDate: '',
      judgeTimeVal: null
    }
  },
  created() {
    //  console.log("$uploadUrl",this.$uploadUrl)
    this.createTime()
    this.setCurrent()
    this.currTime = currTime()
  },
  mounted() {},
  watch: {
    'filterData.meetingDate'(newVal, oldVal) {
      var strtime = newVal.replace('/-/g', '/') //时间转换
      //时间
      var date1 = new Date(strtime)
      //现在时间
      var date2 = new Date(this.currTime.split(' ')[0])
      //判断时间是否过期
      return (this.judgeTimeVal = date1 < date2 ? true : false)
    }
  },
  methods: {
    setCurrent(d = new Date()) {
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      let date = d.getDate()
      this.times =
        year +
        (month < 10 ? '-0' : '-') +
        month +
        (date < 10 ? '-0' : '-') +
        date
      this.filterData.meetingDate = this.times
    },
    changeTime: function (e, key) {
      this.rowForm.times = [this.paramsTime.startTime, this.paramsTime.endTime]
    },
    createTime() {
      // var m_start = 8
      // var n_start = 30
      // var m_end = 9
      // var n_end = 0
      // this.tableTitle = [{
      //   prop: '08:30',
      //   label: '08:30-09:00'
      // }]
      // for (var i = 0; i < 22; i++) {
      //   n_start += 30
      //   if (n_start > 30) {
      //     n_start = 0
      //     n_end += 30
      //     m_start += 1
      //   } else {
      //     n_end = 0
      //     m_end += 1
      //   }
      //   var title = {
      //     prop: (m_start > 9 ? m_start : '0' + m_start) + ':' + (n_start > 9 ? n_start : '0' + n_start),
      //     label: (m_start > 9 ? m_start : '0' + m_start) + ':' + (n_start > 9 ? n_start : '0' + n_start) + '-' + (m_end > 9 ? m_end : '0' + m_end) + ':' + (n_end > 9 ? n_end : '0' + n_end)
      //   }
      //   this.tableTitle.push(title)
      // }

      var m_start = 0
      var n_start = 0
      var m_end = 0
      var n_end = 10
      this.tableTitle = [
        {
          prop: '00:00',
          label: '00:00-00:10'
        }
      ]
      for (var i = 0; i < 143; i++) {
        n_start += 10
        n_end += 10
        if (n_start >= 60) {
          n_start = 0
          m_start += 1
        }

        if (n_end >= 60) {
          m_end += 1
          n_end = 0
        }
        var title = {
          prop:
            (m_start > 9 ? m_start : '0' + m_start) +
            ':' +
            (n_start > 9 ? n_start : '0' + n_start),
          label:
            (m_start > 9 ? m_start : '0' + m_start) +
            ':' +
            (n_start > 9 ? n_start : '0' + n_start) +
            '-' +
            (m_end > 9 ? m_end : '0' + m_end) +
            ':' +
            (n_end > 9 ? n_end : '0' + n_end)
        }
        this.tableTitle.push(title)
      }
      // console.info("this.tableTitle-----", this.tableTitle)/
    },
    /**
     * 判断日期是否今天，不能显示今天已过去的时间
     */
    startDate() {
      this.systemDate = this.currTime.split(' ')[0]
      let currTime = this.currTime.split(' ')[1].split(':')
      if (this.systemDate == this.rowForm.meetingDate) {
        let minute
        if (currTime[1] < 30) {
          minute = '00'
        } else {
          minute = '30'
        }
        let hour = parseInt(currTime[0]) + 1
        hour = hour < 10 ? '0' + hour : hour
        this.initStartTime = hour + ':' + minute

        // 计算结束时间的初始化
        let endsplit = this.initStartTime.split(' ')
        let endHour = parseInt(endsplit[0])
        let endMinuter = parseInt(endsplit[1])
        if (endMinuter == 0) {
          endMinuter = '30'
        } else {
          endHour = endHour + 1
          endMinuter = '00'
        }
        endHour = endHour < 10 ? '0' + endHour : endHour
        this.initEndTime = endHour + ':' + endMinuter
      }
    },
    getInit: function () {
      this.loading = true
      console.info(this.filterData)
      getOrder({ params: this.filterData }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
          console.info(this.tableData)
        }
      })
    },
    resetFun() {
      this.filterData.meetingDate = this.times
      this.filterData.pageIndex = 1
      this.filterData.meetingRoomName = ''
      this.filterData.serve = ''
      this.getInit()
      console.log(this.filterData.meetingDate)
    },
    // 编辑
    rowEdit: function (row, details) {
      this.rowForm = {}
      this.util.clearSelectOptions(this.rowFormData)
      this.initStartTime = '00:00'
      this.paramsTime = {
        startTime: '', //访客通行开始时间
        endTime: '' //访客通行结束时间
      }
      this.showEditAll = true

      console.log('row', this.rowFormData)
      let data = {
        meetingRoomName: row.head.name,
        meetingRoomId: row.head.id,
        meetingDate: this.filterData.meetingDate
      }

      this.rowForm = {
        ...data
      }
      console.info(this.rowForm)
      this.titleText = '预约'
      // console.log("什么鬼--", [].map(Number))
      this.startDate()
      this.showEditAll = true
    },
    saveForm: function () {
      // 保存
      console.log('this.rowForm11111', this.rowForm)
      this.loading_form.isShow = true
      this.rowForm.meetingStartTime = this.rowForm.times[0]
      this.rowForm.meetingEndTime = this.rowForm.times[1]
      addOrder(this.rowForm)
        .then((res) => {
          this.loading_form.isShow = false
          this.$message.success('预约成功')
          this.showEditAll = false
          this.getInit()
        })
        .catch((error) => {
          console.log(error)
          this.loading_form.isShow = false
        })
    },

    // 关闭表单弹窗
    close() {
      this.nameObjList = []
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      let span = [1, 1]
      if (columnIndex != 0) {
        let orderList = row.orderList || []

        if (counter == 0) {
          orderList.forEach((element) => {
            if (column.property == element.meetingStartTime) {
              span = [1, element.timeList.length - 1]
              counter = element.timeList.length - 2
              // TODO 修改样式
              row[element.meetingStartTime] = element.status
              // console.info(row)
              // console.info(column)
            }
          })
          //
        } else if (counter > 0) {
          span = [0, 0]
          counter--
        }
      }
      return span
    },
    //选择用户
    closeUser(show, list) {
      this.nameObjList = this._.unionWith(
        this.nameObjList,
        list,
        this._.isEqual
      )
      if (this.nameObjList.length != 0) {
        let ids = []
        let nameList = this.nameObjList.map((item) => {
          ids.push(item.id)
          return item.name
        })
        this.rowForm.employeeIdList = ids.join(',')
        this.$set(this.rowForm, 'joinByList', nameList.join(','))
      }
      this.showUser = show
    },
    // 添加用户
    selectUser(show) {
      this.showUser = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}
.custom_table {
  /deep/.el-row {
    height: 100%;
    display: flex;
    align-items: center;
    line-height: initial;
    padding: 15px 0;
    p {
      padding-bottom: 8px;
    }
    .el-tag {
      margin: 0 5px 10px 0;
    }
    .el-tag:last-child {
      margin: 0 0px 10px 0;
    }
  }
}
.colorBlocking {
  & > div {
    width: 100%;
    height: 50px;
    text-align: center;
    color: #efefef;
    line-height: 50px;
  }
  //待开会
  .wait {
    background: rgb(59, 164, 235);
  }
  // 进行中
  .inProgress {
    background: rgb(253, 77, 46);
  }
  // 已结束
  .end {
    background: rgb(71, 232, 162);
  }
  // 已取消
  .cancel {
    background: rgb(241, 243, 126);
  }
}
.textInput {
  position: relative;

  .clearButton {
    position: absolute;
    border-radius: 5px;
    right: 0px;
    z-index: 2;
    border: none;
    top: -8px;
    height: 40px;
    cursor: pointer;
    color: #ccc;
    transform: translateX(2px);
  }

  .clearButton:hover {
    color: #878d99;
  }
}
</style>
