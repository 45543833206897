/*
 * @Author: your name
 * @Date: 2020-12-22 15:59:13
 * @LastEditTime: 2020-12-24 18:09:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \projectc:\Users\panthsoni\Desktop\fei\shouchuang\u3d\shcouchuang\pioneering\src\store\modules\permission.js
 */
import { constantRoutes } from '@/router'
import { rqReftMenu } from '@/api'
import Home from '@/views/home/Home'
import Login from '@/views/login/index'
import autoRoute from '@/router/model/index'


const menu_titleList = {}

const permission = {
  state: {
    routes: [],
    addRoutes: [],
    rqRoutes: [],
    menu_activeUrl: sessionStorage.getItem('menu') ? sessionStorage.getItem('menu') : '',
    menu_title: {}
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes
      state.routes = constantRoutes.concat(routes)
    },
    SET_RQROUTES: (state, rqRoutes) => {
      state.rqRoutes = rqRoutes
    },
    // INIT_ROUTES: (state, routes) => {
    //   console.log('初始化----',state.rqRoutes)
    //   state.rqRoutes.unshift(
    //     state.rqRoutes.splice(
    //       state.rqRoutes.findIndex((item) => item.title === "首页"),
    //       1
    //     )[0]
    //   )
    // },
    SET_ACTIVEURL: (state, url) => {
      state.menu_activeUrl = url
    },
    SET_MENUTitle: (state, obj) => {
      state.menu_title = obj
      // console.log('中文菜单====》', state.menu_title)
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes ({ commit }) {
      return new Promise(resolve => {
        // 向后端请求路由数据
        rqReftMenu({
          sourceType: 1
        }).then(res => {
          // console.log('后端真实的左侧菜单===', res, '本地左侧菜单栏====', autoRoute.children)
          // 获取本地菜单列表名称
          const accessedRoutes = filterAsyncRouter(res.data)
          commit('SET_MENUTitle', menu_titleList)
          const replenish_routes = {
            path: '/home',
            name: 'Home',
            component: Home,
            // redirect: '/home/homeIndex',
            redirect: '/home/defaultPage',
            children: [
              ...accessedRoutes,
              {
                path: '*',
                meta: {
                  show: false
                },
                name: '404'
              },
            ]
          }
          // console.log("这个是什么router",accessedRoutes)
          commit('SET_ROUTES', replenish_routes)
          commit('SET_RQROUTES', accessedRoutes)
          resolve(constantRoutes.concat(replenish_routes))
        })
      })
    }
  }
}
// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter (asyncRouterMap) {
  const accessedRouters = asyncRouterMap.filter(item => {
    if (item.component) {
      if (item.name === 'Home') {
        item.component = Home
      } else if (item.name === 'Login') {
        item.component = Login
      } else {
        item.component = loadView(item.component)
      }
    }
    if (!item.children || !item.children.find(v=>v.meta && v.meta.show) ) {
      menu_titleList[item.path] = {}
      menu_titleList[item.path].titleList = item.navigation.split('_')
      menu_titleList[item.path].path = '/home/' + item.name.split('_').join('/')
    }
    if (item.children && item.children.length > 0) {
      item.children = filterAsyncRouter(item.children)
    }
    return true
  })
  return accessedRouters
}



const loadView = (view) => { // 路由懒加载
  return (resolve) => require([`@/views/home/${view}/index.vue`], resolve)
}

export default permission
