<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      :valueFormat="valueFormat"
      @getInit="getInit"
      @search="search"
      @reset="reset"
    >
      <template #pictureUrl="{row}">
        <!-- {{row.pictureUrl}} -->
        <el-button v-if="row.pictureUrl" type="text" @click="urtemp = [row.pictureUrl];urlshow = true">查看</el-button>
          <el-image-viewer
            v-if="urlshow"
            :on-close="()=>{urlshow = false}"
            :url-list="urtemp" />
      </template>
    </ja-table>
  </div>
</template>
<script>
import {
  entranceGuardGet,
  bizBusinessAdd,
  bizBusinessEdit,
  bizBusinessDelete,
  dictData
} from '@/api'
import table from '@/views/mixins/table'
export default {
  title: '门禁通行记录',
  mixins: [table],
  name: 'accessControl',
  components:{
    'el-image-viewer':()=>import('element-ui/packages/image/src/image-viewer')
  },
  data() {
    return {
      parkId: 1,
      urlshow:false,
      allDisabled: false,
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
        // cityId:''
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'name',
          attribute: {
            placeholder: '姓名',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'companyName',
          attribute: {
            placeholder: '访客公司',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'deptName',
          attribute: {
            placeholder: '部门',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'mobile',
          attribute: {
            placeholder: '访客电话',
            size: 'small'
          }
        },
        {
          input_type: 'select',
          key: 'personType',
          attribute: {
            placeholder: '人员类型 ',
            size: 'small'
          },
          options:[
            {
              label:'内部员工',
              value:'0'
            },
            {
              label:'访客',
              value:'1'
            }
          ]
        },
        {
          input_type: 'input',
          key: 'doorName',
          attribute: {
            placeholder: '门禁名称',
            size: 'small'
          }
        },
        {
          input_type: 'date',
          value: 'recTime',
          type: 'date',
          attribute: {
            placeholder: '通行时间',
            size: 'small'
          }
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        },
        { normalBtn: 'download',
          exportObj: { rqParmas: this.rqParmas, rqUrl: '/entranceGuard/export', rqMethod: 'get' }, 
          limit: 'iot::accessControl::download' 
        }
      ],
      columns: [
        // 表头数据
        {
          label: '姓名',
          prop: 'name'
        },
        {
          label: '访客公司',
          prop: 'companyName'
        },
        {
          label: '部门',
          prop: 'deptName',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '访客电话',
          prop: 'mobile',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '人员类型',
          prop: 'personType'
        },
        {
          label: '门禁名称',
          prop: 'doorName'
        },
        {
          label: '通行位置',
          prop: 'address'
        },
        {
          label: '附件',
          prop: 'pictureUrl',
          slotName: 'pictureUrl',
          width: 100
        },
        {
          label: '通行时间',
          prop: 'recTime'
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      titleText: '编辑',
      initFileList: [],
      isExist: null,
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`,
      dialogVisible: false,
      dialogObj: null,
      fileList: [],
      isSearchIng: false,
      isAdd: true
    }
  },
  created() {
    //  console.log("$uploadUrl",this.$uploadUrl)
  },
  mounted() {},
  methods: {
    getInit: function () {
      this.loading = true
      entranceGuardGet(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    // 转换值
    valueFormat(val, key, row){
      if (key == 'personType') {
        let list={
              0:"内部员工",
              1:"访客",
            }
        return list[val]
      }
      return val
    },
    // 关闭表单弹窗
    close(){
     
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}

// 上传图像
.imgDefault {
  width: 150px;
  height: 200px;
  border: 1px solid $theme_bordercolor_DCDFE6;
}

/deep/ .el-upload__tip {
  position: absolute;
  width: 20%;
  top: 45%;
  left: 37%;
  text-align: center;
  line-height: 1;
  margin: 10px 0 8px 0;
}

.upload-img-form /deep/ .el-upload--picture-card {
  background-color: initial;
  border: initial;
  border-radius: initial;
  box-sizing: initial;
  width: initial;
  height: initial;
  line-height: initial;
  vertical-align: initial;
  display: inline-block;
  text-align: initial;
}

/deep/ ul.el-upload-list--picture-card {
  display: block;
}

/deep/ .el-upload-list.el-upload-list--text {
  width: 50%;

  > li.el-upload-list__item {
    cursor: pointer;
  }
}

// 上传图片按钮
.uploadBtn {
  position: absolute;
  top: 30%;
  left: 40%;
  color: $theme_color_0083ee;
  background: transparent;
  border: 1px solid $theme_bordercolor_0083ee;

  &:hover,
  &:focus {
    background: transparent;
    color: $theme_color_0083ee;
  }
}

// 图片缩略图
.videoContent {
  width: 40%;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 180px;
      height: 200px;
      flex-shrink: 0;
      margin: 0;
      border: 1px solid $theme_bordercolor_DCDFE6;
      border-radius: 0;
      overflow: hidden;
      position: relative;

      &:hover {
        .videoPlay_IconBox {
          display: none;
        }
      }

      .img-avatar {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
