<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog :visible.sync="showDetail" title="查看详情" :width="`50%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='9' :loadingForm="loadingForm" @reset="showDetail = false" @save="saveForm" :btnBox="btnBox" v-if="showDetail">
        <template #tableList>
          <tableRefund v-model="detailData.refundContentList" :isFromDetail="true" v-if="detailData.refundContentList"/>
        </template>
        <template #uplpadfaceImg>
          <upload-file ref="Upload" :initFileList="[...initFileList]" v-if="showDetail" :noButton="true" :hideDel="true" />
        </template>
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>
import { auditRefundItem } from '@/api'
import tableRefund from './tableRefund.vue'

export default {
  inject: ['labelWidth', 'getInit',],
  components: {
    tableRefund
  },
  data() {
    return {
      showDetail: false,
      detailOptions:
        [
          { label: '订单号', key: 'orderCode' },
          { type: 'customize', customize: 'tableList', label: '退款内容', key: 'refundContentList', col: 24, alignItems: 'flex-start' }, //attachmentList
          { label: '退款金额', key: 'refundCost' },
          { label: '原因', key: 'reason', },
          { label: '备注', key: 'remark' },
          { type: 'customize', customize: 'uplpadfaceImg', label: '附件', key: 'urlLists', col: 22, alignItems: 'flex-start' }, //attachmentList
          { type: 'customize', customize: 'btns', col: 22, }, //attachmentList
        ],
      detailData: {},
      indexObj: {},

      loadingForm: false,
      btnBox_edit: [
        { label: '审批拒绝', method: 'save', type: 'danger', plain: true, status: 5 },
        { label: '审批通过', method: 'save', type: 'success', plain: true, status: 4 },
        { label: '取消', method: 'reset', type: 'default', }
      ],
      btnBox: [],
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.detailOptions })
  },
  methods: {
    rowDetail(row, cell, btn) {
      this.btnBox = btn.method == 'rowDetail' ? [] : [...this.btnBox_edit]

      let imgArr = row.pictureUrl ? row.pictureUrl.split(',') : ''
      if (imgArr.length > 0) {
        this.initFileList = this.util.backImgVideoObj(imgArr)
      } else {
        this.initFileList = []
      }
      this.$set(this.detailData,'refundContentList',row.refundContentList)
      this.detailData = { ...row }
      this.showDetail = true
    },
    saveForm(btnItem) {
      let obj = {
        orderNo: this.detailData.orderCode,
        scope: this.detailData.scope,
        refundCode: this.detailData.refundCode,
        status: btnItem.status
      }
      this.loadingForm = true
      auditRefundItem(obj).then(res => {
        this.loadingForm = false
        this.getInit()
        this.$message.success('审批完成')
        this.showDetail = false
      }).catch(() => {
        this.loadingForm = false
      })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>