<template>
  <router-view/>
</template>

<script>
export default {
  title: "iot模块",
  name: 'iot'
}
</script>

<style scoped>

</style>
