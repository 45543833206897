<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      @getInit="getInit"
      @search="search"
      @rowDelete="rowDelete"
      @reset="reset"
      border
      :objectSpanMethod="objectSpanMethod"
    >
    </ja-table>
  </div>
</template>
<script>
import {
  finance,
} from '@/api'
import table from '@/views/mixins/table'
export default {
  title: '电费统计表',
  mixins: [table],
  name: 'index',
  data() {
    return {
      filterData: {
        // 默认页码 ,
        
        pageIndex: 1,
        pageSize: 10,
        dateTime:[this.setDate().syqxks, this.setDate().syqxjs]
        // cityId:''
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'contractCode',
          styleWidth: '200px',
          attribute: {
            placeholder: '合同编号',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'corporateName',
          styleWidth: '200px',
          attribute: {
            placeholder: '公司名称',
            size: 'small'
          }
        },
        {
          input_type: 'date',
          type: 'daterange',
          value: 'dateTime',
          format:'yyyy-MM-dd',
          valueFormat:'yyyy-MM-dd', 
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          attribute: {unlinkPanels:true}
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        },
        { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas2, rqUrl: '/finance/export', rqMethod: 'post' }, limit:'iot::powerRate::download' }
      ],
      columns: [
        {
          label: '合同编号',
          prop: 'contractCode'
        },
        // {
        //   label: '账务编号',
        //   prop: 'accountingCode'
        // },
        {
          label: '公司名',
          prop: 'corporateName',
          width:180,
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '电表名称',
          width:140,
          prop: 'electricMeterName',
        },
         {
          label: '设备编码',
          prop: 'deviceCode',
        },
         {
          label: '倍率',
          prop: 'magnification',
        },
        {
          label: '示数类型',
          prop: 'indicationType'
        },
        {
          label: '上次行码',
          prop: 'lastMeterReading'
        },
        {
          label: '本次行码',
          prop: 'thisMeterReading'
        },
        {
          label: '已使用行码',
          prop: 'meterReadingUsed'
        },
        {
          label: '总用电量',
          prop: 'totalElectricPower'
        },
        {
          label: '单价（元/千瓦时)',
          prop: 'unitPrice'
        },
        {
          label: '自用电费',
          prop: 'selfElectricityFees'
        },
        {
          label: '楼层分摊电费',
          prop: 'floorElectricityFees'
        },
        {
          label: '园区分摊电费',
          prop: 'parkElectricityFees'
        },
        {
          label: '合计',
          prop: 'totalMoney'
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      initFileList: [],
      rowFormData: [
        {
          input_type: 'input',
          label: '餐具编号',
          key: 'tablewareCode'
        },
        {
          input_type: 'input',
          label: '餐具名称',
          key: 'tablewareName'
        },
        {
          input_type: 'radio',
          label: '价格类型',
          key: 'suggestedPriceType',
          col: 24,
          method: 'suggestedPriceTypeFun',
          options: [
            {
              label: '自定义',
              value: -1
            },
            {
              label: '建议价格',
              value: 0
            }
          ]
        },
        {
          input_type: 'input',
          label: '建议价格',
          key: 'suggestedPrice',
          hide:true,
          col: 24
        },
        {
          input_type: 'customize',
          customize: 'uplpadfaceImg',
          label: '图片',
          key: 'pictureUrl',
          col: 24
        },
        {
          input_type: 'input',
          type: 'textarea',
          label: '关联条码',
          key: 'barCode',
          col: 22
        },
        {
          input_type: 'input',
          label: '备注',
          type: 'textarea',
          col: 22,
          key: 'remark'
        },
        
      ],
      isExist: null,
      fileList: [],
      isSearchIng: false,
      dArr:[],
      isAdd: true
    }
  },
  created() {
    this.setDate()
  },
  mounted() {},
  methods: {
    getInit: function () {
      this.loading = true
      let obj = this.rqParmas2()
      finance(obj).then((res) => {
        if (res.code === 200) {
          let data=res.data.data;
          this.handlerData(data)
          // this.tableData = data
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    rqParmas2() {
      let obj = this.rqParmas()
      console.log(obj.dateTime)
      if (obj.dateTime) {
        obj.startTime = obj.dateTime[0]
        obj.endTime = obj.dateTime[1]
        delete obj.dateTime
      }
      return obj
    },
    // 关闭表单弹窗
    close(){
      
    },
    // 合并行方法
    objectSpanMethod({ row, column, rowIndex, columnIndex }){
      // console.log("数据----",row,column,columnIndex)
      if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3 ) {
          let obj = [0, 0]
          this.arr.some(v => {
            if (rowIndex === v.index) {
              obj = [v.allNum, 1]
            }
          })

          return obj
      }
      if (columnIndex === 4 || columnIndex === 5) {
          let dObj = [0, 0]
          this.dArr.some(v => {
            if (rowIndex === v.dIndex) {
              dObj = [v.dNum, 1]
            }
          })

          return dObj
      }
    },
    // 整理数据
    handlerData(arrData) {
      let arr = []
      let s = 0
      let dArr =[]
      let getTable = []
      let dIndex=0;
      arrData.forEach((item, i, data) => { // 公司
        let allNum=0 // 最子级的数量
        // 拼接数据
        let dNum=0
        if(item.electricMeters && item.electricMeters.length){  
          item.electricMeters.forEach(subItem => { //电表，1号表、2号表
            
            if(subItem.meterInfos && subItem.meterInfos.length){
              if(dArr.length){
                dIndex= dArr[dArr.length - 1].dIndex + dArr[dArr.length - 1].dNum
              }
               dNum=subItem.meterInfos.length
              dArr.push({
                dIndex:dIndex,
                dNum:dNum,
              })
              allNum=allNum + subItem.meterInfos.length, // 算出总的时段数
              subItem.meterInfos.forEach(lastIem=>{ // 时段，尖、峰、谷、平
                  getTable.push({
                    contractCode: item.contractCode,
                    accountingCode: item.accountingCode,
                    corporateName: item.corporateName,
                    deviceCode: subItem.deviceCode,
                    magnification: subItem.magnification,
                    electricMeterName: subItem.electricMeterName,
                    indicationType:lastIem.indicationType,
                    lastMeterReading:lastIem.lastMeterReading,
                    meterReadingUsed:lastIem.meterReadingUsed,
                    thisMeterReading:lastIem.thisMeterReading,
                    totalElectricPower:lastIem.totalElectricPower,
                    unitPrice:lastIem.unitPrice,
                    selfElectricityFees:lastIem.selfElectricityFees,
                    floorElectricityFees:lastIem.floorElectricityFees,
                    parkElectricityFees:lastIem.parkElectricityFees,
                    totalMoney:lastIem.totalMoney,
                  })
              })
            }else{
              allNum=allNum+1
              if(dArr.length){
                    dIndex= dArr[dArr.length - 1].dIndex + dArr[dArr.length - 1].dNum
              }
              dArr.push({
                dIndex:dIndex,
                dNum:1,
              })
              getTable.push({
                contractCode: item.contractCode,
                accountingCode: item.accountingCode,
                corporateName: item.corporateName,
                magnification: subItem.magnification,
                deviceCode: subItem.deviceCode,
                electricMeterName: subItem.electricMeterName,
                indicationType:"",
                lastMeterReading:"",
                meterReadingUsed:"",
                thisMeterReading:"",
              })
            }
          })
        }else{
          allNum=allNum+1
          if(dArr.length){
                dIndex= dArr[dArr.length - 1].dIndex + dArr[dArr.length - 1].dNum
          }
          dArr.push({
            dIndex:dIndex,
            dNum:1,
          })
          getTable.push({
            contractCode: item.contractCode,
            accountingCode: item.accountingCode,
            corporateName: item.corporateName,
            deviceCode:"",
            electricMeterName: "",
            indicationType:"",
            lastMeterReading:"",
            meterReadingUsed:"",
            thisMeterReading:"",
          })
        }
        
        if (arr.length) {
          //第二次的时候索引值，拿上一次的索引值 加上 上一次的合并的行数
          s = arr[arr.length - 1].index + arr[arr.length - 1].allNum
        }
        arr.push({
          index: s, // 公司从第几行合并
          allNum:allNum,  // 合并公司行数
        })
      })
      this.arr = arr
      this.dArr = dArr
      this.tableData = getTable
    },
    setDate(){
      let curDate = new Date();
      let y = curDate.getFullYear();
      let m = curDate.getMonth() + 1;//本身就得+1才等于当前月份，然而我要计算下一个月，所以直接+2
      if( m > 12 ){
          m = 1;
          y++ 
      }
      let monthLastDay = new Date(y,m,0).getDate();
      let syqxks = y + '-' + (m < 10 ? '0'+m : m) + '-' + '01';
      let syqxjs = y + '-' + (m < 10 ? '0'+m : m) + '-' + (monthLastDay < 10 ? '0'+monthLastDay : monthLastDay);
      return {
        syqxks,
        syqxjs
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}

// 上传图像
.imgDefault {
  width: 150px;
  height: 200px;
  border: 1px solid $theme_bordercolor_DCDFE6;
}

/deep/ .el-upload__tip {
  position: absolute;
  width: 20%;
  top: 45%;
  left: 37%;
  text-align: center;
  line-height: 1;
  margin: 10px 0 8px 0;
}

.upload-img-form /deep/ .el-upload--picture-card {
  background-color: initial;
  border: initial;
  border-radius: initial;
  box-sizing: initial;
  width: initial;
  height: initial;
  line-height: initial;
  vertical-align: initial;
  display: inline-block;
  text-align: initial;
}

/deep/ ul.el-upload-list--picture-card {
  display: block;
}

/deep/ .el-upload-list.el-upload-list--text {
  width: 50%;

  > li.el-upload-list__item {
    cursor: pointer;
  }
}

// 上传图片按钮
.uploadBtn {
  position: absolute;
  top: 30%;
  left: 40%;
  color: $theme_color_0083ee;
  background: transparent;
  border: 1px solid $theme_bordercolor_0083ee;

  &:hover,
  &:focus {
    background: transparent;
    color: $theme_color_0083ee;
  }
}

// 图片缩略图
.videoContent {
  width: 40%;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 180px;
      height: 200px;
      flex-shrink: 0;
      margin: 0;
      border: 1px solid $theme_bordercolor_DCDFE6;
      border-radius: 0;
      overflow: hidden;
      position: relative;

      &:hover {
        .videoPlay_IconBox {
          display: none;
        }
      }

      .img-avatar {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
