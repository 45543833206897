<template>
  <div style="width:100%">
    <el-form :model="form" :rules="ruleschi" ref="form">
      <el-table :data="form.datas" border>
        <!-- <el-table-column prop="id" label="序号" width="60"></el-table-column> -->
        <el-table-column prop="foodName" label="材料名称" width="120">
          <template slot-scope="scope">
            <template v-if="scope.row.action == 'view'">
              {{scope.row.foodName}}
            </template>
            <template v-else>
              <el-form-item :prop="'datas.'+scope.$index + '.foodName'" :rules='ruleschi.foodName' v-if="formType == 'input'">
                <el-input size="mini" v-model="scope.row.foodName" style="width: 100%"></el-input>
              </el-form-item>
              <el-form-item :prop="'datas.'+scope.$index + '.foodName'" :rules='ruleschi.foodName2' v-else>
                <el-select size="mini" v-model="scope.row.foodName" placeholder="请选择" @change="foodNameChange(scope.row.foodName,scope.$index)">
                  <el-option v-for="item in foodNameList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </template>
        </el-table-column>

        <el-table-column prop="cost" label="单价（元）" width="100">
          <template slot-scope="scope">
            <template v-if="scope.row.action == 'view' || formType == 'select'">
              {{scope.row.cost}}
            </template>
            <template v-else>
              <el-form-item :prop="'datas.'+scope.$index + '.cost'" :rules='ruleschi.cost'>
                <el-input size="mini" v-model="scope.row.cost" style="width: 100%;"></el-input>
              </el-form-item>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="数量">
          <template slot-scope="scope">
            <template v-if="scope.row.action == 'view'">
              {{scope.row.num}}
            </template>
            <template v-else>
              <el-form-item :prop="'datas.'+scope.$index + '.num'" :rules='ruleschi.num'>
                <el-input size="mini" v-model.number="scope.row.num" style="width: 100%;"></el-input>
              </el-form-item>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="unit" label="单位" width="120">
          <template slot-scope="scope">
            <template v-if="scope.row.action == 'view' || formType == 'select'">
              {{scope.row.unit}}
            </template>
            <template v-else>
              <el-form-item :prop="'datas.'+scope.$index + '.unit'" :rules='ruleschi.unit'>
                <el-select size="mini" v-model="scope.row.unit" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="totalCost" label="总价">
          <template slot-scope="scope">
            <template v-if="scope.row.action == 'view'">
              {{scope.row.totalCost }}
            </template>
            <template v-else>
              {{scope.row.totalCost | filterTotalCost(scope.row,that,scope.$index)}}
            </template>
            <!-- <template v-else>
              <el-form-item :prop="'datas.'+scope.$index + '.totalCost'">
                <el-input size="mini" v-model.number="scope.row.totalCost" style="width: 100%;" :disabled="true"></el-input>
              </el-form-item>
            </template> -->
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="150" v-if="!allDisabled">
          <template slot-scope="scope">
            <template v-if="scope.row.action == 'view'">
              <el-button size="mini" @click="click_edit(scope.row, scope.$index)">编辑</el-button>
              <el-button size="mini" @click="click_delete(scope.row, scope.$index)">删除</el-button>
            </template>
            <template v-else-if="scope.row.action == 'add'">
              <el-button size="mini" @click="click_add( scope.row, scope.$index)">新增</el-button>
              <el-button size="mini" @click="click_reset(scope.row, scope.$index)">重置</el-button>
            </template>
            <template v-else>
              <el-button size="mini" @click="click_save(scope.row, scope.$index)">保存</el-button>
              <el-button size="mini" @click="click_cancle(scope.row, scope.$index)">取消</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>
<script>
</script>
<script>
import { dictData } from '@/api'
export default {
  data() {
    return {
      that: this,
      form: {
        datas: []
      },
      options: [],
      rowForm: {},

      //表单验证规则
      ruleschi: {
        foodName: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入名称'
          }
        ],
        foodName2: [
          {
            required: true,
            trigger: 'change',
            message: '请选择材料名称'
          }
        ],
        cost: [
          {
            required: true,
            trigger: 'blur',
            // message: '请输入成本价',
            validator: this.util.formRules.checkNumber({ fixedNum: 2, min: 0 })
          },
        ],
        num: [
          {
            required: true,
            trigger: 'blur',
            // message: '请输入数量',
            validator: this.util.formRules.checkNumber({ min: 1 })
          }
        ],
        unit: [
          {
            required: true,
            trigger: 'change',
            message: '请选择单位'
          }
        ]
      },
    }
  },
  props: {
    datas: {
      type: Array,
      default: () => {
        return []
      }
    },
    allDisabled: {
      type: Boolean,
      default: false
    },
    formType: {
      type: String
    },
    foodNameList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  created() {

    //再插入一条添加操作的数据
    if (!this.allDisabled) {
      this.form.datas.unshift({
        foodName: undefined,
        num: undefined,
        cost: undefined,
        unit: undefined,
        totalCost: undefined,
        action: 'add'
      })
    }
    this.getUnit()
    if (this.datas) {
      let list = this.datas.map(item => {
        this.$set(item, 'action', 'view')
        item.totalCost = item.cost * item.num
        return item
      })
      this.form.datas = this.form.datas.concat(list)
    }


  },
  mounted() {

  },
  filters: {
    filterTotalCost: (a, row, that,index) => {
      if (row.cost && row.num) {
        let sum = that.util.accMul(row.cost, row.num)
        that.$set(that.form.datas[index],'totalCost',sum)
        return sum
      } else {
        return ''
      }
    }
  },
  methods: {
    //对部分表单字段进行校验
    validateField(form, index) {
      let result = true
      // console.log("测试---",this.$refs[form].fields)
      for (let item of this.$refs[form].fields) {
        if (item.prop.split('.')[1] == index) {
          this.$refs[form].validateField(item.prop, (error) => {
            if (error != '' && error != undefined) {
              // console.log("测试333---",item.prop,error)
              result = false
            }
          })
        }
        if (!result) break
      }
      return result
    },
    getUnit() {
      dictData("dishes_unit").then(res => {
        if (res.code == 200) {
          // console.log("res---", res)
          let data = res.data
          this.options = data
        }
      })
    },
    //对部分表单字段进行重置
    resetField(form, index) {
      this.$refs[form].fields.forEach((item) => {
        if (item.prop.split('.')[1] == index) {
          item.resetField()
        }
      })
    },

    //新增操作
    click_add(item, index) {
      if (!this.validateField('form', index)) return
      item.totalCost = item.cost * item.num
      //模拟新增一条数据
      let itemClone = JSON.parse(JSON.stringify(item))

      itemClone.action = 'view'
      this.form.datas.push(itemClone)
      this.resetField('form', index)
      this.totalprice()
    },
    // 清除采购明细
    clearForm() {
      this.form.datas.splice(0, this.form.datas.length)
      this.form.datas.unshift({
        foodName: undefined, num: undefined, cost: undefined, unit: undefined, totalCost: undefined, action: 'add'
      })
    },
    //新增-重置操作
    click_reset(item, index) {
      this.resetField('form', index)
      if(this.formType  == 'select'){
        this.$set(this.form.datas[0],'unit','')
        this.$set(this.form.datas[0],'cost','')
      }
    },

    //编辑-保存操作
    click_save(item, index) {
      if (!this.validateField('form', index)) return
      // 
      item.action = 'view'
      this.totalprice()
    },

    //编辑-取消操作
    click_cancle(item, index) {
      this.resetField('form', index)
      item.action = 'view'
      this.totalprice()
    },

    //编辑操作
    click_edit(item, index) {
      // console.log("什么", item)
      item.action = 'edit'
      this.totalprice()
    },

    //删除操作
    click_delete(item, index) {
      this.$confirm('确定删除该条数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          //模拟删除一条数据
          this.form.datas.splice(index, 1)
          this.totalprice()
        })
        .catch(() => { })
    },
    // 计算总价
    totalprice() {
      let list = [];
      let sum = 0
      this.form.datas.forEach((item, i) => {
        if (item.action == "view") {
          list.push(item)
        }
        if (i > 0) {
          sum = this.util.accAdd(sum, item.totalCost)
        }
      })
      if(this.formType  == 'select'){
        this.$set(this.form.datas[0],'unit','')
        this.$set(this.form.datas[0],'cost','')
      }
      this.$emit('addList', { datas: list, sum })
    },
    foodNameChange(val,index){
      let item = this.foodNameList.find(v => v.value == val)
      let {unit,cost} = item
      this.$set(this.form.datas[index],'unit',unit)
      this.$set(this.form.datas[index],'cost',cost)
    }
  }
}
</script>


<style  lang="scss" scoped>
</style>
