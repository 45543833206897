<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="showMealplan"
    v-if="showMealplan"
    width="80%"
    @close="close"
    :title="titleText"
  >
    <ja-form
      :rules="rules"
      :label-width="labelWidth"
      v-model="rowForm"
      :formArray="rowFormData"
      @reset="close"
      @save="saveForm"
      v-if="showMealplan"
      ref="jaForm"
      v-loading="loading_form.isShow"
      :element-loading-text="loading_form.text"
      :element-loading-spinner="loading_form.icon"
      :element-loading-background="loading_form.bg"
      :element-loading-custom-class="loading_form.formLoading"
    >
      <template #addPanMenuDetailReqList="{ data }">
        <div>
          <meal-card-content
            :currDate="currDate"
            :list="list"
            @totalList="totalList"
          ></meal-card-content>
        </div>
      </template>
      <template #preSaleDishes="{ data }">
        <div>
          <pre-sale-dishes
            :currDate="currDate"
            :list="presellPlanMenuList "
            @totalList="preSaleList"
          ></pre-sale-dishes>
        </div>
      </template>
      <template #total="{ data }">
        <ul class="total">
          <li
            v-for="(item, i) in totalTextList1"
            :key="i"
          >
            {{ item.name }} {{ item.total }}
          </li>
        </ul>
        <ul class="total">
          <li
            v-for="(item, i) in totalTextList2"
            :key="i"
          >
            {{ item.name }} {{ item.total }}
          </li>
        </ul>
      </template>
    </ja-form>
  </el-dialog>
</template>

<script>
import table from '@/views/mixins/table'
import mealCardContent from './mealCardContent.vue'
import preSaleDishes from './preSaleDishes.vue'
import { addTableware, editTableware } from '@/api'
export default {
  mixins: [table],
  components: {
    mealCardContent,
    preSaleDishes
  },
  data() {
    return {
      titleText: '制定餐牌',
      showMealplan: true,
      rowForm: {},
      list: [],
      presellPlanMenuList: [],
      labelWidth: `${this.util.setFontSize(140)}px`,
      summaryList: [[], []],
      preSaleAllList: [[], []],
      preSale: [],
      rowFormData: [
        {
          input_type: 'title',
          label: '日期',
          text: '2019-09-09',
          key: 'title',
          col: 24
        },
        {
          input_type: 'customize',
          customize: 'addPanMenuDetailReqList',
          label: '餐牌内容',
          key: 'addPanMenuDetailReqList',
          col: 24
        },
        {
          input_type: 'customize',
          customize: 'preSaleDishes',
          label: '预售菜式',
          key: 'presellPlanMenuList',
          col: 24
        },
        {
          input_type: 'customize',
          label: '价格汇总',
          customize: 'total',
          col: 24,
          key: 'total'
        }
      ],
      isAdd: true,
      rules: {
        addPanMenuDetailReqList: [
          {
            required: true,
            message: '请输入餐牌内容',
            trigger: 'blur'
          }
        ]
      },
      totalCostAllObj: {},
      totalDefaultPriceObj: {},
      totalTextList1: [],
      totalTextList2: [],
      preSaleListData:[]
    }
  },
  props: {
    mealpalanRow: {
      type: Object,
      default: function () {
        return {}
      }
    },
    mealplanTitle: {
      type: String,
      default: ''
    }
  },
  watch: {
    summaryList: {
      handler(newName, oldName) {
        // console.log('变化了----', newName)
      },
      deep: true
    }
  },
  computed: {},
  created() {
    // console.log('显示啊-----')
    this.rowFormData[0].text = this.mealpalanRow.foodName
    // 里面数据需要拼接日期
    this.currDate = this.mealpalanRow.foodName
    // 详情的时候接受餐牌数据列表
    this.list = this.mealpalanRow.list
    this.$set(this.rowForm, 'addPanMenuDetailReqList', this.mealpalanRow.list)
    this.presellPlanMenuList = this.mealpalanRow.presellPlanMenuList
    this.$set(
      this.rowForm,
      'presellPlanMenuList',
      this.mealpalanRow.presellPlanMenuList
    )
  },
  methods: {
    // 关闭
    close() {
      this.$emit('saveFormMealplan', this.rowForm)
    },
    saveForm() {
      // 保存
      console.log('this.rowForm11111', this.rowForm)
      // this.loading_form.isShow = true
      let isEdit = this.rowForm.addPanMenuDetailReqList.some(
        (item) => item.action == 'edit'
      )
      let isEditPreSale =
        this.rowForm.presellPlanMenuList &&
        this.rowForm.presellPlanMenuList.some((item) => item.action == 'edit')
      if (isEdit || isEditPreSale) {
        this.$message.error('所有餐牌内容保存后，再提交！')
      } else {
        this.$emit('saveFormMealplan', this.rowForm)
      }
    },
    addList() {},
    // 显示弹框
    showDialog() {
      this.showMealplan = true
      // this.showDialog
    },
    totalList(data) {
      // console.log("餐牌内容",data)
      this.summaryList = data
      if(this.summaryList.length!=0){
        if(this.preSaleListData.length!=0){ // 防止点击编辑的时候预售为空的时候执行this.preSaleList([])
          this.totalTextList1 = [...this.summaryList[0]]
          this.totalTextList2 = [...this.summaryList[1]]
          this.totalTextList1.splice(this.totalTextList1.length - 1, 1, this.totalCostAllObj)
          this.totalTextList2.splice(this.totalTextList2.length - 1, 1, this.totalDefaultPriceObj)
          let val = this.calculateTotal(this.totalTextList1, 'total')
          let val2 = this.calculateTotal(this.totalTextList1, 'total')
          this.totalTextList1.push({ name: '总成本', total: val })
          this.totalTextList2.push({ name: '总售价', total: val2 })
        }else{
          this.preSaleList([])
        }
      }else{
        this.totalTextList1 = this.preSaleListData[0]
        this.totalTextList2 = this.preSaleListData[1]
      }
      
      this.$set(this.rowForm, 'addPanMenuDetailReqList', data[2] ? data[2] : [])
    },
    // 菜式预售
    preSaleList(data) {
      // console.log("预售",data)
      this.preSaleListData=data
      // 售价总价totalDefaultPrice 成本总价totalCost
      // 计算预售加总价，售价成本总价
      if (data.length != 0) {
        let total=data[0][data[0].length-1]["total"] // 获取预售总成本价
        let total2=data[1][data[1].length-1]["total"] // 获取售价总成本价
        this.totalCostAllObj = { name: '预售成本', total:Number(total) }
        this.totalDefaultPriceObj = { name: '预售售价', total:Number(total2) }
        this.totalTextList1 = [...this.summaryList[0]]
        this.totalTextList2 = [...this.summaryList[1]]

        this.totalTextList1.splice(this.totalTextList1.length - 1, 1, this.totalCostAllObj)
        this.totalTextList2.splice(this.totalTextList2.length - 1, 1, this.totalDefaultPriceObj)
        let val = this.calculateTotal(this.totalTextList1, 'total') // 计算餐牌内容跟预售的总成本价
        let val2 = this.calculateTotal(this.totalTextList2, 'total') // 计算餐牌内容跟预售的总成本价
        this.totalTextList1.push({ name: '总成本', total: val }) // push到新的列表上
        this.totalTextList2.push({ name: '总售价', total: val2 }) // push到新的列表上
      } else {
        this.totalTextList1 = this.summaryList[0]
        this.totalTextList2 = this.summaryList[1]
      }

      this.$set(this.rowForm, 'presellPlanMenuList', data[2] ? data[2] : [])
    },
    calculateTotal(arrList, key) {
      let totalDefaultPrice = arrList.map((z) => {
        return z[key]
      })
      return totalDefaultPrice.reduce((a, b) => a + b)
    }
  }
}
</script>


<style lang="scss" scoped>
.total {
  display: flex;
  flex-wrap: wrap;
  li {
    width: 20%;
  }
}
</style>
