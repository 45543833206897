import { reqLogin, getPersonalmess,  } from '@/api'

const user = {
  state: {
    // token: getToken(),
    token_data: window.localStorage.getItem('token'),
    name: '',
    avatar: '',
    // roles: [],
    roles: null,
    permissions: [],
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token_data = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
  },

  actions: {
    // 登录
    Login ({ commit }, data) {
      return new Promise((resolve, reject) => {
        reqLogin(data).then(res => {
        //   setToken(res.token)
        //   commit('SET_TOKEN', res.token)
          commit('SET_TOKEN', res.data)
          resolve(res.data)
        })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo ({ commit, state }) {
      return new Promise((resolve, reject) => {
        // getInfo(state.token).then(res => {
        getPersonalmess().then(res => {
          // console.log('dj敏===用户信息=====',res)
          commit('SET_ROLES', res.data)
          // sessionStorage.setItem('permissions',res.data.permissions)
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', null)
        sessionStorage.removeItem('menu')
        //   removeToken()
        resolve()
      })
    },
    
  }
}

export default user
