
const requireComponent = require.context('./', true, /\.vue$/)
const components = []
requireComponent.keys().map(file => {
  components.push(requireComponent(file).default)
})

const install = function (Vue) {
  if (install.installed) return
  components.map(component => {
    Vue.component(component.name, component)
  })
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}
const CSUI = {
  install,
  ...components
}
export default CSUI
