// 将层级字符串转为对象变量  
// 如str=“data.p.c” 字符串，能读取到 "oldObj{data{p{c:'111'}}}" 的值 ，oldObj 是要读取的对象
const stringToVariable = function({oldObj,str}){
    let newObj = oldObj || {}
    let keysArr = Object.keys(oldObj)
    let strArr = str.split('.') 
    if(strArr.length>0){
      if(strArr.length == 1){
        return newObj[strArr[0]]
      }
      let key = keysArr.find(v =>v == strArr[0])
      if(key){ 
        let index = str.indexOf('.')
        let nextStr = str.slice(index+1)
        return stringToVariable({oldObj:newObj[key],str:nextStr})
      }else{
        return newObj
      }
    }
}
/**
 * 处理下拉列表的 options 
 * @param {*} optionsArr 要被处理的数组
 * @param {*} labelName 作为 options 数组 的{label:'',value:''},label 读取的是后端哪一个字段
 * @param {*} valueName 作为 options 数组 的{label:'',value:''},value 读取的是后端哪一个字段
 * @param {*} otherKeys options 数组的每一项，是否还需返回的其它值，[{keyName,keyValue}]
 * @param {*} isArrayList  若为true 返回数组，如后端 返回 [{label:'哈哈',value:'1'},{label:'苹果',value:'3'},]，能处理为数组 【'','哈哈','','苹果'】
 * @param {*} isReturnAll  是否返回一个项的所有字段
 * 
 */
  // 清除下拉列表的 options 跟 值
const dealOptions = function({optionsArr = [],labelName, valueName, otherKeys = [],isArrayList = false,isReturnAll= false}){
  let options = [],options_arr =[]
  if(optionsArr.length>0){
    options = optionsArr.map(item => {
      let label = labelName ? item[labelName] : item.dictLabel
      let value = valueName ? item[valueName] : item.dictValue
      if(isArrayList){ // 若是直接 数组
        options_arr[Number(value)] = label
      }else{
        const obj = isReturnAll?{...item}:{}
        obj.label = label,
        obj.value = value
        if (otherKeys.length > 0) {
          otherKeys.forEach(v => {
            obj[v.keyName] = item[v.keyValue]
          })
        }
        return obj
      }
    })
  }
  if(isArrayList){
    options = options_arr
  }
  return  options
}

export {
    stringToVariable,
    dealOptions,

}
