<template>
  <router-view />
</template>

<script>
export default {
  title: "餐具",
  name: 'basicInformation'
}
</script>

<style scoped>
</style>
