import http from './plugin/ajax'

// 新增商户
export const bizBusinessAdd = (data) => http.post('/bizBusiness', data)

//编辑商户
export const bizBusinessEdit = (data) => http.put('/bizBusiness', data)

//删除商户
export const bizBusinessDelete = (data) => http.delete('/bizBusiness/' + data)

//商户信息列表
export const bizBusinessGet = (data) => http.get('/bizBusiness', {
  params: data
})

