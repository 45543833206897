<template>
    <el-form :model="form" :rules="rules" ref="form">
      <el-table :data="form.datas" border style="width: 100%">
        <!-- <el-table-column prop="id" label="序号" width="60"></el-table-column>
         -->
        <el-table-column prop="timeTypeName" label="时段类型">
          <template slot-scope="scope">
            <template v-if="scope.row.action == 'view'">
              {{scope.row.timeTypeName}}
            </template>
            <template v-else>
              <el-form-item :prop="'datas.'+scope.$index + '.timeTypeName'" :rules='rules.timeTypeName'>
                <el-select size="mini" value-key="dictValue" v-model="scope.row.timeTypeName" placeholder="请选择">
                  <el-option
                    v-for="item in planTypeList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </template>
        </el-table-column>

        <el-table-column prop="dishesOrMenlName" label="套餐/菜式">
          <template slot-scope="scope">
            <template v-if="scope.row.action == 'view'">
              {{scope.row.dishesOrMenlName}}
            </template>
            <template v-else>
              <el-form-item :prop="'datas.'+scope.$index + '.dishesOrMenlName'" :rules='rules.dishesOrMenlName'>
                <el-select 
                size="mini" 
                @change="changePackage($event,scope.$index)"
                v-model="scope.row.dishesOrMenlName"
                value-key="flag"
                v-el-select-loadmore="loadmoreTableware"
                placeholder="请选择">
                  <el-option
                    v-for="item in MenuList"
                    :key="item.flag"
                    :label="item.dishesOrmenuName"
                    :value="item">
                  </el-option>
                </el-select>
                <!-- <div v-if="menuDialog" style="text-align: center;color: #999;font-size: 12px;">
                  暂无更多数据
                </div> -->
              </el-form-item>
            </template>
          </template>
        </el-table-column>

        <el-table-column prop="typeName"  width="50" label="类型">
          <template slot-scope="scope">
            <template>
              {{scope.row.typeName}}
              <!-- 套餐 -->
            </template>
          </template>
        </el-table-column>

        <el-table-column prop="num" label="供应量">
          <template slot-scope="scope">
            <template v-if="scope.row.action == 'view'">
              {{scope.row.num}}
            </template>
            <template v-else>
              <el-form-item :prop="'datas.'+scope.$index + '.num'" :rules='rules.num'>
                <el-input size="mini" @change="changeInput($event,scope.$index)" v-model.trim="scope.row.num" ></el-input>
              </el-form-item>
            </template>
          </template>
        </el-table-column>
        
        <el-table-column prop="cost" label="成本单价">
          <template slot-scope="scope">
            <template>
              {{scope.row.cost}}
            </template>
          </template>
        </el-table-column>

        <el-table-column prop="totalCost" label="成本总价">
          <template slot-scope="scope">
            <template>
              {{scope.row.totalCost}}
            </template>
          </template>
        </el-table-column>

        <el-table-column prop="defaultPrice" label="售价单价">
          <template slot-scope="scope">
            <template>
              {{scope.row.defaultPrice}}
            </template>
          </template>
        </el-table-column>

        <el-table-column prop="totalDefaultPrice" label="售价总价">
          <template slot-scope="scope">
            <template>
              {{scope.row.totalDefaultPrice}}
            </template>
          </template>
        </el-table-column>

        <el-table-column prop="operation" width="160" label="操作">
          <template slot-scope="scope">
            <template v-if="scope.row.action == 'view'">
              <el-button size="mini" @click="click_edit(scope.row, scope.$index)">编辑</el-button>
              <el-button size="mini" @click="click_delete(scope.row, scope.$index)">删除</el-button>
            </template>
            <template v-else-if="scope.row.action == 'add'">
              <el-button size="mini" @click="click_add( scope.row, scope.$index)">新增</el-button>
              <el-button size="mini" @click="click_reset(scope.row, scope.$index)">重置</el-button>
            </template>
            <template v-else>
              <el-button size="mini" @click="click_save(scope.row, scope.$index)">保存</el-button>
              <el-button size="mini" @click="click_cancle(scope.row, scope.$index)">取消</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
</template>
<script>
import {
  dictData,
  getDishesAndMenuList
} from '@/api'
  export default {
    props:{
      currDate:{
        type:String,
        default:'2021-09-10'
      },
      list:{
        type:Array,
        default:function(){
          return []
        }
      }
    },
    data() {
      return {
        titleText:"新增",
        showMealplan:true,
         planTypeList:[],
        value: '',
        form: {
          datas: [
            // { id: 1, name: "张三", age:20 },
            // { id: 2, name: "李四", age:32 },
          ],
        },
        MenuList:[],
        menuDialog:false,
        //表单验证规则
        rules: {
          timeTypeName: [{
            required: true,
            trigger: 'change',
            message: '请选择时段类型',
          }],
          dishesOrMenlName: [{
            required: true,
            trigger: 'change',
            message: '请选择套餐/菜式',
          }],
          num: [
          {
            type: 'number',
            trigger: 'blur',
            validator:this.util.formRules.checkNumber({min:1,fixedNum:2,})
          }],
        },
        formMenu:{
          pageIndex:1,
          pageSize:10,
           presellFlag:0
        }
      }
    },

    created() {
      this.planStatusType('time_type')
      this.getDishesAndMenuList(this.formMenu)
      this.form.datas=[...this.form.datas,...this.list]
      // let nameKey=["菜式","套餐"]
      //编辑的时候,计算成本总价，销售总价
      if( this.form.datas.length!=0){
        this.form.datas.map((item,i)=>{
            // console.log(item,i)
            // item.typeName=nameKey[item.type]
          this.changeInput(item.num,i)
        })
      }
      
      //处理数据，为已有数据添加action:'view'
      this.form.datas.map(item => {
        this.$set(item,"action","view")
        return item;
      });

      //再插入一条添加操作的数据
      this.form.datas.unshift({
        date:this.currDate,
        timeType:undefined,
        type:undefined,
        age:undefined,
        action: "add"
      });
      
    },
    mounted() {
      if(this.form.datas.length>1){
        this.count()
      }
    },
    methods: {
      // 请求字典接口
      planStatusType(type){
        dictData(type).then(res=>{
          if(res.code==200){
            // console.log("aaa",res)
            this.planTypeList=res.data
          }
        })
      },
      // 获取套餐/菜式
      getDishesAndMenuList(formMenu){
        getDishesAndMenuList(formMenu).then(res=>{
          if(res.code==200){
            if(res.data.data.length==0){
            this.menuDialog=true
          }else{
            this.MenuList=[...this.MenuList,...res.data.data]
          }
          }
        })
      },
      loadmoreTableware() {
        this.formMenu.pageIndex++;
        if(!this.menuDialog){
          this.getDishesAndMenuList(this.formMenu);
        }
      },
      // 改变套餐/菜式
      changePackage(item,index){
        console.log("菜式-----",item,index)
        this.$set(this.form.datas[index],'type',item.id)
        this.$set(this.form.datas[index],'typeName',item.dishesOrmenuType)
        this.$set(this.form.datas[index],'cost',item.cost)
        this.$set(this.form.datas[index],'defaultPrice',item.defaultPrice)
        // 改变套餐/菜式 且有数量的时候计算 成本总价 销售总价
        if(this.form.datas[index].num){
          this.changeInput(this.form.datas[index].num,index)
        }
        
      },
      // 监听input
      changeInput(data,index){ 
        // 计算成本总价
        if(this.form.datas[index].cost || this.form.datas[index].cost==0){
          let total=this.form.datas[index].cost*data
          // console.log("计算成本总",total)
          total=total.toFixed(2)
          this.$set(this.form.datas[index],'totalCost',Number(total))
        } 
        // 计算销售总价
        if(this.form.datas[index].defaultPrice || this.form.datas[index].defaultPrice==0){
          let totalDefaultPrice=this.form.datas[index].defaultPrice*data
          totalDefaultPrice=totalDefaultPrice.toFixed(2)
          this.$set(this.form.datas[index],'totalDefaultPrice',Number(totalDefaultPrice))
        } 
      },
      //对部分表单字段进行校验
      validateField(form,index){
        let result = true;
        for (let item of this.$refs[form].fields) {
          if(item.prop.split(".")[1] == index){
            this.$refs[form].validateField(item.prop,(error)=>{
              // console.log(error)
              if(error!="" && error!=undefined){
                result = false;
              }
            });
          }
          if(!result) break;
        }
        // console.log("什么---",result)
        return result;
      },
      
      //对部分表单字段进行重置
      resetField(form,index){
        //新增完后清空一下
        this.form.datas[0].totalDefaultPrice=''
        this.form.datas[0].totalCost=''
        this.form.datas[0].cost=''
        this.form.datas[0].defaultPrice=''
        this.form.datas[0].type=''
        this.$refs[form].fields.forEach(item=>{
          if(item.prop.split(".")[1] == index){
            item.resetField();
          }
        })
      },
      
      //新增操作
      click_add(item,index) {
        if( !this.validateField('form',index) ) return;
        //模拟新增一条数据
        let itemClone = JSON.parse(JSON.stringify(item));
        itemClone.action = "view";
        // console.log("itemClone.dishesOrmenuName=998888-----",itemClone)
        // debugger
        // 因为dishesOrMenlName双向绑定的是对象，所有要重新赋值一遍
        itemClone.typeId=itemClone.dishesOrMenlName.id
        itemClone.dishesOrMenlName=itemClone.dishesOrMenlName.dishesOrmenuName

        itemClone.timeType=itemClone.timeTypeName.dictValue
        itemClone.timeTypeName=itemClone.timeTypeName.dictLabel
       
        this.form.datas.push(itemClone);
        this.count()
        this.resetField('form',index);
      },
      //编辑-保存操作
      click_save(item,index) {
        if( !this.validateField('form',index) ) return;
        // let itemClone = JSON.parse(JSON.stringify(item));
        // console.log("保存----",item,index,item.dishesOrMenlName)
        if(item.dishesOrMenlName instanceof Object){
          item.typeId=item.dishesOrMenlName.id
          item.dishesOrMenlName=item.dishesOrMenlName.dishesOrmenuName
        }
        if(item.timeTypeName instanceof Object){
          item.timeType=item.timeTypeName.dictValue
          item.timeTypeName=item.timeTypeName.dictLabel
          
        }
       
        this.count()
        item.action = "view";
      },
      // 价格汇总计算
      count(){
        if(this.form.datas.length>1){
          let newDatas=[...this.form.datas]
          // console.log("删除---newDatas",newDatas)
          newDatas.shift()
          
          // 整合合并相同的时段
          let priceList=this.mergeSame(newDatas,'timeType')
          // 计算
          let totalCostList=this.summary(priceList,'totalCost','成本',"总成本")
          let totalDefaultPriceList=this.summary(priceList,'totalDefaultPrice','售价',"总售价")
          // console.log("summarylist啊啊啊---",totalCostList,totalDefaultPriceList,newDatas)
          this.$emit('totalList',[totalCostList,totalDefaultPriceList,newDatas])
        }else{
          this.$emit('totalList',[])
        }
        // console.log("summarylist---",totalDefaultPriceList)
      },
      
      //新增-重置操作
      click_reset(item,index) {
        this.resetField('form',index);
      },
      
      
      //编辑-取消操作
      click_cancle(item,index) {
        this.resetField('form',index);
        item.action = "view";
        this.count()
      },
      
      //编辑操作
      click_edit(item,index) {
        item.action = "edit";
      },

      //删除操作
      click_delete(item,index) {
        this.$confirm("确定删除该条数据吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
          .then(() => {
            //模拟删除一条数据
            this.form.datas.splice(index,1);
            this.count()
          })
          .catch(() => {});
      },
      // 价格汇总
      summary(data,key,name,totalName){
        let summary=[]
        // 成本总价totalCost
        data.map(item=>{
          let pricle=item.map(i=>{
           return i[key]
          })
          let total=pricle.reduce((a,b)=>a+b)
          summary.push({
            name:item[0].timeTypeName+name,
            total:total
          })
        })
        // 算总的
        let itemTotal=summary.map(z=>{
          return z.total
        })
        let allTotal=itemTotal.reduce((a,b)=>a+b)
        summary.push({
          name:totalName,
          total:allTotal.toFixed(2)
        })
        return summary
      },
      // 合并数组相同项
      mergeSame(data,key){
        let name=data.map(item=>{
          return item[key]
        })
        let newName=[...new Set(name)]
        // console.log('newName',newName)
        let Arr=[]
        newName.map(item=>{
          let items=data.map(i=>{
            return i[key]==item?i:"undefined" 
          })
          Arr.push(items.filter(res=>res!="undefined"))
        })
        return Arr
      },
      // 关闭
      close(){
        
      }

    },
  }
</script>


<style lang="scss" scoped>
  .el-table .cell{
    overflow: visible;
  }
  // .el-form-item{
  //   margin-bottom: 0;
  // }
  .el-form-item__error{
    padding-top:0;
    margin-top:-3px;
  }
  /deep/.el-table {
      td,
      th {
          text-align: center;
      }
  }
</style>
