import http from './plugin/ajax'

// ---  设备报警与报修  ---

// 报修工单列表
export const getRepairManager = (data) => http.post('/repairManager', data)
export const eidtRepairManager = (data) => http.put('/repairManager', data)
export const addRepairManager = (data) => http.post('/repairManager/add', data)
export const updateAssignHandler = (data) => http.put('/repairManager/updateAssignHandler', data)
export const assignRepairMan = (data) => http.put('/repairManager/updateAssignHandler', data)
export const updateStatusRepair = (data) => http.put('/repairManager/updateStatus', data)

