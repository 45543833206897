<template>
  <div ref="wrap" class="wrap"></div>
</template>
<script>
import 'echarts-liquidfill'
export default {
  name: 'jaInitChart',
  data () {
    return {
      defaultOptions: {
        textStyle: {
          color: '#303133',
          fontSize: this.util.setFontSize(30, 1920)
        },
        grid: {
          left: '10%',
          right: '10%',
          top: '10%',
          bottom: '10%'
        }
      }
    }
  },
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    isMap: {
      type: Boolean,
      default: false
    },
    mapConfig: {
      type: Object,
      default: () => {
        return {
          name: 'china',
          data: {}
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.drawInit(this.options)
      const ids = [...this.ids, this.id]
      this.$store.commit('setIds', ids)
    })
  },
  methods: {
    drawInit (options) {
      options = { ...this.defaultOptions, ...options }
      const myChart = this.$echarts.init(this.$refs.wrap)
      if (this.isMap && this.mapConfig.data) {
        console.log('信息', this.mapConfig, 'options-->', options)
        this.$echarts.registerMap(this.mapConfig.name, this.mapConfig.data)
        console.log('options', options)
      }

      window.onresize = () => {
        this.ids.forEach(v => {
          this.$echarts.init(document.getElementById(v)).resize()
        })
        // const echartRE = echarts.init(
        //   document.getElementById(this.id)
        // )
        // echartRE.resize()
      }
      myChart.setOption(options)
    }
  },
  watch: {
    options: {
      handler (val) {
        this.drawInit(val)
      },
      deep: true
    },
    styles: {
      handler (val) {
        this.drawInit(val)
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap{
  height: 100%;
  width: 100%;
}
</style>
