<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset" @append="normalEvent({ref:'addEdit',method:'append'})" @rowDelete="rowDelete"></ja-table>
    <!-- 新增 弹窗 -->
    <add-edit ref="addEdit" />
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit.vue'
import { getVisitorBlackList, delVisitorBlack } from '@/api'

export default {
  title: "访客黑名单",
  mixins: [table],
  components: { AddEdit, },
  name: 'index',
  data() {
    return {
      conditionData: [
        { input_type: 'input', key: 'name', attribute: { placeholder: '访客姓名', } },
        { input_type: 'input', key: 'phone', attribute: { placeholder: '访客电话', } },
        { input_type: 'input', key: 'dept', attribute: { placeholder: '访客公司', } },
        { input_type: 'input', key: 'createBy', attribute: { placeholder: '创建人', } },
        { input_type: 'date', type: 'daterange', key: 'time', value: 'time', startPlaceholder: '创建开始日期', endPlaceholder: '创建结束日期', valueFormat: 'yyyy-MM-dd' },
        { input_type: 'select', key: 'type', attribute: { placeholder: '黑名单类型', }, options: [] },

        { normalBtn: 'search', },
        { normalBtn: 'reset', },
        { normalBtn: 'add', limit:'visitor::black::add'},
        { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas2, rqUrl: '/visitor/black/export', rqMethod: 'post' },limit:'visitor::black::download' }
      ],
      conditionData_indexObj: {},
      columns: [
        { label: '访客姓名', prop: 'name', },
        { label: '访客电话', prop: 'phone', },
        { label: '访客公司', prop: 'dept', },
        { label: '类型', prop: 'typeName' },
        { label: '备注', prop: 'remark', },
        { label: '创建人', prop: 'createBy', },
        { label: '创建日期', prop: 'createTime',width:200 },
        {
          label: '操作', input_type: 'button', width:120 ,
          list: [
            { text: '删除', method: 'rowDelete',limit:'visitor::black::delete' },
          ]
        }
      ],
      tableData: []
    }
  },
  mounted() {
    this.conditionData_indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    this.util.rqOptionsList({ who: [this.conditionData[this.conditionData_indexObj.type], this.$refs.addEdit.rowFormData[3]], rqUrl: "/sys/dictData/black_type", resRule: 'data' })
  },
  methods: {
    rqParmas2(){
      let obj = this.rqParmas()
      if (obj.time) {
        obj.startTime = obj.time[0]
        obj.endTime = obj.time[1]
        delete obj.time
      }
      return obj
    },
    getInit() {
      this.loading = true
      let obj = this.rqParmas2()
      getVisitorBlackList(obj).then(res => {
        this.tableData = res.data.data
        this.total = res.data.total
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm('是否确认删除该黑名单').then(res => {
        delVisitorBlack(row.id).then(res => {
          this.$message.success("已删除")
          this.getInit()
        })
      })
    },
  }
}
</script>