<template>
   <!-- 登录页面 -->
  <div class="login-pages">
    <div class="login-container">
      <h3>
        您好！
        <i class="line"></i> 欢迎来到
        <br />研究院大厦管理平台
      </h3>
      <el-form ref="form" :rules="rules" :model="formItem" label-width="80px">
        <el-form-item prop="username">
          <el-input v-model="formItem.username" placeholder="请输入用户名/账号">
            <i slot="prefix" class="icon icon-username"></i>
          </el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input v-model="formItem.password" placeholder="请输入密码" show-password>
            <i slot="prefix" class="icon icon-password"></i>
          </el-input>
        </el-form-item>

        <el-form-item v-show="isShowCode">
          <el-col :span="16">
            <el-form-item prop="code">
              <el-input v-model="formItem.validateCode" placeholder="请输入验证码">
                <i slot="prefix" class="icon icon-code"></i>
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8" v-show="isShowCode">
            <el-form-item style="padding-left: 10px; cursor: pointer">
              <img :src="formItem.code" alt="验证码" class="img-code"  @click="getsigncodeFun()"/>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item>
          <el-checkbox v-model="checked" style="width: 40%;">记住用户名</el-checkbox>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" :loading="loading" @click="login">登&nbsp;&nbsp;录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import SIdentify from "@/components/VeriCode/VeriCode"
import { reqAuthCode, reqLogin, getPersonalmess, rqIsNeedCode } from "@/api"

export default {
  components: { SIdentify },
  data() {
    return {
      checked: false,
      formItem: {
        username: "",
        password: "",
        code: "",
        validateCode: ""
      },
      validateCode: "", // 暂定，防止输入报错
      isShowCode:false, //是否展示验证码
      info: "",
      uuid: "",
      loading: false,
      rules: {
        code: [{ required: false, message: "请输入验证码", trigger: "blur" }],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.$emit("loading");
        // this.isShowCode = true
        // this.getsigncodeFun();
    const user = localStorage.setItem("user", "");
    localStorage.setItem("token", "");
    this.$store.commit("setToken", "");
    if (user) {
      this.checked = true;
      this.formItem = {
        ...this.formItem,
        ...user,
      };
    }
    console.log('登录页======》调用了')
    this.$store.dispatch('FedLogOut').then(() => {
        localStorage.removeItem("token");
      })
    // 获取验证码
    rqIsNeedCode().then(res=>{
      console.log('是否需要验证码===》',res)
      if(res.data.configValue == 1){
        this.isShowCode = false
      }else {
        this.isShowCode = true
        this.getsigncodeFun();

      }
    })
    
  },
  mounted() {
    document.onkeyup = (e) => {
      if (e.keyCode === 13) {
        this.login();
      }
    };
  },
  destroyed() {
    document.onkeyup = null;
  },
  methods: {
    login () {
      if (!this.loading) {
        this.loading = true
        const that = this
        this.$refs.form.validate((ret) => {
          if (ret) {
            this.$store.dispatch("Login", {
              // ...this.formItem,
              username: this.formItem.username,
              password: this.formItem.password,
              client_id: "web",
              client_secret: "123456",
              grant_type: "password",
              scope: "server",
              validateCode: this.formItem.validateCode,
              uuid: this.uuid
            })
            .then((res) => {
                  this.loading = false;
                  localStorage.setItem("token", JSON.stringify(res));
                  that.$store.commit("setToken", res);
                  // that.getpersonmesg()
                  this.$router.push({ path: this.redirect || "/" });
            })
            .catch((err) => {
              this.loading = false;
              if(this.isShowCode){
                this.getsigncodeFun();
              }

            });
          } else {
            this.loading = false;
          }
        });
      }
    },
    // 随机获取验证码
    getsigncodeFun() {
      reqAuthCode()
        .then((res) => {
          this.formItem.code = res.data.validateCode;
          console.log(this.formItem)
          this.info = res.data;
          this.uuid = res.data.uuid
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 账号信息获取
    // getpersonmesg () {
    //   getPersonalmess().then(res=>{
    //     console.log('账号信息',res.data)
    //     let storage = window.localStorage
    //     const d = JSON.stringify(res.data.data)
    //     storage.setItem("user",d);
    //     console.log('账号信息1',storage.user)
    //     this.$router.push("/");
    //   })
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/style/mixin.scss";
// 登录页
/deep/ .el-form-item__content {
  margin: 0 !important;
  line-height: 1;
}

/deep/ .el-input__inner {
  height: $theme_height_48;
  line-height: $theme_height_48;
  border: 1px solid $theme_bordercolor_fff;
  background: $theme_background_input;
  opacity: 1;
  border-radius: 8px;
  font-size: $theme_fontsize_16;
  color: $theme_color_6f89a3;

  &:hover {
    border: 1px solid $theme_bordercolor_fff;
  }

  &:focus {
    border: 1px solid $theme_bordercolor_0083ee;
  }
}

/deep/ .el-input--prefix .el-input__inner {
  padding-left: 58px;
}

/deep/ .el-input__inner::-webkit-input-placeholder {
  color: $theme_color_6f89a3 !important;
}

/deep/ .el-input__prefix,
/deep/ .el-input__suffix {
  top: 0;
  bottom: 0;
  margin: auto;
  height: auto;
  display: flex;
  align-items: center;
}

/deep/ .el-input__suffix {
  right: 20px;
}

.img-code {
  height: $theme_height_48;
  width: 100%;
  display: inline-block;
  background-color: $theme_background_fff;
  border-radius: 8px;
}

.icon-username,
.icon-password,
.icon-code {
  width: 18px;
  height: 18px;
  background: url('/images/login/username.png') center no-repeat;
  background-size: cover;
  display: inline-block;
  margin: 0 14px 0 20px;
}

.icon-password {
  background: url('/images/login/password.png') center no-repeat;
  background-size: cover;
}

.icon-code {
  background: url('/images/login/code.png') center no-repeat;
  background-size: cover;
}

/deep/ .el-checkbox {
  display: flex;
  align-items: center;
  padding-left: 6px;
}

/deep/ .el-checkbox__inner {
  border-radius: 50%;
  margin-top: 2px;
  border: 1px solid $theme_bordercolor_5a7591;
}

/deep/ .el-checkbox__label {
  font-size: $theme_fontsize_16;
  color: $theme_color_5a7591;
}

/deep/ .el-button {
  width: 100%;
  height: $theme_height_48;
  line-height: 1;
  border-radius: 8px;
  margin-top: 24px;
  font-size: $theme_fontsize_18;
  background-color: $theme_background_0083ee;
}


.login-pages {
  width: 100%;
  min-width: 1000px;
  height: 100%;
  background: url("/images/login/bg.png") center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .login-container {
    position: relative;
    width: 24%;
    min-width: 300px;
    margin-right: 20%;

    h3 {
      font-size: $theme_fontsize_30;
      font-weight: bold;
      padding-bottom: 20px;
      color: #6c6c6c;
      text-align: left;
      line-height: 38px;

      & .line {
        display: block;
        width: 20px;
        height: 4px;
        background-color: #22fa6d;
        margin: 18px 0 8px 0;
      }
    }
  }
}
</style>