<template>
  <div class="content">
    <el-row
      :gutter="20"
      style="width:100%"
    >
      <el-col :span="6">
        <div class="title">
          <ul class="title_list">
            <li
              :class="[i==currIndex?'title_list_curr':'']"
              v-for="(item,i) in title_list"
              @click="clickTitleList(i)"
              :key="i"
            >
              {{item}}
            </li>
          </ul>
        </div>
        <videoTree
          :categoryType="(currIndex+1)+''"
          @handleNodeClick="handleNodeClick"
          @search="search"
          @clickTag="clickTag"
        ></videoTree>
      </el-col>
      <el-col :span="18">
        <div class="search">
          <h4>操作</h4>
          <ul class="search_list">
            <li>
              <el-button type="primary" @click="reportingBarrier">一键报障</el-button>
            </li>
            <li>
              <el-button
                type="primary"
                v-if="btnList[currIndex].length!==0"
              >重置</el-button>
              <el-button
                type="primary"
                v-for="(item,i) in btnList[currIndex]"
                :key="i"
                @click="deviceControl(item)"
              >{{item.name}}</el-button>
            </li>
          </ul>
        </div>
        <div class="search device_list">
          <ul class="search_list">
            <li>
              设备列表
            </li>
            <li>
              <span>
                <i></i>
                正常运转
              </span>
              <span>
                <i class="warning"></i>
                告警
              </span>
              <span>
                <i class="not_started"></i>
                未启动
              </span>
              <span>
                <i class="fault"></i>
                故障
              </span>
              <span>
                <i class="under_repair"></i>
                维修中
              </span>
            </li>
          </ul>
          <p class="all">
            <label>
              <input
                type="checkbox"
                id="checkbox"
                v-model="checked"
                @click="changeAllChecked()"
              >全选
            </label>
          </p>
          <ol class="checkbox_list">
            <li
              v-for="(item, i) in deviceLIst"
              :class="statusChange(item )"
              :key="i"
            >
              <label style="width:100%;height:100%;display: inline-block;cursor: pointer;">
                <input
                  type="checkbox"
                  :id="item.deviceId"
                  :value="item.deviceId"
                  v-model="checkedNames"
                >
                <p>{{item.deviceName}}</p>
                <p>{{item.deviceCode}}</p>
              </label>
            </li>
          </ol>
        </div>
        <div class="set_device search">
          <h4>操作</h4>
          <!-- 水表 -->
          <template v-if="currIndex==2">
            <el-form
              :inline="true"
              :model="formData"
              :rules="rules"
              key="1"
              ref="ruleFormElectric"
            >
              <el-row>
                <el-col :span="22">
                  <el-form-item label="告警值"  prop="threshold">
                    <el-input
                      v-model="formData.threshold"
                      placeholder=""
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col
                  :span="2"
                  style="text-align: right;"
                >
                  <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleFormElectric')">提交</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </template>
          <!-- 水表end-->
          <!-- 电表 -->
          <template v-if="currIndex==3">
            <el-form
              :inline="true"
              :model="formData"
              key="2"
              :rules="rules" 
              ref="ruleFormWater"
            >
              <el-row>
                <el-col :span="22">
                  <el-form-item label="告警值" prop="threshold">
                    <el-input
                      v-model="formData.threshold"
                      placeholder=""
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col
                  :span="2"
                  style="text-align: right;"
                >
                  <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleFormWater')">提交</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </template>
          <!-- 电表end -->
          <!-- 会议室门禁 -->
          <template v-if="currIndex==4">
            <el-form
              :inline="true"
              :model="fromGateLock"
              ref="fromGateLock"
              key="3"
              :rules="fromGateLockRules"
            >
              <el-row>
                <el-col :span="22">
                  <el-form-item label="开锁方式" prop="resource">
                    <el-radio-group v-model="fromGateLock.resource">
                      <el-checkbox label="刷卡"></el-checkbox>
                      <el-checkbox label="密码"></el-checkbox>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col
                  :span="2"
                  style="text-align: right;"
                >
                  <el-form-item>
                    <el-button type="primary"  @click="submitForm('fromGateLock')">提交</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </template>
          <!--会议室门禁end-->
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import videoTree from '../components/videoTree/videoTree.vue'
import { deviceList,deviceControl,deviceThreshold,repairDevice } from '@/api'
export default {
  title: '设备控制台',
  name: 'deviceConsole',
  components: {
    videoTree
  },
  data() {
    return {
      dateValue: new Date(),
      title_list: ['门禁', '监控', '水表', '电表',  '会议室门锁'],
      currIndex: 0,
      btnList: [
        [
          { name: '一次开门', key: 1 },
          { name: '持续开门', key: 1 },
          { name: '关门', key: 1 }
        ],
        [
          // { name: '打开', key: 1 },
          // { name: '关闭', key: 1 }
        ],
        [],
        [
          { name: '拉闸', key: 1 },
          { name: '合闸', key: 1 },
          { name: '抄表', key: 1 }
        ],
        
        [
          { name: '开锁', key: 1 },
          { name: '关锁', key: 1 }
        ]
      ],
      formData: {
        threshold: ''
      },
      fromGateLock:{
        resource:''
      },
      rules:{
        threshold:[
          { required: true, message: '请输入阀值', trigger: 'blur' },
        ]
      },
      fromGateLockRules:{
        resource:[
          { required: true, message: '请选择开锁方式', trigger: 'blur' },
        ]
      },
      checked: false,
      checkedNames: [],
      deviceLIst: [
        
      ],
      deviceListParams: {
        categoryType: '1',
        selectType:'0',
        deviceId: '',
        buildingId: '',
        floorId: ''
      }
    }
  },
  computed: {},
  watch: {
    checkedNames: {
      handler: function () {
        if (this.checkedNames.length != this.deviceLIst.length || this.deviceLIst.length==0) {
          this.checked = false
        } else {
          this.checked = true
        }
      },
      deep: true
    },
    deviceListParams: {
      handler: function () {
        this.deviceList()
      },
      deep: true
    }
  },

  mounted() {
    this.deviceList()
  },
  methods: {
    // 设备类型分组
    clickTitleList(i) {
      this.currIndex = i
      this.deviceListParams.categoryType = this.currIndex + 1
      this.deviceListParams.buildingId = ''
      this.deviceListParams.floorId = ''
      this.deviceListParams.deviceId = ''
      this.formData.threshold=""
     
    },
    // 点击树
    handleNodeClick(data) {
      if (data.level == 1) {
        // 点击楼栋
        this.deviceListParams.buildingId = data.id
        this.deviceListParams.floorId = ''
        this.deviceListParams.deviceId = ''
      } else if (data.level == 2) {
        //点击楼层
        this.deviceListParams.buildingId = ''
        this.deviceListParams.floorId = data.id
        this.deviceListParams.deviceId = ''
      } else if (data.level == 3) {
        // 点击设备
        this.deviceListParams.buildingId = ''
        this.deviceListParams.floorId = ''
        this.deviceListParams.deviceId = data.id
      }
    },
    /**
     * 设备列表
     */
    deviceList() {
      deviceList(this.deviceListParams).then((res) => {
        this.checkedNames=[]  //清空已选中的设备
        this.deviceLIst = res.data
      })
    },
    /**
     * 搜索
     */
    search(data) {
       this.deviceLIst = []
      if(data.length!=0){
        this.deviceListParams.buildingId = data[0].id
        this.deviceListParams.floorId = ''
        this.deviceListParams.deviceId = ''
        this.deviceList()
      }
    },

    /**
     * 点击分组
     */
    clickTag(data,i){
      this.deviceListParams.selectType=i
      this.search(data)
    },
    // 按钮列表
    deviceControl(item){
      let params={
        action:item.name,
        deviceId:this.checkedNames.join(',')
      }
      if(this.checkedNames.length != 0){
        deviceControl(params).then(res=>{
          this.$message.success(res.msg);
          this.checkedNames=[]
        })
      }else{
        this.$message({
          message: '必须选择一个设备！',
          type: 'error'
        });
      }
     
     
    },
    /**
     * 提交表单
     */
    submitForm(res){
      if(this.checkedNames.length != 0){
        let params={}
        if(res=='ruleFormElectric' || res=='ruleFormWater'){
           params={
            deviceId:this.checkedNames.join(","),
            thresholds:[{
              threshold:this.formData.threshold,
              thresholdType:'告警值'
            }]
          }
        }
        this.$refs[res].validate((valid) => {
          if (valid) {
            deviceThreshold(params).then(res=>{
              this.$message.success("提交成功");
              this.checkedNames=[]
              this.formData.threshold=""
            })
          } else {
            
            return false;
          }
        });
      }else{
        this.$message({
          message: '必须选择一个设备！',
          type: 'error'
        });
      }
      
    },
    // 复选框
    changeAllChecked: function () {
      var self = this
      if (!self.checked) {
        this.arr = []
        this.deviceLIst.forEach((item) => {
          this.checkedNames.push(item.deviceId)
        })
      } else {
        self.checkedNames = []
      }
    },
    statusChange(item) {
      let val = ''
      switch (item.runStatus) {
        case '告警':
          val = 'warning'
          break
        case '未启动':
          val = 'not_started'
          break
        case '故障':
          val = 'fault'
          break
        case '维修中':
          val = 'under_repair_icon fault'
          break

        default:
          break
      }
      return val
    },
    // 一键暴涨
    reportingBarrier(){
      let params=this.deviceLIst.filter(res=>{
        if(res.runStatus=="故障"){
          return res
        }  
      })
      if(params.length!=0){
        repairDevice(params).then(res=>{
          this.$message.success("报障成功！");
        })
      }else{
        this.$message({
          message: '没有故障的设备！',
          type: 'error'
        });
      }
      
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  overflow-x: hidden;
  .title {
    .title_list {
      display: flex;
      flex-wrap: wrap;
      margin: 18px;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 0;
      li {
        width: 44%;
        height: 40px;
        background: #fff;
        line-height: 40px;
        text-align: center;
        color: #000;
        font-size: 16px;
        cursor: pointer;
        margin-bottom: 20px;
        border-radius: 10px;
      }
      // li:nth-of-type(3),
      // li:nth-of-type(4) {
      //   margin-bottom: 0;
      // }
      .title_list_curr {
        background: #409eff;
        color: #fff;
      }
    }
  }
  .search {
    width: 97%;
    background: #ffffff;
    margin: 18px;
    h4 {
      font-size: 16px;
      color: #000;
      margin-bottom: 20px;
    }
    background: #fff;
    padding: 24px;
    .search_list {
      display: flex;
      width: 100%;
      justify-content: space-between;
      li:nth-of-type(1) {
        width: 30%;
      }
      li:nth-of-type(2) {
        width: 70%;
        text-align: right;
        span {
          margin-left: 15px;
          display: inline-block;
        }
      }
    }
  }
  .device_list {
    li {
      font-size: 14px;
      color: #000000;
      padding-bottom: 20px;
      span {
        i {
          width: 20px;
          height: 20px;
          background: #43b0ee;
          display: inline-block;
          vertical-align: middle;
          border-radius: 4px;
        }
      }
    }
    .checkbox_list {
      flex-wrap: wrap;
      display: flex;
      margin-top: 20px;
      width: 100%;
      // justify-content:space-between;
      li {
        margin-right: 12px;
        margin-bottom: 10px;
        width: 208px;
        height: 120px;
        padding: 10px;
        background: #38aced;
        opacity: 0.94;
        border-radius: 8px;
        font-size: 16px;
        color: #ffffff;
        cursor: pointer;
        p {
          padding: 16px 0 0px;
        }
        input {
          margin: 0;
          margin-bottom: 10px;
        }
      }
      li:nth-child(5n + 5) {
        margin-right: 0;
      }
    }
    .all {
      font-size: 16px;
      color: #cecece;
      input {
        vertical-align: middle;
        margin-right: 8px;
      }
    }
    .warning {
      background: #fece6c !important;
    }
    .not_started {
      //未启动
      background: #788fff !important;
    }
    .fault {
      // 警告
      background: #f65164 !important;
    }
    .under_repair {
      background-color: #72e1f4;
      background-image: url('~@/assets/images/home/weixiu.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70%;
    }
    .under_repair_icon {
      background-image: url('~@/assets/images/home/weixiu.png') !important;
      background-repeat: no-repeat !important;
      background-size: 14% !important;
      background-position: 174px 10px !important;
    }
  }
  .set_device {
    background: #fff;
  }
}
</style>
