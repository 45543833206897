<template>
  <!--  普通的表单 -->
  <el-dialog :close-on-click-modal="false" :visible.sync="showEditAll" width="50%" :title="isAdd?'新增':'编辑'">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="preSaveForm" v-if="showEditAll">
      <template #tableList>
        <tableRefund v-model="rowForm.tableList" />
      </template>
      <template #purCodeText>
        <p v-if="isShowError" class="errorText">该订单号不存在！</p>
      </template>
      <template #uploadFile="row">
        <upload-file ref="Upload" :autoUpload="true" :accept='accept' :keyItem="row.data" :initFileList="initFileList" :fileSize="10" />
      </template>
    </ja-form>
  </el-dialog>
</template>
<script>
import tableRefund from './tableRefund.vue'
import { addRefundItem, getRefundData } from '@/api'

export default {
  inject: ['labelWidth', 'getInit',],
  components: {
    tableRefund
  },
  data() {
    return {
      rowFormData: [
        { input_type: 'input', label: '订单号', key: 'orderCode', col: 24, styleWidth: "36%", rightBox: { input_type: 'customize', customize: 'purCodeText' }, },
        { input_type: 'input', label: '退款内容', key: 'tableList', maxlength: 20, col: 22, input_type: 'customize', customize: 'tableList', isRequired: true },
        { input_type: 'input', label: '退款金额', key: 'refundCost', disabled: true },
        { input_type: 'select', label: '原因', key: 'reason', options: [] },
        { input_type: 'input', label: '备注', key: 'remark', type: 'textarea', col: 22, showWordLimt: true, rows: 5, maxRows: 5, maxlength: 500 },
        { input_type: 'customize', customize: 'uploadFile', label: '上传附件', key: 'pictureUrl', col: 24, },
      ],
      rowForm: {},
      rules: {
        orderCode: [{ required: true, message: '请输入订单号', trigger: 'blur' }],
        reason: [{ required: true, message: '请选择退款原因', trigger: 'change' }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      indexObj: {},

      debounce_fun: null,
      isShowError: false, //是否展示错误提示

      initFileList: [],
      accept: this.$config.imgType,
    }
  },
  watch: {
    'rowForm.orderCode'(newVal, oldVal) {
      this.debounce_fun(newVal)
    },
    'rowForm.tableList': {
      deep: true,
      handler(arr) {
        if (arr && arr.length > 0) {
          let sum = 0
          arr.forEach(item => {
            let prev = this.util.accMul(item.dishesPrice || 0, item.num)
            sum = sum + prev
          });
          this.$set(this.rowForm, 'refundCost', sum)
        } else {
          this.$set(this.rowForm, 'refundCost', '')
        }
      }
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
    this.util.rqOptionsList({ who: this.rowFormData[this.indexObj.reason], rqUrl: "/sys/dictData/order_refund_reason", resRule: 'data' })
  },
  mounted() {
    this.debounce_fun = this.util.debounce(this.getCodeList, 500);
  },
  methods: {
    append() {
      this.isAdd = true
      this.initFileList = []
      this.rowForm = { tableList: [] }
      this.showEditAll = true
    },
    saveForm() {
      if (this.isShowError) {
        this.$message.warning('该订单号不存在,请先清空')
        return
      }
      // 检查 退款份数
      let arr = this.rowForm.tableList
      let list_0 = (arr || []).filter(v => v.num == 0)
      if (list_0.length === arr.length && arr.lengt !== 0) {
        this.$message.warning('退款份数，不能全为0')
        return
      }
      this.loadingForm = true
      let rqMethod = this.isAdd ? addRefundItem : ''
      let rqObj = { ...this.rowForm,orderRefundDetailReqList: [...arr] }
      rqMethod(rqObj).then(res => {
        this.loadingForm = false
        this.$message.success(res.data || '提交成功')
        this.showEditAll = false
        this.getInit()
      }).catch(error => {
        this.loadingForm = false
      })
    },
    preSaveForm() {
      let fileList = this.$refs.Upload.fileList
      if (fileList.length == 0) {
        this.rowForm.pictureUrl = ''
        this.saveForm()
      } else {
        let isExist = fileList.find(item => item.status != 'success')
        if (!isExist) {
          let imgArr = fileList.map(item => {
            if (item.response && item.response.data) {
              return item.response.data
            }
          })
          this.rowForm.pictureUrl = imgArr.join(',')
          this.saveForm()
        } else {
          this.$message.warning("存在文件尚未完成上传")
          return
        }
      }
    },
    // 获取采购编号的下拉列表
    getCodeList(val) {
      if (val === undefined || val === '' || val && val.trim() === '') {
        this.isShowError = false
        this.$set(this.rowForm, 'tableList', [])
      } else {
        this.loadingForm = true
        getRefundData(val).then(res => {
          this.loadingForm = false
          let arr = res.data || []
          this.$set(this.rowForm, 'tableList', arr)
          this.isShowError = arr.length == 0
        }).catch(err => {
          this.loadingForm = false
        })
      }
    },
  },

}
</script>
<style lang="scss" scoped>
.errorText {
  margin: 0 auto;
  color: #f56c6c;
  font-size: 13px;
}
</style>