<template>
  <div class="edit">
    <el-dialog
      title="已指派管理员"
      :visible.sync="show"
      width="60%"
      @close="close"
      :before-close="close"
    >
      <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @getInit="getInit"
      @search="search"
      @rowDelete="rowDelete"
      :showSelect="true"
      @handleSelectionChange="handleSelectionChange"
      @reset="reset"
    >
      <template v-slot:right>
         <el-button type="primary" @click="delAdminList" size="small" plain>批量删除</el-button>
         <el-button @click="selectUser" type="primary" size="small" plain>添加用户</el-button>
      </template>
    </ja-table>
    </el-dialog>
  </div>
</template>

<script>
import JaForm from '@/components/ja-form'
import table from '@/views/mixins/table'

import {
  getAdminList,
  delAdminList,
} from '@/api'
export default {
  name: 'EditOccupant',
  mixins: [table],
  components: {
    JaForm
  },
  data () {
    return {
      filterData:{
        pageIndex: 1,
        pageSize: 10
      },
       conditionData: [
        {
          input_type: 'input',
          key: 'adminName',
          styleWidth: '200px',
          
          attribute: {
            placeholder: '用户姓名',
            size: 'small'
          }
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '用户姓名',
          prop: 'employeeName',
          width: 300,
          attribute: { showOverflowTooltip: true, align: 'center' }
        },
        {
          label: '所属公司',
          prop: 'company'
        },
        {
          label: '所属部门',
          prop: 'department'
        },
        {
          label: '操作',
          input_type: 'button',
          width: 100,
          attribute: {
            fixed: 'right',
            align: 'center'
          },
          list: [
            { text: '删除', method: 'rowDelete' },
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      assignAdminList:[]
    }
  },
  props: {
    title: String,
    id: String,
    show: {
      type: Boolean,
      default: false
    },
    parkId:Number,
  },
  watch:{
  
  },
  created () {
  },
  methods: {
    /** 保存表单 */
    saveForm () {
      this.close()
    },
    /** 关闭弹出框 */
    close () {
      this.$emit('closeAdmin', false)
    },
    // 初始化
    getInit() {
      // console.log("哈哈哈----")
      this.loading = true

      // 查询园区列表信息
      this.filterData.parkId=this.parkId
      getAdminList(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    // 新增
    append() {},
    // 删除某一项
    rowDelete(row) {
      this.$confirm('是否确认删除此社区管理员？').then((res) => {
        // console.log('row', [row.id])
        delAdminList([row.id]).then((res) => {
          this.$message.success('已删除')
          this.getInit()
          this.$parent.getInit()
        })
      })
    },
    // 多选
    handleSelectionChange(val){

      console.log("val---",val)
      this.assignAdminList=val
    },
    // 点击添加用户
    selectUser(){
      // console.log("点击添加用户")
      this.$emit("selectUser",true)
    },
    // 删除全部
    delAdminList(){
      if(this.assignAdminList.length==0){
        this.$message.error('至少要选择一个用户哦！');
        return false
      }
      let assignAdminList=this.assignAdminList.map(item=>{
        return item.id
      })
      this.$confirm('是否确认删除已选择的社区管理员？').then((res) => {
        delAdminList(assignAdminList).then((res) => {
          if(res.code==200){
            this.$message.success(res.msg)
            this.getInit()
            this.$parent.getInit()
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scope>
</style>
