<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset"></ja-table>
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import { rqVistorCountList } from '@/api'

export default {
  title: "访客申请统计",
  mixins: [table],
  name: 'index',
  data() {
    return {
      conditionData: [
        { input_type: 'input', key: 'visitCompany', attribute: { placeholder: '拜访公司', } },
        { input_type: 'input', key: 'visitDept', attribute: { placeholder: '拜访部门', } },
        { input_type: 'date', type: 'daterange', key: 'time', value: 'time', startPlaceholder: '周期开始日期', endPlaceholder: '周期结束日期', valueFormat: 'yyyy-MM-dd' },

        { normalBtn: 'search', },
        { normalBtn: 'reset', },
        { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas2, rqUrl: '/visitor/apply/count/export', rqMethod: 'post' }, limit: 'visitor::applyStatistics::download' }
      ],
      columns: [
        { label: '拜访公司', prop: 'visitCompany', attribute: { showOverflowTooltip: false, } },
        { label: '拜访部门', prop: 'visitDept', attribute: { showOverflowTooltip: false, } },
        // { label: '接待人', prop: 'nickname', },
        { label: '访客申请次数', prop: 'applyNum' },
        { label: '通过次数', prop: 'passNum' },
        { label: '拒绝次数', prop: 'refuseNum' },
        { label: '访客总人数', prop: 'visitorNum' },
        // { label: '入园人数', prop: 'intoNum' },
        // { label: '未入园人数', prop: 'waitNum' },
      ],
      tableData: []
    }
  },
  methods: {
    rqParmas2() {
      let obj = this.rqParmas()
      if (obj.time) {
        obj.visitorStartTime = obj.time[0]
        obj.visitorEndTime = obj.time[1]
        delete obj.time
      }
      return obj
    },
    getInit() {
      this.loading = true
      let obj = this.rqParmas2()
      rqVistorCountList(obj).then(res => {
        this.tableData = res.data.data
        this.total = res.data.total
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
  }
}
</script>