<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog :visible.sync="showDetail" title="查看问卷调查" :width="`40%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='9'>
        <template #persons>
          <div class="persons" @click="showJoinNum">受邀100人，参与90人，未参与10人</div>
        </template>
      </ja-detail>

      <!-- 调查问卷列表 -->
      <answer-list :answerList="detailData.answerList" @showLeaveWord="showLeaveWord"/>
    </el-dialog>

    <!-- 调查人数 弹窗-->
    <number-of-survey :isShowNumberSurvey.sync="isShowNumberSurvey" ref="personsNum" />

    <!-- 查看留言 弹窗-->
    <leaveWord :isShowLeaveWord.sync="isShowLeaveWord"/>
  </div>
</template>
<script>
import numberOfSurvey from './detailChild/numberOfSurvey.vue'
import answerList from './detailChild/answerList.vue'
import leaveWord from './detailChild/leaveWord.vue'
// import { getStockInside } from '@/api'
export default {
  inject: ['labelWidth', 'getInit',],
  components: { numberOfSurvey, answerList ,leaveWord},
  data() {
    return {
      showDetail: false,
      detailOptions:
        [
          { label: '标题', key: 'title', },
          { label: '说明', key: 'content', col: 24 },
          { label: '有效期', key: 'receiverName', },
          { label: '问卷公开', key: 'status', },
          { label: '结果公开', key: 'methodText', },
          // { label: '调查人数', key: 'senderName',col:24 },
          { type: 'customize', customize: 'persons', label: '调查人数', key: 'senderName', col: 24 },
        ],
      detailData: {},
      indexObj: {},

      isShowNumberSurvey: false, // 是否展示调查人数
      isShowLeaveWord: false, // 是否弹出留言弹窗
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.detailOptions })
  },
  methods: {
    rowDetail(row) {
      this.showDetail = true
      this.detailData = { 
        answerList: [
          {
            title: '请问您对食堂的满意评分', type: 0, list: [
              { name: '满意', num: 49, percent: 10, leaveWordNum: 5, },
              { name: '一般', num: 49, percent: 40, leaveWordNum: 0, },
              { name: '不满意', num: 49, percent: 50, leaveWordNum: 2, },
            ]
          },
          {
            title: '请问您期望的菜式', type: 1, list: [
              { name: '粤菜', num: 49, percent: 32, leaveWordNum: 0, },
              { name: '湘菜', num: 49, percent: 18, leaveWordNum: 0, },
              { name: '川菜', num: 49, percent: 50, leaveWordNum: 2, },
            ]
          },
        ]
       }
    },
    // 点击了调查人数
    showJoinNum() {
      this.$refs.personsNum.rowDetail()
      this.isShowNumberSurvey = true
    },
    // 点击了 查看留言
    showLeaveWord(val){
      this.isShowLeaveWord = true
    }
  }
}
</script>
<style lang='scss' scoped>
.persons {
  color: #409eff;
  cursor: pointer;
}
</style>