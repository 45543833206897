import http from './plugin/ajax'

// =========  餐品采购 ===========

// 餐品采购管理
export const getPurchase = (data) => http.get('/foods/purchase',data)
export const addPurchase = (data) => http.post('/foods/purchase',data)
export const editPurchase = (data) => http.put('/foods/purchase',data)
export const delPurchase = (data) => http.delete('/foods/purchase/'+data)
export const getPurchaseDetail = (data) => http.get('/plan/manager/supplier/food',{params:data})
export const getPlanPersonInfo = (data) => http.get('/plan/manager/getCreatePlanUserInfo',{params:data})

// 产品计划
export const getDishesAndMenuList = (data) => http.post('/plan/manager/getDishesAndMenuList',data) // 获取套餐/菜式
export const getManagerList = (data) => http.post('/plan/manager',data) // 获取套餐/菜式
export const addManagerList = (data) => http.post('/plan/manager/add',data) // 添加计划列表
export const delManagerList = (data) => http.delete('/plan/manager/'+data) // 获取套餐/菜式
export const detailManager = (data) => http.post('/plan/manager/detail',data) // 获取套餐/菜式
export const editManager = (data) => http.put('/plan/manager',data) // 获取套餐/菜式

export const getCheckPlan = (data) => http.post('/plan/manager/getCheckPlan',data) // 审核列表
export const updateStatus = (data) => http.put('/plan/manager/updateStatus',data) // 提交审核
export const getPlandetailMenu = (data) => http.post('/plan/manager/getPlandetailMenu',data) // 提交审核


// 材料库存
export const getStock = (data) => http.get('/foods/stock',data)
export const getStockDetail = (data) => http.get('/foods/stock/detail',data)
export const addStockDetail = (data) => http.post('/foods/stock/detail',data)
export const getStockFoodDetail = (data) => http.get('/foods/stock/food/detail',data)
export const getPlanFood = (data) => http.get('/foods/purchase/detail',data)


// 采购详情

export const getStockInside = (data) => http.get('/foods/purchase/stock/inside',data)
//获取食堂采购对比统计的数据
export const getCompare = (data) => http.post('/purchase/compare/detail',data)