<template>
  <div class="allwh minWidth1000">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" @append="append" @getInit="getInit" @search="search" @reset="reset">
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="showEditAll" width="50%" @close="close" :title="titleText">
      <ja-form :rules="rules" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :allDisabled="allDisabled" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll" ref="jaForm" v-loading="loading_form.isShow" :element-loading-text="loading_form.text" :element-loading-spinner="loading_form.icon" :element-loading-background="loading_form.bg" :element-loading-custom-class="loading_form.formLoading">
        <template #purCodeText>
          <p v-if="isShowError" class="errorText">该采购编号不存在！</p>
        </template>
      </ja-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  getStockDetail,
  addStockDetail,
  getStockFoodDetail,
  getPlanFood
} from '@/api'
import table from '@/views/mixins/table'
export default {
  title: '材料出入库明细',
  mixins: [table],
  name: 'index',
  data() {
    return {
      show: false,
      showUser: false,
      allDisabled: false,
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'location',
          attribute: {
            placeholder: '库存位置',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'foodName',
          attribute: {
            placeholder: '材料名称',
            size: 'small'
          }
        },
        {
          input_type: 'select',
          key: 'type',
          attribute: {
            placeholder: '类型',
            size: 'small',
            clearable: true
          },
          options: [{ label: "采购入库", value: 0 }, { label: "盘点入库", value: 1 }, { label: "出库", value: 2 }, { label: "盘点出库", value: 3 }]
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '新增',
          input_type: 'button',
          method: 'append',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-plus',
            plain: true
          },
          limit: 'purchase::stockDetail::add'
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '材料名称',
          prop: 'foodName'
        },
        {
          label: '数量',
          prop: 'num'
        },
        {
          label: '单位',
          prop: 'unit'
        },
        {
          label: '库存位置',
          prop: 'location'
        },
        {
          label: '类型',
          prop: 'typeLabel'
        },
        {
          label: '操作人',
          prop: 'nickname'
        },
        {
          label: '操作时间',
          prop: 'createTime'
        },
      ],
      tableData: [],
      loading: false,
      total: 30,
      titleText: '新增',
      initFileList: [],

      rowFormData: [
        {
          input_type: 'input',
          label: '采购编号',
          key: 'purchaseCode',
          col: 24,
          styleWidth: "36%",
          changeMethod: 'purCodeChange',
          rightBox: {
            input_type: 'customize',
            customize: 'purCodeText'
          },
        },
        {
          input_type: 'select',
          label: '材料名称',
          key: 'foodName',
          options: [],
        },
        {
          input_type: 'text2',
          label: '计划明细',
          key: 'detail',
          col: 18
        },
        {
          input_type: 'number',
          label: '数量',
          key: 'num',
          min: 1,
          rightBox:{
            text:''
          }
        },
        {
          input_type: 'input',
          label: '批次成本价',
          key: 'cost'
        },
        {
          input_type: 'radio',
          label: '类型',
          key: 'type',
          col: 24,
          options: [
            {
              label: '采购入库',
              value: 0
            },
            {
              label: '盘点入库',
              value: 1
            },
            {
              label: '订单出库',
              value: 2
            },
            {
              label: '盘点出库',
              value: 3
            }
          ],
        },
        {
          input_type: 'input',
          label: '库存位置',
          key: 'location'
        },
        {
          input_type: 'date',
          label: '日期',
          key: 'date',
          value: 'date',
        },
      ],
      checkImgSuccess: false,
      isExist: null,
      rules: {
        foodName: [{ required: true, message: '请选择材料名称', trigger: 'change' }],
        number: [{ required: true, message: '请完善数量', trigger: 'change' }],
        type: [{ required: true, message: '请选择类型', trigger: 'change' }],
        cost: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkNumber({ min: 0, fixedNum: 2 }) }],
        date: [{ required: true, message: '请选择日期', trigger: 'change' }],
        location: [{ required: true, message: '请输入库存位置', trigger: 'blur' }],

      },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`,
      indexFormObj: {},
      debounce_fun: null,
      allFood: [], // 所有的采购名称
      isShowError: false, //是否展示错误提示
      isRqLoading: false
    }
  },
  watch: {
    'rowForm.purchaseCode'(newVal, oldVal) {
      !this.isRqLoading ? this.isRqLoading = true : false
      this.debounce_fun(newVal)
    },
    'rowForm.foodName'(newVal, oldVal) {
      console.log('v===',newVal)
      this.getPlanDetail()
      let item = this.rowFormData[this.indexFormObj.foodName].options.find(v=>v.label === this.rowForm.foodName)
      this.$set(this.rowFormData[this.indexFormObj.num].rightBox,'text',item?item.unit:'')
    },

  },
  created() {
    // 这种方式，不用通过索引
    this.indexFormObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
    this.util.rqOptionsList({ who: this.rowFormData[this.indexFormObj.foodName], rqUrl: "/dishes/foods/manager", labelName: 'foodName', valueName: 'foodName',rqMethod:'post', rqData: { pageIndex: 1, pageSize: 200  },resRule:'data' ,isReturnAll:true}).then(res => {
      this.allFood = res
    })
  },
  mounted() {
    this.debounce_fun = this.util.debounce(this.getCodeList, 500);
  },
  methods: {
    getInit: function () {
      this.loading = true
      // 查询列表信息
      getStockDetail({ params: this.filterData }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        }
        this.loading = false
      })
    },
    // 新增
    append() {
      this.titleText = '新增'
      this.isAdd = true
      this.rowForm = {
        num: 1,
        date: this.$moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      }
      this.isShowError = false
      this.isRqLoading = false
      this.datas = []
      this.rowFormData[this.indexFormObj.cost].disabled = false
      this.util.clearSelectOptions(this.rowFormData)
      this.showEditAll = true
    },

    saveForm: function () {
      // 保存
      // console.log("this.rowForm11111",this.rowForm)
      if(this.isShowError){
        this.$message.warning('该采购编号不存在,请先清空')
        return
      }
      this.loading_form.isShow = true
      
      //  添加信息
      addStockDetail({
        ...this.rowForm,
        unit:this.rowFormData[this.indexFormObj.num].rightBox.text
      })
        .then((res) => {
          this.loading_form.isShow = false
          if (res.code === 200) {
            this.$message.success('新增成功')
          } else {
            this.$message.warning(res.msg)
          }

          this.showEditAll = false
          this.getInit()
        })
        .catch((error) => {
          console.log(error)
          this.loading_form.isShow = false
        })
    },
    // 关闭表单弹窗
    close() {
    },
    // 获取采购编号的下拉列表
    getCodeList(val) {
      console.log('val==>', val)

      if (val === undefined || val === '' || val && val.trim() === '') {
        this.resetSomeData({ isShowError: false, isClearDetail: true, arr: this.allFood })
      } else {
        const paramJson = { params: { pageIndex: 1, pageSize: 200, purchaseCode: val } }
        getPlanFood(paramJson).then(res => {
          let data = res.data.data, newArr = this.allFood
          if (data.length != 0) {
            newArr = data.map(v => {
              return {
                label: v.foodName,
                value: v.foodName,
                ...v
              }
            })
          }
          this.resetSomeData({ isShowError: data.length == 0, isClearDetail: data.length == 0, arr: newArr })
          this.isRqLoading = false
          this.getPlanDetail()
        }).catch(err => {
          this.isRqLoading = false
        })
      }
    },
    // 重置 计划明细 以及 材料列表、是否清除材料名称
    resetSomeData({ isShowError, isClearDetail, arr }) {
      this.rowFormData[1].options = arr
      isClearDetail ? this.$set(this.rowForm, 'detail', '') : ''
      this.isShowError = isShowError

      let isExist = arr.find(v => v.value == this.rowForm.foodName)
      if (!isExist && this.rowForm.foodName) {
        this.$set(this.rowForm, 'foodName', '')
      }
      this.dealTypeList()
    },
    // 获取 “计划明细”
    getPlanDetail() {
      if (!this.isShowError && this.rowForm.purchaseCode && this.rowForm.foodName && !this.isRqLoading) {
        const paramJson = { purchaseCode: this.rowForm.purchaseCode, foodName: this.rowForm.foodName }
        getStockFoodDetail({ params: paramJson }).then((res) => {
          if (res.code === 200 && res.data) {
            let { unit, planNum, putNum, waitNum } = res.data
            let text = '计划数量：' + planNum + unit + ' 已入库数量：' + putNum + unit + ' 待入库数量：' + waitNum + unit
            this.$set(this.rowForm, 'detail', text)
          }
        })
        let options = this.rowFormData[this.indexFormObj.foodName].options

        let foodItem = options.find( item => item.value == this.rowForm.foodName)
        this.$set(this.rowForm,'cost',foodItem.cost)

      } else {
        this.$set(this.rowForm, 'detail', '')
      }
    },
    // 处理类型 是否禁用
    dealTypeList() {
      let arr = this.rowFormData[this.indexFormObj.type].options
      let isFromExistCode = Boolean(!this.isShowError && this.rowForm.purchaseCode)
      this.rowFormData[this.indexFormObj.type].options = arr.map(item => {
        return {
          ...item,
          disabled: item.label.includes('盘点') && isFromExistCode ? true : false
        }
      })
      let type = this.rowForm.type
      if ((type == 1 || type == 3) && isFromExistCode) {
        this.$set(this.rowForm, 'type', '')
      }
      this.$set(this.rowFormData[this.indexFormObj.cost], 'disabled', isFromExistCode)
      if(isFromExistCode){
        this.$set(this.rowForm,'cost','')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}
.errorText {
  margin: 0 auto;
  color: #f56c6c;
  font-size: 13px;
}
</style>
