import util from '@/util/util'
/** 用户管理搜索栏 */
export const userQueryColumn = [
  { input_type: 'select', key: 'companyId', attribute: { placeholder: '所属公司', size: 'small' }, options: [] },
  { input_type: 'input', key: 'nickname', attribute: { placeholder: '人员姓名', size: 'small' } },
  { normalBtn: 'search', key: 'search' },
  { normalBtn: 'add', key: 'add',limit:'user::manage::add' },
  { normalBtn: 'reset', key: 'reset' }
]

export const userQueryColumnMap = function(){
  const map = {};
  userQueryColumn.forEach((element, index) => {
    map[element.key] = index
  });
  return map
}()

/** 用户管理表头栏 */
export const userColumn = [
  { label: '人员名称', prop: 'nickname', attribute: { showOverflowTooltip: true, align: 'center' } },
  { label: '登录用户名', width:120, prop: 'loginName', attribute: { showOverflowTooltip: true, align: 'center' } },
  { label: '人员类型',width:100, prop: 'employeeType' },
  { label: '职位', prop: 'positionName', attribute: { showOverflowTooltip: true, align: 'center' } },
  // { label: '性别', prop: 'genderLabel' },
  { label: '所属公司', width:150, prop: 'companyName', attribute: { showOverflowTooltip: true, align: 'center' } },
  { label: '所属部门', prop: 'deptName',width:150, attribute: { showOverflowTooltip: true, align: 'center' } },
  { label: '状态', prop: 'statusLabel' },
  // { label: '身份证号', prop: 'idCard', attribute: { showOverflowTooltip: true, align: 'center' } },
  { label: '手机号', prop: 'mobile', attribute: { showOverflowTooltip: true, align: 'center' } },
  { label: '用户角色', prop: 'roleName', attribute: { showOverflowTooltip: true, align: 'center' } },
  { label: '通行卡号', prop: 'accessCard' },
  { label: '人脸图像', prop: 'faceImg', slotName: 'img' },
  { label: '更新人', prop: 'updateBy', attribute: { showOverflowTooltip: true, align: 'center' } },
  { label: '更新时间', prop: 'updateTime', attribute: { showOverflowTooltip: true, align: 'center' } },
  {
    label: '操作',
    input_type: 'button',
    width: 200,
    attribute: { fixed: "right", align: 'center' },
    list: [
      { text: '编辑', method: 'rowEdit',limit:'user::manage::update' },
      { text: '同步饭卡', method: 'syncDr',limit:'user::manage::sameMealcard'},
      { text: '删除', method: 'rowDelete',limit:'user::manage::del' }]
  }
]

/** 新增/编辑用户表单项 */
export const userFormData = [
  { input_type: 'input', label: '人员姓名', key: 'nickname' },
  { input_type: 'input', label: '登录用户名',  key: 'loginName', hide: true, disabled: true },
  // { input_type: 'select', label: '性别', key: 'gender', options: [{ "value": 0, "label": "女" }, { "value": 1, "label": "男" }, { "value": 2, "label": "未知" }] },
  { input_type: 'select', label: '所属公司', key: 'companyId', method: 'change2', options: [] },
  { input_type: 'select', label: '所属部门', key: 'deptId', method: 'change2', options: [] },
  { input_type: 'select', label: '人员类型', key: 'employeeType', options: [{ "value": "内部人员", "label": "内部人员" }, { "value": "外部人员", "label": "外部人员" }] },
  { input_type: 'input', label: '职位', key: 'positionName' },
  { input_type: 'select', label: '状态', key: 'status', options: [{ "value": 0, "label": "在职" }, { "value": 1, "label": "离职" }] },
  // { input_type: 'input', label: '身份证号', key: 'idCard' },
  { input_type: 'input', label: '通行卡号', key: 'accessCard' },
  { input_type: 'input', label: '手机号', key: 'mobile', col: 24, styleWidth: 'auto', rightBox: { input_type: 'customize', customize: 'statusPhone' } },
  { input_type: 'input', label: '初始化密码', type: 'password', key: 'password', hide: true, showPassword: true },
  // { input_type: 'select', label: '角色选择', key: 'roleId', options: []},
  { input_type: 'customize', customize: 'upload', label: '人脸图像', key: 'faceImg', col: 24, formType: 13,  },
]
 
export const userFormDataMap = function(){
  const map = {};
  userFormData.forEach((element, index) => {
    map[element.key] = index
  });
  return map
}()

/** 用户新增/编辑校验规则 */
export const userFormRules = {
  nickname: [{ required: true, message: '请输入人员姓名', trigger: 'blur' }],
  // gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
  companyId: [{ required: true, message: '请选择所属公司', trigger: 'change' }],
  deptId: [{ required: true, message: '请选择所属部门', trigger: 'change' }],
  // employeeType: [{ required: true, message: '请选择人员类型', trigger: 'change' }],
  status: [{ required: true, message: '请选择状态', trigger: 'change' }],
  // idCard: [{ required: true, trigger: 'blur', validator: util.formRules.checkIdCard() }],
  mobile: [{ required: true, trigger: 'blur', validator: util.formRules.checkPhone() }],
  roleId: [{ required: true, message: '请选择角色', trigger: 'change' }],
  password: [{ required: true, message: '请输入初始化密码', trigger: 'change' }]
}

/** 用户通行权限设置项目 */
export const userForm2Data = [
  { input_type: 'select', label: '所属楼栋', key: 'building_id', options: [] },
  { input_type: 'select', label: '所属楼层', key: 'floor_id', options: [] }
]
