<template>
  <!-- 设备列表 -->
  <!-- rowDetail: 详情操作->详情面板 -->
  <!-- append: 新增操作->新增面板 -->
  <!-- rowEdit: 编辑操作->编辑面板 -->
  <!-- rowDelete: 删除操作->删除面板 -->
  <!-- getInit: 获取ajax数据 -->
  <!-- outPut_: 导出数据 -->
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      @getInit="getInit"
      @search="search"
      @rowDelete="rowDelete"
      @assignAdmin="assignAdmin"
      @reset="reset"
    >
     <template #parkName="{row}">
        <div class="firstcell el-button--text" @click="seeDetails(row)">{{row.parkName}}</div>
      </template>
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="40%"
      @close="allDisabled=false"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :allDisabled="allDisabled"
        @reset="showEditAll = false"
        @save="saveForm"
        v-if="showEditAll"
        ref="jaForm"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
        @changeProvince="changeProvince"
      >

      </ja-form>
    </el-dialog>
    <assign-admin :show.sync="show" ref="assignAdmin" v-if="show" :parkId="parkId" @selectUser="selectUser" @closeAdmin="closeAdmin"></assign-admin>
    <select-user :parkId="parkId" :show.sync="showUser" v-if="showUser" @closeUser="closeUser"></select-user>
  </div>
</template>
<script>
import {
  getParkList,
  addPark,
  updatePark,
  delPark,
  getDepartment
} from '@/api'
import table from '@/views/mixins/table'
import assignAdmin from './components/assignAdmin.vue'
import selectUser from './components/selectUser.vue'
export default {
  title: '社区管理',
  mixins: [table],
  name: 'index',
  components: {
    assignAdmin,
    selectUser
  },
  data() {
    return {
      show: false,
      showUser:false,
      parkId:1,
      allDisabled:false,
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
        // cityId:''
      },
      conditionData: [
        {
          input_type: 'select',
          key: 'provinceId',
          attribute: { placeholder: '省', size: 'small' },
          options: [],
          method: 'selectFun',
          relevance: {
            sonKey: 'cityId', // 必填
            otherSonKey: ['districtId'], // 若还涉及到孙子字段
            This: this, // 必填
            // childrenKey:'children',// 非异步的 情况下。拿的是自己的 的哪个字段.默认 是 children 。
            dealOptionsParma: {
              // 若还要对子元素的值，进行处理。（如果本来就是 {label,value} 字段，就不必写 dealOptionsParma 字段）
              labelName: 'label',
              valueName: 'value',
              isReturnAll: true
            }
          }
        },
        {
          input_type: 'select',
          key: 'cityId',
          relevance: { sonKey: 'districtId', This: this }
        },
        { input_type: 'select', key: 'districtId' },
        {
          input_type: 'input',
          key: 'search',
          styleWidth: '200px',
          
          attribute: {
            placeholder: '社区名称 / 社区地址 / 社区管理员',
            size: 'small'
          }
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '新增',
          input_type: 'button',
          method: 'append',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-plus',
            plain: true
          },
          limit: 'park::management::add'
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '社区名称',
          prop: 'parkName',
          width: 300,
          slotName:"parkName",
          name:"parkName",
          attribute: { showOverflowTooltip: true, align: 'center' }
        },
        {
          label: '省',
          prop: 'province'
        },
        {
          label: '市',
          prop: 'city'
        },
        {
          label: '区',
          prop: 'district',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '社区地址',
          prop: 'address',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '社区管理员',
          prop: 'admin'
        },
        {
          label: '联系电话',
          prop: 'phone'
        },
        {
          label: '操作',
          input_type: 'button',
          width: 180,
          attribute: {
            fixed: 'right',
            align: 'center'
          },
          list: [
            {
              text: '编辑',
              method: 'rowEdit',
              limit: 'park::management::update'
            },
            { text: '删除', method: 'rowDelete',limit:'park::management::del' },
            { text: '指派管理员', method: 'assignAdmin',limit:'park::management::assign' }
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      titleText: '编辑',
      rowFormData: [
        {
          input_type: 'input',
          label: '社区名称',
          
          key: 'parkName'
        },
        {
          input_type: 'select',
          label: '省',
          key: 'provinceId',
          options: [],
          relevance: {
            type: 'select', // 必填 -----注意！！！！！！！！！！
            sonKey: 'cityId', // 必填
            otherSonKey: ['districtId'], // 若还涉及到孙子字段
            This: this, // 必填
            // childrenKey:'children',// 非异步的 情况下。拿的是自己的 的哪个字段.默认 是 children 。
            dealOptionsParma: {
              // 若还要对子元素的值，进行处理。（如果本来就是 {label,value} 字段，就不必写 dealOptionsParma 字段）
              labelName: 'label',
              valueName: 'value',
              isReturnAll: true
            }
          }
        },
        {
          input_type: 'select',
          label: '市',
          key: 'cityId',
          options: [],
          relevance: { sonKey: 'districtId', This: this, type: 'select' }
        },
        { input_type: 'select', options: [], label: '区', key: 'districtId' },
        {
          input_type: 'input',
          label: '详细地址',
          maxlength: 30,
          key: 'address'
        },
        {
          input_type: 'input',
          label: '联系电话',
          key: 'phone'
        },
        {
          input_type: 'select',
          label: '管理部门',
          key: 'deptId',
          method: 'changeCity',
          multiple: true,
          col: 22,
          options: function () {
            return getDepartment({ pageIndex: 1, pageSize: 200 }).then(
              (res) => {
                if (res.code == 200) {
                  let data = res.data.data.map((item) => {
                    return {
                      label: item.companyName + '-' + item.deptName,
                      value: item.id
                    }
                  })
                  return data.length != 0 ? data : []
                }
              }
            )
          }
        },
        {
          input_type: 'input',
          type: 'textarea',
          label: '备注',
          key: 'remark',
          col: 22
        }
      ],
      rules: {
        parkName: [
          {
            required: true,
            message: '请输入社区名称',
            trigger: 'blur'
          }
        ],
        provinceId: [
          {
            required: true,
            message: '请选择社区省份',
            trigger: 'change'
          }
        ],
        cityId: [
          {
            required: true,
            message: '请选择社区城市',
            trigger: 'change'
          }
        ],
        districtId: [
          {
            required: true,
            message: '请选择社区所在区',
            trigger: 'change'
          }
        ],
        phone: [
          {
            // required: true,
            // message: '请输入正确的联系电话',
            trigger: 'blur',
            // message: '请选填写电话',
            phoneType:3,
            validator: this.util.formRules.checkPhone()
          }
        ],
        address: [
          {
            required: true,
            message: '请填写详细地址',
            trigger: 'blur'
          }
        ]
      },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`,
      // 图片相关
      headers: {},
      dialogVisible: false,
      dialogObj: null,
      fileList: [],
      isSearchIng: false,
      isAdd: true
    }
  },
  created() {

  },
  mounted() {
    // console.log('jd')
    this.util.rqOptionsList({
      who: [this.conditionData[0], this.rowFormData[1]],
      rqUrl: '/sys/region',
      labelName: 'label',
      valueName: 'value',
      resRule: 'data',
      isReturnAll: true
    })
  },
  methods: {
    changeProvince(row, val) {
      let data = row.options.find((item) => {
        if (item.value == val) {
          return item
        }
      })
      this.rowForm.province = data.label
    },
    getInit: function () {
      this.loading = true

      // 查询园区列表信息
      getParkList(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    // 新增
    append() {
      this.titleText = '新增'
      this.isAdd = true
      this.rowForm = {}
      this.util.clearSelectOptions(this.rowFormData)
      this.showEditAll = true
    },
    // 编辑
    rowEdit: function (row,details) {
      this.isAdd = false
      
      // console.log("row",row)
      this.rowForm = {
        ...row
      }
      if(details=="details"){
        this.titleText = '详情'
        this.allDisabled=true
      }else{
        this.titleText = '编辑'
      }
      // console.log(this.rowForm)
      if(row.deptId!=""){
        this.rowForm.deptId = row.deptId.split(',').map(Number)
      }else{
        this.rowForm.deptId=[]
      }
      // console.log("什么鬼--", [].map(Number))
      this.showEditAll = true
      this.$nextTick(() => {
        this.util.setOptionsInit({
          arr: this.rowFormData,
          This: this,
          obj: this.rowForm,
          ref: 'jaForm'
        })
      })
    },
    //删除
    // 删除
    rowDelete(row) {
      this.$confirm('社区删除后，所有社区下的物业数据与用户数据将不再可用，请谨慎操作。').then((res) => {
        console.log('row', row)
        delPark(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    saveForm: function () {
      // 保存
      this.loading_form.isShow = true
      if (this.isAdd) {
        //  添加园区信息
        addPark(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('新增成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      } else {
        //  更新园区信息
        updatePark(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('修改成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      }
    },
    //指派管理
    assignAdmin(val) {
      // console.log('你好',val)
      this.parkId=val.id
      // this.deptId=val.deptId
      this.show = true
    },
    // 添加用户
    selectUser(show){
      this.showUser=show
    },
    // 已指派的用户
    closeAdmin(show) {
      this.show = show
    },
    //选择用户 
    closeUser(show,key){
      // console.log("刷新父节点了",key)
      if(key){
        this.$refs.assignAdmin.getInit();
        this.getInit()
      }
      this.showUser=show
    },
    seeDetails(row){
      // console.log(row)
      this.rowEdit(row,"details")
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}

// 上传图像
.imgDefault {
  width: 150px;
  height: 200px;
  border: 1px solid $theme_bordercolor_DCDFE6;
}

/deep/ .el-upload__tip {
  position: absolute;
  width: 20%;
  top: 45%;
  left: 37%;
  text-align: center;
  line-height: 1;
  margin: 10px 0 8px 0;
}

.upload-img-form /deep/ .el-upload--picture-card {
  background-color: initial;
  border: initial;
  border-radius: initial;
  box-sizing: initial;
  width: initial;
  height: initial;
  line-height: initial;
  vertical-align: initial;
  display: inline-block;
  text-align: initial;
}

/deep/ ul.el-upload-list--picture-card {
  display: block;
}

/deep/ .el-upload-list.el-upload-list--text {
  width: 50%;

  > li.el-upload-list__item {
    cursor: pointer;
  }
}

// 上传图片按钮
.uploadBtn {
  position: absolute;
  top: 30%;
  left: 40%;
  color: $theme_color_0083ee;
  background: transparent;
  border: 1px solid $theme_bordercolor_0083ee;

  &:hover,
  &:focus {
    background: transparent;
    color: $theme_color_0083ee;
  }
}

// 图片缩略图
.videoContent {
  width: 40%;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 180px;
      height: 200px;
      flex-shrink: 0;
      margin: 0;
      border: 1px solid $theme_bordercolor_DCDFE6;
      border-radius: 0;
      overflow: hidden;
      position: relative;

      &:hover {
        .videoPlay_IconBox {
          display: none;
        }
      }

      .img-avatar {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
