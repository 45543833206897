<template>
  <!-- formArray： 操作栏数据 -->
  <div 
      v-loading="loadingForm||false"
      :element-loading-text="loadingFormObj.text"
      :element-loading-spinner="loadingFormObj.icon"
      :element-loading-background="loadingFormObj.bg"
      :element-loading-custom-class="loadingFormObj.formLoading"
  >
    <el-form
      :class="['ja_new_form',isEditFormDetail?'ja-EditForm':'']"
      :rules="rules"
      ref="formbox"
      :model="nowFilterData"
      :label-width="labelWidth"
      :disabled="allDisabled"
    >
      <el-form-item class="nolabel">
        <el-col
          :class="{'facac': item.isCenter, 'fac': item.rightBox}"
          :span='item.col ? Number(item.col) : Number(cols)'
          v-for="(item,index) in formArray"
          :key="index+0.1"
          v-if="!item.hide && (item.limit?isAuth(item.limit):true)"
          :style="{marginLeft: item.colMarginLeft}"
        >
          <el-form-item
            :prop="item.OtherKey?(Array.isArray(nowFilterData[item.key])?`${item.key}[${index-1}].${item.OtherKey}`:item.OtherKey):item.key"
            v-if="!item.noLabel && !item.relationDisplay"
            :label="item.label?item.label + ':':(item.labelEmpty?' ':'')"
            :rules="item.OtherKey?rules[item.otherRule]:rules[item.key]"
            :class="[item.isFlex?'el-form-itemFlex':'',
            item.isNotText?'formItemNormal':(item.input_type === 'text'||item.input_type === 'text2'||item.noMarginBottom?'formItemText':''),item.isRequired?'is-required':'']"
            :label-width="item.labelWidth"
          >
            <!-- lable自定义 -->
            <span
              v-if="item.requiredIcon"
              slot="label"
              class="requiredIconParent"
            >
              {{item.label}}:
              <!-- <co-tooltip placement="top" > <div slot="content" class="requiredIcon"> </div> </co-tooltip> -->
            </span>
            <div v-if="item.input_type==='title'" style="display:flex">
              <div class="titlePart">{{item.text}}</div>
              <slot v-bind:data="item" :name="item.customizeTitle" v-if="item.customizeTitle"></slot>
            </div>
            <slot
              v-bind:data="item"
              :name="item.customize"
              v-if="item.input_type==='customize'"
            >
              <div></div>
            </slot>
            <!--数字-->
            <el-input-number
              :style="{width:item.styleWidth}"
              :controls-position="item.position"
              :min="0"
              :max="item.max"
              :step="item.step"
              :step-strictly="item.stepStrictly?item.stepStrictly:false"
              v-if="item.input_type === 'number'"
              v-model="nowFilterData[item.key]"
            ></el-input-number>
            <!-- 文本 -->
            <div
              v-if="item.input_type === 'text'"
              class="input_text"
              :style="{width:item.styleWidth}"
            >{{item.text}}</div>
            <div
              v-if="item.input_type === 'text2'"
              class="input_text"
              :style="{width:item.styleWidth}"
            >{{nowFilterData[item.key] | optionsFormat(item)}}</div>
            <!-- input与textarea -->
            <el-input
              :style="{width:item.styleWidth}"
              controls-position="right"
              v-if="item.input_type === 'input' && item.type==='number'"
              :max="item.max || ''"
              :min="item.min || ''"
              :disabled="item.disabled || isDisabledFun(item)"
              :readonly="item.readonly"
              v-model.number="nowFilterData[item.key]"
              @input="nowFilterData[item.key]=nowFilterData[item.key].toString().replace(/[^\d]/g,'')"
              @focus="itemEvent(item.focusMethod)"
            ></el-input>
            <!-- 密码+textarea -->
            <el-input
              :style="{width:item.styleWidth}"
              v-if="item.input_type === 'input'&& item.type!=='number'"
              :disabled="item.disabled || isDisabledFun(item)"
              :readonly="item.readonly"
              :type="item.type || 'text'"
              v-model="nowFilterData[item.key]"
              :clearable="!item.clearable?false:true"
              :placeholder="item.placeholder||'请输入'+item.label"
              @focus="itemEvent(item.focusMethod)"
              :autosize="{ minRows: item.minRows || 2, maxRows: item.maxRows || 20}"
              :show-password="item.showPassword?item.showPassword:false"
              :show-word-limit="item.showWordLimt||(item.type === 'textarea'?true:false)"
              :maxlength="item.maxlength?item.maxlength:item.type === 'textarea'? 200:50"
              :rows="item.rows"
              :auto-complete="item.autoComplete?item.autoComplete:'new-password'"
              @change="itemEvent(item.changeMethod)"
            ></el-input>
            <!-- 下拉框 -->
            <el-select
              :style="{width:item.styleWidth}"
              @change="itemEvent(item.method, item, nowFilterData[item.key] )"
              filterable
              :clearable="!item.clearable?false:true"
              :multiple="item.multiple||false"
              @remove-tag="itemEvent(item.method2)"
              :disabled="item.disabled || isDisabledFun(item)"
              v-if="item.input_type === 'select'"
              v-model="nowFilterData[item.key]"
              :placeholder="item.placeholder||item.label"
              :loading="item.loading?item.loading:false"
              :loading-text="item.loadingText?item.loadingText:'加载中...'"
              :no-match-text="item.noMatchText?item.noMatchText:'无匹配的项'"
              :no-data-text="item.noDataText?item.noDataText:'无数据'"
            >
              <!-- :value="{value: list.value, label: list.value, itemObj: list}" -->
              <el-option
                v-for="(list,index2) in item.options"
                :key="index2+0.01"
                :label="list.label"
                :value="list.value"
                :class="nowFilterData[item.key] === list.label ? 'selected' : ''"
              ></el-option>
            </el-select>

            <!-- 联动 -->
            <el-cascader
              :style="{width:item.styleWidth}"
              v-if="item.input_type === 'cascader'"
              v-model="nowFilterData[item.key]"
              :props="item.props || {}"
              :options="item.options || []"
              :show-all-levels="!item.allLevels?false: item.allLevels"
            ></el-cascader>
            <!-- switch -->
            <el-switch
              :style="{width:item.styleWidth}"
              @change="itemEvent(item.method)"
              v-if="item.input_type === 'switch'"
              :disabled="item.disabled"
              :active-value="item.activeValue"
              :inactive-value="item.inactiveValue"
              v-model="nowFilterData[item.key]"
            ></el-switch>
            <!-- 时间 -->
            <el-time-picker
              v-if="item.input_type === 'time'"
              :is-range="item.isRange"
              v-model="nowFilterData[item.key]"
              :disabled="item.disabled"
              :picker-options="item.pickerOptions"
              :value-format="item.valueFormat"
              :format="item.format"
              :default-value="item.defaultValue"
              :placeholder="item.placeholder||'选择时间'"
              :start-placeholder="item.startPlaceholder||'开始时间'"
              :end-placeholder="item.endPlaceholder||'结束时间'"
              :range-separator="item.rangeSeparator ? item.rangeSeparator: '至' "
              :style="{width:item.styleWidth}"
              :unlink-panels="item.unlinkpanels || false"
            >
            </el-time-picker>
            <!-- 日期 -->
            <el-date-picker
              @change="itemEvent(item.method)"
              :disabled="item.disabled"
              v-if="item.input_type ==='date'"
              :type="item.type"
              :format="item.format"
              :default-value="item.defaultValue"
              :value-format="item.valueFormat ||'yyyy-MM-dd HH:mm:ss'"
              :placeholder="item.placeholder||'选择日期'"
              v-model="nowFilterData[item.key]"
              :start-placeholder="item.startPlaceholder||'开始时间'"
              :end-placeholder="item.endPlaceholder||'结束时间'"
              :range-separator="item.rangeSeparator ? item.rangeSeparator: '至' "
              :default-time="item.type == 'datetimerange'?(item.defaultTime||['00:00:00','23:59:59']):(item.defaultTime)"
              :style="{width:item.styleWidth?item.styleWidth:'100%'}"
              :picker-options="item.pickerOptions"
              :unlink-panels="item.unlinkpanels || false"
              :clearable="!item.clearable?false:true"
            ></el-date-picker>
            <!-- 单选 -->
            <div
              v-if="item.input_type === 'radio'"
              class="radioDiv"
              :style="{width:item.styleWidth}"
            >
              <span
                v-if="item.text"
                class="radioText"
              >{{item.text}}</span>
              <el-radio-group
                @change="itemEvent(item.method,item,nowFilterData[item.key])"
                :disabled="item.disabled"
                v-model="nowFilterData[item.key]"
              >
                <el-radio
                  v-for="(list,index3) in item.options"
                  :key="index3+0.001"
                  :label="list.value"
                  :disabled="list.disabled"
                  class="radio_item"
                >{{list.label}}</el-radio>
              </el-radio-group>
            </div>
            <!-- 多选 -->
            <template v-if="item.input_type === 'checkbox'">
              <el-checkbox
                v-if="item.allBoxObj"
                v-model="item.allBoxObj.checkAll"
                @change="itemEvent(item.allBoxObj.method)"
              >{{item.allBoxObj.label}}</el-checkbox>
              <!-- v-model必须绑定一个对象-->
              <el-checkbox-group
                :style="{width:item.styleWidth}"
                @change="itemEvent(item.method)"
                v-model="nowFilterData[item.key]"
                class="radioGroup"
              >
                <el-checkbox
                  v-for="(list,index4) in item.options"
                  :key="index4+0.005"
                  :label="list.value"
                  :name="list.name"
                  :disabled="list.disabled ? list.disabled : false"
                  class="radio"
                >{{list.label}}</el-checkbox>
              </el-checkbox-group>
            </template>
            <!-- 上传 -->
            <el-upload
              v-if="item.input_type === 'upload'"
              :style="{width:item.styleWidth}"
              class="upload-demo"
              :action="item.url || ''"
              :on-preview="item.handlePreview"
              :on-remove="item.handleRemove"
              :on-success="onSuccess"
              :headers="header"
              :file-list="item.fileList2"
              list-type="picture"
            >
              <el-button
                size="small"
                type="primary"
              >点击上传</el-button>
              <div
                slot="tip"
                class="el-upload__tip"
              >只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
            <el-button
              v-if="item.input_type ==='button'"
              :disabled="item.disabled"
              :size="item.size?item.size:'mini'"
              :type="item.type || 'primary'"
              @click="itemEvent(item.method)"
            >{{item.text}}</el-button>
            <!-- 表格 -->
            <ja-table
              v-if="item.input_type ==='table'"
              v-bind="item"
              v-on="$listeners"
              :v-model="item.filterData"
              :tableData="nowFilterData[item.key]"
              :isFromForm="true"
              ref="JaTable"
              :style="{'width':item.styleWidth}"
            >
            </ja-table>
            <!-- 右边 -->
            <span
              v-if="item.rightBox && (item.rightBox.input_type === 'text' || !item.rightBox.input_type)"
              class="right-text"
            >{{item.rightBox.text}}</span>
            <div
              v-if="item.rightBox && item.rightBox.input_type === 'customize'"
              class="right-customize"
              :style="{width: item.rightBox.width,marginLeft:item.rightBox.marginLeft }"
            >
              <slot
                :name="item.rightBox.customize"
                :row="nowFilterData"
              >
              </slot>
            </div>
            <el-button
              v-if="item.rightBoxItem && item.rightBoxItem.input_type === 'button' && !item.rightBoxItem.hide"
              :disabled="item.disabled"
              @click="itemEvent(item.rightBoxItem.method)"
              :type="item.rightBoxItem.type || 'primary'"
              :size="item.size || 'small'"
              class="right-text"
            >{{item.rightBoxItem.label|| item.rightBoxItem.text}}</el-button>
          </el-form-item>
          <el-button
            v-if="item.rightBox && item.rightBox.input_type === 'button'"
            :disabled="item.disabled"
            @click="itemEvent(item.rightBox.method)"
            :type="item.rightBox.type"
            :size="item.size || ''"
            class="right-text"
            :style="{marginBottom: item.marginBottom}"
          >{{item.rightBox.label|| item.rightBox.text}}</el-button>
        </el-col>

        <el-col :span="24">
          <slot></slot>
        </el-col>
        <!-- 默认按钮 -->
        <el-col
          v-if="(!noButton && btnBox.length>0) && !allDisabled"
          :span="24"
          class="facac"
        >
          <el-button
            v-for="(item,index) in btnBox"
            :disabled="item.disabled"
            :style="item.style"
            :plain="item.plain || false"
            :key="index+0.0001"
            :type="item.type || ''"
            @click="itemEventBtn(item.method,item.monitor,item)"
          >{{item.label}}</el-button>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/**
 * nowFilterData: 当前编辑行的数据
 */
import constObj from '@/util/config'

export default {
  name: 'ja-form',
  data() {
    return {
      loadingFormObj:{},
      header: {
        Authorization: ''
      },
      nowFilterData: this.value,
      // 图片预览相关
      dialogVisible: false,
      dialogImageUrl: ''
    }
  },
  props: {
    cols: {
      type: [Number, String],
      default: 11
    },
    // 选定参数
    value: {
      type: Object,
      default: function () {
        return {}
      }
    },
    // 底部是否有按钮
    noButton: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object,
      default: () => {}
    },
    formArray: {
      type: Array,
      default: function () {
        return []
      }
    },
    marginLeft: {
      type: [String, Number],
      default: '80px'
    },
    labelWidth: {
      type: [String, Number],
      default: '80px'
    },
    btnBox: {
      type: Array,
      default: function () {
        return [
          {
            label: '保存',
            method: 'save',
            type: 'primary',
            monitor: true,
            col: 8,
            isCenter: true
          },
          { label: '取消', method: 'reset', col: 8, isCenter: true }
        ]
      }
    },
    // 是否是编辑的弹窗
    isEditFormDetail: {
      type: Boolean,
      default: false
    },
    onSuccess: {
      type: Function,
      default: () => () => {}
    },
    // 是否禁止全部表单
    allDisabled:{
      type:Boolean,
      default:false
    },
    // 表单的加载
    loadingForm:{
      type: Boolean,
      default: false
    },
    // 是否禁用函数
    isDisabledFun:{
      type:Function,
      default:()=>{
        return false
      }
    }
  },
  methods: {
    async init() {
      // 加动态下拉框
      for (let i = 0; i < this.formArray.length; i++) {
        const el = this.formArray[i]
        if (
          (el.input_type === 'select' ||
            el.input_type === 'radio' ||
            el.input_type === 'checkbox') &&
          typeof el.options === 'function'
        ) {
          const res = await el.options()
          el.options = res
          this.$forceUpdate() // 强制刷新
        }
        if(Object.keys(this.nowFilterData).length != 0 ) {  // 判断是否编辑页面，是就往下执行
          if(el.isInit){
            console.log("this.nowFilterData---",this.nowFilterData)
            for (var j = 0; j < this.formArray.length; j++){
              var item=this.formArray[j]
              if(item.handleSelectContent){
                let key=el.key
                item.options=await item.handleSelectContent(el,el.key,this.nowFilterData[key],item,this.nowFilterData)
                this.$forceUpdate()
              }
            }
          }
        }
      }
    },
    // 处理下拉框关联切换
    dealSelect(itemData, val,isFromEdit){
      const relevance = itemData.relevance
          const {sonKey,This,childrenKey,dealOptionsParma,otherSonKey,rowFormDataFun,rowFormFun} = relevance
          let RowFormData =  rowFormDataFun?rowFormDataFun():This.rowFormData
          let RowForm = rowFormFun?rowFormFun():This.rowForm
          let index = RowFormData.findIndex(item=>item.key === sonKey)
          if(!isFromEdit){
            RowFormData[index].options = []
            This.$set(RowForm,sonKey,'')
            if(otherSonKey && otherSonKey.length>0){ 
              otherSonKey.forEach(item=>{
                let i = RowFormData.findIndex(v=>v.key == item) 
                if(i>-1){
                  RowFormData[i].options = []
                  This.$set(RowForm,item,'')
                }
              })
            }
          }
          if(relevance.rqParam){// 说明是异步的
            this.util.rqOptionsList({who:RowFormData[index],...relevance.rqParam,rqData:relevance.rqParam.rqData()})
          }else{ // 说明是拿父级的 children 字段
            // console.log('dela------',itemData.key,itemData,val)
            let options = itemData.options
            let sonObj =  options.find(item=> item.value == val)
            // console.log('sonObj------',sonObj)
            if(sonObj){
              let childOptions = sonObj[childrenKey||'children']
              if(dealOptionsParma){
                RowFormData[index].options = this.dealOptions({optionsArr:childOptions,...dealOptionsParma})
              }else{
                RowFormData[index].options = childOptions
              }
            }
          }
    },
    // 处理 关联的 显示隐藏
    dealDisplay(itemData, val,isFromEdit){
      
    },
    // 自定义事件monitor开启校验
    itemEvent(method, monitor={input_type:""}, val, data) {
      // console.log('触发开始---',method, monitor, val, data)
      if(monitor.relevance){
        const relevance = monitor.relevance
        if(relevance.type == 'select'){
          this.dealSelect(monitor, val)
        }else if(relevance.type == 'isDisplay'){
          this.dealDisplay(monitor, val)
        }
      }
      if (method) this.$emit(method, monitor, val, data)

      // 触发change判断是否，是下拉框
      if(monitor.input_type=="select"){
        // 遍历所有的表单项
        this.formArray.forEach(async(item,index)=>{
          // 判断每一项是否有handleSelectContent函数，有则执行这项函数
          if(item.handleSelectContent){
            item.options=await item.handleSelectContent(monitor, monitor.key, val, item, this.nowFilterData,true)
            this.$forceUpdate()
          }
        })
      }
    },
    itemEventBtn(method, monitor, val) {
      // console.log('按钮点击', method, monitor, val)
      if (monitor) {
        this.$refs.formbox.validate((valid) => {
          if (valid) {
            this.$emit(method, val)
          } else {
            return false
          }
        })
      } else {
        
        this.$emit(method, val)
      }
    },
    // 是否开启校验规则
    checkRules(callback, monitor) {
      // console.log('ddd--childchild->>', monitor)
      if (monitor) {
        this.$refs.formbox.validate((valid) => {
          console.log('valid--->', valid)
          if (!valid) {
            console.log('error submit!!')
            return false
          } else {
            // console.log('method, param')
            // this.$emit(method, param)
            callback()
          }
        })
      } else {
        callback()
      }
    }
  },
  created() {
    // console.log('btne--->', this.btnBox)
    // console.log(this.formArray)
    // consolelog('*********',this.constObj)
    this.loadingFormObj = constObj.loadingFormObj
    this.init()
  },
  mounted() {
    const token = this.$store.state.token
    this.header.Authorization = token.token_type + ' ' + token.access_token
  },
  watch: {
    value(val) {
      this.nowFilterData = val
    },
    nowFilterData: {
      deep: true,
      handler(val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  filters:{
    optionsFormat:( val, item )=>{
      if( item.options && item.options.length >0  ){
        let obj = {}
        item.options.forEach(item => [
          obj[item.value] = item.label
        ])
        return obj[val]
      }else{
        return val
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.facac {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.right-text {
  margin-left: 10px;
  flex-shrink: 0;
}
.avatar {
  height: 90px;
}
</style>

<style lang="scss">
@import '~@/style/mixin.scss';
.el-input.is-disabled .el-input__inner {
  // color: $theme_color_333 ;
  color: #606266 !important;
}
.el-input-number {
  line-height: 30px;
}
.ja_new_form {
  .el-date-editor .el-range__icon {
    margin-top: -6px;
  }
  .el-form-item {
    width: 100%;
  }
  .el-form-item .el-form-item {
    margin-bottom: 22px;
  }
  .el-form-item .el-form-item.formItemText {
    margin-bottom: 0;
  }
  &.ja-EditForm {
    .el-form-item .el-form-item {
      margin-bottom: 0;
    }
    .el-form-item .el-form-item.formItemNormal {
      margin-bottom: 22px;
    }
  }
  // .el-input {
  //   width: auto;
  // }
  // .el-button {
  //   height: $theme_height_32;
  //   line-height: $theme_height_32;
  //   padding-top: 0;
  //   padding-bottom: 0;
  // }
  & /deep/ textarea {
    background-color: $theme-color !important;
  }
  & .el-select {
    width: 100%;
    .el-input__inner {
      box-sizing: border-box;
      // width: calc(100% - 4px);
    }
    .el-input__suffix {
      right: 4px;
      top: 0;
      // right: 20px;
    }
  }
  & .el-date-editor .el-range-separator {
    width: 8% !important;
    margin-top: -6px;
  }
  .radioDiv {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    .radioText {
      margin-right: 10px;
    }
    .radio_item {
      line-height: 40px;
    }
  }
}
.right-customize {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
}
.nolabel > .el-form-item__content {
  margin-left: 10px !important;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}
.el-form-itemFlex {
  .el-form-item__content {
    display: flex;
  }
}
.titlePart{
    font-weight: 600;
    font-size: 17px;
    color: #606266;
}
</style>
