// ========================================================================
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home'
import Login from '../views/login/index'
import emptyPage from '../views/emptyPage'
import autoRoute from './model/index'

Vue.use(VueRouter)
// console.log("autoRoute----",autoRoute)
const v = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return v.call(this, location).catch(err => err)
}
// //  公共路由
export const constantRoutes = [
  {
    path: '/',
    // redirect: '/home/homeIndex'
    redirect: '/home/defaultPage'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '*',
    redirect: '/home/404',
  },
]
const route = [
  ...autoRoute.children
]
const routes = [
  {
    path: '/',
    // redirect: '/home/homeIndex'
    redirect: '/home/defaultPage'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '*',
    redirect: '/home/404',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    // redirect: '/home/homeIndex',
    redirect: '/home/defaultPage',
    children: [
      ...route,
      {
        path: '*',
        meta: {
          show: false
        },
        name: '404',
        component: emptyPage
      },
    ]
  }
]
console.log('routes----------yu', routes)
const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
