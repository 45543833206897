<template>
  <router-view/>
</template>

<script>
export default {
  title: "访客模块",
  name: 'meterReadingSet'
}
</script>

<style scoped>

</style>
