import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import 'reset.scss/reset.scss'
import 'element-ui/lib/theme-chalk/index.css'
import '@/style/common.scss'
import '@/assets/icons' // icon


import JAUI from '@/components'
import util from '@/util/util'
import {dealOptions} from '@/util/common'
import config from "@/util/config"
import focus from "@/util/directive" // 全局指令

import _ from 'lodash'
Vue.prototype._ = _

Vue.prototype.$uploadUrl = process.env.NODE_ENV === 'development' ? '/prod-api/admin' : process.env.VUE_APP_ROOT + '/admin'

import './permission'


import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import VXETablePluginElement from 'vxe-table-plugin-element'
import 'vxe-table-plugin-element/dist/style.css'

VXETable.use(VXETablePluginElement)
Vue.use(VXETable)

Vue.config.productionTip = false // permission control

Vue.prototype.$baseUrl = process.env.VUE_APP_ROOT
Vue.prototype.$moment = moment
Vue.prototype.$echarts = echarts
Vue.prototype.$config = config
Vue.prototype.util = util
Vue.prototype.isAuth = util.isAuth
Vue.prototype.dealOptions = dealOptions

Vue.use(JAUI)

if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')