<template>
  <router-view />
</template>

<script>
export default {
  title: "设备告警与报修",
  name: 'basicInformation'
}
</script>

<style scoped>
</style>
