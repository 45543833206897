export default {
  data () {
    return { 
      InitPageSize:10, // 会设置的页数
      labelWidth: `${this.util.setFontSize(150)}px`,
      total: 0,
      // titleText: '编辑',
      pagination: {
        // currentPage: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      filterData: { // 部分接口分页是currentPage，部分是pageNum,故都传
        pageIndex: 1,
        pageSize: 10,
        // currentPage: 1,
        // showCount: 10
      },
      tableData: [],
      loading_form: {
        isShow: false,
        text: 'loading',
        icon: 'el-icon-loading',
        bg: 'rgba(255,255,255,0.5)',
        formLoading: 'loading_form'
      }
    }
  },
  provide(){
    return{
      labelWidth: this.labelWidth,
      getInit: this.getInit,
    }
  },
  created () {
    this.init()
    // this.reset()
  },
  methods: {
    init () {
      this.loading = true
      this.filterData.pageSize =  this.InitPageSize
      // 超时隐藏（实际应在接口返回数据时去掉loading）
      setTimeout(() => {
        this.loading = false
      }, 50 * 1000)
      this.getInit()
    },
    rqParmas(){
      let obj = { }
      let keysArr = (this.conditionData||[]).map(v => v.key || v.value )
      keysArr.forEach(v => {
        if ((typeof this.filterData[v]) !== "undefined" && this.filterData[v] !== null) {
          obj[v] = this.filterData[v]
        }
      })
      obj.pageIndex = this.filterData.pageIndex
      obj.pageSize = this.filterData.pageSize
      return obj
    },
    // 初始化数据
    getInit (item) {
      // setTimeout(() => {
      //   this.loading = false
      // }, 5 * 1000)
    },
    search () {
      this.filterData.pageIndex = 1
      // this.filterData.currentPage = 1
      this.init()
    },
    reset () {
      this.filterData = {
        pageIndex: 1,
        pageSize: this.InitPageSize
        // currentPage: 1,
        // showCount: 10
      }
      this.pagination.total = 0
      this.pagination.pageSize = this.InitPageSize
      this.util.clearSelectOptions(this.conditionData)
      this.init()
    },
  
    
    pageChange () {
      this.filterData = {
        ...this.filterData,
        pageSize: this.pagination.pageSize,
        // showCount: this.pagination.pageSize,
        currentPage: this.pagination.currentPage,
        pageIndex: this.pagination.currentPage
      }
      this.init()
    },
    // table的值转换（根据实际重写）
    // valueFormat (val, key, row) {
    //   return val
    // },
    // 部分格式转换（table值的转换，勿删）
    showData (data, val, key) {
      let newVal = '未知'
      data.forEach(v => {
        if (v.value === key) {
          v.options.forEach(item => {
            if (val === item.value) {
              newVal = item.label
            }
          })
          // newVal = v.label
        }
      })
      return newVal
    },
    // 事件调用子组件
    normalEvent(row,cell,btn){
      // console.log('btn===',row,cell,btn)
      if(!btn){ // 说明，不是来自 某一行，属于 新增 或者 自定义 触发的。
        this.$refs[row.ref][row.method](row.row||row)
        return
      }
      if(btn.method){
        if(btn.method == 'rowDetail'){
          this.$refs.detail[btn.method](row,cell,btn)
        }else{
          this.$refs.addEdit[btn.method](row,cell,btn)
        }
      }
    },
  }
}
