<template>
  <!-- 查看问卷调查 —— 下面的问题列表   -->
  <div class="surveyList" v-if="answerList.length>0">
    <el-card class="box-card" v-for="(item,index) in answerList" :key="index">
      <div slot="header" class="title">
        <span class="text">【{{index+1}}】{{item.title}}</span>
        <el-tag size="small" :type="item.type==0?'warning':''" class="right">{{typeList[item.type]}}</el-tag>
      </div>
      <ul class="content">
        <li v-for="(item2,index2) in item.list" :key="index2">
          <div class="first">
            <div class="name"><span class="serialNum">（{{index2+1}}）</span>{{item2.name}}</div>
            <div class="leaveWordNum" v-if="item2.leaveWordNum>0" @click="showLeaveWord(item2)">{{item2.leaveWordNum}}条备注留言</div>
          </div>
          <div class="second">
            <el-progress class="percentage" :text-inside="true" :stroke-width="18" :percentage="item2.percent"></el-progress>
            <div class="number">
              {{item2.num}}票（{{item2.percent+'%'}}）
            </div>
          </div>
        </li>
      </ul>
    </el-card>
  </div>
</template>
<script>

export default ({
  props: {
    answerList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      typeList: ['单选', '多选'],
    }
  },
  methods: {
    showLeaveWord(val) {
        this.$emit('showLeaveWord',val)
    }
  }
})
</script>
<style lang="scss" scoped>
.surveyList {
  margin-top: 30px;
  .box-card {
    margin-bottom: 10px;
    .title {
      .text {
        line-height: 1.8;
        font-size: 16px;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      .right {
        margin-left: 20px;
      }
    }
  }
}
ul.content {
  > li {
    margin-bottom: 20px;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.first {
        margin-bottom: 10px;
        .name {
          .serialNum {
            width: 40px;
            display: inline-block;
            text-align: right;
          }
        }
        .leaveWordNum {
          color: #409eff;
          cursor: pointer;
        }
      }
      &.second {
        .percentage {
          width: 70%;
          margin-left: 40px;
        }
      }
    }
  }
}
</style>
