<template>
  <div
    style="width:100%"
  >
    <el-form
      :model="form"
      :rules="ruleschi"
      ref="form"
    >
      <el-table :data="form.datas" border>
        <!-- <el-table-column prop="id" label="序号" width="60"></el-table-column> -->
        <el-table-column
          prop="foodName"
          label="材料名称"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.action == 'view'">
              {{scope.row.foodName}}
            </template>
            <template v-else>
              <el-form-item
                :prop="'datas.'+scope.$index + '.foodName'"
                :rules='ruleschi.foodName'
              >
                <el-input
                  size="mini"
                  v-model="scope.row.foodName"
                  style="width: 120px;"
                ></el-input>
              </el-form-item>
            </template>
          </template>
        </el-table-column>

        <el-table-column
          prop="cost"
          label="成本价（元）"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.action == 'view'">
              {{scope.row.cost}}
            </template>
            <template v-else>
              <el-form-item
                :prop="'datas.'+scope.$index + '.cost'"
                :rules='ruleschi.cost'
              >
                <el-input
                  size="mini"
                  v-model="scope.row.cost"
                  style="width: 60px;"
                ></el-input>
              </el-form-item>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="num"
          label="数量"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.action == 'view'">
              {{scope.row.num}}
            </template>
            <template v-else>
              <el-form-item
                :prop="'datas.'+scope.$index + '.num'"
                :rules='ruleschi.num'
              >
                <el-input
                  size="mini"
                  v-model="scope.row.num"
                  style="width: 60px;"
                ></el-input>
              </el-form-item>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="unit"
          label="单位"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.action == 'view'">
              {{scope.row.unit}}
            </template>
            <template v-else>
              <el-form-item
                :prop="'datas.'+scope.$index + '.unit'"
                :rules='ruleschi.unit'
              >
                <el-select size="mini" v-model="scope.row.unit" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="operation"
          label="操作"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.action == 'view'">
              <el-button
                size="mini"
                @click="click_edit(scope.row, scope.$index)"
              >编辑</el-button>
              <el-button
                size="mini"
                @click="click_delete(scope.row, scope.$index)"
              >删除</el-button>
            </template>
            <template v-else-if="scope.row.action == 'add'">
              <el-button
                size="mini"
                @click="click_add( scope.row, scope.$index)"
              >新增</el-button>
              <el-button
                size="mini"
                @click="click_reset(scope.row, scope.$index)"
              >重置</el-button>
            </template>
            <template v-else>
              <el-button
                size="mini"
                @click="click_save(scope.row, scope.$index)"
              >保存</el-button>
              <el-button
                size="mini"
                @click="click_cancle(scope.row, scope.$index)"
              >取消</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>
<script>
</script>
<script>
import {dictData} from '@/api'
export default {
  data() {
    return {
      form: {
        datas: [
          // { foodName: '牛肉', num: '100', cost: '10', unit: '斤' },
          // { foodName: '羊肉', num: '10', cost: '15', unit: '斤' }
        ]
      },
      options: [],
      //表单验证规则
      ruleschi: {
        foodName: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入名称'
          }
        ],
        num: [
          {
            required: true,
            trigger: 'blur',
            // message: '请输入成本价',
            validator:this.util.formRules.checkNumber({fixedNum:2,min:0})
          },
          // {
          //   trigger: 'blur',
          //   min: 0,
          //   max: 120,
          //   message: '年龄最小0，最大120'
          // }
        ],
        cost: [
          {
            required: true,
            trigger: 'blur',
            // message: '请输入数量',
            validator: this.util.formRules.checkNumber({min:0,fixedNum:2})
          }
        ],
        unit: [
          {
            required: true,
            trigger: 'change',
            message: '请选择单位'
          }
        ]
      }
    }
  },
  props:{
    datas:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  created() {
    //处理数据，为已有数据添加action:'view'
    // this.form.datas.map((item) => {
    //   this.$set(item, 'action', 'view')
    //   return item
    // })
    
    
    console.log("foodsCosts---",this.form)
    //再插入一条添加操作的数据
    this.form.datas.unshift({
      foodName: undefined,
      num: undefined,
      cost: undefined,
      unit: undefined,
      action: 'add'
    })
    this.getUnit()
    if(this.datas){
      let list=this.datas.map(item=>{
        this.$set(item, 'action', 'view')
        return item
      })
      this.form.datas=this.form.datas.concat(list)
    }

    
  },
  mounted(){
      
  },
  methods: {
    //对部分表单字段进行校验
    validateField(form, index) {
      let result = true
      // console.log("测试---",this.$refs[form].fields)
      for (let item of this.$refs[form].fields) {
        if (item.prop.split('.')[1] == index) {
          this.$refs[form].validateField(item.prop, (error) => {
            if (error != '' && error!=undefined) {
              // console.log("测试333---",item.prop,error)
              result = false
            }
          })
        }
        if (!result) break
      }
      return result
    },
    getUnit(){
      dictData("dishes_unit").then(res=>{
        if(res.code==200){
          console.log("res---",res)
          let data=res.data
          this.options=data
        }
      })
    },
    //对部分表单字段进行重置
    resetField(form, index) {
      this.$refs[form].fields.forEach((item) => {
        if (item.prop.split('.')[1] == index) {
          item.resetField()
        }
      })
    },

    //新增操作
    click_add(item, index) {
      if (!this.validateField('form', index)) return
      //模拟新增一条数据
      let itemClone = JSON.parse(JSON.stringify(item))
      // itemClone.id = this.form.datas.length
      itemClone.action = 'view'
      this.form.datas.push(itemClone)
      this.resetField('form', index)
      this.totalprice()
    },
    //新增-重置操作
    click_reset(item, index) {
      this.resetField('form', index)
    },

    //编辑-保存操作
    click_save(item, index) {
      if (!this.validateField('form', index)) return
      // 
      item.action = 'view'
      this.totalprice()
    },

    //编辑-取消操作
    click_cancle(item, index) {
      this.resetField('form', index)
      item.action = 'view'
      this.totalprice()
    },

    //编辑操作
    click_edit(item, index) {
      console.log("什么",item)
      item.action = 'edit'
      this.totalprice()
    },

    //删除操作
    click_delete(item, index) {
      this.$confirm('确定删除该条数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          //模拟删除一条数据
          this.form.datas.splice(index, 1)
          this.totalprice()
        })
        .catch(() => {})
    },
    // 计算总价
    totalprice(){
      let list=[];
      let priceList=this.form.datas.map((item,i)=>{
        if(item.action=="view"){
          list.push(item)
          return Number(item.num)*Number(item.cost)
        }else{
          return 0
        }
      })
      let total=priceList.reduce((a,b)=>a+b)
      this.$emit('addList',{total,datas:list})
    }
  }
}
</script>


<style  lang="scss" scoped>
.ja_new_form .el-form-item .el-form-item {
    margin-bottom: 0;
}
/deep/.el-form-item__error{
    position: initial;
  }
</style>
