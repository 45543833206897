<template>
  <!--  普通的表单 -->
  <el-dialog :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" :title="isAdd?'新增':'编辑'">

    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="preSaveForm" v-if="showEditAll" @selectBoxItem="selectBoxItem">
      <template #uploadFile="row">
        <upload-file ref="Upload" :autoUpload="true" :accept='accept' :keyItem="row.data" :initFileList="initFileList" :fileSize="10" />
      </template>
    </ja-form>

    <el-dialog width="80%" title="选择设备" :visible.sync="showSelectBox" v-if="showSelectBox" append-to-body>
      <select-service @selectRow="selectRow" v-if="showSelectBox" />
    </el-dialog>
  </el-dialog>
</template>
<script>
import { addRepairManager, eidtRepairManager } from '@/api'
import selectService from './selectService/selectService.vue'

export default {
  inject: ['labelWidth', 'getInit',],
  components: {
    selectService
  },
  data() {
    return {
      rowFormData: [
        { input_type: 'input', label: '设备编号', disabled: true, key: 'deviceCode', col: 11, },
        { input_type: 'button', text: '选择', readonly: true, isFlex: true, colMarginLeft: '2%', method: 'selectBoxItem',key:'btn' },

        { input_type: "input", label: '设备名称', key: 'deviceName', disabled: true, },
        { input_type: "input", label: '设备位置', key: 'installLocation', disabled: true, },
        // { input_type: "radio", label: '类型', key: 'type', options: [{ label: '物业维修', value: '0' }, { label: '外委维修', value: '1' },], col: 24 },
        // { input_type: "input", label: '预计工时', key: 'workTime', col: 11, rightBox: { text: '小时' }, isFlex: true, },

        { input_type: "input", label: '联系人', key: 'linkman', },
        { input_type: "input", label: '联系人手机', key: 'mobile', },
        { label: '报修内容', key: 'content', input_type: 'input', type: 'textarea', col: 22, showWordLimt: true, rows: 5, maxRows: 5 },
        // { input_type: "input", label: '预估金额', key: 'cost', col: 11, rightBox: { text: '元' }, isFlex: true, },
        { input_type: 'customize', customize: 'uploadFile', label: '上传附件', key: 'pictureUrl', col: 24, },
      ],
      rowForm: {},
      rules: {
        deviceCode: [{ required: true, message: '请输入设备编号', trigger: 'blur' }],
        // deviceName: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        linkman: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        installLocation: [{ required: true, message: '请输入设备位置', trigger: 'blur' }],
        mobile: [{ required: true, validator: this.util.formRules.checkPhone(), trigger: 'change' }],
        // workTime: [{ required: true, validator: this.util.formRules.checkNumber({ max: 999, fixedNum: 1 }), trigger: 'change' }],
        // cost: [{ required: true, validator: this.util.formRules.checkNumber({ max: 99999, fixedNum: 2 }), trigger: 'change' }],
        // type: [{ required: true, message: '请选择类型', trigger: 'change' }],
        content: [{ required: true, message: '请输入报修内容', trigger: 'blur' }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      rowFormData_indexObj: {},
      initFileList: [],
      accept: this.$config.videoType + ',' + this.$config.imgType,
      //选择设备
      showSelectBox: false,
    }
  },
  created() {
    this.rowFormData_indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
  },
  watch: {
    // 'rowForm.type':function(newVal){
    //   let index =  this.rowFormData_indexObj.cost
    //   this.$set(this.rowFormData[index],'hide',newVal === undefined || newVal==1 ? false:true)
    // }
  },
  methods: {
    append() {
      this.isAdd = true
      this.initFileList = []
      this.rowForm = {}
      this.$set(this.rowFormData[this.rowFormData_indexObj.deviceCode],'hide',false)
      this.$set(this.rowFormData[this.rowFormData_indexObj.deviceCode],'hide',false)
      this.$set(this.rules.installLocation[0],'required',false)
      this.$set(this.rowFormData[this.rowFormData_indexObj.installLocation],'disabled',true)
      this.$set(this.rowFormData[this.rowFormData_indexObj.btn],'hide',false)

      this.showEditAll = true
    },
    rowEdit(row) {
      console.log('row---', row)
      this.isAdd = false
      this.titleText = '编辑'
      let imgArr = row.pictureUrl ? row.pictureUrl.split(',') : ''
      if (imgArr.length > 0) {
        this.initFileList = this.util.backImgVideoObj(imgArr)
      } else {
        this.initFileList = []
      }
      this.rowForm = row
      this.$set(this.rowFormData[this.rowFormData_indexObj.deviceCode],'hide',row.source === 'wx')
      this.$set(this.rowFormData[this.rowFormData_indexObj.deviceName],'hide',row.source === 'wx')
      this.$set(this.rowFormData[this.rowFormData_indexObj.btn],'hide',row.source === 'wx')
      this.$set(this.rowFormData[this.rowFormData_indexObj.installLocation],'disabled',row.source !== 'wx')
      this.$set(this.rules.installLocation[0],'required',row.source === 'wx')
      
      // this.initFileList = [{ url: 'xxx', status: 'success', }]
      this.showEditAll = true
    },
    preSaveForm() {
      let fileList = this.$refs.Upload.fileList
      if (fileList.length == 0) {
        this.rowForm.pictureUrl = ''
        this.saveForm()
      } else {
        let isExist = fileList.find(item => item.status != 'success')
        if (!isExist) {
          let imgArr = fileList.map(item => {
            if (item.response && item.response.data) {
              return item.response.data
            }
          })
          this.rowForm.pictureUrl = imgArr.join(',')
          this.saveForm()
        } else {
          this.$message.warning("存在文件尚未完成上传")
          return
        }
      }
    },
    saveForm() {
      this.loadingForm = true
      let rqMethod = this.isAdd ? addRepairManager : eidtRepairManager
      rqMethod({ ...this.rowForm, source: 'pc' }).then(res => {
        this.loadingForm = false
        this.$message.success('提交成功')
        this.showEditAll = false
        this.getInit()
      }).catch(error => {
        this.loadingForm = false
      })
    },
    // 选择设备
    selectBoxItem() {
      this.showSelectBox = true
    },
    selectRow({ list }) {
      this.showSelectBox = false
      console.log('list==.', list)
      if (list[0]) {
        let obj = list[0]
        this.$set(this.rowForm, 'deviceName', obj.deviceName)
        this.$set(this.rowForm, 'deviceCode', obj.deviceCode)
        this.$set(this.rowForm, 'installLocation', obj.installLocation)
        // let val = this.checkList[this.activeClickedIndex].value
        // let index = this.realchekedList.findIndex(v => v.parts == val)
        // let obj = this.realchekedList[index]
        // obj.deviceCode = list[0].deviceCode
        // obj.deviceId = list[0].id
        // this.realchekedList.splice(index, 1, obj)

        // this.$set(this.checkList[this.activeClickedIndex], 'deviceCode', list[0].deviceCode)
      }
    },

  }
}
</script>