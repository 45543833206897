<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      @getInit="getInit"
      @search="search"
      :valueFormat="valueFormat"
      @rowDelete="rowDelete"
      @reset="reset"
    >
      <template #pictureUrl="{row}">
        <!-- <div class="firstcell el-button--text" @click="seeDetails(row)">{{row.pictureUrl}}</div> -->
        <el-image
          style="width: 50px; height: 50px"
          :src="row.pictureUrl"
          :preview-src-list="[row.pictureUrl]"
          fit="cover"
        ></el-image>
      </template>
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="40%"
      @close="close"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :allDisabled="allDisabled"
        @reset="showEditAll = false"
        @save="saveForm"
        @linkageDeviceFun="linkageDeviceFun"
        v-if="showEditAll"
        ref="jaForm"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
        <template #uplpadfaceImg>
          <upload-file
            ref="Upload"
            @uploadFinish="uploadFinish"
            @imgRemoveMethod="imgRemoveMethod"
            :autoUpload="true"
            :accept="'.jpeg,.png,.jpg'"
            :limit="1"
            :initFileList="[...initFileList]"
            :fileSize="10"
            v-if="showEditAll"
          />
        </template>
      </ja-form>
    </el-dialog>
     <!-- 选择设备弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showDevice"
      width="50%"
      title="选择设备"
    >
      <deviceList @appendDevice="appendDevice"></deviceList>
    </el-dialog>
    <!-- 选择设备弹窗end -->
  </div>
</template>
<script>
import {
  getMeetingRoom,
  addMeetingRoom,
  editMeetingRoom,
  delMeetingRoom,
  dictData

} from '@/api'
import table from '@/views/mixins/table'
import deviceList from './components/deviceList.vue'
export default {
  title: '会议室管理',
  mixins: [table],
  name: 'index',
  components:{
    deviceList
  },
  data() {
    return {
      showDevice:false,
      show: false,
      showUser: false,
      parkId: 1,
      allDisabled: false,
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
        // cityId:''
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'name',
          styleWidth: '200px',
          attribute: {
            placeholder: '会议室名称',
            size: 'small'
          }
        },
        {
          input_type: 'select',
          key: 'status',
          styleWidth: '200px',
          attribute: {
            placeholder: '状态',
            size: 'small'
          },
          options:[
            {label:"禁用",value:1},
            {label:"启用",value:0}
          ]
        },
        {
          input_type: 'date',
          value: 'createTime',
          type: 'date',
          styleWidth: '200px',
          placeholder: '创建日期'
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '新增',
          input_type: 'button',
          method: 'append',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-plus',
            plain: true
          },
          limit: 'conferenceRoom::Management::add'
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '图片',
          prop: 'pictureUrl',
          slotName: 'pictureUrl',
          width: 100
        },
        {
          label: '编号',
          prop: 'code'
        },
        {
          label: '会议室名称',
          prop: 'name'
        },
        {
          label: '位置',
          prop: 'location',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '状态',
          prop: 'status'
        },
        {
          label: '配置',
          prop: 'meetingRoomAssetName',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '面积',
          prop: 'price'
        },
        {
          label: '容纳人数',
          prop: 'num'
        },
        {
          label: '创建时间',
          prop: 'createTime',
          width:160,
        },
        {
          label: '操作',
          input_type: 'button',
          width: 180,
          attribute: {
            fixed: 'right',
            align: 'center'
          },
          list: [
            {
              text: '编辑',
              method: 'rowEdit',
              limit: 'conferenceRoom::Management::update'
            },
            {
              text: '删除',
              method: 'rowDelete',
              limit: 'conferenceRoom::Management::del'
            }
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      titleText: '编辑',
      initFileList: [],
      rowFormData: [
        {
          input_type: 'input',
          label: '编号',
          key: 'code'
        },
        {
          input_type: 'input',
          label: '会议室名称',
          key: 'name'
        },
        {
          input_type: 'input',
          label: '位置',
          key: 'location'
        },
        {
          input_type: 'radio',
          label: '状态',
          key: 'status',
          options: [
            {
              label: '启用',
              value: 0
            },
            {
              label: '禁用',
              value: 1
            }
          ]
        },
        {
          input_type: 'number',
          label: '容纳人数',
          max:10000,
          key: 'num',
        },
         {
          input_type: 'input',
          label: '面积',
          key: 'price',
        },
        {
          input_type: 'input',
          customize: 'linkageDeviceName',
          label: '关联设备',
          key:'linkageDeviceName',
          focusMethod:"linkageDeviceFun"
        },
        {
          input_type: 'checkbox',
          label: '配置',
           col: 24,
          key: 'meetingRoomAsset',
          options: 
          function () {
            return dictData('meeting_room_asset').then((res) => {
              if (res.code == 200) {
                
                let data = res.data.map((item) => {
                  console.info(item)
                  return {
                    label: item.dictLabel,
                    value: item.dictValue
                  }
                })
                console.info(data)
                return data
              }
            })
          },
        },
        {
          input_type: 'customize',
          customize: 'uplpadfaceImg',
          label: '图片',
          key: 'pictureUrl',
          col: 24
        },
        
      ],
      checkImgSuccess: false,
      isExist: null,
      rules: {
        pictureUrl: [
          {
            required: true,
            // message: '图片必传',
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (!this.checkImgSuccess) {
                //  console.log("图片----2525252525-",this.checkImgSuccess)
                return callback(new Error('图片必传！'))
              } else if (this.isExist) {
                return callback(new Error('存在上传失败文件，请重新上传!'))
              } else {
                callback()
              }
            }
          }
        ],
        code: [
          {
            required: true,
            message: '请输会议室编号',
            trigger: 'blur'
          }
        ],
        location: [
          {
            required: true,
            message: '请输会议室位置',
            trigger: 'change'
          }
        ],
        price: [
          {
            required: true,
            // message: '请填写建议价格',
            validator: this.util.formRules.checkNumber({ min: 0, fixedNum: 2 }),
            trigger: 'change'
          }
        ],
        name: [
          {
            required: true,
            message: '请输入会议室名称',
            trigger: 'blur'
          }
        ],
        num: [
          {
            required: true,
            message: '请输入容纳人数',
            trigger: 'change'
          }
        ],
        status: [
          {
            required: true,
            message: '请选择状态',
            trigger: 'change'
          }
        ]
      },
      rowForm: {
      },
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`,
      // 图片相关
      headers: {},
      dialogVisible: false,
      dialogObj: null,
      fileList: [],
      isSearchIng: false,
      isAdd: true
    }
  },
  created() {
    //  console.log("$uploadUrl",this.$uploadUrl)
  },
  mounted() {},
  methods: {
    getInit: function () {
      this.loading = true
      getMeetingRoom(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    // 新增
    append() {
      this.titleText = '新增'
      this.isAdd = true
      this.rowForm = { meetingRoomAsset:[] }
      this.initFileList = []
      this.util.clearSelectOptions(this.rowFormData)
      this.showEditAll = true
    },
    // 编辑
    rowEdit: function (row, details) {
      this.isAdd = false

      console.log("row",row)
      this.rowForm = {
        ...row
      }
      // this.initFileList=[this.rowForm.pictureUrl]
      if(this.rowForm.pictureUrl){
        this.initFileList=[
          {
            url:this.rowForm.pictureUrl,
            raw: {type :'image'},
            status:'success',
            response:{data:this.rowForm.pictureUrl}

          }
        ]
        this.checkImgSuccess=true
      }
      console.log(this.initFileList)
      if (details == 'details') {
        this.titleText = '详情'
      } else {
        this.titleText = '编辑'
      }
      console.log("什么鬼--", this.rowForm)
      this.showEditAll = true
    },

    // 删除
    rowDelete(row) {
      this.$confirm(
        '确定删除该条数据吗？'
      ).then((res) => {
        console.log('row', row)
        delMeetingRoom(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    saveForm: function () {
      // 保存
      // console.log("this.rowForm11111",this.rowForm)
      this.loading_form.isShow = true
      if (this.isAdd) {
        addMeetingRoom(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('新增成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      } else {
        //  更新园区信息
        editMeetingRoom(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('修改成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      }
    },
    // 图片上传成功之后
    uploadFinish() {
      let fileList = this.$refs.Upload.fileList
      console.log('fileList---', fileList)
      this.checkImgSuccess = true
      this.isExist = fileList.find((item) => item.response.code != 200)
      if (!this.isExist) {
        let imgList=fileList.map((item) => {
          return item.response.data
        })
        this.rowForm.pictureUrl=imgList[0]
      }
      // this.rowForm.pictureUrl =
      //   'http://www.weaving-comm.com/assets/images/Home/house.png'
      this.$refs.jaForm.$refs.formbox.clearValidate('pictureUrl')
    },
    // 删除图片
    imgRemoveMethod() {
      this.checkImgSuccess = false
      console.log('this.rowForm', this.rowForm)
    },
    // 关闭表单弹窗
    close(){
      
    },
    valueFormat(val, key) {
      if (key == 'status') {
        const statusVal = { 0: '启用', 1: '禁用' }
        return statusVal[val]
      }
      if (key == 'meetingRoomAssetName') {
        const meetingRoomAssetName = val.join(',')
        return meetingRoomAssetName
      }
      return val
    },
    // 关联设备
    linkageDeviceFun(){
      this.showDevice=true;
    },
    // 选择设备
    appendDevice(data){
      this.$set(this.rowForm,'linkageDeviceName',data[0].deviceName)
      this.rowForm.linkageDevice=data[0].id
      this.showDevice=false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}

// 上传图像
.imgDefault {
  width: 150px;
  height: 200px;
  border: 1px solid $theme_bordercolor_DCDFE6;
}

/deep/ .el-upload__tip {
  position: absolute;
  width: 20%;
  top: 45%;
  left: 37%;
  text-align: center;
  line-height: 1;
  margin: 10px 0 8px 0;
}

.upload-img-form /deep/ .el-upload--picture-card {
  background-color: initial;
  border: initial;
  border-radius: initial;
  box-sizing: initial;
  width: initial;
  height: initial;
  line-height: initial;
  vertical-align: initial;
  display: inline-block;
  text-align: initial;
}

/deep/ ul.el-upload-list--picture-card {
  display: block;
}

/deep/ .el-upload-list.el-upload-list--text {
  width: 50%;

  > li.el-upload-list__item {
    cursor: pointer;
  }
}

// 上传图片按钮
.uploadBtn {
  position: absolute;
  top: 30%;
  left: 40%;
  color: $theme_color_0083ee;
  background: transparent;
  border: 1px solid $theme_bordercolor_0083ee;

  &:hover,
  &:focus {
    background: transparent;
    color: $theme_color_0083ee;
  }
}

// 图片缩略图
.videoContent {
  width: 40%;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 180px;
      height: 200px;
      flex-shrink: 0;
      margin: 0;
      border: 1px solid $theme_bordercolor_DCDFE6;
      border-radius: 0;
      overflow: hidden;
      position: relative;

      &:hover {
        .videoPlay_IconBox {
          display: none;
        }
      }

      .img-avatar {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
