<template>
  <!-- 建议收集列表 -->
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" @rowDetail="normalEvent" @getInit="getInit" @search="search" @reset="reset">
    </ja-table>
    <!-- 查看详情 -->
    <detail ref="detail" />
  </div>
</template>
<script>
import { getMsgSuggestList } from '@/api'
import table from '@/views/mixins/table'
import Detail from './childComp/Detail.vue'

export default {
  title: '建议收集列表',
  mixins: [table],
  components: {
    Detail
  },
  data() {
    return {
      conditionData: [
        { input_type: 'input', key: 'content', attribute: { placeholder: '建议内容', size: 'small' } },
        { input_type: 'select', key: 'type', attribute: { placeholder: '业务', size: 'small' }, options: [], },
        { input_type: 'input', key: 'submitter', attribute: { placeholder: '提交人', size: 'small' } },
        { input_type: 'date', key: 'occurrenceTime', value: 'occurrenceTime', type: 'date', placeholder: '提交日期', valueFormat: 'yyyy-MM-dd' },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
      ],
      columns: [
        { label: '内容', prop: 'content', attribute: { showOverflowTooltip: true, align: 'center' } },
        { label: '业务', prop: 'typeValue', },
        { label: '提交人', prop: 'submitter' },
        { label: '联系电话', prop: 'mobile' },
        { label: '创建时间', prop: 'createTime', },
        {
          label: '操作', input_type: 'button', attribute: { fixed: "right", align: 'center' },
          list: [
            { text: '查看', method: 'rowDetail' },
          ]
        },
      ],
      indexObj: {}
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    this.util.rqOptionsList({ who: [this.conditionData[this.indexObj.type]], rqUrl: "/sys/dictData/advise_type", resRule: 'data' })
  },
  methods: {
    getInit() {
      this.loading = true
      const rqData = this.rqParmas()
      getMsgSuggestList(rqData).then(res => {
        this.loading = false
        const { total, data } = res.data
        this.total = total
        this.tableData = data
      })
    }
  }
}
</script>
