import http from './plugin/ajax'

// 新增资讯
export const infoManagementAdd = (data) => http.post('/infoManagement', data)

//编辑资讯
export const infoManagementEdit = (data) => http.put('/infoManagement', data)
//审批资讯
export const infoExamineAndApprove = (data) => http.put('/infoManagement/examineAndApprove', data)
//删除资讯
export const infoManagementDelete = (data) => http.delete('/infoManagement/' + data)

//资讯信息列表
export const infoManagementGet = (data) => http.get('/infoManagement', {
  params: data
})

