<template>
  <!-- 新增/编辑 -->
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="showEditAll"
    width="40%"
    @close="close"
    :title="titleText"
  >
    <ja-form
      :rules="rules"
      :label-width="labelWidth"
      v-model="rowForm"
      :formArray="rowFormData"
      ref="jaForm"
      :allDisabled="allDisabled"
      @reset="showEditAll = false"
      @save="saveForm"
      :isDisabledFun="isDisabledFun"
      v-if="showEditAll"
    >
      <template #address>
        <div>地址相关</div>
      </template>
    </ja-form>
  </el-dialog>
</template>
<script>
import { getDictData,addRoom,updateRoom } from '@/api'
export default {
  inject: ['labelWidth'],
  data() {
    return {
      // 新增/编辑
      titleText: '编辑',
      rowFormData: [
        { input_type: 'input', label: '房间名称', key: 'roomName' ,},
         { label:'楼栋', input_type: 'select',key: 'buildingId',
                    relevance:{ sonKey:'floorId', This:this, type:'select', // 必填。而 rqParam 是异步请求，必填字段
                        rqParam:{ rqUrl:"/floor/all",rqData:()=>{return {params:{ buildingId: this.rowForm.buildingId } }},labelName:'floorName',valueName:'floorId',resRule:'data'}, // 
                    }
                },
          { label:'楼层', input_type: 'select',key: 'floorId',options: [],},
        {
          input_type: 'select',
          label: '用途',
          key: 'purpose',
          options: function () {
            return getDictData('room_purpose').then((res) => {
              console.log(res.data)
              let data = res.data.map((item) => {
                return {
                  label: item.dictLabel,
                  value: item.dictValue
                }
              })
              return data
            })
          }
        },
        { input_type: 'select', label: '户型', key: 'category',  options: function () {
            return getDictData('room_category').then((res) => {
              if (res.code == 200) {
                let data = res.data.map((item) => {
                  return {
                    label: item.dictLabel,
                    value: item.dictValue
                  }
                })
                return data
              }
            })
          } 
        },
        {
          input_type: 'input',
          label: '套内面积',
          key: 'insideArea',
          max:10000000,
          min:0,
          options: []
        },
        { input_type: 'input', label: '建筑面积', key: 'outsideArea' },
        {
          label: '备注',
          key: 'remark',
          input_type: 'input',
          type: 'textarea',
          col: 22,
          showWordLimt: true,
          rows: 5
        }
      ],
      rules: {
        roomName: [
          { required: true, message: '请填写房间名称', trigger: 'change' }
        ],
        buildingId: [
          { required: true, message: '请选择楼栋', trigger: 'change' }
        ],
        floorId: [{ required: true, message: '请选择楼层', trigger: 'change' }],
        insideArea: [
          { 
            required: true, 
          // message: '请填套内面积', 
          validator: this.util.formRules.checkNumber({max:1000000}),
          trigger: 'blur'
           }
        ],
        outsideArea: [
          { required: true,  validator: this.util.formRules.checkNumber({max:1000000}), trigger: 'blur' }
        ]
      },
      allDisabled:false,
      rowForm: {},
      showEditAll: false // 显示新增/编辑弹窗
    }
  },
  created() {

  },
  mounted() {
    let index2 =  this.rowFormData.findIndex(item=>item.key == 'buildingId' )
      this.util.rqOptionsList({who:[this.rowFormData[index2]],rqUrl:"/building/list",rqData:{ pageIndex: 1, pageSize: 200 },rqMethod:'post',labelName:'buildingName',valueName:'id'})
  },
  methods: {
    append() {
      this.isAdd = true
      this.titleText = '新增'
      this.rowForm = {}
      this.util.clearSelectOptions(this.rowFormData)
      this.showEditAll = true
    },
    /**
     *Disabled回调函数
     编辑的时候就禁用 楼栋跟楼层编辑
     */
    isDisabledFun(item){
      if((item.key=="buildingId" ||item.key=="floorId") &&  this.titleText=="编辑"){
        return true
      }else{
        return false
      }
      
    },
    rowEdit(row,details) {
      // console.log('row===', row)
      // console.log("details---11111",details)
      if(details=="details"){ // 当点击标题查看详情时让所有表单禁用
        // console.log("details---",details)
        this.allDisabled=true 
        this.titleText = '详情'
      }else{
        this.titleText = '编辑'
      }

      this.isAdd = false
      this.rowForm = { ...row }
      console.log('row===', this.rowForm)
      this.showEditAll = true
      this.$nextTick(()=>{
          this.util.setOptionsInit({arr:this.rowFormData,This:this,obj:this.rowForm,ref:'jaForm'})
      })
    },
    // 保存
    saveForm() {
      if (this.isAdd) {
        console.log('新增===》', this.rowForm)
        addRoom(this.rowForm)
          .then((res) => {
            this.$message.success('新增成功')
            this.showEditAll = false
            this.$parent.getInit()
          })
          .catch((error) => {
            // this.$message.error(error)
          })
      } else {
        updateRoom(this.rowForm)
          .then((res) => {
            this.$message.success('修改成功')
            this.showEditAll = false
            this.$parent.getInit()
          })
          .catch((error) => {
            // this.$message.error(error)
          })
      }
    },
    // 关闭弹窗
    close(){
      this.allDisabled=false
    }
  }
}
</script>