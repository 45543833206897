<template>
  <router-view />
</template>

<script>
export default {
  title: "统计",
  name: 'eleCount'
}
</script>

<style scoped>
</style>
