<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @append="append"
      @rowEdit="rowEdit"
      @getInit="getInit"
      @search="search"
      @rowDelete="rowDelete"
      @approve="approve"
      @offline="offline"
      @see="see"
      :valueFormat="valueFormat"
      :editDataFormat="editDataFormat"
      @reset="reset"
    >
      <template #pictureUrl="{row}">
        <!-- <div class="firstcell el-button--text" @click="seeDetails(row)">{{row.pictureUrl}}</div> -->
        <el-image
          style="width: 50px; height: 50px"
          :src="row.pictureUrl"
          :preview-src-list="[row.pictureUrl]"
          fit="cover"
        ></el-image>
      </template>
      <template #content="{row}">
        <div class="table_content" >
          {{row.contentText }}
        </div>
      </template>
    </ja-table>
    <!-- 新增/编辑 -->
    <!-- reset: 取消 -->
    <!-- saveForm: 保存操作 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="50%"
      @close="close"
      :title="titleText"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :allDisabled="allDisabled"
        @reset="showEditAll = false"
        @save="saveForm"
        v-if="showEditAll"
        ref="jaForm"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
        <template #uplpadfaceImg>
          <upload-file
            ref="Upload"
            @uploadFinish="uploadFinish"
            @imgRemoveMethod="imgRemoveMethod"
            :autoUpload="true"
            :accept="'.jpeg,.png,.jpg'"
            :limit="1"
            :initFileList="[...initFileList]"
            :fileSize="10"
            @uploadBtn="uploadBtn"
            v-if="showEditAll"
          />
        </template>
        <template #content>
          <!-- <Tinymce ref="editor" :toolbar="toolbar" v-model="rowForm.content" :height="400" /> -->
           <editor-mce :config="Config" :value.sync="rowForm.content" :Id="Date.now()" ref="msg" :url="$uploadUrl+'/oss/upload'"></editor-mce>
        </template> 
      </ja-form>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showDetail"
      width="50%"
      @close="close"
      title="详情"
    >
      <ja-detail :options="detailOptions" :detailData="detailData"></ja-detail>
    </el-dialog>
  </div>
</template>
<script>
import {
  infoManagementGet,
  infoManagementAdd,
  infoManagementEdit,
  infoManagementDelete,
  infoExamineAndApprove
} from '@/api'
import table from '@/views/mixins/table'
import editorMce from '@/components/editorMce'
export default {
  title: '资讯管理',
  mixins: [table],
  components:{
    editorMce
  },
  name: 'merchantManage',
  data() {
    return {
      Config: { height: 300 },
      showDetail:false,
      detailOptions:
      [
          { label: '标题', key: 'title', col: 24 },
          { label: '排序', key: 'weight', col: 24},
          { label:'图片',type: 'img', key: 'pictureUrl', col: 24 },
          { label: '内容', key: 'content', col: 24 },
      ],
      detailData: {},
      show: false,
      showUser: false,
      parkId: 1,
      allDisabled: false,
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
        // cityId:''
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'title',
          attribute: {
            placeholder: '资讯标题',
            size: 'small'
          }
        },
        {
          input_type: 'input',
          key: 'content',
          attribute: {
            placeholder: '内容',
            size: 'small'
          }
        },
        {
          input_type: 'select',
          key: 'approveStatus',
          attribute:{
           placeholder: '状态',
          },
          options:[
            {
              label:"待审批",
              value:"0"
            },
            {
              label:"已发布",
              value:"1"
            },
            {
              label:"审批拒绝",
              value:"2"
            },
            {
              label:"下线",
              value:"3"
            },
          ]
        },
        {
         input_type: 'input',
          key: 'createBy',
          attribute:{
           placeholder: '创建人',
          },
        },
        {
          input_type: 'date',
          value: 'createTime',
          type: 'date',
          placeholder: '创建日期'
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '新增',
          input_type: 'button',
          method: 'append',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-plus',
            plain: true
          },
          limit: 'info::infoManage::add'
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '图片',
          prop: 'pictureUrl',
          slotName: 'pictureUrl',
          width: 100
        },
        {
          label: '标题',
          prop: 'title'
        },
        // {
        //   label: '内容',
        //   prop: 'content'
        // },
        { label: '内容', prop: 'content', width: 250, attribute: { showOverflowTooltip: true, align: 'center' }, slotName: 'content' },

        {
          label: '排序',
          prop: 'weight',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '状态',
          prop: 'approveStatus',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '创建人',
          prop: 'createBy'
        },
        {
          label: '创建时间',
          prop: 'createTime'
        },
        {
          label: '操作',
          input_type: 'button',
          width: 180,
          attribute: {
            fixed: 'right',
            align: 'center'
          },
          list: [
            {
              text: '编辑',
              method: 'rowEdit',
              limit: 'info::infoManage::update'
            },
            {
              text: '审批',
              method: 'approve',
              limit: 'info::infoManage::approve'
            },
            {
              text: '查看',
              method: 'see',
              limit: 'info::infoManage::see'
            },
            {
              text: '下线',
              method: 'offline',
              limit: 'info::infoManage::offline'
            },
            {
              text: '删除',
              method: 'rowDelete',
              limit: 'info::infoManage::del'
            }
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      titleText: '编辑',
      initFileList: [],
      rowFormData: [
        {
          input_type: 'input',
          label: '标题',
          key: 'title'
        },
        {
          input_type: 'customize',
          customize: 'uplpadfaceImg',
          label: '图片',
          key: 'pictureUrl',
          col: 24
        },
        {
          input_type: 'customize',
          customize: 'content',
          label: '内容',
          key: 'content',
          col: 22
        },
        {
          input_type: 'number',
          max:100,
          label: '排序',
          stepStrictly:true,
          col: 22,
          key: 'weight'
        },
        
      ],
      checkImgSuccess: false,
      isExist: null,
      rules: {
        // pictureUrl: [
        //   {
        //     required: true,
        //     trigger: 'change',
        //     validator: (rule, value, callback) => {
        //       if (!this.checkImgSuccess) {
        //         return callback(new Error('图片必传！'))
        //       } else if (this.isExist) {
        //         return callback(new Error('存在上传失败文件，请重新上传!'))
        //       } else {
        //         callback()
        //       }
        //     }
        //   }
        // ],
        title: [
          {
            required: true,
            message: '请输入标题',
            trigger: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: '请输入内容',
            trigger: 'change'
          }
        ],
      },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`,
      // 图片相关
      headers: {},
      dialogVisible: false,
      dialogObj: null,
      fileList: [],
      isSearchIng: false,
      isAdd: true,
      imgState:true,
    }
  },
  created() {
    //  console.log("$uploadUrl",this.$uploadUrl)
  },
  watch: {
    'rowForm.content': {
      handler: function () {
        if (this.$refs.jaForm) {
          this.$refs.jaForm.$refs.formbox.validateField('content')
        }
      }
    }
  },
  mounted() {},
  methods: {
    getInit: function () {
      this.loading = true
      infoManagementGet(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = (this.tableData||[]).map((item) => {
            let content = item.content
            var arrEntities={'lt':'<','gt':'>','nbsp':' ','amp':'&','quot':" "}; 
            item.contentText = content.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ').replace(/&(lt|gt|nbsp|amp|quot);/ig,function(all,t){return arrEntities[t];})
                                .replace(/&ldquo;/g,'“').replace(/&rdquo;/g,'”').replace(/&mdash;/g,'——')
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    // 新增
    append() {
      this.titleText = '新增'
      this.isAdd = true
      this.rowForm = {}
      this.initFileList = []
      this.util.clearSelectOptions(this.rowFormData)
      this.showEditAll = true
    },
    // 编辑
    rowEdit: function (row, details) {
      this.isAdd = false

      // console.log("row",row)
      this.initFileList = [] // 清空图片
      this.rowForm = {
        ...row
      }
      // this.initFileList=[this.rowForm.pictureUrl]
      if(this.rowForm.pictureUrl){
        this.initFileList=[
          {
            url:this.rowForm.pictureUrl,
            raw: {type :'image'},
            status:'success',
            response:{data:this.rowForm.pictureUrl}

          }
        ]
        this.checkImgSuccess=true
      }
      console.log(this.initFileList)
      if (details == 'details') {
        this.titleText = '详情'
      } else {
        this.titleText = '编辑'
      }
      // console.log("什么鬼--", [].map(Number))
      this.showEditAll = true
    },
    // 查看
    see(row){
      this.showDetail=true
      this.detailData=row
    },
    // 删除
    rowDelete(row) {
      this.$confirm(
        '确定删除该条数据吗？'
      ).then((res) => {
        console.log('row', row)
        infoManagementDelete(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    saveForm: function () {
      // 保存
      // console.log("this.rowForm11111",this.rowForm)
      if(!this.imgState){
        this.$message.error("图片正在上传中,请稍等！")
        return false
      }
      this.loading_form.isShow = true
      if (this.isAdd) {
        //  添加园区信息
        infoManagementAdd(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('新增成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      } else {
        //  更新园区信息
        infoManagementEdit(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success('修改成功')
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            console.log(error)
            this.loading_form.isShow = false
          })
      }
    },
    // 图片上传成功之后
    uploadFinish(response, file) {
      let fileList = this.$refs.Upload.fileList
      this.checkImgSuccess = true
      this.isExist = fileList.find((item) => item.response.code != 200)
      if (!this.isExist) {
        this.imgState=true;  // 监听 图片上传完成
        let imgList=fileList.map((item) => {
          return item.response.data
        })
        this.rowForm.pictureUrl=imgList[0]
      }
      
      this.$refs.jaForm.$refs.formbox.clearValidate('pictureUrl')
    },
    // 删除图片
    imgRemoveMethod() {
      this.checkImgSuccess = false
      console.log('this.rowForm', this.rowForm)
    },
    // 审核
    approve: function(row) {
      let params = {
        id: row.id,
        approveStatus: 0,
        approveRemark: ''
      }
      let _this = this
      this.$prompt('请输入备注', '审批', {
        confirmButtonText: '通过',
        cancelButtonText: '拒绝',
        distinguishCancelAndClose: true,
        inputErrorMessage: '审批内容不能为空，且小于200字数！',
        inputValidator:function(params) {
          if(!params || params.trim().length>200){
            return false
          }else{
            return true
          }
        },
        beforeClose: function(action, instance,done) {
          // console.log(action, instance,done)
          params.approveRemark = instance.inputValue
          if (action == 'confirm'){
            params.approveStatus = 1
            infoExamineAndApprove(params).then((res) => {
              if (res.code === 200) {
                _this.$message.success('已通过')
              }
              _this.getInit()
              done()
            })
          } 
          if (action == 'cancel') {
            params.approveStatus = 2
            if(!instance.inputValue || instance.inputValue.trim().length>200){
              this.$message({
                type: 'error',
                message: '拒绝内容不能为空，且小于200字数！'
              })
            }else{
              infoExamineAndApprove(params).then((res) => {
                if (res.code === 200) { 
                  _this.$message.success('已拒绝')
                }
                _this.getInit()
                done()
              })
            }
          }
          if (action=='close'){
            done()
          }
        }
      }).then(action => {
          
      }).catch(err =>{

      })
  
        
    },
    offline:function(row) {
      let params = {
        id: row.id,
        approveStatus:3,
        approveRemark: ''
      }
      let _this = this
      this.$confirm(
        '确定下线该条资讯吗？'
      ).then((res) => {
        infoExamineAndApprove(params).then((res) => {
          _this.$message.success('已下线')
          _this.getInit()
        })
      })
    },
    // 转换值
    valueFormat(val, key, row){
      if (key == 'approveStatus') {
        let list={
              0:"待审批",
              1:"已发布",
              2:"审批拒绝",
              3:"下线",
            }
        return list[val]
      }
      return val
    },
     /**
     * 控制按钮显示隐藏 审批状态 0-待审批 1-审批通过(已发布) 2-审批拒绝 3-下线
     *  1） 待审批：显示审批、编辑按钮；
        2） 审批拒绝状态：显示查看、删除按钮；
        3） 已发布状态：显示下线、查看按钮；
        4） 下线状态：显示删除按钮。
     */
    editDataFormat(row, button, scope){
      if(row.approveStatus == 0){
        if(button.text=='审批' || button.text=='编辑'){
          return true
        }
      }else if(row.approveStatus==1){
        if(button.text=='下线' || button.text=='查看' ){
          return true
        }
      }else if(row.approveStatus==2){
        if(button.text=='查看'|| button.text=='删除' ){
          return true
        }
      }else if(row.approveStatus==3){
        if(button.text=='删除'){
          return true
        }
      }
      return false
    },
    // 关闭表单弹窗
    close(){
      this.imgState=true;
    },
    /***
     * 点击上传图片按钮
     */
    uploadBtn(){
      this.imgState=false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}

// 上传图像
.imgDefault {
  width: 150px;
  height: 200px;
  border: 1px solid $theme_bordercolor_DCDFE6;
}

/deep/ .el-upload__tip {
  position: absolute;
  width: 20%;
  top: 45%;
  left: 37%;
  text-align: center;
  line-height: 1;
  margin: 10px 0 8px 0;
}

.upload-img-form /deep/ .el-upload--picture-card {
  background-color: initial;
  border: initial;
  border-radius: initial;
  box-sizing: initial;
  width: initial;
  height: initial;
  line-height: initial;
  vertical-align: initial;
  display: inline-block;
  text-align: initial;
}

/deep/ ul.el-upload-list--picture-card {
  display: block;
}

/deep/ .el-upload-list.el-upload-list--text {
  width: 50%;

  > li.el-upload-list__item {
    cursor: pointer;
  }
}

// 上传图片按钮
.uploadBtn {
  position: absolute;
  top: 30%;
  left: 40%;
  color: $theme_color_0083ee;
  background: transparent;
  border: 1px solid $theme_bordercolor_0083ee;

  &:hover,
  &:focus {
    background: transparent;
    color: $theme_color_0083ee;
  }
}

// 图片缩略图
.videoContent {
  width: 40%;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 180px;
      height: 200px;
      flex-shrink: 0;
      margin: 0;
      border: 1px solid $theme_bordercolor_DCDFE6;
      border-radius: 0;
      overflow: hidden;
      position: relative;

      &:hover {
        .videoPlay_IconBox {
          display: none;
        }
      }

      .img-avatar {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.table_content {
  max-height: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
