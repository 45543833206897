<template>
  <!-- 消息模板 -->
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" @rowDelete="rowDelete" :tableData="tableData" :total="total" :loading="loading" @append="normalEvent({ref:'addEdit',method:'append'})" @rowEdit="normalEvent" @getInit="getInit" @search="search" @reset="reset">
      <!-- <template #content="{row}">
        <div class="table_content">
          {{row.contentText }}
        </div>
      </template> -->
    </ja-table>
    <add-edit ref="addEdit" />
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit'
import { getMsgTemplalteList, delMsgTemplalte } from '@/api'

export default {
  title: '消息模板',
  mixins: [table],
  components: {
    AddEdit,
  },
  data() {
    return {
      conditionData: [
        { input_type: 'input', key: 'title', attribute: { placeholder: '模板名称', size: 'small' } },
        { input_type: 'input', key: 'createBy', attribute: { placeholder: '创建人', size: 'small' } },
        { input_type: 'date', value: 'createTime', type: 'date', placeholder: '创建日期', valueFormat: 'yyyy-MM-dd' },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
        { normalBtn: 'add', text: '新增模板', },
      ],
      columns: [
        { label: '模板名称', prop: 'title'},
        // { label: '内容', prop: 'content', width: 250, attribute: { showOverflowTooltip: true, align: 'center' }, slotName: 'content' },
        { label: '内容', prop: 'content', attribute: { showOverflowTooltip: true, align: 'center' }, },
        { label: '关联事件编号', prop: 'eventId', },
        { label: '创建人', prop: 'createBy',  },
        { label: '创建时间', prop: 'createTime' },
        {
          label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", align: 'center' },
          list: [
            { text: '编辑', method: 'rowEdit', },
            { text: '删除', method: 'rowDelete', }
          ]
        }
      ],
      rowForm: {},
      indexObj: {}
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    // this.util.rqOptionsList({ who: [this.conditionData[this.indexObj.category]], rqUrl: "/sys/dictData/tenant_category", resRule: 'data' })
  },
  methods: {
    getInit() {
      this.loading = true
      const rqData = this.rqParmas()
      getMsgTemplalteList(rqData).then(res => {
        this.loading = false
        const { total, data } = res.data
        this.total = total
        this.tableData = data
        //   this.tableData = data || [].map(item => {
        //     let content = item.content
        //     item.contentText = content.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ')
        //     return item
        //   })
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm(`是否确认删除模板“${row.title}”`).then(res => {
        delMsgTemplalte(row.id).then(res => {
          this.$message.success("已删除")
          this.getInit()
        })
      })
    },
  }
}
</script>
<style scoped>
.table_content {
  max-height: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
