<template>
<div class="detail" v-loading="loadingForm||false" :element-loading-text="loadingFormObj.text" :element-loading-spinner="loadingFormObj.icon" :element-loading-background="loadingFormObj.bg" :element-loading-custom-class="loadingFormObj.formLoading">
  <el-row class='ja-detail' >
    <h2 v-if="titleText">{{titleText}}</h2>
    <template v-for="(item,index) in options">
      <el-col :key="index" :span="parseInt(item.col) || 12" v-if="!item.hide" :style="{margin:isPrint?'10px 0':'',}" class="el-col-mine">
        <template v-if="item.type === 'normal' || !item.type">
          <div class="item">
            <span :class="{'isTitle': item.isTitle}" :style="{width:item.label_width?item.label_width+'em':label_width+'em'}">{{item.label}}:</span>
            <span v-if="item.options && item.options.length>0">{{ detailData[item.key] | optionsFormat(item)}}</span>
            <span class="itemRight" v-html="detailData[item.key]" v-else></span>
          </div>
        </template>

        <template v-if="item.type === 'img'">
          <div class="item itemImg">
            <span :style="{width:item.label_width?item.label_width+'em':label_width+'em'}">{{item.label}}：</span>
            <div v-if="Array.isArray(detailData[item.key]) && detailData[item.key].length>0" class="imgBox">
              <el-image v-for="(imgUrl,img_index) in detailData[item.key]" :key="img_index" class="img" :lazy='true' :src="imgUrl" :preview-src-list="detailData[item.key]" fit='cover'></el-image>
            </div>
            <el-image v-if="!Array.isArray(detailData[item.key]) && detailData[item.key]" class="img" :lazy='true' :src="detailData[item.key]" :preview-src-list="[detailData[item.key]]" fit='cover'></el-image>
          </div>
        </template>

        <template v-if="item.type === 'table'">
          <div class="item tableBox">
            <span :style="{width:item.label_width?item.label_width+'em':label_width+'em'}">{{item.label}}:</span>
            <div class="table" :style="{marginLeft:'0',width:`calc(100% - ${item.label_width?item.label_width+1+'em':label_width+1+'em'} - 6%)`}">
              <ja-table class="conditon" v-bind="item" v-on="$listeners" :columns="item.columns" v-model="item.filterData" :tableData="detailData[item.key]" :valueFormat="valueFormat" :isFromForm="true" :style="{width:item.styleWidth}">
              </ja-table>
            </div>
          </div>
        </template>

        <template v-if="item.type === 'customize'">
          <div class="item" :style="{display: 'flex',alignItems:item.alignItems}">
            <span :style="{width:item.label_width?item.label_width+'em':label_width+'em'}" v-if="item.label">{{item.label}}{{item.label && item.label.trim() ?':':''}}</span>
            <div :style="{marginLeft:'0',width:`calc(100% - ${item.label_width?item.label_width+1+'em':label_width+1+'em'} - 10%)`}">
              <slot :name="item.customize" :row="detailData[item.key]"></slot>
            </div>
          </div>
        </template>
      </el-col>
    </template>

    <slot></slot>
  </el-row>
    <div v-if="btnBox.length>0" class="btns">
      <el-button v-for="(item,index) in btnBox" :disabled="item.disabled" :style="item.style" :plain="item.plain || false" :key="index+0.0001" :type="item.type || ''" @click="btnClick(item.method,item)">{{item.label}}</el-button>
    </div>
  </div>
</template>

<script>
import constObj from '@/util/config'
import tableM from '@/views/mixins/table.js'

export default {
  mixins: [tableM],
  name: 'ja-detail',
  props: {
    options: Array, // label,key,col,show,type, img、table、normal
    detailData: Object,
    // 表单的加载
    loadingForm: {
      type: Boolean,
      default: false
    },
    btnBox: {
      type: Array,
      default: function () {
        return []
      }
    },
    // table内容格式转换
    valueFormat: {
      type: Function,
      default: function (val, key, row, scope) {
        // console.log('val--->', val, key)
        return val
      }
    },
    label_width: {
      type: Number,
      default: 8
    },
    titleText: String,
    isPrint: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingFormObj: {},
    }
  },
  computed: {
    i18n() {
      return ''
    }
  },
  created() {
    this.loadingFormObj = constObj.loadingFormObj
  },
  filters: {
    optionsFormat: (val, item) => {
      if (item.options && item.options.length > 0) {
        let obj = {}
        item.options.forEach(item => [
          obj[item.value] = item.label
        ])
        return obj[val]
      } else {
        return val
      }
    }
  },
  methods: {
    btnClick(method, item) {
      this.$emit(method, item)
    }
  }
}
</script>

<style scoped lang='scss'>
@import '~@/style/mixin.scss';
.ja-detail {
  h2 {
    color: $sub-title-color;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .el-col-mine {
    margin-bottom: 5px;
  }
  .item {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    line-height: 40px;
    font-size: 14px;
    .itemRight {
      width: 100%;
      flex: 1;
      line-height: 18px;
      /deep/ img {
        max-width: 100%;
      }
    }
    &.tableBox {
      display: flex;
      margin: 10px 0;
      .title {
        margin-top: 8px;
        margin-bottom: initial;
        font-weight: bold;
        text-align: left;
      }
    }
    &.itemImg {
      display: flex;
      .imgBox {
        display: flex;
        flex-wrap: wrap;
        .img {
          margin: 10px 10px 10px;
        }
      }
    }
    .img {
      width: 150px;
      height: 150px;
    }
    > span:nth-of-type(1) {
      width: 8em;
      text-align: right;
      margin-right: 1em;
      &.isTitle {
        text-align: left;
        font-weight: 600;
        font-size: 15px;
        color: #606266;
      }
    }
    h2 {
      width: 6em;
      margin-top: 20px;
      font-size: 18px;
      text-align: right;
    }
    .table {
      width: 100%;
      // margin-top: 20px;
      margin-left: 20%;
    }
  }
}
.btns{
  display: flex;
  justify-content: center;
}
</style>
