<template>
  <div class="allwh minWidth1000">
    <ja-table
      ref="tst"
      border
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      :showPage="false"
      @getInit="getInit"
      @search="search"
      @reset="reset"
    >
    <template #date>
      <el-date-picker
      v-model="filterData.date"
      type="daterange"
      align="right"
      unlink-panels
      value-format='yyyy-MM-dd'
      
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :picker-options="pickerOptions">
      </el-date-picker>
    </template>
    <template #tableBot>
      <div class="tableBot">
        <p>供应情况</p>
        <ja-table
        border
        noSearch
        ref="tst"
        :showPage="false"
        :columns="columnsChi"
        :tableData="tableDataChi"
        :loading="loading"
        >
        </ja-table>
      </div>
    </template>
    </ja-table>
  </div>
</template>
<script>
import {
  getCompare,
} from '@/api'
import table from '@/views/mixins/table'
export default {
  title: '采购对比统计',
  mixins: [table],
  name: 'index',
  data() {
    return {
      show: false,
      showUser: false,
      allDisabled: false,
      pickerOptions: {
        shortcuts: [
        {
          text: '昨天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            picker.$emit('pick', [start, end]);
          }
        }, 
        {
          text: '近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
            picker.$emit('pick', [start, end]);
          }
        }, 
        {
          text: '近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, 
        {
          text: '近一月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10,
        date:''
      },
      conditionData: [
        {
          input_type: 'date',
          value: 'date',
          type: 'date',
          slotName:"date",
          placeholder: '统计日期',
          size: 'small'
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '材料名称',
          prop: 'foodsName'
        },
        {
          label: '采购金额（元）',
          prop: 'purcharsePrice'
        },
        {
          label: '单位',
          prop: 'unit'
        },
        {
          label: '采购数量',
          prop: 'purcharseNum'
        },
        {
          label: '到货数量',
          prop: 'receiveNum'
        },
        {
          label: '入库数量',
          prop: 'enterStockNum'
        },
        {
          label: '出库数量',
          prop: 'outStockNum'
        },
        {
          label: '采购金额',
          prop: 'purcharsePrice'
        },
        {
          label: '供应商',
          prop: 'supplier'
        },
      ],
      columnsChi:[
        {
          label: '菜式/套餐',
          prop: 'dishesName'
        },
        {
          label: '单位',
          prop: 'unit'
        },
        {
          label: '计划供应量',
          prop: 'planNum'
        },
        {
          label: '实际供应量',
          prop: 'goDownNum'
        },
        {
          label: '供应商',
          prop: 'supplier'
        },
      ],
      tableData: [
        {
          foodsName:"ces"
        }
      ],
      tableDataChi: [
        
      ],
      loading: false,
      total:'',
      initFileList: [],
      labelWidth: `${this.util.setFontSize(140)}px`,
    }
  },
  created() {
    
  },
  mounted() {},
  methods: {
    getInit: function () {
      this.loading = true
      // 查询列表信息
      getCompare({params: this.filterData}).then((res) => {
        if (res.code === 200) {
          // this.tableData = res.data.dishesList
          // this.tableDataChi = res.data.foodsList
          // this.total = res.data.total
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';
.tiem_list{
  display: flex;
  li{
    width: 100px;
    text-align:center;
    color: #0083ee;
    cursor: pointer;
  }
}
/deep/ .tableBot {
  p{
    padding-top: 60px;
    font-weight: bold;
  }
  .table{
      margin: 0;
      padding: 10px 0;
  }
}

/deep/ .el-dialog {
  margin-top: 2vh !important;
}

/deep/ .el-dialog .statusPhone {
  margin-left: 20px;
}

/deep/ .el-dialog .statusPhoneTxt {
  color: $theme_color_c0c4cc;
  font-size: $theme_fontsize_12;
  display: inline-block;
  margin-left: 14px;
}

</style>
